import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {
    DriftforstyrrelseModel,
    FromStamdataDriftsforstyrrelserResponseModel,
    StamdataDriftsforstyrrelserResponseModel
} from "./stamdataDriftsforstyrrelserResponseModel";

export class StamdataDriftsforstyrrelserApi {
    private httpClientService: HttpClientService;

    private baseUrl = () => config.stamdataApiUrl + "api/driftsforstyrrelser/";

    constructor(httpClientService: HttpClientService) {
        this.httpClientService = httpClientService;
    }

    async getDriftsforstyrrelser(): Promise<Array<DriftforstyrrelseModel>> {
        const url = this.baseUrl();
        const result = await this.httpClientService.Get<ApiResponse<StamdataDriftsforstyrrelserResponseModel[]>>(url);
        return result.data.map(x => FromStamdataDriftsforstyrrelserResponseModel(x));
    }
}

export class LogbogAnsoegningModel {
    constructor(brugerId : string, brugerMaalbeskrivelseId : string) {
        this.brugerMaalbeskrivelseId = brugerMaalbeskrivelseId;
        this.brugerId = brugerId;
    }

    public brugerId: string;
    public brugerMaalbeskrivelseId: string;
}


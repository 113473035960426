import React, {createContext, ReactNode, useCallback, useContext, useReducer} from "react";
import {NotificationModel} from "../models/notificationModel";
import {NotificationCountRoleModel} from "../models/notificationCountRoleModel";

type NotifikationerContextType = {
    notifikationer: NotificationModel[];
    notifikationCountRoleModels: NotificationCountRoleModel[];
}

const NotifikationContext = createContext<{
    state: NotifikationerContextType,
    setNotifikationer: (payload: NotificationModel[]) => void,
    setNotifikationerCount: (payload: NotificationCountRoleModel[]) => void,
    setIsSeen: (notificationId: string) => void,
    setIsSeenOnAll: () => void,
    setIsSeenStatusOnIds: (notificationIds: string[], isSeen: boolean) => void;
} | undefined>(undefined);

enum NotifikationerReducerActionType {
    SetNotifikationer,
    SetCount,
    UpdateAllIsSeen,
}

const notifikationerReducer = (
    state: NotifikationerContextType,
    action: { type: NotifikationerReducerActionType, payload: Partial<NotifikationerContextType>}
): NotifikationerContextType => {
    switch(action.type) {
        case NotifikationerReducerActionType.SetNotifikationer:
            return {
                ...state,
                notifikationer: action.payload.notifikationer ?? state.notifikationer
            }
        case NotifikationerReducerActionType.SetCount:
            return {
                ...state,
                notifikationCountRoleModels: action.payload.notifikationCountRoleModels ?? state.notifikationCountRoleModels
            }
        case NotifikationerReducerActionType.UpdateAllIsSeen:
            return {
                ...state,
                notifikationer: state.notifikationer.map(x => ({ ...x, erSet: true }))
            }
    }
}

export const useNotifikationContext = () => {
    const context = useContext(NotifikationContext);
    if (!context) throw new Error("useNotifikationContext must be used in NotifikationContextProvider");

    return context;
};

type NotifikationContextProviderProps = { children?: ReactNode; }

const NotifikationContextProvider = ({ children }: NotifikationContextProviderProps) => {

    const [state, dispatch] = useReducer(notifikationerReducer, { notifikationer: [], notifikationCountRoleModels: [] });

    const setNotifikationer = useCallback((payload: NotificationModel[]) => {
        dispatch({
            type: NotifikationerReducerActionType.SetNotifikationer,
            payload: { notifikationer: payload }
        });
    }, []);

    const setNotifikationerCount = useCallback((payload: NotificationCountRoleModel[]) => {
        dispatch({
            type: NotifikationerReducerActionType.SetCount,
            payload: { notifikationCountRoleModels: payload }
        });
    }, [])

    const setIsSeen = useCallback((notificationId: string) => {
        dispatch({
            type: NotifikationerReducerActionType.SetNotifikationer,
            payload: {
                notifikationer: [...state.notifikationer.map(x => x.id === notificationId ? ({ ...x, erSet: true }) : x)]
            }
        });
    }, [state.notifikationer])

    const setIsSeenOnAll = useCallback(() => {
        dispatch({
            type: NotifikationerReducerActionType.UpdateAllIsSeen,
            payload: {}
        });
    }, []);

    const setIsSeenStatusOnIds = useCallback((notificationIds: string[], isSeen: boolean) => {
        dispatch({
            type: NotifikationerReducerActionType.SetNotifikationer,
            payload: {
                notifikationer: [...state.notifikationer.map(x => notificationIds.includes(x.id) ? ({ ...x, erSet: isSeen }) : x)]
            }
        });
    }, [state.notifikationer]);

    return (
        <NotifikationContext.Provider
            value={{
                state,
                setNotifikationer,
                setNotifikationerCount,
                setIsSeen,
                setIsSeenOnAll,
                setIsSeenStatusOnIds
            }}
        >
            {children}
        </NotifikationContext.Provider>
    );
};

export default NotifikationContextProvider;

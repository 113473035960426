import React from "react";
import {EmblaIcon} from "../../../core/components/emblaIcon/emblaIcon";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum,
    UserProfileLayoutEnum
} from "../../../core/components/userProfileAvatar/userProfileAvatar";
import {KompetenceEvalueringModel} from "../../../core/sharedmodels/evaluering/kompetenceEvalueringModel";
import {UserProfileAvatarModel} from "../../../core/components/userProfileAvatar/userProfileAvatarModel";
import {SpoergsmaalType} from "../../../core/sharedmodels/evaluering/spoergsmaalType";
import classNames from "classnames";
import {EmblaIcons} from "../../../core/emblaIcons";
import {MultiReadSvar} from "../readSvar/multiReadSvar";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {ReactComponent as GreenCircle} from "./../../../content/icons/greenCircle.svg";

export class CompletedEvalueringCardProps {
    kompetenceEvaluering: KompetenceEvalueringModel;
    showKompetenceEvalueringModalCallback: (show: boolean) => void;
}

export const getFeedbackCount = (evalueringModel: KompetenceEvalueringModel) => {
    const feedbackQuestions = evalueringModel.sektioner.flatMap(x => x.spoergsmaal).filter(x => x.isFeedback);
    const feedbackQuestionsAnswered = feedbackQuestions.filter(x => x.evalueringSvar.length > 0);
    return feedbackQuestionsAnswered.length;
}


export function CompletedKompetenceEvalueringCard({
                                                      kompetenceEvaluering,
                                                      showKompetenceEvalueringModalCallback
                                                  }: CompletedEvalueringCardProps) {
    const evalCardRenderId = "evalCardRenderId";

    //Bør også tjekke IsMulti type, men det filtreres nede i jsx-mapningen, da nogle eksisterende KV-skemaer har "feedback" spørgmål, som ikke er markeret som feedback.
    const allSectionHasOneQuestion = kompetenceEvaluering.sektioner
        .flatMap(x => x.spoergsmaal)
        .filter(s => !s.isFeedback)
        .every(s => s.antalSvar === 1 && s.spoergsmaalTypeEnum === SpoergsmaalType.Multi);

    return (
        <div className="flex-container margin-top-m margin-bottom-m" style={{cursor: "pointer"}}
             onClick={() => showKompetenceEvalueringModalCallback(true)}>
            <div className="flex-column">
                <div key={kompetenceEvaluering.laegekompetenceEvalueringId}>
                    <h4>{kompetenceEvaluering.titel}</h4>
                </div>
                <div className="d-flex margin-top-m wrap">
                    {allSectionHasOneQuestion ?
                        kompetenceEvaluering.sektioner.map(sektion => {
                                const notFeedbackMultiQuestionsInCurrentSection = sektion.spoergsmaal.filter(s => !s.isFeedback && s.spoergsmaalTypeEnum === SpoergsmaalType.Multi);
                                return <>
                                    <div className={"flex-container-row wrap"}>
                                        {notFeedbackMultiQuestionsInCurrentSection.length > 0 &&
                                            notFeedbackMultiQuestionsInCurrentSection
                                            .sortBy(s => s.sortOrder)
                                            .filter(x => x.spoergsmaalTypeEnum === SpoergsmaalType.Multi)
                                            .map(spoergsmaal => {
                                                return <div key={spoergsmaal.id}
                                                            className={classNames("flex-container-column wrap margin-bottom-m ")}>
                                                    {
                                                        spoergsmaal.evalueringSvar?.map(es =>
                                                            <MultiReadSvar evalueringSvar={es}
                                                                           excludeAnswerText
                                                                           showScoreText={false}
                                                                           renderId={evalCardRenderId}
                                                                           spoergsmaal={spoergsmaal}
                                                            />
                                                        )
                                                    }
                                                </div>
                                            })
                                        }
                                    </div>

                                </>
                            }
                        ) :
                        <div className="margin-right-m">
                            <span
                                className="badge badge-pill badge-default padding-xs"> <GreenCircle/> {Localizer.evalueringPage_kompetenceevalueringUdfyldtKlikForMereInfo()}</span>
                        </div>
                    }
                </div>
                <div className="d-flex flex-row margin-top-m wrap ">
                    <p className="subtle margin-right-m d-flex flex-center">
                        <EmblaIcon iconName="calendar"/>
                        <div>{kompetenceEvaluering.indsendtDato?.dateWithoutTimeFormat(false)}</div>
                    </p>
                    <div className="margin-right-m">
                        {kompetenceEvaluering.createdByUser &&
                            <UserProfileAvatar
                                size={UserProfileAvatarSizeEnum.extraSmall}
                                userProfileLayoutEnum={UserProfileLayoutEnum.AsTextOnRightSide}
                                userProfileAvatarModel={new UserProfileAvatarModel(kompetenceEvaluering.createdByUser?.firstName + " " + kompetenceEvaluering.createdByUser?.lastName, kompetenceEvaluering.createdByUser.initialer)}/>
                        }
                    </div>
                    {kompetenceEvaluering.sektioner.flatMap(x => x.spoergsmaal).some(x => x.isFeedback) &&
                        <div>
                            <EmblaIcon iconName={EmblaIcons.Comment}/>
                            {getFeedbackCount(kompetenceEvaluering)}
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export enum FilterItemType {
    Region = 0,
    Speciale = 1,
    Afdeling = 2,
    Hospital = 3,
    Praksis = 4,
    UddannelseType = 5,
    StartYear = 6,
    Omraade = 7,
    Period = 8,
    Date = 9,
    UddannelseTypePeriode = 10,
    UddannelseTypeYear = 11,
}


import {dateTimeStringFromApiToDate} from "../../../index";

export type DriftforstyrrelseModel = {
    id: string;
    tekst : string;
    startDato: Date;
    slutDato: Date;
}

export type StamdataDriftsforstyrrelserResponseModel = {
    id: string;
    tekst : string;
    startDato: string;
    slutDato: string;
}

export const FromStamdataDriftsforstyrrelserResponseModel = (responseModel: StamdataDriftsforstyrrelserResponseModel) => {
    return {
        id: responseModel.id,
        startDato: dateTimeStringFromApiToDate(responseModel.startDato),
        slutDato: dateTimeStringFromApiToDate(responseModel.slutDato),
        tekst: responseModel.tekst
    } as DriftforstyrrelseModel;
}

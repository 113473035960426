import {dateTimeStringFromApiToDate} from "../../../index";
import {UddannelseTypeEnum} from "../stilling/uddannelseTypeEnum";
import {StillingTypeEnum} from "../stilling/stillingTypeEnum";
import {ForloebSimpleInfoModelResponseModel} from "../../../services/api/forloeb/forloebSimpleInfoModelResponseModel";
import {FravaerskodeEnum} from "./fravaerskoderEnum";

export class ForloebSimpleInfoModel {

    public id: string;
    public brugerId: string;
    public brugerMaalbeskrivelseId: string;
    public startDate: Date;
    public endDate: Date;
    public laegeInstitutionName: string;
    public afdelingName: string;
    public uddannelseType: UddannelseTypeEnum;
    public uddannelsesName: string;
    public specialeId: string;
    public specialeName: string;
    public stillingsType: StillingTypeEnum;
    public stillingNummer: string;
    public manueltAfsluttet: boolean;
    public irrelevant: boolean;

    public godkendtDato?: Date;
    public evalueretDato?: Date;

    public skalIndgaaIEvalueringsstatistik: boolean;
    public erFravaer: boolean;
    public fravaerskodeEnum: FravaerskodeEnum;
    public harTidligereMidlertidigtFravaer: boolean;

    public static FromResponseModel(responseModel: ForloebSimpleInfoModelResponseModel): ForloebSimpleInfoModel {
        return {
            id: responseModel.id,
            brugerId: responseModel.brugerId,
            brugerMaalbeskrivelseId: responseModel.brugerMaalbeskrivelseId,
            startDate: dateTimeStringFromApiToDate(responseModel.startDate),
            endDate: dateTimeStringFromApiToDate(responseModel.endDate),
            laegeInstitutionName: responseModel.laegeInstitutionName,
            afdelingName: responseModel.afdelingName,
            uddannelseType: responseModel.uddannelseType,
            uddannelsesName: responseModel.uddannelseName,
            specialeId: responseModel.specialeId,
            specialeName: responseModel.specialeName,
            stillingsType: responseModel.stillingsType,
            stillingNummer: responseModel.stillingNummer,
            manueltAfsluttet: responseModel.manueltAfsluttet,
            godkendtDato: responseModel.godkendtDato ? new Date(responseModel.godkendtDato) : undefined,
            evalueretDato: responseModel.evalueretDato ? new Date(responseModel.evalueretDato) : undefined,
            skalIndgaaIEvalueringsstatistik: responseModel.skalIndgaaIEvalueringsstatistik,
            erFravaer: responseModel.erFravaer,
            irrelevant: responseModel.irrelevant,
            fravaerskodeEnum: responseModel.fravaerskodeEnum,
            harTidligereMidlertidigtFravaer: responseModel.harTidligereMidlertidigtFravaer,
        };
    }
}

//TODO: Move this logic (also the identical logic inside ForloebModel) to shared helper "helpers/forloebHelper.ts" (+ maybe custom effect)

export const DaysLeftOnForloeb = (model: ForloebSimpleInfoModel) =>{
    const diff = model.endDate.getTime() - new Date().getTime();
    return Math.ceil(diff / (1000 * 3600 * 24));
}

export const IsActiveForloeb = (model: ForloebSimpleInfoModel) => {
    const todayDate = new Date().getWithoutTime();
    const forloebStart = model.startDate.getWithoutTime();
    const forloebEnd = model.endDate.getWithoutTime();

    const isActive = forloebStart < todayDate && forloebEnd >= todayDate;
    return isActive;
}

export const GetUddannelsesstedInfo = ({stillingsType, laegeInstitutionName, afdelingName}: ForloebSimpleInfoModel) => {
    return GenericGetUddannelsesstedInfo(stillingsType === StillingTypeEnum.Sygehus, laegeInstitutionName, afdelingName)
}

export const GenericGetUddannelsesstedInfo = (isSygehus: boolean, laegeInstitutionName: string, afdelingName?: string) => {
    return isSygehus
        ? laegeInstitutionName + ", " + afdelingName
        : laegeInstitutionName
}

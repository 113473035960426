import {ApiQueryParams} from "../apiQueryParams";
import {NotatFilterModel} from "../../../core/componentsPage/notat/notatFilterModel";
import {NotatType} from "../../../core/sharedmodels/notat/notatType";
import {NotatSynlighedEnum} from "../../../core/sharedmodels/notat/notatSynlighedEnum";

export class NotatFilterRequestModel {
    constructor(inputModel: NotatFilterModel) {
        this.apiQueryParams = new ApiQueryParams(inputModel.paginationModel.page, inputModel.paginationModel.pageLength, inputModel.search);
        this.synligFor = inputModel.synligfor;
        this.type = inputModel.type;
        this.laegeId = inputModel.laegeId;
    }
    public apiQueryParams: ApiQueryParams;
    public synligFor? : NotatSynlighedEnum;
    public type? : NotatType;
    public laegeId? : string;
}

import React from "react";
import {Score} from "../../../core/components/score/score";
import {ScoreTypeEnum} from "../../../core/componentsPage/score/scoreTypeEnum";
import {SvarReadTypeProps} from "./svarReadTypeCompareMap";
import classNames from "classnames";

export const MultiReadSvar = ({
                                  spoergsmaal,
                                  evalueringSvar,
                                  showScoreText,
                                  excludeAnswerText = false
                              }: SvarReadTypeProps) => {
    const svarmulighed = spoergsmaal.svarmuligheder.find(s => s.id === evalueringSvar.svarmulighedId);
    return <div className={classNames("d-flex align-items-center ")}>
        {!excludeAnswerText &&
            <li className={classNames("margin-right-s padding-tbr-s")}>
                {svarmulighed?.tekst}
            </li>
        }
        {!svarmulighed?.udeladSvarvaerdi &&
            <Score
                svarmuligheder={spoergsmaal.svarmuligheder}
                userDefinedScoreColor={svarmulighed?.farvekode}
                svar={evalueringSvar}
                showText={showScoreText ?? true}
                scoreType={ScoreTypeEnum.KompetenceEvalueringScore}
            />
        }
    </div>
};

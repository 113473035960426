import {LogbogLaegekompetenceResponseModel} from "../../../services/api/logbogLaegekompetence/logbogLaegekompetenceResponseModel";

export class Laegekompetence {

    static FromResponseModel(responseModel: LogbogLaegekompetenceResponseModel): Laegekompetence{
        const model = new Laegekompetence;
        model.brugerId = responseModel.brugerId;
        model.kompetenceIds = responseModel.kompetencerIds;

        return model;
    }

    brugerId: string;
    kompetenceIds: string[];
}

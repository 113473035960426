
export class FileDownloadHandler {

    public DownloadFile(blobFile: Blob, filename: string): void {
        var url = window.URL || window.webkitURL;
        let link = url.createObjectURL(blobFile);

        var a = $("<a />");
        a.attr("download", filename);
        a.attr("href", link);
        a.attr("style", "display: none;");
        $("body").append(a);
        a[0].click();
        a.remove();

        window.URL.revokeObjectURL(link);
    }
}
import {LogbogUddannelsesplanResponseModel} from "../../../services/api/logbogUddannelsesplan/logbogUddannelsesplanResponseModel";
import {CrudUddannelsesplanInputModel} from "./components/crudUddannelsesplanInputModel";
import {dateTimeStringFromApiToDate} from "../../../index";

export class UddannelsesplanModel {

    public static FromResponseModel(responseModel : LogbogUddannelsesplanResponseModel) : UddannelsesplanModel {
        const model = new UddannelsesplanModel();

        model.id = responseModel.id;
        model.forloebId = responseModel.forloebId;
        model.titel = responseModel.titel;
        model.beskrivelse = responseModel.beskrivelse;
        model.oprettelsesDato = dateTimeStringFromApiToDate(responseModel.oprettelsesDato);
        model.godkendelseTilkendegivetDato = responseModel.godkendelseTilkendegivetDato ? dateTimeStringFromApiToDate(responseModel.godkendelseTilkendegivetDato) : undefined;
        model.godkendelseTilkendegivetAf = responseModel.godkendelseTilkendegivetAf ? responseModel.godkendelseTilkendegivetAf : undefined;
        model.klarmeldtAt = responseModel.klarmeldtAt;
        model.createdByName = responseModel.createdByName;
        return model;
    }

    public static FromEditUddannelsesplanInputModel(responseModel : CrudUddannelsesplanInputModel) : UddannelsesplanModel {
        const model = new UddannelsesplanModel();
        model.id = responseModel.id;
        model.forloebId = responseModel.forloebId;
        model.titel = responseModel.titel;
        model.beskrivelse = responseModel.beskrivelse;
        model.oprettelsesDato =  new Date(responseModel.oprettelsesDato);
        model.klarmeldtAt = responseModel.klarmeldtAt;
        return model;
    }

    public id: string;
    public forloebId: string;
    public titel: string;
    public beskrivelse: string;
    public oprettelsesDato: Date;
    public godkendelseTilkendegivetDato?: Date;
    public godkendelseTilkendegivetAf?: string;
    public klarmeldtAt?: string;
    public createdByName: string;
}

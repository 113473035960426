import {Spinner, SpinnerProps} from "../spinner/spinner";
import React, {useEffect, useRef, useState} from "react";
import useEffectAsync from "../../../infrastructure/effect";

export type LoadingProps = {
    isLoading: boolean;
    text?: string;
    emptyText?: string;
    spinnerClasses?: string;
    useAsHidden?: boolean;
    timeout?: number;
    extraLoadingInfo?: string;
    /** Hide content when "isLoading" is true (e.g. always before showing spinner) */
    hideContentOnPreload?: boolean;
} & Pick<SpinnerProps, "spinnerSize">;

export function Loading({
    spinnerSize = "small",
    hideContentOnPreload = true,
    ...props
}: React.PropsWithChildren<LoadingProps>) {
    const [showSpinner, setShowSpinner] = useState(false)
    let isMounted = useRef(true);

    useEffectAsync(() => {
        setTimeout(() => {

                if (!props.isLoading){
                    if (isMounted.current)
                    {
                    setShowSpinner(false);
                    }
                }
                else {
                    if (isMounted.current) {
                        setShowSpinner(true);
                    }
                }
        },props.timeout ?? 1000);
    }, [props.isLoading])

    useEffect(() => {
        return () => { isMounted.current = false };
    }, [])

    const showLoading = props.isLoading && showSpinner;
    const showContent = hideContentOnPreload ? !props.isLoading : !showLoading;

    return (
        <>
            {showLoading &&
              <Spinner 
                spinnerSize={spinnerSize} 
                text={props.text} 
                additionalLoadingText={props.extraLoadingInfo} 
                additionalClasses={props.spinnerClasses}
            />
            }

            {props.useAsHidden &&
                <div className={(showContent && !props.emptyText) ? "" : "hidden"}>
                    {props.children}
                </div>
            }

            {showContent && !props.useAsHidden && !props.emptyText &&
                props.children
            }

            {showContent && props.emptyText &&
                <div className={`${ props.spinnerClasses }`}>{props.emptyText}</div>
            }
        </>
    );
}

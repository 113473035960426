import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {AccountService} from "../../account/accountService";
import {AfdelingModel} from "../../../core/sharedmodels/afdeling/afdelingModel";
import {StamdataAfdelingResponseModel} from "./stamdataAfdelingResponseModel";
import {ForSelectModel} from "../../../core/sharedmodels/forSelectModel";
import {ForSelectResponseModel} from "../forSelectResponseModel";
import {UddannelsesansvaligAfdelingResponseModel} from "./stamdataUddannelsesansvarligAfdelingResponseModel";
import {
    UddannelsesstederModel
} from "../../../pages/uddannelseslaegeOverview/components/uddannelsesstederModel";

export class StamdataAfdelingApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = () => config.stamdataApiUrl + "api/afdeling/";
    public getBySygehusUrl = () => this.baseUrl() + "getbysygehus/";
    public uaoAfdelingerUrl = () => this.baseUrl() + "uddannelsesansvarligDetails/"
    
    private allAfdelingerSelectUrl = this.baseUrl() + "AfdelingerForSelect/all";

    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    async getAfdelingerBySygehusId(sygehusId: string): Promise<AfdelingModel[]> {
        const url = this.getBySygehusUrl() + sygehusId;
        const result = await this.httpClientService.Get<ApiResponse<StamdataAfdelingResponseModel[]>>(url);

        if (result.data === undefined || result.data === null)
            return [];

        return result.data.map(afdeling => {
            return AfdelingModel.FromResponseModel(afdeling)
        });
    }

    async getAfdelingerSelect(): Promise<ForSelectModel[]> {
        const url = this.baseUrl() + "AfdelingerForSelect";
        const result = await this.httpClientService.Get<ApiResponse<ForSelectResponseModel[]>>(url);
        return result.data.map(x => new ForSelectModel(x));
    }

    async getAllAfdelingerSelect(): Promise<ForSelectModel[]> {
        const url = this.allAfdelingerSelectUrl;
        const result = await this.httpClientService.Get<ApiResponse<ForSelectResponseModel[]>>(url);
        return result.data.map(x => new ForSelectModel(x));
    }

    async GetUddannelsesansvarligAfdelinger(): Promise<UddannelsesstederModel[]> {
        const result = (await this.httpClientService.Get<ApiResponse<UddannelsesansvaligAfdelingResponseModel[]>>(this.uaoAfdelingerUrl())).data;
        return result.map(x => new UddannelsesstederModel(x))
    }

}

import {
    StillingForloebOverviewResponseModel
} from "../../../services/api/forloeb/laegeinstitutionWithStillingerResponseModel";
import {LaegeStillingModel} from "./laegeStillingModel";

export class StillingForloebOverviewModel {

    public static FromResponseModel(responseModel: StillingForloebOverviewResponseModel): StillingForloebOverviewModel {
        const model = new StillingForloebOverviewModel();
        model.stillingId = responseModel.stillingId;
        model.stillingNummer = responseModel.stillingNummer;
        model.laegeStillinger = responseModel.laegerStillinger.map(l => LaegeStillingModel.FromResponseModel(l));
        return model;
    }

    stillingId: string;
    stillingNummer: string;
    laegeStillinger: LaegeStillingModel[];
}

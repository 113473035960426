import {SeAlleKompetencerInputModel} from "../../../core/componentsPage/forloeb/SeAlleKompetencerInputModel";

export class SeAlleKompetencerPostModel {
    constructor(inputModel: SeAlleKompetencerInputModel) {
        this.id = inputModel.id;
        this.seAlleKompetencer = inputModel.seAlleKompetencer;
    }

    public id: string;
    public seAlleKompetencer: boolean;
}

export class UddannelsesmaalbeskrivelseRequestModel {
    constructor(brugerId : string, uddannelseId : string, brugerMaalbeskrivelseId?: string) {
        this.brugerId = brugerId;
        this.stillingId = uddannelseId;
        this.brugerMaalbeskrivelseId = brugerMaalbeskrivelseId;
    }

    public brugerId: string;
    public stillingId: string;
    public brugerMaalbeskrivelseId?: string;
}

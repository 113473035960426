import { UddannelseTypeEnum } from "core/sharedmodels/stilling/uddannelseTypeEnum";

export class stamdataCanSendStpsDocumentationRequestModel {
    constructor(brugerId: string, brugerMaalbeskrivelseId: string, uddanelseType: UddannelseTypeEnum) {
        this.brugerId = brugerId;
        this.brugerMaalbeskrivelseId = brugerMaalbeskrivelseId;
        this.uddannelseType = uddanelseType;
    }

    brugerId: string;
    brugerMaalbeskrivelseId: string;
    uddannelseType: UddannelseTypeEnum;
}
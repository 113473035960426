import {HttpClientService} from "../../httpClient/httpClientService";
import config from "../../../config/config";
import {ApiResponse} from "../apiResponse";
import { AccountService } from "services/account/accountService";
import { FromStamdataBrugerIndstillingResponseModel, StamdataBrugerIndstillingResponseModel } from "./stamdataBrugerIndstillingResponseModel";
import { BrugerIndstillingModel } from "core/sharedmodels/brugerIndstilling/brugerIndstillingModel";
import { BrugerIndstillingTypeKeyEnum } from "core/sharedmodels/brugerIndstilling/brugerIndstillingTypeKeyEnum";
import { StamdataBrugerIndstillingPostModel } from "./stamdataBrugerIndstillingPostModel";

export class StamdataBrugerIndstillingApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = () => `${config.stamdataApiUrl}api/brugerindstilling`;
    private getIndstillingerUrl = `${this.baseUrl()}/`;
    private setIndstillingUrl = (typeKeyEnum: BrugerIndstillingTypeKeyEnum) => `${this.baseUrl()}/${typeKeyEnum}`;

    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    async GetBrugerIndstillinger(): Promise<BrugerIndstillingModel[]> {
        const url = this.getIndstillingerUrl;
        const result = await this.httpClientService.Get<ApiResponse<StamdataBrugerIndstillingResponseModel[]>>(url);
        
        return result.data.map(x => FromStamdataBrugerIndstillingResponseModel(x));
    }

    async SetBrugerIndstilling(typeKey: BrugerIndstillingTypeKeyEnum, value: string): Promise<boolean> {
        const url = this.setIndstillingUrl(typeKey);
        
        const body: StamdataBrugerIndstillingPostModel = { IndstillingValue: value };
        const result = await this.httpClientService.Post<ApiResponse<boolean>, StamdataBrugerIndstillingPostModel>(url, body);
        
        return result.data;
    }
}

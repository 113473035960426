import { Localizer } from "infrastructure/localization/localizer";

export enum RolleTitelEnum {
    Systemadministrator = 1,
    Administrator = 2,
    Uddannelseslaege = 3,
    Vejleder = 4,
    Tutorlaege = 5,
    Uao = 6,
    Cheflaege = 7,
    LedendeOverlaege = 8,
    VusMedarbejder = 9,
    HrMedarbejder = 10,
    Sekretaer = 11,
    Hospitalsledelse = 12,
    Uko = 13,
    Pkl = 14,
    Lektor = 15,
    Amu = 16,
    Puk = 17,
    Dynamu = 18,
    Ukyl = 19,
    Hovedkursusleder = 20,
    Delkursusleder = 21,
    SekretaerForHovedkursusleder = 22,
    Kursusudbyder = 23,
    Forskningstraeningsvejleder = 24
}

export const AdminOrVusRoles = [RolleTitelEnum.Systemadministrator, RolleTitelEnum.Administrator, RolleTitelEnum.VusMedarbejder];

export const StringToUserRoleTitle = (userRoleTitle: string): RolleTitelEnum => {
    switch (userRoleTitle) {
        case "Systemadministrator":
            return RolleTitelEnum.Systemadministrator;
        case "Administrator":
            return RolleTitelEnum.Administrator;
        case "Uddannelseslaege":
            return RolleTitelEnum.Uddannelseslaege;
        case "Vejleder":
            return RolleTitelEnum.Vejleder;
        case "Tutorlaege":
            return RolleTitelEnum.Tutorlaege;
        case "Uao":
            return RolleTitelEnum.Uao;
        case "Cheflaege":
            return RolleTitelEnum.Cheflaege;
        case "LedendeOverlaege":
            return RolleTitelEnum.LedendeOverlaege;
        case "VusMedarbejder":
            return RolleTitelEnum.VusMedarbejder;
        case "HrMedarbejder":
            return RolleTitelEnum.HrMedarbejder;
        case "Sekretaer":
            return RolleTitelEnum.Sekretaer;
        case "Hospitalsledelse":
            return RolleTitelEnum.Hospitalsledelse;
        case "Uko":
            return RolleTitelEnum.Uko;
        case "Pkl":
            return RolleTitelEnum.Pkl;
        case "Lektor":
            return RolleTitelEnum.Lektor;
        case "Amu":
            return RolleTitelEnum.Amu;
        case "Puk":
            return RolleTitelEnum.Puk;
        case "Dynamu":
            return RolleTitelEnum.Dynamu;
        case "Ukyl":
            return RolleTitelEnum.Ukyl;
        case "Hovedkursusleder":
            return RolleTitelEnum.Hovedkursusleder;
        case "Delkursusleder":
            return RolleTitelEnum.Delkursusleder;
        case "SekretaerForHovedkursusleder":
            return RolleTitelEnum.SekretaerForHovedkursusleder;
        case "Kursusudbyder":
            return RolleTitelEnum.Kursusudbyder;
        case "Forskningstraeningsvejleder":
            return RolleTitelEnum.Forskningstraeningsvejleder;
        default:
            return RolleTitelEnum.Uddannelseslaege;
    }
}

export const UserRoleTitleToString = (userRoleTitle: RolleTitelEnum): string => {
    switch (userRoleTitle) {
        case RolleTitelEnum.Systemadministrator:
            return Localizer.global_systemAdmin();
        case RolleTitelEnum.Administrator:
            return Localizer.global_admin();
        case RolleTitelEnum.Uddannelseslaege:
            return Localizer.global_uddannelseslaege();
        case RolleTitelEnum.Vejleder:
            return Localizer.global_vejleder();
        case RolleTitelEnum.Tutorlaege:
            return Localizer.global_tutorlaege();
        case RolleTitelEnum.Uao:
            return Localizer.global_Uao();
        case RolleTitelEnum.Cheflaege:
            return Localizer.global_chefLaege();
        case RolleTitelEnum.LedendeOverlaege:
            return Localizer.global_ledendeOverlaege();
        case RolleTitelEnum.VusMedarbejder:
            return Localizer.global_vusMedarbejder();
        case RolleTitelEnum.HrMedarbejder:
            return Localizer.global_hrMedarbejder();
        case RolleTitelEnum.Sekretaer:
            return Localizer.global_sekretaer();
        case RolleTitelEnum.Hospitalsledelse:
            return Localizer.global_hospitalLedelse();
        case RolleTitelEnum.Uko:
            return Localizer.global_Uko();
        case RolleTitelEnum.Pkl:
            return Localizer.global_Pkl();
        case RolleTitelEnum.Lektor:
            return Localizer.global_lektor();
        case RolleTitelEnum.Amu:
            return Localizer.global_Amu();
        case RolleTitelEnum.Puk:
            return Localizer.global_Puk();
        case RolleTitelEnum.Dynamu:
            return Localizer.global_dynamu();
        case RolleTitelEnum.Ukyl:
            return Localizer.global_Ukyl();
        case RolleTitelEnum.Hovedkursusleder:
            return Localizer.global_hovedKursusLeder();
        case RolleTitelEnum.Delkursusleder:
            return Localizer.global_delKursusLeder();
        case RolleTitelEnum.SekretaerForHovedkursusleder:
            return Localizer.global_sekretaerForHovedKursusLeder();
        case RolleTitelEnum.Kursusudbyder:
            return Localizer.global_kursusudbyder();
        case RolleTitelEnum.Forskningstraeningsvejleder:
            return Localizer.global_forskningstraeningsvejleder();
        default:
            return RolleTitelEnum.Uddannelseslaege.toString();
    }
}

export class EditUserPhoneModel {
    constructor(userId: string, phoneNumber: string) {
        this.userId = userId;
        this.phoneNumber = phoneNumber;
    }

    userId: string;
    phoneNumber: string;
}


import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {AccountService} from "../../account/accountService";
import {ForSelectModel} from "../../../core/sharedmodels/forSelectModel";
import {ForSelectResponseModel} from "../forSelectResponseModel";

export class StamdataKbuRundeApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = config.stamdataApiUrl + "api/forloeb/";

    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    async getKbuPeriodSpan(): Promise<number[]> {
        const url = this.baseUrl + "kbuperiodyearspan";
        const result = await this.httpClientService.Get<ApiResponse<number[]>>(url);
        return result.data;
    }

}

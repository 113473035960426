import { UserModel } from "services/account/userModel";
import { AdminMenu } from "./userRoleMenus/adminMenu";
import { ForskningstraeningsvejlederMenu } from "./userRoleMenus/forskningstraeningsvejlederMenu";
import { HrMedarbejderMenu } from "./userRoleMenus/hrMedarbejderMenu";
import { KonsulentMenu } from "./userRoleMenus/konsulentMenu";
import { KursuslederMenu } from "./userRoleMenus/kursuslederMenu";
import { LedelseMenu } from "./userRoleMenus/ledelseMenu";
import { SekretaerMenu } from "./userRoleMenus/sekretaerMenu";
import { UddannelsesLaegeMenu } from "./userRoleMenus/uddannelsesLaegeMenu";
import { UddannelsesansvarligMenu } from "./userRoleMenus/uddannelsesansvarligMenu";
import { VejlederMenu } from "./userRoleMenus/vejlederMenu";
import { VusMenu } from "./userRoleMenus/vusMenu";
import { useNyhederMenuLink } from "./hooks/useNyhederMenuLink";
import MenuProps from "./userRoleMenus/menuProps";
import { KursusudbyderMenu } from "./userRoleMenus/kursusudbyderMenu";

type RoleDynamicMenuProps = {
    user: UserModel;
    wrapperDivClass?: string;
    showNotification: boolean;
}

export const RoleDynamicMenu = ({user, wrapperDivClass, showNotification}: RoleDynamicMenuProps) => {

    const nyhederMenuLink = useNyhederMenuLink();

    const menuLinkProps: MenuProps = {
        showNotification,
        nyhederMenuLink
    };

    return (
        <div className={`${wrapperDivClass ?? ""}`}>
            {user.IsAdmin() &&
                <AdminMenu {...menuLinkProps} />
            }

            {user.IsLedelse() &&
                <LedelseMenu {...menuLinkProps} />
            }

            {user.IsVusMedarbejeder() &&
                <VusMenu {...menuLinkProps} />
            }

            {user.IsHrMedarbejder() &&
                <HrMedarbejderMenu {...menuLinkProps} />
            }

            {user.IsKursusleder() &&
                <KursuslederMenu {...menuLinkProps} />
            }
            
            {user.IsKursusudbyder() && 
                <KursusudbyderMenu {...menuLinkProps} />
            }

            {user.IsSekretaer() &&
                <SekretaerMenu {...menuLinkProps} />
            }

            {user.IsUddannelsesansvarlig() &&
                <UddannelsesansvarligMenu {...menuLinkProps} />
            }

            {user.IsVejleder() &&
                <VejlederMenu {...menuLinkProps} />
            }

            {user.IsForskningstraeningsvejleder() &&
                <ForskningstraeningsvejlederMenu {...menuLinkProps} />
            }

            {user.IsKonsulentForLaegeligVidereuddannelse() &&
                <KonsulentMenu {...menuLinkProps} />
            }

            {user.IsLaege() &&
                <UddannelsesLaegeMenu uddannelseslaegeId={user.UserId} showNotifications={showNotification}/>
            }
        </div>
    );
}

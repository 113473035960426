import { NotifikationEventTypeEnum } from "core/components/notifications/models/notificationModel";
import { NotifikationIndstillingModel } from "core/components/notifications/notificationSettings/models/notifikationIndstillingModel";

export type LogbogNotifikationIndstillingResponseModel = {
  eventType: NotifikationEventTypeEnum;
  visNotifikation: boolean;
  sendEmail: boolean;
  showEmail: boolean;
  description: string;
}

export const FromNotifikationIndstillingResponseModel = (responseModel : LogbogNotifikationIndstillingResponseModel): NotifikationIndstillingModel => {

  const model = {
    eventType: responseModel.eventType,
    visNotifikation: responseModel.visNotifikation,
    sendEmail: responseModel.sendEmail,
    shouldShowEmailIndstilling: responseModel.showEmail,
    description: responseModel.description,
  } as NotifikationIndstillingModel;

  return model;
}
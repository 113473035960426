
import React, {useEffect, useState} from "react";
import {DatasetModel} from "../datasetModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {LogbogSpoergsmaalResponseModel} from "../../../services/api/logbogEvaluering/logbogSpoergsmaalResponseModel";
import useEffectAsync from "../../../infrastructure/effect";
import {
    EvalueringStatistikForloebModel
} from "../../../services/api/evalueringStatistik/evalueringStatistikForloebModel";
import { EvalueringStatistikForloebSvarModel } from "services/api/evalueringStatistik/evalueringStatistikForloebSvarModel";
import { useAppSelector } from "app/hooks";
import { EvalueringsstatistikFiltersState, evalueringsstatistikFiltersReducer } from "../evalueringsstatistikFiltersSlice";
import { SpoergsmaalTypeEnum } from "core/sharedmodels/evaluering/spoergsmaalTypeEnum";
import { GetQuestionNumbersInGroups, SvarGruppeEnum } from "core/sharedmodels/evaluering/svarGruppeEnum";
import { EvalueringsstatistikPageConstants } from "./EvalueringsstatistikPageConstants";
import useLogbogApi from "core/hooks/useLogbogApi";
import Title from "core/components/titels/title";

type KommentarerComponentComponentProps = {
    dataset: DatasetModel;
    forloebEvaluering: EvalueringStatistikForloebModel;
    uddannelsesstedNavn: string;
    sygehusNavn: string;
}

const generelCommentsQuestionNumber = EvalueringsstatistikPageConstants.generelCommentsQuestionNumber;

const Dot = () => <div> ⋅ </div>;

export const KommentarerComponent = (props: KommentarerComponentComponentProps) => {

    const {evalueringstatistikApi} = useLogbogApi();

    const [spoergsmaal, setSpoergsmaal] = useState<LogbogSpoergsmaalResponseModel[]>([]);
    let questionsInGroups = new Set<Number>()
    const evalueringsstatistikFilterSliceState = useAppSelector(evalueringsstatistikFiltersReducer) as EvalueringsstatistikFiltersState;

    useEffectAsync(async () => {
        const forloebEvalueringModel = await evalueringstatistikApi.getForloebEvalueringSpoergsmaal();
        forloebEvalueringModel.sektioner.forEach(s => s.spoergsmaal.forEach(sp => sp.sortOrder = s.sortOrder));
        let questions = forloebEvalueringModel.sektioner.flatMap(s => s.spoergsmaal);

        let questionOrder = questions.map(q => q.id);
        props.forloebEvaluering.forloebEvalueringSvar.sort((a, b) => questionOrder.indexOf(a.spoergsmaalId) - questionOrder.indexOf(b.spoergsmaalId));

        setSpoergsmaal(questions);
    }, [])

    function hasFiltersBeenChosen() {
        return evalueringsstatistikFilterSliceState.spoergsmaalToShow.length > 0;
    }

    const isQuestionWithComment = (svar: EvalueringStatistikForloebSvarModel) => {
        const isGenerelCommentsQuestion = svar.spoergsmaalsnummer === generelCommentsQuestionNumber && svar.tekstSvar;
        return svar.kommentar || isGenerelCommentsQuestion;
    };

    function anyQuestionModelsAllowedIn() : boolean {
        if(!hasFiltersBeenChosen()) return true;

        const isEnkeltvis = evalueringsstatistikFilterSliceState.spoergsmaalsType === SpoergsmaalTypeEnum.Enkeltvis;
        const evals =  props.forloebEvaluering.forloebEvalueringSvar;

        const elementIncluded = evals.filter(svar => isQuestionWithComment(svar) && isEnkeltvis ? IsIncludedInFilter(svar.spoergsmaalsnummer) : GroupsInFilterHasQuestionNumber(svar.spoergsmaalsnummer));
        return elementIncluded.length > 0;
    }

    function GroupsInFilterHasQuestionNumber(spoergsmaalsnummer: Number) {
        const isQuestionsInGroupsEmptyOrundefined = questionsInGroups.size === 0 || questionsInGroups === undefined;
        if(isQuestionsInGroupsEmptyOrundefined) {
            const groups = evalueringsstatistikFilterSliceState.spoergsmaalToShow.map(questionNoStr => Number(questionNoStr) as SvarGruppeEnum);
            questionsInGroups = GetQuestionNumbersInGroups(groups);
        }
        return questionsInGroups.has(spoergsmaalsnummer);
    }

    function IsIncludedInFilter(spoergsmaalsnummer: number) : boolean {
        return evalueringsstatistikFilterSliceState.spoergsmaalToShow.includes(String(spoergsmaalsnummer))
    }

    function shouldRenderQuestion(svar: EvalueringStatistikForloebSvarModel) : boolean {
        if(!hasFiltersBeenChosen()) return true;

        const isEnkeltvis = evalueringsstatistikFilterSliceState.spoergsmaalsType === SpoergsmaalTypeEnum.Enkeltvis;
        return isEnkeltvis ? IsIncludedInFilter(svar.spoergsmaalsnummer) : GroupsInFilterHasQuestionNumber(svar.spoergsmaalsnummer);
    }

    const isGenerelComment = (question: EvalueringStatistikForloebSvarModel) => question.spoergsmaalsnummer === generelCommentsQuestionNumber;

    const hasMultipleInfilter = evalueringsstatistikFilterSliceState.spoergsmaalToShow.length > 1;

    const getQuestionDisplayString = (svar: EvalueringStatistikForloebSvarModel) => {
        const answer = spoergsmaal.filter(s => s.id === svar.spoergsmaalId)[0];
        const hasGeneralComment  = isGenerelComment(svar);
        const displaySubString = hasGeneralComment  ? Localizer.evalueringPage_generelComments() : answer?.sortOrder;
        return hasGeneralComment  ? displaySubString : `${displaySubString}. ${answer?.titel}`;
    }

    return (
        <div className="padding-top-s">
            <div className="col-12 margin-bottom-m">
                <div className="row">
                    <div className={"dataset-color-border"} style={{borderColor: props.dataset.datasetColor}}></div>
                    <div className="margin-left-s max-uddannelsessted-width">

                        <div>
                            <Title
                                title={props.sygehusNavn}
                                subTitle={props.uddannelsesstedNavn}
                                largeSubTitle
                                nonSubtleSubTitle
                            />
                        </div>

                        <div className="flex-container wrap align-items-center">
                            {props.forloebEvaluering.forloebStartDato && props.forloebEvaluering.forloebSlutDato &&
                                <>
                                    <div className="flex-container small">
                                        <div className="subtle">{Localizer.global_periode()}: </div>
                                        &nbsp;
                                        <div>{new Date(props.forloebEvaluering.forloebStartDato)?.dateWithoutTimeFormat(true)} - {new Date(props.forloebEvaluering.forloebSlutDato)?.dateWithoutTimeFormat(true)}</div>
                                    </div>
                                    &nbsp;
                                    <Dot/>
                                </>
                            }
                            {props.forloebEvaluering.uddannelseNavn &&
                                <>
                                    &nbsp;
                                    <div className="flex-container small">
                                        <div className="subtle">{Localizer.global_uddannelsestrin()}: </div>
                                        &nbsp;
                                        <div>{props.forloebEvaluering.uddannelseNavn}</div>
                                    </div>
                                </>
                            }
                            {props.forloebEvaluering.uddannelsesansvarligeNavn && props.forloebEvaluering.uddannelsesansvarligeNavn.length > 0 &&
                                <>
                                    &nbsp;
                                    <Dot/>
                                    &nbsp;
                                    <div className="flex-container small">
                                        <div className="subtle">{Localizer.global_uddannelsesansvarlig()}: </div>
                                        &nbsp;
                                        <div>{props.forloebEvaluering.uddannelsesansvarligeNavn.join(", ")}</div>
                                    </div>
                                </>
                            }
                            {props.forloebEvaluering.vejlederNavn &&
                                <>
                                    &nbsp;
                                    <Dot/>
                                    &nbsp;
                                    <div className="flex-container small">
                                        <div className="subtle">{Localizer.vejleder()}: </div>
                                        &nbsp;
                                        <div>{props.forloebEvaluering.vejlederNavn}</div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12">
                <div className="row">
                    <div className="dataset-color-reduced-margin" style={{
                        backgroundColor: "white",
                        width: "5px",
                    }}>
                    </div>
                    <div className="margin-left-s">
                        <div>
                            {props.forloebEvaluering.forloebEvalueringSvar && props.forloebEvaluering.forloebEvalueringSvar.length > 0 &&
                                <>
                                    {anyQuestionModelsAllowedIn()
                                        ?
                                            props.forloebEvaluering.forloebEvalueringSvar.filter(svar => isQuestionWithComment(svar)).map(svar => {
                                                return (
                                                    <div key={svar.id}>
                                                        {(spoergsmaal.length > 0 && shouldRenderQuestion(svar)) &&
                                                            <div className={"padding-bottom-m"}>
                                                                <h6>{getQuestionDisplayString(svar)}</h6>
                                                                {isGenerelComment(svar) ? svar.tekstSvar : svar.kommentar}
                                                            </div>
                                                        }
                                                    </div>
                                                    )
                                                }
                                            )
                                        :
                                        <div className={"padding-bottom-m"}>
                                            <h6>{hasMultipleInfilter ? Localizer.noCommentsMatchingQuestions() : Localizer.noCommentsMatchingQuestion()}</h6>
                                        </div>
                                    }
                                </>}
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

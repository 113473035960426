import * as Sentry from "@sentry/react";

const errorTypes = {
  GenericUnhandledRejectionError: "generic-undhandled-rejection-error",
  GenericError: "generic-error",
  GenericDidCatchError: "generic-did-catch-error",
  LoginError: "login-error",
} as const;

type SentryErrorType = typeof errorTypes[keyof typeof errorTypes]

/**
 * Sends an error-log to sentry with a specified type-tag.
 * @param exception exception to send to sentry (e.g. Error or custom Error)
 * @param errorType the type of error
 * @returns sentryEventId (string)
 */
export const captureSentryException = (exception: any, errorType: SentryErrorType): string => {
  return Sentry.captureException(exception, {tags: { "type": errorType }})
};

export class ForloebOverviewConstants {
    public static years = [new Date().getUTCFullYear()-2, new Date().getUTCFullYear()-1, new Date().getUTCFullYear(), new Date().getUTCFullYear()+1, new Date().getUTCFullYear()+2, new Date().getUTCFullYear()+3, new Date().getUTCFullYear()+4]; //Will be overwriten..
    public static months = ["Jan", "Feb", "Mar", "Apr", "Maj", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"];
    public static timelineColumnStart = 2;
    public static laegeRowStart = 3;
    public static defaultPageSize = 25;
    public static defaultPageNumber = 1;
    public static pageSizeSelectOptions = [25, 50, 100]
    public static forloebChartHeaderSelector = ".forloeb-chart-header";
    public static uddannelsesstedHorizontalLinesBackgroundColor = "#D6DFF5";
    public static afdelingHorizontalLinesBackgroundColor = "#eaeffa";
    public static defaultBackgroundColor = "#FFFFFF";
}

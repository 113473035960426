import { HTMLAttributes, PropsWithChildren } from "react";
import { asPixelString } from "./OverviewComponent/StyleVariables";

export const defaultMonthColPixelWidth = 50;

const ForloebOverviewcontainer = ({children, ...props} : PropsWithChildren<HTMLAttributes<HTMLDivElement>>) => {

    const style = {
        "--month-column-width": asPixelString(defaultMonthColPixelWidth),
    } as React.CSSProperties;

    return (
        <div className={`card-body padding-0 forloeb-container`} style={style} {...props}>
            {children}
        </div>
    );
}

export default ForloebOverviewcontainer;

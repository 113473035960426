import config from "config/config";
import {HttpClientService} from "services/httpClient/httpClientService";
import {ApiResponse} from "../apiResponse";
import {EvalueringStatistikModel, EvalueringStatistikResponseModel} from "./evalueringStatistikReponseModel";
import {
    EvalueringsstatistikEnkelteMedKommentarerRequestModel,
    EvalueringsstatistikEnkelteRequestModel,
    EvalueringsstatistikRequestModel
} from "./evalueringsstatistikRequestModel";
import {ForSelectModel} from "../../../core/sharedmodels/forSelectModel";
import {ForSelectResponseModel} from "../forSelectResponseModel";
import {GetSelectOptionPostModel} from "./getSelectOptionPostModel";
import {
    EvalueringsstatistikInputModel
} from "../../../core/componentsPage/evalueringsstatistik/evalueringsstatistikInputModel";
import {StamdataForloebEvalueringModel} from "../../../core/sharedmodels/evaluering/stamdataForloebEvalueringModel";
import {StamdataEvalueringResponseModel} from "./stamdataEvalueringResponseModel";
import {
    EvalueringStatistikForloebModel,
    EvalueringStatistikForloebResponseModel
} from "./evalueringStatistikForloebModel";
import {
    EvalueringStatistikEnkeltModel,
    EvalueringStatistikEnkeltReponseModel
} from "./evalueringStatistikEnkeltReponseModel";
import {evalueringsstatistikDateRangeRequestModel} from "./evalueringsstatistikDateRangeInputModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {FileDownloadHandler} from "../logbogFile/fileDownloadHandler";


export class EvalueringStatistikApi {
    private httpClientService: HttpClientService;
    private fileDownloadHandler: FileDownloadHandler;

    private baseUrl = () => config.logbogApiUrl + "api/EvalueringStatistik/";
    private baseUrlPublic = () => config.logbogApiUrl + "api/EvalueringStatistikPublic/";
    private evalueringsstatistikker = () => this.baseUrl() + "GetEvalueringsstatistik/";
    private defaultEvalueringsstatistikker = () => this.baseUrl() + "GetDefaultEvalueringsstatistik/";
    private evalueringsstatistikkerPublic = () => this.baseUrlPublic() + "GetEvalueringsstatistik/";
    private evalueringSpoergsmaalUrl = () => this.baseUrlPublic() + "getEvalueringSpoergsmaal";
    private evalueringerUrl = () => this.baseUrl() + "GetEvalueringer/";
    private enkelteEvalueringerMedKommentarerUrl = () => this.baseUrl() + "EnkelteEvalueringerMedKommentarer/";
    private stamdataBaseUrl = () => config.stamdataApiUrl + "api/Evalueringsstatistik/";
    private regionerForSelect = () => this.stamdataBaseUrl() + "regionerForSelect";
    private sygehusForSelect = () => this.stamdataBaseUrl() + "sygehusForSelect/";
    private omraaderForSelect = () => this.stamdataBaseUrl() + "omraaderForSelect/";
    private specialerForSelect = () => this.stamdataBaseUrl() + "specialerForSelect";
    private praksisForSelect = () => this.stamdataBaseUrl() + "praksis/";
    private afdelinger = () => this.stamdataBaseUrl() + "afdelinger/";
    private antalUddannelsessteder = () => this.stamdataBaseUrl() + "GetAntalUddannelsessteder/";
    private getEvalueringSpoergerammePdfUrl = () => this.baseUrlPublic() + "DownloadSpoergerammeFile";

    constructor(httpClientService: HttpClientService, fileDownloadHandler: FileDownloadHandler) {
        this.httpClientService = httpClientService;
        this.fileDownloadHandler = fileDownloadHandler;
    }

    async getStatistik(input: EvalueringsstatistikInputModel): Promise<EvalueringStatistikModel[]> {
        const postModel = new EvalueringsstatistikRequestModel(input.startDato, input.slutDato, input.uddannelsesstedIds, input.specialer, input.uddannelsestyper, input.includeInactive);
        const result = await this.httpClientService.Post<ApiResponse<EvalueringStatistikResponseModel[]>, EvalueringsstatistikRequestModel>(this.evalueringsstatistikker(), postModel);
        return result.data.map(statistik => {
            return EvalueringStatistikModel.FromResponseModel(statistik)
        });
    }

    async getDefaultEvalueringStatistik(startDate: Date, endDate: Date): Promise<EvalueringStatistikModel[]> {
        const requestModel = new evalueringsstatistikDateRangeRequestModel(startDate, endDate);
        const result = await this.httpClientService.Post<ApiResponse<EvalueringStatistikResponseModel[]>, evalueringsstatistikDateRangeRequestModel>(this.defaultEvalueringsstatistikker(), requestModel);
        return result.data.map(statistik => {
            return EvalueringStatistikModel.FromResponseModel(statistik)
        });
    }

    async getStatistikPublic(input: EvalueringsstatistikInputModel): Promise<EvalueringStatistikModel[]> {
        const postModel = new EvalueringsstatistikRequestModel(input.startDato, input.slutDato, input.uddannelsesstedIds, input.specialer, input.uddannelsestyper, input.includeInactive);
        const result = await this.httpClientService.Post<ApiResponse<EvalueringStatistikResponseModel[]>, EvalueringsstatistikRequestModel>(this.evalueringsstatistikkerPublic(), postModel);
        return result.data.map(statistik => {
            return EvalueringStatistikModel.FromResponseModel(statistik)
        });
    }

    async getRegionerSelect(): Promise<ForSelectModel[]> {
        const result = await this.httpClientService.Get<ApiResponse<ForSelectResponseModel[]>>(this.regionerForSelect());
        return result.data.map(x => new ForSelectModel(x));
    }

    async getSpecialerSelect(): Promise<ForSelectModel[]> {
        const result = await this.httpClientService.Get<ApiResponse<ForSelectResponseModel[]>>(this.specialerForSelect());
        return result.data.map(x => new ForSelectModel(x));
    }

    async getSygehuseSelect(regionIds: string[], includeInactive?: boolean): Promise<ForSelectModel[]> {
        const postModel = new GetSelectOptionPostModel(regionIds, undefined, includeInactive);
        const result = await this.httpClientService.Post<ApiResponse<ForSelectResponseModel[]>, GetSelectOptionPostModel>(this.sygehusForSelect(), postModel);
        return result.data.map(x => new ForSelectModel(x));
    }

    async getOmraaderForSelect(regionIds: string[], includeInactive?: boolean): Promise<ForSelectModel[]> {
        const postModel = new GetSelectOptionPostModel(regionIds, undefined, includeInactive);
        const result = await this.httpClientService.Post<ApiResponse<ForSelectResponseModel[]>, GetSelectOptionPostModel>(this.omraaderForSelect(), postModel);
        return result.data.map(x => new ForSelectModel(x));
    }

    async getPraksisSelect(regionIds: string[], omraadeIds?: string[], includeInactive?: boolean): Promise<ForSelectModel[]> {
        const postModel = new GetSelectOptionPostModel(regionIds, undefined, includeInactive, omraadeIds);
        const result = await this.httpClientService.Post<ApiResponse<ForSelectResponseModel[]>, GetSelectOptionPostModel>(this.praksisForSelect(), postModel);
        return result.data.map(x => new ForSelectModel(x));
    }

    async getAfdelingerSelect(regionIds: string[], sygehusIds: string[], includeInactive?: boolean): Promise<ForSelectModel[]> {
        const postModel = new GetSelectOptionPostModel(regionIds, sygehusIds, includeInactive);
        const result = await this.httpClientService.Post<ApiResponse<ForSelectResponseModel[]>, GetSelectOptionPostModel>(this.afdelinger(), postModel);
        return result.data.map(x => new ForSelectModel(x));
    }

    async getForloebEvalueringSpoergsmaal(){
        const url = this.evalueringSpoergsmaalUrl();
        const result = await this.httpClientService.Get<ApiResponse<StamdataEvalueringResponseModel>>(url);
        return StamdataForloebEvalueringModel.FromResponseModel(result.data);
    }

    async getUddannelsesstederMedEnkelteEvalueringer(input: EvalueringsstatistikInputModel, uddannelsesstederIds: string[]): Promise<EvalueringStatistikEnkeltModel[]> {
        const postModel = new EvalueringsstatistikEnkelteRequestModel(input.startDato, input.slutDato, input.specialer, input.uddannelsestyper, uddannelsesstederIds);
        const result = await this.httpClientService.Post<ApiResponse<EvalueringStatistikEnkeltReponseModel[]>, EvalueringsstatistikEnkelteRequestModel>(this.evalueringerUrl(), postModel);
        return result.data.map(statistik => {
            return EvalueringStatistikEnkeltModel.FromResponseModel(statistik)
        });
    }

    async getEvalueringerMedKommentarer(input: EvalueringsstatistikInputModel, evalueringerIds: string[]): Promise<EvalueringStatistikForloebModel[]> {
        const postModel = new EvalueringsstatistikEnkelteMedKommentarerRequestModel(input.startDato, input.slutDato, input.specialer, input.uddannelsestyper, evalueringerIds);
        const result = await this.httpClientService.Post<ApiResponse<EvalueringStatistikForloebResponseModel[]>, EvalueringsstatistikEnkelteRequestModel>(this.enkelteEvalueringerMedKommentarerUrl(), postModel);
        return result.data.map(statistik => {
            return EvalueringStatistikForloebModel.FromResponseModel(statistik)
        });
    }

    async getAntalUddannelsessteder(): Promise<number> {
        const url = this.antalUddannelsessteder();
        const result = await this.httpClientService.Get<ApiResponse<number>>(url);
        return result.data;
    }

    async downloadPublicSpoergeRamme(): Promise<void> {
        const apiUrl = this.getEvalueringSpoergerammePdfUrl();
        let blob = await this.httpClientService.DownloadSpoergerammeFile(apiUrl);

        //Hack to force browser to show AJAX blob file as downloaded file
        this.fileDownloadHandler.DownloadFile(blob, Localizer.spoergerammeModal_spoergeramme());
    }
}

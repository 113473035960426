import {
    CrudUddannelsesstedInputModel
} from "../../../pages/vejledere/components/crudUddannelsesstedInputModel";
import {RootTypeEnum} from "../../../core/sharedmodels/laegeInstitution/rootTypeEnum";


export class StamdataVejlederAfdelingPostModel {
    constructor(inputModel : CrudUddannelsesstedInputModel) {
        this.startDatePlain = inputModel.startDato ? inputModel.startDato.dateToApiPostFormat() : "";
        this.endDatePlain = inputModel.slutDato ? inputModel.slutDato.dateToApiPostFormat() : "";
        this.laegeinstitutionRootType = inputModel.laegeinstitutionstype ?? null;
        this.uddannelsesstedId = inputModel.laegeinstitutionstype === RootTypeEnum.Sygehus
            ? inputModel.afdelingId
            : inputModel.praksisId;
        this.laegerIds = inputModel.alleLaeger ? [] : inputModel.laeger;

        this.alleLaeger = inputModel.alleLaeger;
        this.alleKbuLaeger = inputModel.alleKbuLaeger;
        this.alleIntroduktionsLaeger = inputModel.alleIntroduktionsLaeger;
        this.alleHoveduddannelsesLaeger = inputModel.alleHoveduddannelsesLaeger;
    }

    public startDatePlain: string;
    public endDatePlain: string;
    public alleLaeger : boolean;
    public laegeinstitutionRootType : RootTypeEnum | null;
    public uddannelsesstedId : string;
    public laegerIds: string[];
    public alleKbuLaeger : boolean;
    public alleIntroduktionsLaeger : boolean;
    public alleHoveduddannelsesLaeger : boolean;
}

import {RoutePaths} from "../../infrastructure/routes";
import {showTitlebar} from "./titleBar/titlebarSlice";
import {useMediaQuery} from "react-responsive";
import {useAppDispatch} from "../../app/hooks";
import useScreenResolution from "core/hooks/useScreenResolution";

export const DispatchShowTitlebar = (currentRoutePath : string) => {
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const dispatch = useAppDispatch();

    let shouldShowTitlebar: boolean;

    if (IsMobile){
        shouldShowTitlebar = currentRoutePath === RoutePaths.Vejledning().path
            || currentRoutePath === RoutePaths.Evalueringsstatistik.path
            || currentRoutePath === RoutePaths.CreateEvalueringsstatistik.path
            || currentRoutePath === RoutePaths.Nyheder.path;
    }

    else if (currentRoutePath === RoutePaths.Brugere.path) {
        shouldShowTitlebar = false;
    }
    else if (currentRoutePath === RoutePaths.Forloeb().path) {
        shouldShowTitlebar = false;
    }
    else if(currentRoutePath === RoutePaths.UddannelseslaegeOverview.path) {
        shouldShowTitlebar = false;
    }
    else if(currentRoutePath === RoutePaths.KursisterOverview.path) {
        shouldShowTitlebar = false;
    }
    else if(currentRoutePath === RoutePaths.Kompetencer().path) {
        shouldShowTitlebar = false;
    }
    else if (currentRoutePath === RoutePaths.forloebOverview.path) {
        shouldShowTitlebar = false;
    }
    else if (currentRoutePath === RoutePaths.Notater().path)
    {
        shouldShowTitlebar = false;
    }
    else if (currentRoutePath === RoutePaths.Kurser().path)
    {
        shouldShowTitlebar = false;
    }
    else if (currentRoutePath === RoutePaths.Vejledning().path)
    {
        shouldShowTitlebar = false;
    }
    else if (currentRoutePath === RoutePaths.Uddannelseslaege().path){
        shouldShowTitlebar = false;
    }
    else if (currentRoutePath === RoutePaths.Vejledere.path) {
        shouldShowTitlebar = false;
    }
    else if (currentRoutePath === RoutePaths.FileAdministration.path) {
        shouldShowTitlebar = false;
    }
    else if (currentRoutePath === RoutePaths.Files().path) {
        shouldShowTitlebar = false;
    }
    // Handle NotFoundPage:
    else if (currentRoutePath === "*") {
        shouldShowTitlebar = false;
    }
    else {
        shouldShowTitlebar = true;
    }


    dispatch(showTitlebar(shouldShowTitlebar));
    return shouldShowTitlebar;
}

export const ShouldBeFullscreen = (currentRoutePath : string) : boolean => {
    const { isMobile } = useScreenResolution();

    if (isMobile){
        return true;
    }

    return currentRoutePath === RoutePaths.forloebOverview.path;


}

export const mobileMaxWidthQuery = {query: 'screen and (((max-width: 767px) and (orientation:portrait)) or ((max-width: 1281px) and (orientation:landscape)))'}

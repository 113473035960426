
import "./notificationSettingsSlideIn.scss";
import { createPortal } from "react-dom";
import { SlideIn } from "core/components/slideIn/slideIn";
import { Localizer } from "infrastructure/localization/localizer";
import { ModalSize } from "core/components/slideIn/modalSize";
import useScreenResolution from "core/hooks/useScreenResolution";
import NotificationSettingsOverview from "./notificationSettingsOverview";

const notificationSettingsModalTargetId = "notification-settings-overview-modal";

type NotificationSettingsSlideInProps = {
  showSlideIn: boolean;
  onCloseSlideInCallback: () => void;
}

const NotificationSettingsSlideIn = (props: NotificationSettingsSlideInProps) => {

  const { isMobile } = useScreenResolution();

  return (
    <>
      {props.showSlideIn &&
        createPortal(
          <SlideIn 
            id={notificationSettingsModalTargetId}
            modalSize={ModalSize.md}
            defaultOpen={true}
            title={Localizer.notifikationer_NotifikationsIndstillinger()}
            bodyContent={<NotificationSettingsOverview />}
            actionOnCloseCallback={props.onCloseSlideInCallback}
            showBackArrowAsClose={isMobile}
            />,
            document.body
        )
      }
    </>
  )
}

export default NotificationSettingsSlideIn;

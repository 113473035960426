export function ObjectToQueryString(object: any) {
    return ObjectToQueryStringRecursively(object);
}

const ObjectToQueryStringRecursively = (object: any, propertyName?: string, isFirst?: boolean) => {
    let tempQuerystring = "";

    if(typeof object === "string" || typeof object === "number") {
        if(!propertyName)
            throw Error("ObjectToQueryStringRecursively called for string without propertyName (string:" + object + ")")

        return stringToQueryString(isFirst!, propertyName!, object);
    }

    for (const propertyName in object) { // for..in for objects

        if(!object.hasOwnProperty(propertyName)) {
            continue;
        }

        isFirst = setIsFirst(tempQuerystring, isFirst);

        if (typeof object[propertyName] === 'object' && object[propertyName] !== null && Array.isArray(object[propertyName]))
        {
            for (const o of object[propertyName]) { // for..of for arrays
                isFirst = setIsFirst(tempQuerystring, isFirst);
                if (isFirst) {
                    tempQuerystring = "?";
                }
                tempQuerystring += ObjectToQueryStringRecursively(o, propertyName, isFirst);
            }
        }
        else if (typeof object[propertyName] === 'object' && object[propertyName] !== null)
        {
            tempQuerystring += ObjectToQueryStringRecursively(object[propertyName], propertyName, isFirst);
        }
        else
        {
            let propertyValue = object[propertyName];
            if (propertyValue !== undefined) {

                if(isFirst)
                    tempQuerystring = "?";

                tempQuerystring += stringToQueryString(isFirst, propertyName, propertyValue);
            }
        }
    }

    return tempQuerystring;
}

const stringToQueryString = (isFirst: boolean, propertyName: string, propertyValue: string | number) => {

    let tempQueryString = isFirst ? "" : "&";

    return tempQueryString + propertyName + "=" + propertyValue;
}


const setIsFirst = (tempQueryString: string, isFirst?: boolean) : boolean => {
    if(isFirst === undefined || isFirst === null || isFirst) {
        return !tempQueryString;
    }
    return false;
}

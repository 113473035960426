import * as yup from "yup";
import {nameof} from "ts-simple-nameof";
import {BasicNotatModel} from "./basicNotatModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {NotatType} from "./notatType";
import createValidationSchema from "../../components/validation/createValidationSchema";

export class NotatCrudModel {
    id: string;
    contextIds: string[];
    brugerId: string;
    title: string;
    description: string;
    type: NotatType;
    isPrivate: boolean;
    visibleToEveryone: boolean;
    visibleForUserIds?: Array<string>;
}

export const BuildNewCrudNotatModel = (
    contextIds : string[],
    type : NotatType,
    brugerId: string,
    title? : string,
    description? : string,
    isPrivate?: boolean,
    visibleToEveryone? : boolean,
    visibleForUserIds?: string[],
) => {
    const model = new NotatCrudModel();
    model.contextIds = contextIds;
    model.type = type;
    model.brugerId = brugerId;

    model.title = title ?? "";
    model.description = description ?? "";
    model.isPrivate = isPrivate ?? false;
    model.visibleToEveryone = visibleToEveryone ?? true;
    model.visibleForUserIds = visibleForUserIds ?? [];

    return model;
}

export const BuildExistingNotatCrudModel = (
    notatModel: BasicNotatModel,
    contextIds: string[],
) => {
    const model = new NotatCrudModel();
    model.id = notatModel.id;
    model.contextIds = contextIds;
    model.brugerId = notatModel.brugerId;
    model.title = notatModel.title;
    model.description = notatModel.description;
    model.type = notatModel.type;
    model.isPrivate = notatModel.isPrivate;
    model.visibleToEveryone = notatModel.visibleToEveryone;
    model.visibleForUserIds = VisibleForUserIdsByStandAlone(notatModel.visibleForUserIds, notatModel.isPrivate, notatModel.visibleToEveryone);

    return model;
}

export const VisibleToEveryoneKey = nameof<NotatCrudModel>(x => x.visibleToEveryone);
export const PrivateNoteKey = nameof<NotatCrudModel>(x => x.isPrivate);

export const VisibleForUserIdsByStandAlone = (visibleForUserIds: string[], isPrivate: boolean, visibleToEveryone: boolean): string[] => {
    return visibleToEveryone ? [VisibleToEveryoneKey] 
            : isPrivate ? [PrivateNoteKey] 
                : visibleForUserIds
}

export const IsPrivateNote = (visibleForUserIds?: Array<string>): boolean => {
    return  visibleForUserIds != undefined ? visibleForUserIds?.some(x => PrivateNoteKey == x) : false;
}

export const IsVisibleForAllNote = (visibleForUserIds?: Array<string>): boolean => {
    return  visibleForUserIds != undefined ? visibleForUserIds?.some(x => VisibleToEveryoneKey == x) : false;
}

export const VisibleForUserIdsFilter = (visibleForUserIds?: Array<string>): Array<string> => {
    return visibleForUserIds?.filter(x => VisibleToEveryoneKey != x && PrivateNoteKey != x) ?? [];
}


export const crudNotatValidationSchema = createValidationSchema<NotatCrudModel>({
    id: yup.string().notRequired(),
    title: yup.string().notRequired(),
    description: yup.string().notRequired(),
    brugerId: yup.string().notRequired(),
    contextIds: yup.array().min(1),
    type: yup.mixed<NotatType>().required(),
    isPrivate: yup.bool().required(),
    visibleToEveryone: yup.bool().required(),
    visibleForUserIds: yup.array().min(1, Localizer.validate_visibilityRequired()),
});

import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {LaegekompetenceMeta} from "../../../core/sharedmodels/kompetence/laegekompetenceMeta";
import {LogbogLaegekompetenceMetaResponseModel} from "./logbogLaegekompetenceMetaResponseModel";
import {LogbogKlarmeldTilUserRequestModel, LogbogLaegekompetenceRequestModel} from "./logbogLaegekompetenceRequestModel";
import {LogbogLaegekompetenceResponseModel} from "./logbogLaegekompetenceResponseModel";
import {Laegekompetence} from "../../../core/sharedmodels/kompetence/laegekompetence";
import {FileDownloadHandler} from "../logbogFile/fileDownloadHandler";
import {AddKompetencerPatchModel} from "../forloeb/addKompetencerPatchModel";
import {AccountService} from "../../account/accountService";
import {
    logbogLaegekompetenceByUserAndUddannelseRequestModel
} from "./logbogLaegekompetenceByUserAndUddannelseRequestModel";
import { KlarmeldingTilBrugerModel } from "pages/klarmeldinger/klarmeldingModel";
import IdAndModelsContainer from "core/sharedmodels/containers/IdAndModelsContainer";

export class LogbogLaegekompetenceApi {
    private httpClientService: HttpClientService;
    private fileDownloadHandler: FileDownloadHandler;
    private userService: AccountService;

    private laegekompetenceBaseUrl = () => config.logbogApiUrl + "api/laegekompetence/";
    private laegekompetenceChangeStatusUrl = () => this.laegekompetenceBaseUrl() + "changeStatus";
    private laegekompetenceChangeStatusMultipleUrl = () => this.laegekompetenceBaseUrl() + "changeStatusMultiple";
    private laegekompetenceKlarmeldingerUrl = () => this.laegekompetenceBaseUrl() + "KlarmeldtWithUserinfo";
    private laegekompetenceByBrugerIdUrl = () => this.laegekompetenceBaseUrl() + "byBruger/";
    private laegekompetenceByBrugerIdForPDfUrl = () => this.laegekompetenceBaseUrl() + "byBrugerForPDf/";
    private getLaegekompetencerByBrugerAndUddannelseIdUrl = () => this.laegekompetenceBaseUrl() + "byBrugerAndUddannelse/";
    private laegekompetenceByBrugerMaalbeskrivelseIdUrl = () => this.laegekompetenceBaseUrl() + "byBrugerMaalbeskrivelse/";
    private kompetenceKlarmeldingerByUsersUrl = () => this.laegekompetenceBaseUrl() + "KlarmeldtByUserIds";
    private kompetenceByIdUrl = () => this.laegekompetenceBaseUrl() + "ById/";
    private updateKompetencerOnForloebUrl = () => this.laegekompetenceBaseUrl() + "UpdateKompetencer/";

    constructor(httpClientService: HttpClientService, fileDownloadHandler: FileDownloadHandler, userService: AccountService, ) {
        this.httpClientService = httpClientService;
        this.fileDownloadHandler = fileDownloadHandler;
        this.userService = userService;
    }

    async getLaegekompetencerByForloebId(forloebId: string): Promise<LaegekompetenceMeta[]> {
        const url = this.laegekompetenceBaseUrl() + forloebId;
        const result = await this.httpClientService.Get<ApiResponse<LogbogLaegekompetenceMetaResponseModel[]>>(url);

        return result.data.map(laegekompetence => {
            return LaegekompetenceMeta.FromResponseModel(laegekompetence)
        });
    }

    async getLaegekompetencerForPDfByBrugerId(brugerId: string): Promise<IdAndModelsContainer<LaegekompetenceMeta>[]> {
        const url = this.laegekompetenceByBrugerIdForPDfUrl() + brugerId;
        const result = await this.httpClientService.Get<ApiResponse<IdAndModelsContainer<LogbogLaegekompetenceMetaResponseModel>[]>>(url);

        return result.data.map(c => ({
            id: c.id,
            models: c.models.map(lk => LaegekompetenceMeta.FromResponseModel(lk))
        }));
    }

    async getLaegekompetencerByBrugerId(brugerId: string): Promise<LaegekompetenceMeta[]> {

        const url = this.laegekompetenceByBrugerIdUrl() + brugerId;
        
        const result = await this.httpClientService.Get<ApiResponse<LogbogLaegekompetenceMetaResponseModel[]>>(url);

        return  result.data.map(laegekompetence => {
            return LaegekompetenceMeta.FromResponseModel(laegekompetence)
        });
    }


    async getLaegekompetencerByBrugerAndUddannelseIdAndSpecialeId(brugerId: string, uddannelseId: string, specialeId: string): Promise<LaegekompetenceMeta[]> {
        const url = this.getLaegekompetencerByBrugerAndUddannelseIdUrl() ;
        const requestmodel = new logbogLaegekompetenceByUserAndUddannelseRequestModel(brugerId, uddannelseId, specialeId)
        const result = await this.httpClientService.Post<ApiResponse<LogbogLaegekompetenceMetaResponseModel[]>, logbogLaegekompetenceByUserAndUddannelseRequestModel>(url, requestmodel);
        return  result.data.map(laegekompetence => {
            return LaegekompetenceMeta.FromResponseModel(laegekompetence)
        });
    }

    async getLaegekompetenceKlarmeldingerBrugerIds(brugerIds: string[]): Promise<Laegekompetence[]> {
        const url = this.kompetenceKlarmeldingerByUsersUrl();
        const result = await this.httpClientService.Post<ApiResponse<LogbogLaegekompetenceResponseModel[]>, string[]>(url, brugerIds);
        return result.data.map(laegekompetence => {
            return Laegekompetence.FromResponseModel(laegekompetence)
        });
    }

    async getLaegekompetenceKlarmeldinger (): Promise<LaegekompetenceMeta[]> {
        const url = this.laegekompetenceKlarmeldingerUrl();
        const result = await this.httpClientService.Get<ApiResponse<LogbogLaegekompetenceMetaResponseModel[]>>(url);

        return result.data.map(laegekompetence => {
            return LaegekompetenceMeta.FromResponseModel(laegekompetence)
        });
    }

    async patchFavorite(laegekompetence: LaegekompetenceMeta): Promise<string> {
        const url = this.laegekompetenceBaseUrl();
        const user = this.userService.getUser();
        let data = LaegekompetenceMeta.ToRequestModel(laegekompetence, user.CurrentRolle, user.CurrentRolleTitel);
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogLaegekompetenceRequestModel>(url, data);

        return result.data;
    }

    async patchStatus(laegekompetence: LaegekompetenceMeta, klarmeldtTilBrugere?: KlarmeldingTilBrugerModel[], klarmeldtTilAlleMedAdgang?: boolean): Promise<string> {
        const url = this.laegekompetenceChangeStatusUrl();
        const user = this.userService.getUser();
        let data = LaegekompetenceMeta.ToRequestModel(laegekompetence, user.CurrentRolle, user.CurrentRolleTitel, user.UserId, klarmeldtTilBrugere, klarmeldtTilAlleMedAdgang);
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogLaegekompetenceRequestModel>(url, data);

        return result.data;
    }

    async patchStatusMultiple(laegekompetencer: LaegekompetenceMeta[], klarmeldtTilBrugere?: KlarmeldingTilBrugerModel[], klarmeldtTilAlleMedAdgang?: boolean): Promise<string[]> {
        const user = this.userService.getUser();
        let data = LaegekompetenceMeta.ToRequestModelList(laegekompetencer, user.CurrentRolle, user.CurrentRolleTitel, user.UserId, klarmeldtTilBrugere, klarmeldtTilAlleMedAdgang);
        const result = await this.httpClientService.Post<ApiResponse<string[]>, LogbogLaegekompetenceRequestModel[]>(this.laegekompetenceChangeStatusMultipleUrl(), data);
        return result.data;
    }

    async getLaegekompetenceById (id: string, brugerId: string): Promise<LaegekompetenceMeta> {
        const url = this.kompetenceByIdUrl() + id + "/" + brugerId;

        const result = await this.httpClientService
            .Get<ApiResponse<LogbogLaegekompetenceMetaResponseModel>>(url);

        return LaegekompetenceMeta.FromResponseModel(result.data);
    }

    async updateKompetencerOnForloeb(forloebId: string, laegeKompetenceIds: string[]): Promise<string> {
        const patchModel = new AddKompetencerPatchModel(forloebId, laegeKompetenceIds);
        const url = this.updateKompetencerOnForloebUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, AddKompetencerPatchModel>(url, patchModel);

        return result.data;
    }

    async getLaegekompetencerByBrugerMaalbeskrivelse(brugerMaalbeskrivelseId: string): Promise<LaegekompetenceMeta[]>  {
        const url = this.laegekompetenceByBrugerMaalbeskrivelseIdUrl() + brugerMaalbeskrivelseId;
        const result = await this.httpClientService.Get<ApiResponse<LogbogLaegekompetenceMetaResponseModel[]>>(url);
        return result.data.map(laegekompetence => {
            return LaegekompetenceMeta.FromResponseModel(laegekompetence)
        });
    }
}

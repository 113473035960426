import {
    UddannelsesansvaligAfdelingResponseModel
} from "../../../services/api/stamdataAfdeling/stamdataUddannelsesansvarligAfdelingResponseModel";


export class UddannelsesstederModel{

    constructor(uddannelsesansvaligAfdelingerResponseModel: UddannelsesansvaligAfdelingResponseModel) {
        this.LaegeInstitutionId = uddannelsesansvaligAfdelingerResponseModel.laegeInstitutionId;
        this.LaegeInstitutionNavn = uddannelsesansvaligAfdelingerResponseModel.laegeInstitutionNavn;
        this.AfdelingId = uddannelsesansvaligAfdelingerResponseModel.afdelingId;
        this.AfdelingNavn = uddannelsesansvaligAfdelingerResponseModel.uddannelsesstedNavn;
        this.PeriodeSlut = undefined;
    }

    public LaegeInstitutionId: string;
    public AfdelingId?: string;
    public LaegeInstitutionNavn: string;
    public AfdelingNavn?: string;
    public PeriodeSlut?: Date;

    public GetStedInfo() {
        return !!this.AfdelingId && !!this.AfdelingNavn
            ? `${this.LaegeInstitutionNavn}, ${this.AfdelingNavn}`
            : this.LaegeInstitutionNavn;
    }
}

import { DropdownOption } from "../dropdown";

/** Use in Array.filter: filters duplicate options from the array. 
 * If the option is a complex object, you can specify the "key" to find duplicates by, via "getValueKey" */
export const filterDistinctDropdownOptions = <T = unknown>(option: DropdownOption<T>, optionIndex: number, array: DropdownOption<T>[], getValueKey?: (optionValue: T) => any) => {
  const getKey = !!getValueKey ? getValueKey : (optionValue: T) => optionValue;
  const firstInstanceIndex = array.findIndex(x => getKey(x.value) === getKey(option.value));
  
  return firstInstanceIndex === optionIndex;
}

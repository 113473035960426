export class logbogLaegekompetenceByUserAndUddannelseRequestModel {
    constructor(brugerId: string, uddannelseId: string, specialeId: string) {
        this.brugerId = brugerId;
        this.uddannelseId = uddannelseId;
        this.specialeId = specialeId;
    }

    brugerId: string;
    uddannelseId: string;
    specialeId: string;
}

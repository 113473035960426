import {RolleTitelEnum} from "infrastructure/enums/rolleTitelEnum";
import {KlarmeldingResponseModel} from "../../services/api/klarmelding/klarmeldingResponseModel";

export class KlarmeldingModel {

    constructor(responseModel : KlarmeldingResponseModel) {
        this.contextId = responseModel.contextId;
        this.klarmeldBrugere = responseModel.klarmeldtTilBrugere.map(x => ({ UserId: x.brugerId, RolleTitelEnums: x.rolleTitelEnums, isFavorite: false }));
        this.klarmeldtTilAlleMedAdgang = responseModel.klarmeldtTilAlleMedAdgang;
    }

    public contextId: string;
    public klarmeldBrugere?: KlarmeldingTilBrugerModel[];
    public klarmeldtTilAlleMedAdgang: boolean;
}

export type KlarmeldingTilBrugerModel = {
    UserId: string;
    RolleTitelEnums: RolleTitelEnum[];
    isFavorite?: boolean;
}

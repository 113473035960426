import {ApiQueryParams} from "../apiQueryParams";

export class SynkroniseredeForloebRequestModel extends ApiQueryParams
{
    constructor(page: number, searchLaege?: string, specialer?: string[], regioner?: string[]) {
        super(page, 10);
        this.searchLaege = searchLaege;
        this.specialer = specialer;
        this.regioner = regioner;
    }

    public searchLaege?: string;
    public specialer?: string[];
    public regioner?: string [];
}

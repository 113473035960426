import {
    EvalueringStatistikForloebSvarModel,
    EvalueringStatistikForloebSvarResponseModel
} from "./evalueringStatistikForloebSvarModel";
import {dateTimeStringFromApiToDate} from "../../../index";

export class EvalueringStatistikForloebResponseModel {
    public id: string;
    public indsendtDato: string;
    public slutDato?: string;
    public forloebId: string;
    public uddannelseNavn: string;
    public uddannelsesstedId: string;
    public evalueringId: string;
    public forloebEvalueringSvar: EvalueringStatistikForloebSvarResponseModel[];
    public forloebStartDato?: string;
    public forloebSlutDato?: string;
    public uddannelsesansvarligeNavn?: string[];
    public vejlederNavn?: string;
    public sygehusnavn?: string;
    public omraadenavn?: string;
}

export class EvalueringStatistikForloebModel {
    public static FromResponseModel(responseModel : EvalueringStatistikForloebResponseModel) {
        let model = new EvalueringStatistikForloebModel();
        model.id = responseModel.id;
        model.uddannelsesstedId = responseModel.uddannelsesstedId;
        model.indsendtDato = dateTimeStringFromApiToDate(responseModel.indsendtDato);
        model.slutDato = responseModel.slutDato ? dateTimeStringFromApiToDate(responseModel.slutDato) : undefined;
        model.forloebId = responseModel.forloebId;
        model.uddannelseNavn = responseModel.uddannelseNavn;
        model.evalueringId = responseModel.evalueringId;
        model.forloebEvalueringSvar = responseModel.forloebEvalueringSvar;
        model.forloebStartDato = responseModel.forloebStartDato ? dateTimeStringFromApiToDate(responseModel.forloebStartDato) : undefined;
        model.forloebSlutDato = responseModel.forloebSlutDato ? dateTimeStringFromApiToDate(responseModel.forloebSlutDato) : undefined;
        model.uddannelsesansvarligeNavn = responseModel.uddannelsesansvarligeNavn;
        model.vejlederNavn = responseModel.vejlederNavn;
        model.sygehusnavn = responseModel.sygehusnavn;
        model.omraadenavn = responseModel.omraadenavn;
        return model;
    }
    public id: string;
    public indsendtDato: Date;
    public slutDato?: Date;
    public uddannelseNavn: string;
    public uddannelsesstedId: string;
    public forloebId: string;
    public evalueringId: string;
    public forloebEvalueringSvar: EvalueringStatistikForloebSvarModel[];
    public forloebStartDato?: Date;
    public forloebSlutDato?: Date;
    public uddannelsesansvarligeNavn?: string[];
    public vejlederNavn?: string;
    public sygehusnavn?: string;
    public omraadenavn?: string;
}

import {
    AfdelingMedStillingResponseModel
} from "../../../services/api/forloeb/laegeinstitutionWithStillingerResponseModel";
import {StillingForloebOverviewModel} from "./stillingForloebOverviewModel";

export class AfdelingMedStillingModel {

    public static FromResponseModel(responseModel: AfdelingMedStillingResponseModel): AfdelingMedStillingModel {
        const model = new AfdelingMedStillingModel();
        model.afdelingId = responseModel.afdelingId;
        model.laegeinstitutionId = responseModel.laegeinstitutionId;
        model.afdelingNavn = responseModel.afdelingNavn;
        model.stillinger = responseModel.stillinger.map(l => StillingForloebOverviewModel.FromResponseModel(l));
        return model;
    }

    afdelingId: string;
    laegeinstitutionId: string;
    afdelingNavn: string;
    stillinger: StillingForloebOverviewModel[];
}

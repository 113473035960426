import {CrudVejledersamtaleInputModel} from "./components/crudVejledersamtaleInputModel";
import {LogbogVejledersamtaleResponseModel} from "../../../services/api/logbogVejledersamtale/logbogVejledersamtaleResponseModel";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {dateTimeStringFromApiToDate} from "../../../index";

export enum VejledersamtaleEnum {
    Introduktion = 1,
    Justering = 2,
    Afsluttende = 3,
    Anden = 4
}

export class VejledersamtaleModel {

    public static CreatePlaceholder(forloebId: string, vejledersamtaleType: VejledersamtaleEnum, placeholderText: string) {
        const model = new VejledersamtaleModel();
        model.forloebId = forloebId;
        model.titel = this.GetTitelFromVejledersamtaleType(vejledersamtaleType);
        model.dato = new Date();
        model.vejleder = "";
        model.referat = "";
        model.placeholderText = placeholderText;
        model.vejledersamtaleType = vejledersamtaleType;
        model.createdByName = "";
        model.klarmeldtAt = undefined;
        return model;
    }

    public static GetTitelFromVejledersamtaleType(vejledersamtaleType: VejledersamtaleEnum, fallbackTitle="") {
        switch (vejledersamtaleType) {
            case VejledersamtaleEnum.Introduktion:
                return Localizer.global_introduktion();
            case VejledersamtaleEnum.Justering:
                return Localizer.global_justerende();
            case VejledersamtaleEnum.Afsluttende:
                return Localizer.global_afsluttende();
            default:
                return fallbackTitle;
        }
    }

    public static FromResponseModel(responseModel : LogbogVejledersamtaleResponseModel) : VejledersamtaleModel {
        const model = new VejledersamtaleModel();

        model.id = responseModel.id;
        model.forloebId = responseModel.forloebId;
        model.titel = responseModel.titel;
        model.dato = dateTimeStringFromApiToDate(responseModel.dato);
        model.starttidspunkt = responseModel.starttidspunkt ? dateTimeStringFromApiToDate(responseModel.starttidspunkt) : undefined;
        model.sluttidspunkt = responseModel.sluttidspunkt ? dateTimeStringFromApiToDate(responseModel.sluttidspunkt) : undefined;
        model.vejleder = responseModel.vejleder;
        model.referat = responseModel.referat;
        model.vejlederNavn = responseModel.vejlederNavn;
        model.vejlederInitialer = responseModel.vejlederInitialer;
        model.vejledersamtaleType = responseModel.vejledersamtaleType;
        model.afholdtTilkendegivetDato = responseModel.afholdtTilkendegivetDato ? dateTimeStringFromApiToDate(responseModel.afholdtTilkendegivetDato) : undefined;
        model.afholdtTilkendegivetAf = responseModel.afholdtTilkendegivetAf ? responseModel.afholdtTilkendegivetAf : undefined;
        model.publicAccess = responseModel.publicAccess;
        model.visibleToUserIds = responseModel.visibleToUserIds;
        model.isPrivate = responseModel.isPrivate;
        model.createdByName = responseModel.createdByName;
        model.klarmeldtAt = responseModel.klarmeldtAt;
        model.currentUserHasAccess = responseModel.currentUserHasAccess;

        return model;
    }

    public static FromEditVejledersamtaleInputModel(responseModel : CrudVejledersamtaleInputModel) : VejledersamtaleModel {
        const model = new VejledersamtaleModel();
        model.id = responseModel.id;
        model.forloebId = responseModel.forloebId;
        model.titel = responseModel.titel;
        model.dato = new Date(responseModel.dato);
        model.starttidspunkt = responseModel.starttidspunkt ? new Date(responseModel.starttidspunkt) : undefined;
        model.sluttidspunkt = responseModel.sluttidspunkt ? new Date(responseModel.sluttidspunkt) : undefined;
        model.vejleder = responseModel.vejleder;
        model.referat = responseModel.referat;
        model.vejledersamtaleType = responseModel.vejledersamtaleType;
        model.isPrivate = responseModel.isPrivateSamtale;
        model.publicAccess = responseModel.publicAccess;
        model.klarmeldtAt = responseModel.klarmeldtAt;
        return model;
    }

    public id: string;
    public forloebId: string;
    public titel: string;
    public dato: Date;
    public starttidspunkt?: Date;
    public sluttidspunkt?: Date;
    public vejleder: string;
    public vejlederNavn: string;
    public vejlederInitialer: string;
    public referat: string;
    public placeholderText: string;
    public vejledersamtaleType: VejledersamtaleEnum;
    public afholdtTilkendegivetDato?: Date;
    public afholdtTilkendegivetAf?: string;
    public visibleToUserIds : string[];
    public publicAccess: boolean;
    public isPrivate: boolean;
    public createdByName: string;
    public klarmeldtAt?: string
    public currentUserHasAccess: boolean;
}

import { NotifikationEventTypeEnumToTitleString } from "../../models/notificationModel";
import useNotificationSettingMutations from "../hooks/useNotificationSettingMutations";
import { CheckboxSwitch } from "core/components/checkbox/checkboxSwitch";
import { useState } from "react";
import { NotifikationIndstillingModel } from "../models/notifikationIndstillingModel";
import { LogbogNotifikationEventIndstillingPatchModel } from "services/api/logbogNotifikationIndstilling/logbogNotifikationEventIndstillingPatchModel";
import { Localizer } from "infrastructure/localization/localizer";

export type NotificationSettingListItemProps = {
  settingModel: NotifikationIndstillingModel;
}

const NotificationSettingListItem = ({
  settingModel
}: NotificationSettingListItemProps) => {
  const eventTypeTitle = NotifikationEventTypeEnumToTitleString(settingModel.eventType);

  const [ visNotifikation, setVisNotifikation ] = useState<boolean>(settingModel.visNotifikation);
  const [ sendEmail, setSendEmail ] = useState<boolean>(settingModel.sendEmail);

  const { updateNotificationEventSetting } = useNotificationSettingMutations();

  const onVisNotifikationChanged = async (visNotifikation: boolean) => {
    setVisNotifikation(visNotifikation);

    if(!visNotifikation) {
      setSendEmail(false);
    }
    
    const patchModel = {
      visNotifikation: visNotifikation,
      sendEmail: !visNotifikation ? false : undefined
    } as LogbogNotifikationEventIndstillingPatchModel;

    await updateNotificationEventSetting(settingModel.eventType, patchModel);
  }

  const onSendEmailChanged = async (sendEmail: boolean) => {
    setSendEmail(sendEmail);

    await updateNotificationEventSetting(settingModel.eventType, { sendEmail: sendEmail });
  }

  return (
    <div className="notification-setting-list-item">
      <div className="d-flex flex-space flex-align-c padding-bottom-s">
        <h4>{eventTypeTitle}</h4>
        <div>
          <CheckboxSwitch
            id="vis-notifikation-switch"
            value={visNotifikation}
            onChange={(e) => onVisNotifikationChanged(e.target.checked)}
          />
        </div>
      </div>
      <p className="subtle small">
        {settingModel.description} 
      </p>
      {(settingModel.shouldShowEmailIndstilling && visNotifikation) &&
        <div className="d-flex flex-space">
          <label htmlFor="send-email-switch">{Localizer.notifikationer_NotifikationsIndstillinger_SendEmailLabel()}</label>
          <div>
            <CheckboxSwitch
              id="send-email-switch"
              value={sendEmail}
              onChange={(e) => onSendEmailChanged(e.target.checked)}
            />
          </div>
        </div>
      }   
    </div>
  );
}

export default NotificationSettingListItem;

import {useRouteMatch} from "react-router-dom";
import {LaegekompetenceMeta} from "../../sharedmodels/kompetence/laegekompetenceMeta";
import {useEffect, useState} from "react";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {LaegekompetenceStatusEnum} from "../../sharedmodels/kompetence/laegekompetenceStatusEnum";
import PdfComponent from "core/components/pdf/pdfComponent";
import {PdfKompetenceHeaderComponent} from "./pdfHeaders/pdfKompetenceHeaderComponent";
import {useFetchWithErrorHandlingPdf} from "./useFetchWithErrorHandlingPdf";
import useLogbogApi from "../../hooks/useLogbogApi";
import IdAndModelsContainer from "core/sharedmodels/containers/IdAndModelsContainer";
import { setDocumentPdfAsReady } from "./helpers/pdfHelper";
import { GenericIdPath } from "infrastructure/route/routePathConstants";

type Contianer = IdAndModelsContainer<LaegekompetenceMeta>;

export const AllKompetencerOnUserPdf = () => {

    const { notatApi, logbogLaegeKompetenceApi, forloebApi } = useLogbogApi();

    const params = useRouteMatch<GenericIdPath>().params;
    const userId = params.id;

    const [containers, setContainers] = useState<Contianer[]>([]);

    const setError = useFetchWithErrorHandlingPdf();

    useEffect(() => {
        if(userId === undefined || userId === "") return;

        const fetchData = async () => await logbogLaegeKompetenceApi.getLaegekompetencerForPDfByBrugerId(userId);

        fetchData()
            .then((containers) => setContainers(containers))
            .catch(err => setError(err))
            .finally(() => setDocumentPdfAsReady());

    }, [forloebApi, logbogLaegeKompetenceApi, notatApi, setError, userId]);

    return (
        <PdfComponent>

            {containers.length > 0 && containers.map(({id: brugerMaalbeskrivelseId, models: laegeKompetencer}, i) => (
                <PdfComponent.PageBreakAfter key={brugerMaalbeskrivelseId}>

                    <PdfKompetenceHeaderComponent
                        brugerId={userId}
                        brugerMaalbeskrivelseId={brugerMaalbeskrivelseId}
                    />

                    <div className={"card shadow-none"}>
                        <div className={"card-body"}>
                            <div className={"card-list-modal"}>
                                <div className={"row margin-bottom-m"}>
                                    <h3 className={"col-4"}>{Localizer.global_kompetencer()}</h3>
                                    <h3 className={"col-4"}>{Localizer.global_status()}</h3>
                                    <h3 className={"col-4"}>{Localizer.global_godkendtAf()}</h3>
                                </div>
                                {laegeKompetencer?.map((laegekompetence, index) =>
                                    <PdfComponent.NonBreakableItem key={index}>
                                        <div className="row margin-top-s margin-bottom-s">
                                            <div className={"col-4 flex-align-c"}>
                                                <div className="flex-row">
                                                    <div className="subtle">
                                                        {laegekompetence.kompetenceNummer} <br/> {laegekompetence.fullKompetenceTitle}
                                                    </div>
                                                </div>

                                            </div>
                                            <div className={"col-4 flex-align-c"}>
                                                {laegekompetence.godkendtDato &&
                                                    <>
                                                        {laegekompetence.meritNotat !== null &&
                                                            <div className={"flex-row"}>
                                                                <div>{Localizer.godkendtMedMeritDen() +": "}</div>
                                                                <div>{new Date(laegekompetence.godkendtDato).dateWithTimeFormat()}</div>
                                                                <div>{laegekompetence.meritNotat}</div>
                                                            </div>
                                                        }
                                                        {laegekompetence.meritNotat === null &&
                                                            <div className={"flex-row"}>
                                                                <div>{Localizer.godkendtDen() +": "}</div>
                                                                <div>{new Date(laegekompetence.godkendtDato).dateWithTimeFormat()}</div>
                                                            </div>
                                                        }

                                                    </>

                                                }
                                                {!laegekompetence.godkendtDato && laegekompetence.status != LaegekompetenceStatusEnum.Klarmeldt &&
                                                    <> {Localizer.global_ikkeGodkendt()} </>
                                                }
                                                {laegekompetence.status === LaegekompetenceStatusEnum.Klarmeldt &&
                                                    <> {Localizer.global_klarmeldt()} </>
                                                }
                                            </div>
                                            <div className={"col-4 flex-align-c"}>
                                                {laegekompetence.godkendtAfNavn &&
                                                    <div>
                                                        {`${Localizer.global_name()}: ${laegekompetence.godkendtAfNavn}`}

                                                        {laegekompetence.godkendtAfAuthId &&
                                                            <div className={"flex-container"}>
                                                                <div
                                                                    className={"padding-right-xs"}>{`${Localizer.global_authorisationId()}:`}
                                                                </div>
                                                                <div>
                                                                    {laegekompetence.godkendtAfAuthId}
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className={"flex-container"}>
                                                            <div
                                                                className={"padding-right-xs"}>{`${Localizer.rolle()}:`}</div>
                                                                <div>{laegekompetence.godkendtAfRolleTitel}</div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className={"card-list-border"}/>
                                    </PdfComponent.NonBreakableItem>
                                )}
                            </div>
                        </div>
                    </div>

                    <PdfComponent.PageBreak/>

                    {/* {(notater && notater.length > 0) && notater.filter(n => n.type === NotatType.Merit).map(notat =>
                        <div key={notat.id} className={"card shadow-none"}>
                            <div className={"card-body"}>
                                <div className={"margin-l"}>
                                    <div>
                                        <h5>{notat.title}</h5>
                                    </div>
                                    <p>{notat.description}</p>
                                    <div className='w-50 margin-bottom-m margin-top-m'></div>
                                    <div className='subtle d-flex flex-row'>
                                        <div className='d-flex flex-row margin-right-m'>
                                            <EmblaIcon iconName='calendar'/>
                                            <p className='margin-left-xs'>{notat.createdAt.dateAndTimeToApiPostFormat()}</p>
                                        </div>
                                        <>
                                            <UserProfileAvatar
                                                size={UserProfileAvatarSizeEnum.extraSmall}
                                                userProfileAvatarModel={new UserProfileAvatarModel(notat.createdByName, notat.createdByInitials, false)}
                                                userProfileLayoutEnum={UserProfileLayoutEnum.AsTextOnRightSide}
                                            />
                                        </>

                                        {notat.isPrivate &&
                                            <div className='d-flex flex-row margin-left-m'>
                                                <span><EyeIcon/></span>
                                                <p className='margin-left-xs'>{Localizer.global_private()}</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )} */}

                </PdfComponent.PageBreakAfter>

            ))}
        </PdfComponent>
    );
}

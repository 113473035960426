import { useMemo } from "react";
import { AccountService } from "services/account/accountService";
import { ForloebApi } from "services/api/forloeb/forloebApi";
import { LogbogLaegekursusGruppeApi } from "services/api/laegekursusGruppe/logbogLaegekursusGruppeApi";
import { LogbogForloebEvalueringApi } from "services/api/logbogEvaluering/logbogForloebEvalueringApi";
import { LogbogEvalueringApi } from "services/api/logbogEvaluering/logbogEvalueringApi";
import { LogbogExportApi } from "services/api/logbogExport/logbogExportApi";
import { FileDownloadHandler } from "services/api/logbogFile/fileDownloadHandler";
import { LogbogLaegekompetenceApi } from "services/api/logbogLaegekompetence/logbogLaegekompetenceApi";
import { LogbogNotatApi } from "services/api/logbogNotat/logbogNotatApi";
import { LogbogUddannelsesplanApi } from "services/api/logbogUddannelsesplan/logbogUddannelsesplanApi";
import { LogbogUserInfoApi } from "services/api/logbogUser/logbogUserInfoApi";
import { HttpClientService } from "services/httpClient/httpClientService";
import {EvalueringStatistikApi} from "../../services/api/evalueringStatistik/evalueringStatistikApi";
import {LogbogBeskaeftigelseApi} from "../../services/api/logbogBeskaeftigelse/logbogBeskaeftigelseApi";
import {LogbogLaegekursusApi} from "../../services/api/laegekursus/logbogLaegekursusApi";
import { LogbogNyhederApi } from "services/api/logbogNyheder/logbogNyhederApi";
import { LogbogFilterApi } from "services/api/logbogFilter/logbogFilterApi";
import { LogbogVejledersamtaleApi } from "services/api/logbogVejledersamtale/logbogVejledersamtaleApi";
import { LogbogNotifikationApi } from "services/api/logbogNotifikation/logbogNotifikationApi";
import { LogbogNotifikationIndstillingApi } from "services/api/logbogNotifikationIndstilling/logbogNotifikationIndstillingApi";
import { LogbogSpecialespecifikkeFilerApi } from "services/api/logbogSpecialespecifikkeFiler/logbogSpecialespecifikkeFilerApi";
import { LogbogAnsoegningApi } from "services/api/logbogAnsoegning/logbogAnsoegningApi";
import { LogbogFileApi } from "services/api/logbogFile/logbogFileApi";
import { LogbogStillingApi } from "services/api/logbogStilling/logbogStillingApi";
import { LogbogEmailApi } from "services/api/logbogEmail/logbogEmailApi";
import {KlarmeldingApi} from "../../services/api/klarmelding/klarmeldingApi";

const useLogbogApi = () => {

    return useMemo(() => {
        return {
            logbogUserInfoApi: new LogbogUserInfoApi(new HttpClientService(), new AccountService()),
            logbogLaegeKompetenceApi: new LogbogLaegekompetenceApi(new HttpClientService(), new FileDownloadHandler(), new AccountService()),
            forloebApi: new ForloebApi(new HttpClientService(), new AccountService()),
            ansoegningApi: new LogbogAnsoegningApi(new HttpClientService(), new AccountService(), new FileDownloadHandler()),
            forloebEvalueringApi: new LogbogForloebEvalueringApi(new HttpClientService()),
            evalueringApi: new LogbogEvalueringApi(new HttpClientService()),
            notatApi: new LogbogNotatApi(new HttpClientService(), new AccountService()),
            logbogLaegekursusGruppeApi: new LogbogLaegekursusGruppeApi(new HttpClientService(), new AccountService()),
            uddannelsesplanApi: new LogbogUddannelsesplanApi(new HttpClientService(), new AccountService()),
            logbogExportApi: new LogbogExportApi(new HttpClientService(), new AccountService(), new FileDownloadHandler()),
            evalueringstatistikApi: new EvalueringStatistikApi(new HttpClientService(), new FileDownloadHandler()),
            logbogLaegekursusApi: new LogbogLaegekursusApi(new HttpClientService(), new AccountService()),
            beskaeftigelseApi: new LogbogBeskaeftigelseApi(new HttpClientService(), new AccountService(), new FileDownloadHandler()),
            logbogFilterApi: new LogbogFilterApi(new HttpClientService(), new AccountService()),
            vejledersamtaleApi: new LogbogVejledersamtaleApi(new HttpClientService(), new AccountService()),
            logbogNotifikationApi: new LogbogNotifikationApi(new HttpClientService(), new AccountService()),
            logbogNotifikationIndstillingApi: new LogbogNotifikationIndstillingApi(new HttpClientService(), new AccountService()),
            specialespecifikkeFilerApi: new LogbogSpecialespecifikkeFilerApi(new HttpClientService()),
            logbogAnsoegningApi: new LogbogAnsoegningApi(new HttpClientService(), new AccountService(), new FileDownloadHandler()),
            logbogFileApi: new LogbogFileApi(new HttpClientService(), new FileDownloadHandler()),
            logbogStillingApi: new LogbogStillingApi(new HttpClientService(), new AccountService()),
            logbogNyhederApi: new LogbogNyhederApi(new HttpClientService()),
            logbogEmailApi: new LogbogEmailApi(new HttpClientService()),
            klarmeldingApi: new KlarmeldingApi(new HttpClientService(), new AccountService()),
        }
    }, []);
};

export default useLogbogApi;

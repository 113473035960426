import { BrugerIndstillingModel } from "core/sharedmodels/brugerIndstilling/brugerIndstillingModel";
import { BrugerIndstillingTypeKeyEnum } from "core/sharedmodels/brugerIndstilling/brugerIndstillingTypeKeyEnum";

export type StamdataBrugerIndstillingResponseModel = {
  indstillingTypeKey: BrugerIndstillingTypeKeyEnum;
  indstillingValue: string;
}

export const FromStamdataBrugerIndstillingResponseModel = (responseModel: StamdataBrugerIndstillingResponseModel): BrugerIndstillingModel => {
  return {
    typeKey: responseModel.indstillingTypeKey,
    value: responseModel.indstillingValue,
  };
}

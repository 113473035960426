export class StorageKeys {
    public static token = "token";
    public static user = "user";

    public static leftMenuCollapsed = "leftMenuCollapsed";
    public static localizations = "localizations";
    public static validateVejledereShown = "validateVejledereShown";
    public static forloebsoverblikPageSize = "forloebsoverblikPageSize";
    public static forloebsoverblikPageNumber = "forloebsoverblikPageNumber";
    public static forloebsoverblikViewOption = "forloebsoverblikViewOption";
    public static forloebsoverblikAdvancedSearch = "forloebsoverblikAdvancedSearch";
}

import {ForSelectModel} from "../../../core/sharedmodels/forSelectModel";
import {BrugerMaalbeskrivelserForSelectModelResponseModel} from "./brugerMaalbeskrivelserForSelectModelResponseModel";

export class BrugerMaalbeskrivelserForSelectModel extends ForSelectModel{
    public versionsdato: Date;

    constructor(responseModel : BrugerMaalbeskrivelserForSelectModelResponseModel) {
        super(responseModel.selectModel);
        this.versionsdato = responseModel.versionsdato;
    }
}

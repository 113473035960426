import { NotificationCountRoleModel } from "core/components/notifications/models/notificationCountRoleModel";
import { RolleTitelEnum } from "infrastructure/enums/rolleTitelEnum";

export type LogbogNotifikationCountBrugerRolleResponseModel = {
  brugerId: string;
  rolleTitelEnum: RolleTitelEnum;
  useteNotifikationerCount: number;
  useteNewsNotifikationerCount: number;
  alleNotifikationerCount: number;
}

export const FromNotifikationCountResponseModel = (responseModel : LogbogNotifikationCountBrugerRolleResponseModel): NotificationCountRoleModel => {

  const model = {
    brugerId: responseModel.brugerId,
    rolleTitelEnum: responseModel.rolleTitelEnum,
    useteNotifikationerCount: responseModel.useteNotifikationerCount,
    useteNewsNotifikationerCount: responseModel.useteNewsNotifikationerCount,
    alleNotifikationerCount: responseModel.alleNotifikationerCount,
  } as NotificationCountRoleModel;

  return model;
}
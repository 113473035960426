import React from "react";
import {SvarInputTypeProps} from "./svarInputTypeCompareMap";
import {Localizer} from "../../../infrastructure/localization/localizer";
import classNames from "classnames";

export const TekstInputSvar = ({spoergsmaal, svarNummerIndex, kompetenceSvarList, tekstCallback, indsendt, readOnly}: SvarInputTypeProps) => {
    return (<textarea
        key={spoergsmaal.id + svarNummerIndex}
        name={spoergsmaal.id + svarNummerIndex}
        className={classNames("form-control margin-bottom-m")}
        placeholder={Localizer.evalueringPage_angivTekstsvar()}
        defaultValue={kompetenceSvarList.find(x => x.svarIndex === svarNummerIndex)?.tekstSvar}
        onBlur={e => tekstCallback(e, svarNummerIndex)}
        disabled={indsendt || readOnly}
    />)
};

import { Localizer } from "infrastructure/localization/localizer";

export enum LaegekompetenceStatusEnum {
    Planlagt = 0,
    Klarmeldt = 1,
    Godkendt = 2
}

export const LaegekompetenceStatusEnumTranslator = (laegekompetenceStatus: LaegekompetenceStatusEnum): string => {
    switch(laegekompetenceStatus) {
        case LaegekompetenceStatusEnum.Planlagt:
            return Localizer.global_ejKlarmeldtGodkendt();
        case LaegekompetenceStatusEnum.Klarmeldt:
            return Localizer.global_klarmeldt();
        case LaegekompetenceStatusEnum.Godkendt:
            return Localizer.global_godkendt();
        default:
            throw Error("LaegekompetenceStatusEnum could not be mapped (input:" + laegekompetenceStatus + ")");
    }
}

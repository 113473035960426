import useLogbogApi from "core/hooks/useLogbogApi";
import useUser from "core/hooks/useUser";
import { RolleTitelEnum } from "infrastructure/enums/rolleTitelEnum";
import { useCallback, useEffect, useState } from "react";
import { useNotifikationContext } from "../context/notifikationContextProvider";
import { NotificationCountRoleModel } from "../models/notificationCountRoleModel";

const getNotifikationCountRoleModelForRole = (countModels: NotificationCountRoleModel[], rolleTitelEnum: RolleTitelEnum) => {
  const notificationCountModelForRolleTitel = countModels.find(x => x.rolleTitelEnum === rolleTitelEnum);
  return notificationCountModelForRolleTitel;
}

const useNotificationCount = (defaultFetch = true) => {
  const { currentUser } = useUser();
  const { logbogNotifikationApi } = useLogbogApi();

  const [hasFetched, setHasFetched] = useState<boolean>(false);
  const { state, setNotifikationerCount } = useNotifikationContext();

  const fetchNotifikationerCount = useCallback(async () => {
    const notifikationCountBrugerRolleModels = await logbogNotifikationApi.getNotifikationerCountForRoles();
    setNotifikationerCount(notifikationCountBrugerRolleModels);
    setHasFetched(true);
  }, [logbogNotifikationApi, setNotifikationerCount])

  useEffect(() => {
    if(defaultFetch && !hasFetched && state.notifikationCountRoleModels.length <= 0) {
      fetchNotifikationerCount();
    }
  }, [defaultFetch, fetchNotifikationerCount, hasFetched, state.notifikationCountRoleModels.length]);

  const getUnseenRoleNotificationCount = (rolleTitelEnum: RolleTitelEnum) => {
    const notificationCountModelForRolleTitel = getNotifikationCountRoleModelForRole(state.notifikationCountRoleModels, rolleTitelEnum);
    return notificationCountModelForRolleTitel?.useteNotifikationerCount ?? 0.
  }

  const anyUnseenNotificationsOnOtherRoles = state.notifikationCountRoleModels
    .filter(x => x.rolleTitelEnum !== currentUser.CurrentRolleTitel)
    .map(x => x.useteNotifikationerCount)
    .sum() > 0;

  const currentUnseenRoleNewsNotificationCount = getNotifikationCountRoleModelForRole(state.notifikationCountRoleModels, currentUser.CurrentRolleTitel)?.useteNewsNotifikationerCount ?? 0;

  return {
    currentUnseenRoleNotificationCount: getUnseenRoleNotificationCount(currentUser.CurrentRolleTitel),
    currentUnseenRoleNewsNotificationCount: currentUnseenRoleNewsNotificationCount,
    anyUnseenNotificationsOnOtherRoles,
    getUnseenRoleNotificationCount,
    refetchNotifikationerCount: fetchNotifikationerCount
  }
}

export default useNotificationCount;

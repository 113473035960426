import {PopoverModule, TooltipModule} from "ditmer-embla";
import {LaegekompetenceMeta} from "./sharedmodels/kompetence/laegekompetenceMeta";

export const initAllPopovers = (hideOnClick : boolean = false) => {
    const defaultSelector = "[data-toggle='popover']";
    $(".tooltip").tooltip('hide'); //Make sure to hide all open tooltips
    initPopover(defaultSelector, hideOnClick);
}

export const initPopover = (selector : string, hideOnClick : boolean = false) => {
    const popoverSelector = $(selector);
    new PopoverModule(popoverSelector);

    if (hideOnClick){
        popoverSelector.off('click').on('click', function () {
            $(this).popover('hide')
        });
    }
}

export const defaultTooltipSelector = "[data-tooltip]";

export const initAllTooltips = (isMobile: boolean) => {
    hideAllTooltips(); //Make sure to hide all open tooltips
    initTooltip(defaultTooltipSelector, isMobile);
}

export const hideAllTooltips = () => {
    $(".tooltip").tooltip('hide');
};

export const initTooltip = (selector : string, isMobile: boolean) => {
    const $tooltip = $(selector);

    new TooltipModule($tooltip);

    if (!isMobile) {
        $tooltip.off('click').on('click', function () {
            $(this).tooltip('hide')
        });
    } else {
        $tooltip.off('click').on('click', function () {
            $(this).tooltip('toggle')
        });
    }
}

export const groupByAndMap = (array:any[], key:string): any[] => {
    return Object.values(array.reduce((result: any[], currentValue: any) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        return result;
    }, {})).map(function(x) {return x;});
};

export const genericGroupByAndMap = <TModel, TKey extends keyof TModel>(array: TModel[], key: TKey): Map<TModel[TKey], TModel[]> => {
    const map = new Map();
    array.forEach((item) => {
       const itemKey = item[key];

       const itemArray: TModel[] = map.has(itemKey) ? map.get(itemKey) : [];

       itemArray.push(item);
       map.set(itemKey, itemArray);
    });

    return map;
};

export const sortKompetenceBySortOrder = (kompetence: LaegekompetenceMeta[]) => {
    return kompetence.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1)
}

import {Localizer} from "../../../infrastructure/localization/localizer";
import React from "react";
import {Loading} from "../../../core/components/loading/loading";
import {KompetenceEvalueringOverview} from "./kompetenceEvalueringOverview";
import useKompetenceEvalueringsskemaHook from "./useKompetenceEvalueringsskemaHook";
import {LaegekompetenceMeta} from "../../../core/sharedmodels/kompetence/laegekompetenceMeta";

export interface KompetenceEvalueringsskemaerOverviewProps {
    laegeKompetence: LaegekompetenceMeta;
    deleteKompetenceEvalueringCallback: () => void;
}

export function KompetenceEvalueringsskemaerOverview({
                                                         laegeKompetence,
                                                         deleteKompetenceEvalueringCallback
                                                     }: KompetenceEvalueringsskemaerOverviewProps) {

    const { kompetenceEvalueringsskemaerState, isLoading, deleteKompetenceEvaluering } = useKompetenceEvalueringsskemaHook(laegeKompetence.kompetenceId, laegeKompetence.laegekompetenceId);

    return (
        <Loading isLoading={isLoading} text={Localizer.KompetenceVurderingLoading()} spinnerClasses={"margin-m"}>
            {kompetenceEvalueringsskemaerState?.map(x =>
                <KompetenceEvalueringOverview key={x.kompetenceEvalueringsskemaId} kompetenceEvalueringsskema={x}
                                              brugerMaalbeskrivelseId={laegeKompetence.brugerMaalbeskrivelseId}
                                              deleteKompetenceEvaluering={(deletedRelationId: string) => {
                                                  deleteKompetenceEvaluering(deletedRelationId).then(() => deleteKompetenceEvalueringCallback());
                                              }}/>
            )}
        </Loading>
    );
}

export class ApiQueryParams{
    constructor(page: number, pageLength: number, search?: string) {
        this.page = page;
        this.pageLength = pageLength;
        this.search = search;
    }

    public page : number;
    public pageLength : number;
    public search? : string;
}

export type ApiQueryParamsType = {
    Page : number;
    PageLength : number;
    Search : string;
}

import { FileMetadata } from "core/sharedmodels/fileMetaData/fileMetaData";
import {CreateMailInputModel} from "../../../core/components/mail/createMailInputModel";
import { EmailTypeEnum } from "core/sharedmodels/email/emailTypeEnum";

export class logbogEmailUserEmailRequestModel {
    constructor(inputModel : CreateMailInputModel, userIds : string[], emailTypeEnum: EmailTypeEnum) {
        this.userIds = userIds;
        this.emailTypeEnum = emailTypeEnum;
        this.bodyMarkdown = inputModel.message;
        this.subjectText = inputModel.subject;
        this.fileMetadata = inputModel.fileMetadata;
    }

    public userIds: string[];
    public subjectText: string;
    public emailTypeEnum: EmailTypeEnum;
    public bodyMarkdown: string;
    public fileMetadata: FileMetadata[];
}

import {ApiQueryParams} from "../apiQueryParams";

export class UserWithKursusGrupperRequestModel extends ApiQueryParams
{
    constructor(searchLaege: string, searchKursus: string, page: number) {
        super(page, 10);
        this.searchLaege = searchLaege;
        this.searchKursus = searchKursus;
    }

    public searchLaege?: string;
    public searchKursus?: string;

}

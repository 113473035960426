import {SpoergsmaalType} from "./spoergsmaalType";
import {SvarmulighedModel} from "./svarmulighedModel";
import {LogbogSpoergsmaalResponseModel} from "../../../services/api/logbogEvaluering/logbogSpoergsmaalResponseModel";
import {SvarModel} from "./svarModel";
import {SvarGruppeEnum} from "./svarGruppeEnum";

export class SpoergsmaalModel {

    public static FromResponseModel(spoergsmaalResponseModel: LogbogSpoergsmaalResponseModel): SpoergsmaalModel{
        let newModel = new SpoergsmaalModel();

        newModel.id = spoergsmaalResponseModel.id;
        newModel.titel = spoergsmaalResponseModel.titel;
        newModel.tekst = spoergsmaalResponseModel.tekst;
        newModel.sortOrder = spoergsmaalResponseModel.sortOrder;
        newModel.isRequired = spoergsmaalResponseModel.isRequired;
        newModel.spoergsmaalTypeEnum = spoergsmaalResponseModel.spoergsmaalTypeEnum;
        newModel.svarmuligheder = spoergsmaalResponseModel.svarmuligheder.map(s => SvarmulighedModel.FromResponseModel(s));
        newModel.svar = SvarModel.FromResponseModel(spoergsmaalResponseModel.svar);
        newModel.isFeedback = spoergsmaalResponseModel.isFeedback;
        newModel.svarGruppeEnums = spoergsmaalResponseModel.svarGruppeEnums;

        return newModel;
    }

    id: string;
    titel: string;
    tekst: string;
    spoergsmaalTypeEnum: SpoergsmaalType;
    sortOrder: number;
    isRequired: boolean;
    svarmuligheder: SvarmulighedModel[];
    svar?: SvarModel;
    isFeedback: boolean;
    svarGruppeEnums?: SvarGruppeEnum[];
}

import {SynkroniseredeForloebResponseModel} from "../../../services/api/forloeb/synkroniseredeForloebResponseModel";
import {UddannelseTypeEnum} from "../stilling/uddannelseTypeEnum";
import {StillingTypeEnum} from "../stilling/stillingTypeEnum";
import {dateTimeStringFromApiToDate} from "../../../index";
import {toDropdownOptions} from "../forSelectModel";
import {DropdownOption} from "../../components/dropdown/dropdown";

export class SynkroniseredeForloebModel {
    public static FromResponseModel(responseModel: SynkroniseredeForloebResponseModel): SynkroniseredeForloebModel {
        const model = new SynkroniseredeForloebModel();
        model.id = responseModel.id;
        model.uddannelseslaegeId = responseModel.uddannelseslaegeId;
        model.uddannelseslaegeNavn = responseModel.uddannelseslaegeNavn;
        model.uddannelseslaegeInitialer = responseModel.uddannelseslaegeInitialer;
        model.stillingsType = responseModel.stillingsType;
        model.specialeName = responseModel.specialeName;
        model.uddannelseType = responseModel.uddannelseType;
        model.uddannelsesstedName = responseModel.uddannelsesstedName;
        model.sygehus = responseModel.sygehus;
        model.startDate = dateTimeStringFromApiToDate(responseModel.startDate);
        model.endDate = dateTimeStringFromApiToDate(responseModel.endDate);
        model.deaktiveredeForloebInfo = toDropdownOptions(responseModel.forloebInfo);
        return model;
    }

    public id: string;
    public uddannelseslaegeId: string;
    public uddannelseslaegeNavn: string;
    public uddannelseslaegeInitialer: string;
    public brugerKursusraekkeId: string;
    public startDate: Date;
    public endDate: Date;
    public uddannelsesstedName: string;
    public sygehus?: string;
    public specialeName: string;
    public uddannelseType: UddannelseTypeEnum;
    public stillingsType: StillingTypeEnum;
    public deaktiveredeForloebInfo: DropdownOption<string>[];
}

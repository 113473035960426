import {LogbogUserSimpleResponseModel} from "../../../services/api/logbogUser/logbogUserSimpleResponseModel";
import {RolleTitelEnum} from "../../../infrastructure/enums/rolleTitelEnum";
import {StamdataUserSimpleResponseModel} from "../../../services/api/stamdataUser/stamdataUserSimpleResponseModel";

export class UserSimpleModel {
    public static FromLogbogResponseModel(responseModel : LogbogUserSimpleResponseModel) {
        let model = new UserSimpleModel();

        model.userId = responseModel.userId;
        model.navn = responseModel.navn;
        model.email = responseModel.email;
        model.initialer = responseModel.initialer;
        model.rolleTitelEnums = responseModel.rolleTitelEnums;

        return model;
    }
    public static FromStamdataResponseModel(responseModel : StamdataUserSimpleResponseModel) {
        let model = new UserSimpleModel();

        model.userId = responseModel.userId;
        model.navn = responseModel.navn;
        model.email = responseModel.email;
        model.initialer = responseModel.initialer;
        model.rolleTitelEnums = responseModel.rolleTitelEnums;

        return model;
    }

    public userId: string;
    public navn: string;
    public initialer: string;
    public email: string;
    public rolleTitelEnums: RolleTitelEnum[];
}

import React from "react";
import {KompetenceSpoergsmaalModel} from "../../../core/sharedmodels/evaluering/kompetenceSpoergsmaalModel";
import {KompetenceSvarModel} from "../../../core/sharedmodels/evaluering/KompetencesvarModel";
import {SvarmulighedModel} from "../../../core/sharedmodels/evaluering/svarmulighedModel";

export type MultiInputSvarProps = {
    spoergsmaal: KompetenceSpoergsmaalModel;
    svarNummerIndex?: number;
    sektionId: string;
    kompetenceSvarList: KompetenceSvarModel[];
    indsendt: boolean;
    readOnly: boolean;
    multiSvarCallback: (svarmulighed: SvarmulighedModel, checked: boolean,) => void;
    removeSvarCallback: (svarmulighedId: string, isChecked: boolean, svarIndex?: number) => void;
}

export function MultiInputSvar({
                                   spoergsmaal,
                                   kompetenceSvarList,
                                   indsendt,
                                   sektionId,
                                   multiSvarCallback,
                                   removeSvarCallback,
                                   readOnly
                               }: MultiInputSvarProps) {
    const spoergsmaalHarMereEndEtSvar = spoergsmaal.antalSvar > 1;

    return (
        <div>
            {spoergsmaal.svarmuligheder.sortBy(x => x.sortOrder).map((svarmulighed) => (
                <div className="card"
                     key={svarmulighed.id}>
                    <input type={`${spoergsmaalHarMereEndEtSvar ? "checkbox" : "radio"}`}
                           id={svarmulighed.id + spoergsmaal.id + sektionId}
                           name={spoergsmaal.id}
                           onClick={(e) => {
                               const isChecked = e.currentTarget.checked;
                               isChecked ? multiSvarCallback(svarmulighed, isChecked) : removeSvarCallback(svarmulighed.id, isChecked);
                           }}
                           defaultChecked={kompetenceSvarList.find(x => x.svarmulighedId === svarmulighed.id) !== undefined}
                           disabled={indsendt || readOnly || (spoergsmaalHarMereEndEtSvar && kompetenceSvarList.length >= spoergsmaal.antalSvar && !kompetenceSvarList.find(x => x.svarmulighedId === svarmulighed.id) )}/>
                    <label className={"pre-line"}
                        htmlFor={svarmulighed.id + spoergsmaal.id + sektionId}>{svarmulighed.tekst}</label>
                </div>
            ))}
        </div>
    )
}

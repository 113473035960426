import {KompetenceSpoergsmaalModel} from "./kompetenceSpoergsmaalModel";
import {
    logbogKompetenceSektionResponseModel
} from "../../../services/api/logbogEvaluering/logbogKompetenceSektionResponseModel";

export class KompetenceSektionModel {

    public static FromResponseModel(sektionResponseModel: logbogKompetenceSektionResponseModel): KompetenceSektionModel{
        let newModel = new KompetenceSektionModel();

        newModel.id = sektionResponseModel.id;
        newModel.title = sektionResponseModel.title;
        newModel.includedInProgress = sektionResponseModel.includedInProgress;
        newModel.tekst = sektionResponseModel.tekst;
        newModel.sortOrder = sektionResponseModel.sortOrder;
        newModel.subTitle = sektionResponseModel.subTitle;
        newModel.spoergsmaal = sektionResponseModel.spoergsmaal.map(s => KompetenceSpoergsmaalModel.FromResponseModel(s));

        return newModel;
    }

    public AllQuestionsAnswered = () : boolean => {
        if (!this.spoergsmaal){
            return false;
        }

        return this.spoergsmaal.filter(s => !s.isFeedback || !s.isRequired).every(s => s.evalueringSvar);
    }

    id: string;
    title: string;
    subTitle: string;
    tekst: string;
    includedInProgress: boolean;
    sortOrder: number;
    spoergsmaal: KompetenceSpoergsmaalModel[];
}

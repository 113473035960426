import {HttpClientService} from "../../httpClient/httpClientService";
import {AccountService} from "../../account/accountService";
import config from "../../../config/config";
import {ApiResponse} from "../apiResponse";
import {FileMetadata} from "../../../core/sharedmodels/fileMetaData/fileMetaData";
import {FileDownloadHandler} from "../logbogFile/fileDownloadHandler";

export class StamdataEmailApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;
    private fileDownloadHandler?: FileDownloadHandler;

    private baseUrl = () => config.stamdataApiUrl + "api/email/";
    public emailDatalist = () => this.baseUrl() + "receivedEmails";
    public emailFileUrl = () => this.baseUrl() + "file/";

    constructor(httpClientService: HttpClientService, userService: AccountService, fileDownloadHandler?: FileDownloadHandler) {
        this.httpClientService = httpClientService;
        this.userService = userService;
        this.fileDownloadHandler = fileDownloadHandler
    }

    async GetAttachments(fileMetadata: FileMetadata): Promise<void> {
        const apiUrl = this.emailFileUrl() + fileMetadata.id;
        let blob = await this.httpClientService.DownloadFile(apiUrl, fileMetadata.fileContentType);
        //TODO: Hack to force browser to show AJAX blob file as downloaded file
        this.fileDownloadHandler?.DownloadFile(blob, fileMetadata.fileName);
    }

    async uploadAttachment(file: File): Promise<FileMetadata> {
        const postModel = new FormData();
        postModel.append("FormFile", file, file.name);

        const url = this.emailFileUrl();
        return (await this.httpClientService.UploadFormdata<ApiResponse<FileMetadata>>(url, postModel)).data;
    }

    async deleteFile(fileMetadataId: string): Promise<string> {
        const apiUrl = this.emailFileUrl() + fileMetadataId;
        let result = await this.httpClientService.Delete<ApiResponse<string>>(apiUrl);
        return result.data;
    }
}

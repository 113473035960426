import {GenericEnumType, GenericEnumTypeKeys} from "./enumGenericTypes";

/**
 * Get all values of the specified Enum (e.g. array of all numbers)
 * @param enumType The Enum-type
 * @param enumvaluesToExclude The values which should be excluded from the final array
 * @returns
 */
export const getEnums = <TType extends GenericEnumType, TKeys extends GenericEnumTypeKeys>(enumType: TType, enumvaluesToExclude?: TKeys[]): TKeys[] => {
    const enumTypeValues = Object.values(enumType)
        .filter((x) => (!enumvaluesToExclude || !enumvaluesToExclude.includes(x as TKeys)) && Number.isInteger(x))
        .map((x) => x as TKeys);

    return enumTypeValues;
}

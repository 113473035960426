import {FileArchiveEnum} from "./fileArchiveEnum";
import {dateTimeStringFromApiToDate} from "../../../index";
import {NotatType} from "../notat/notatType";
import {Localizer} from "../../../infrastructure/localization/localizer";
import { LocalizerPageSpecifics } from "infrastructure/localization/localizerPageSpecifics";

export class FileMetadataResponseModel {
    id: string;
    fileName: string;
    fileGuid: string;
    fileContentType: string;
    fileArchiveEnum: FileArchiveEnum;
    isDeleted: boolean;
    createdAt: string;
    oprettetAf: string;
    createdByName: string;
    notatFileType?: NotatType;
    MappeId?: string;
}

export class FileMetadata {
    id: string;
    fileName: string;
    fileGuid: string;
    fileContentType: string;
    fileArchiveEnum: FileArchiveEnum;
    isDeleted: boolean;
    createdAt: Date;
    createdByName: string;
    oprettetAf: string; // Not CreatedBy
    notatType?: NotatType;
    mappeId?: string;
}

export const FromFileMetaDataResponseModel = (file: FileMetadataResponseModel, type?: NotatType): FileMetadata => {

    let fileMetaDataModel = new FileMetadata();
    fileMetaDataModel.id = file.id
    fileMetaDataModel.fileName = file.fileName
    fileMetaDataModel.fileGuid = file.fileGuid
    fileMetaDataModel.fileContentType = file.fileContentType
    fileMetaDataModel.fileArchiveEnum = file.fileArchiveEnum
    fileMetaDataModel.isDeleted = file.isDeleted
    fileMetaDataModel.createdAt = dateTimeStringFromApiToDate(file.createdAt)
    fileMetaDataModel.createdByName = file.createdByName
    fileMetaDataModel.oprettetAf = file.oprettetAf
    fileMetaDataModel.notatType = type
    fileMetaDataModel.mappeId = file.MappeId

    return fileMetaDataModel;
}

export function getNotatTypeText(notatFileType: NotatType | undefined) {
    switch (notatFileType) {
        case 0:
            return Localizer.notatPage_tekstNotat()
        case 2:
            return Localizer.forloebpage_AttestationForTid()
        case 5:
            return Localizer.ansoegOmSelvstaendigVirke_ansaettelseOgUddannelsesaftaler()
        case 6:
            return Localizer.notatPage_meritNotat()
        case 7:
            return Localizer.notatPage_kursusNotat()
        case 10:
            return Localizer.ansoegOmSelvstaendigVirke_dokumentationTilSTPS()
        case 11:
            return LocalizerPageSpecifics.notatPage_logbogLegacyNotat();
        case 12:
            return LocalizerPageSpecifics.forloebpage_uddannelsesprogram();
        default:
            break;
    }
}

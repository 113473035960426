import config from "config/config";
import {HttpClientService} from "services/httpClient/httpClientService";
import {ApiResponse} from "../apiResponse";
import {AccountService} from "../../account/accountService";
import {KlarmeldingInputModel} from "../../../pages/klarmeldinger/components/klarmeldingInputModel";
import {KlarmeldingResponseModel} from "./klarmeldingResponseModel";
import {KlarmeldingModel} from "../../../pages/klarmeldinger/klarmeldingModel";
import {KlarmeldingTypeEnum} from "../../../infrastructure/enums/klarmeldingTypeEnum";
import {KlarmeldingRequestModel} from "./klarmeldingRequestModel";
import {ObjectToQueryString} from "../objectToQueryString";


export class KlarmeldingApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = () => config.logbogApiUrl + "api/klarmelding/";
    private editKlarmeldingUrl = () => this.baseUrl() + "edit/";
    private getKlarmeldingBrugere = () => this.baseUrl() + "getKlarmeldingBrugere/";

    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    async editKlarmeldinger(inputModel: KlarmeldingInputModel): Promise<string> {
        const result = await this.httpClientService.Post<ApiResponse<string>, KlarmeldingInputModel>(this.editKlarmeldingUrl(), inputModel);
        return result.data;
    }

    async getKlarmeldingBrugereByContext(contextId: string, klarmeldingTypeEnum: KlarmeldingTypeEnum): Promise<KlarmeldingModel> {

        const requestModel = ({
            contextId: contextId,
            klarmeldingType: klarmeldingTypeEnum
        }) as KlarmeldingRequestModel;

        const url = this.getKlarmeldingBrugere() + ObjectToQueryString(requestModel);;
        const result = await this.httpClientService.Get<ApiResponse<KlarmeldingResponseModel>>(url);

        return new KlarmeldingModel(result.data)
    }
}

import { useRef } from "react";
import { TextEditor } from "core/components/textEditor/textEditor";
import { TextEditorActions } from "core/components/textEditor/useForwardedTextEditorActionsRef";
import { debounce } from "lodash";
import { Loading } from "core/components/loading/loading";
import useBrevMergeFields from "../hooks/useBrevMergeField";

type BrevMergedMarkdownEditorProps = {
    markdown: string;
    onChange: (htmlString: string) => void;
    uddannelseslaegeId: string;
    onlyMergeOnce?: boolean;
}

export const BrevMergedMarkdownEditor = ({markdown, onChange, uddannelseslaegeId, onlyMergeOnce=false}: BrevMergedMarkdownEditorProps) => {

    const textEditorActionsRef = useRef<TextEditorActions>(null);

    const {mergedMarkdown, isLoading} = useBrevMergeFields(uddannelseslaegeId, markdown, onlyMergeOnce);
    
    return (
        <Loading isLoading={isLoading}>
            <TextEditor
                ref={textEditorActionsRef}
                id={"brevMergedMarkdownEditor"}
                markdown={mergedMarkdown}
                onChange={debounce(() => onChange(textEditorActionsRef.current?.getHtmlString() ?? ""), 500)}
                showImageItem
                showTableItem
                minHeightPx={500}
            />
        </Loading>
    );
}

export default BrevMergedMarkdownEditor;
import {CrudForloebInputModel} from "../../../core/componentsPage/forloeb/crudForloebInputModel";
import {FravaerskodeEnum} from "../../../core/sharedmodels/forloeb/fravaerskoderEnum";

export class ForloebCreatePostModel {
    constructor(inputModel: CrudForloebInputModel) {
        this.startDatePlain = (inputModel.startDato as Date).dateToApiPostFormat();
        this.endDatePlain = (inputModel.slutDato as Date).dateToApiPostFormat();
        this.skalIndgaaIEvalueringsstatistik = inputModel.skalIndgaaIEvalueringsstatistik;
        this.senesteDatoForEvaluering = inputModel.skalIndgaaIEvalueringsstatistik ? (inputModel.senesteDatoForEvaluering as Date).dateToApiPostFormat() : undefined;
        this.stillingId = inputModel.stillingId;
        this.brugerId = inputModel.brugerId;
        this.erFravaer = inputModel.erFravaer;
        this.fravaerskode = inputModel.fravaerskode;
        this.infoTekst = inputModel.infoTekst;
        this.irrelevant = inputModel.irrelevant;
    }

    public startDatePlain: string;
    public senesteDatoForEvaluering?: string;
    public endDatePlain: string;
    public skalIndgaaIEvalueringsstatistik: boolean;
    public stillingId: string;
    public brugerId: string;
    public erFravaer?: boolean;
    public irrelevant: boolean;
    public fravaerskode?: FravaerskodeEnum;
    public infoTekst?: string;
}


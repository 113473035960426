import config from "config/config";
import {
    NotificationEditSeenStatusModel,
    NotificationModel,
    NotifikationEventTypeEnum
} from "core/components/notifications/models/notificationModel";
import {AccountService} from "services/account/accountService";
import {HttpClientService} from "services/httpClient/httpClientService";
import {ApiResponse} from "../apiResponse";
import {FromNotifikationResponseModel, LogbogNotifikationResponseModel} from "./logbogNotifikationResponseModel";
import {NotificationCountRoleModel} from "core/components/notifications/models/notificationCountRoleModel";
import {
    FromNotifikationCountResponseModel,
    LogbogNotifikationCountBrugerRolleResponseModel
} from "./logbogNotifikationCountBrugerRolleResponseModel";
import {PromiseWithCancel} from "services/httpClient/promiseWithCancel";
import {FilterModel} from "../../../core/sharedmodels/filterModel";
import {AsyncResponseModel} from "../asyncResponseModel";
import {ObjectToQueryString} from "../objectToQueryString";
import {NotifikationHistorikRequestModel} from "./notifikationHistorikRequestModel";

export class LogbogNotifikationApi {
    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = () => config.logbogApiUrl + "api/notifikation";
    private notifikationerUrl = () => `${this.baseUrl()}/`;
    private notifikationHistorikUrl = () => `${this.baseUrl()}/historik`;
    private notifikationerCountUrl = () => `${this.baseUrl()}/count/`;
    private notifikationerUpdateSeenUrl = (notificationId: string) => `${this.baseUrl()}/updateIsSeen/${notificationId}`;
    private markAllAsSeenUrl = () => `${this.baseUrl()}/markAllAsSeen`;
    private markSeenStatusUrl = () => `${this.baseUrl()}/markSeenStatus`;

    private getNotifikationerWithCancelOption: PromiseWithCancel<ApiResponse<Array<LogbogNotifikationResponseModel>>>;

    async getNotifikationer(): Promise<Array<NotificationModel>> {
        if (this.getNotifikationerWithCancelOption)
            this.getNotifikationerWithCancelOption.cancel();

        const url = this.notifikationerUrl();

        this.getNotifikationerWithCancelOption = this.httpClientService.GetWithCancellation<ApiResponse<Array<LogbogNotifikationResponseModel>>>(url);

        const result = await this.getNotifikationerWithCancelOption;
        return result.data.map(x => FromNotifikationResponseModel(x));
    }

    async getNotifikationHistorik(page: number, search?: string, pageLength?: number, typeEnums?: NotifikationEventTypeEnum[]): Promise<FilterModel<NotificationModel>> {
        const requestModel = new NotifikationHistorikRequestModel(page, search, pageLength, typeEnums);
        const url = this.notifikationHistorikUrl() + ObjectToQueryString(requestModel);
        let result = await this.httpClientService.Get<ApiResponse<AsyncResponseModel<LogbogNotifikationResponseModel>>>(url);
        let notifikationer = result.data.data.map(x => FromNotifikationResponseModel(x));
        return new FilterModel<NotificationModel>(notifikationer, result.data.recordsFiltered, result.data.recordsTotal);
    }

    private getNotifikationerCountForRolesWithCancelOption: PromiseWithCancel<ApiResponse<Array<LogbogNotifikationCountBrugerRolleResponseModel>>>;

    async getNotifikationerCountForRoles(): Promise<Array<NotificationCountRoleModel>> {
        if (this.getNotifikationerCountForRolesWithCancelOption)
            this.getNotifikationerCountForRolesWithCancelOption.cancel();

        const url = this.notifikationerCountUrl();

        this.getNotifikationerCountForRolesWithCancelOption = this.httpClientService.GetWithCancellation<ApiResponse<Array<LogbogNotifikationCountBrugerRolleResponseModel>>>(url);

        const result = await this.getNotifikationerCountForRolesWithCancelOption;
        return result.data.map(x => FromNotifikationCountResponseModel(x));
    }

    async setNotificationIsSeen(notificationId: string): Promise<boolean> {
        const url = this.notifikationerUpdateSeenUrl(notificationId);
        const result = await this.httpClientService.Get<ApiResponse<boolean>>(url);
        return result.data;
    }

    async markAllUserRoleNotificationsAsSeen(): Promise<boolean> {
        const url = this.markAllAsSeenUrl();
        const result = await this.httpClientService.Get<ApiResponse<boolean>>(url);
        return result.data;
    }

    async markSeenStatus(notificationIdList: string[], markAsSeen: boolean): Promise<boolean> {
        const requestModel : NotificationEditSeenStatusModel = {
            markAsSeen: markAsSeen,
            notifikationIdList:notificationIdList
        };
        const url = this.markSeenStatusUrl() + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.Get<ApiResponse<boolean>>(url);
        return result.data;
    }
}

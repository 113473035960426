import {CrudVejlederInputModel} from "../../../pages/vejledere/components/crudVejlederInputModel";

export class StamdataVejlederPatchModel {
    constructor(inputModel : CrudVejlederInputModel) {
        const uddannelsessted = inputModel.uddannelsessted;

        this.afdelingId = uddannelsessted.afdelingId;
        this.praksisId = uddannelsessted.praksisId;
        this.vejlederTilknytningId = inputModel.vejlederTilknytningId;
        this.startDatePlain = uddannelsessted.startDato ? uddannelsessted.startDato.dateToApiPostFormat() : "";
        this.endDatePlain = uddannelsessted.slutDato ? uddannelsessted.slutDato.dateToApiPostFormat() : "";
        this.alleLaeger = uddannelsessted.alleLaeger;
        this.laegerIds = uddannelsessted.alleLaeger ? [] : uddannelsessted.laeger;
        this.alleKbuLaeger = uddannelsessted.alleKbuLaeger;
        this.alleIntroduktionsLaeger = uddannelsessted.alleIntroduktionsLaeger;
        this.alleHoveduddannelsesLaeger = uddannelsessted.alleHoveduddannelsesLaeger;
    }

    public afdelingId: string;
    public praksisId: string;
    public vejlederTilknytningId: string;
    public startDatePlain: string;
    public endDatePlain: string;
    public alleLaeger: boolean;
    public laegerIds : string[];
    public alleKbuLaeger : boolean;
    public alleIntroduktionsLaeger : boolean;
    public alleHoveduddannelsesLaeger : boolean;
}

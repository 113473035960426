import classNames from "classnames";
import "./CounterCircle.scss";
import { CSSProperties } from "react";

export type CounterCircleProps = {
  count?: number;
  size?: "extra-small" | "auto" | "small" | "medium" | "large";
  color?: "color-red" | "color-green" | "color-primary";
  additionalClasses?: string;
  hideIfZero?: boolean;
  dontShowCountIfZero?: boolean;
  positionAbsolute?: boolean;
  positionOffsetPx?: number;
  transformCountDisplay?: (count: number) => string | number;
}

const CounterCircle = ({
  count,
  additionalClasses,
  size = "auto",
  color = "color-red",
  hideIfZero = true,
  dontShowCountIfZero = false,
  positionAbsolute = false,
  positionOffsetPx,
  transformCountDisplay
}: CounterCircleProps) => {

  const shouldHide = hideIfZero && (count !== undefined && count === 0);
  const shouldHideCountText = dontShowCountIfZero && (!count || count === 0);
  const customStyle: CSSProperties = positionAbsolute 
    ? { top: positionOffsetPx, right: positionOffsetPx } 
    : {};

  const countText = (!!transformCountDisplay && !!count) 
    ? transformCountDisplay(count) 
    : shouldHideCountText 
      ? "" 
      : count;

  return (
    <div 
      className={
        classNames(
          "counter-circle", 
          size,
          color,
          additionalClasses, 
          shouldHide && "hidden",
          positionAbsolute && "position-absolute",
          
        )}
        style={customStyle}
      >
      <div className={classNames("count-text")}>{countText}</div>
    </div>
  )
}

export default CounterCircle;

import {EvalueringType} from "./evalueringType";
import {SektionModel} from "./sektionModel";
import {LogbogEvalueringResponseModel} from "../../../services/api/logbogEvaluering/logbogEvalueringResponseModel";

export class EvalueringModel {
    public static FromResponseModel(evaluering: LogbogEvalueringResponseModel): EvalueringModel{
        let evalueringModel = new EvalueringModel();

        evalueringModel.evalueringId = evaluering.evalueringId;
        evalueringModel.evalueringTypeEnum = evaluering.evalueringTypeEnum;
        evalueringModel.sektioner = evaluering.sektioner?.map(s => SektionModel.FromResponseModel(s));
        evalueringModel.indsendt = evaluering.indsendt;
        evalueringModel.forloebEvalueringId = evaluering.forloebEvalueringId;

        return evalueringModel;
    }

    evalueringId: string;
    evalueringTypeEnum: EvalueringType;
    sektioner: SektionModel[];
    forloebEvalueringId?: string;
    indsendt: boolean;
}


import {LaegekompetenceMetaState} from "pages/kompetencerPage/cards/kompetenceCardSlice";
import {LogbogKlarmeldTilUserRequestModel, LogbogLaegekompetenceRequestModel} from "services/api/logbogLaegekompetence/logbogLaegekompetenceRequestModel";
import {
    LogbogLaegekompetenceMetaResponseModel
} from "../../../services/api/logbogLaegekompetence/logbogLaegekompetenceMetaResponseModel";
import {LaegekompetenceStatusEnum} from "./laegekompetenceStatusEnum";
import {RolleEnum} from "../../../infrastructure/enums/userRole";
import {dateTimeStringFromApiToDate} from "../../../index";
import {ForloebState} from "../../forloebSlice";
import {RolleTitelEnum} from "../../../infrastructure/enums/rolleTitelEnum";
import { KlarmeldingTilBrugerModel } from "pages/klarmeldinger/klarmeldingModel";

export class LaegekompetenceMeta {
    static FromState(state: LaegekompetenceMetaState, forloebState: ForloebState): LaegekompetenceMeta {
        const model = new LaegekompetenceMeta();

        model.laegekompetenceId = state.laegekompetenceId;
        model.brugerMaalbeskrivelseId = state.brugerMaalbeskrivelseId;
        model.kompetenceId = state.kompetenceId;
        model.maalbeskrivelseNavn = state.maalbeskrivelseNavn;
        model.kompetenceNummer = state.kompetenceNummer;
        model.sortOrder = state.kompetenceSortOrder;
        model.titel = state.titel;
        model.konkretisering = state.konkretisering;
        model.laeringsstrategi = state.laeringsstrategi;
        model.laegeroller = state.laegeroller;
        model.kompetenceVurderingsmetoder = state.kompetenceVurderingsmetoder;
        model.kompetenceVurderingsmetoderLink = state.kompetenceVurderingsmetoderLink;
        model.underoverskrift = state.underoverskrift;
        model.isFavorite = state.isFavorite;
        model.status = state.status;
        model.userId = state.UserId;
        model.userNavn = state.UserNavn;
        model.userInitialer = state.UserInitialer;
        model.notatCount = state.notatCount;
        model.evalueringCount = state.evalueringCount;
        model.forloebIds = state.forloebIds;
        model.hasActiveKvSkema = state.hasActiveKvSkema;
        model.fase = state.fase;
        model.klarmeldtAt = state.klarmeldtAt;

        model.godkendtDato = state.godkendtDato;
        model.godkendtAfNavn = state.godkendtAfNavn;
        model.godkendtAfAuthId= state.godkendtAfAuthId;
        model.godkendtAfRolleTitel= state.godkendtAfRolleTitel;

        model.godkendtUddannelsessted = (forloebState.afdelingName === null || forloebState.afdelingName === "") ? forloebState.laegeInstitutionName : forloebState.laegeInstitutionName + ", " + forloebState.afdelingName; //todo (fix/send props med i stedet for hele state)
        model.klarmeldtTilNavn = state.klarmeldtTilNavn;

        return model;
    }

    public ToState(): LaegekompetenceMetaState {
        return {
            isFavorite: this.isFavorite,
            kompetenceId: this.kompetenceId,
            brugerMaalbeskrivelseId: this.brugerMaalbeskrivelseId,
            maalbeskrivelseNavn: this.maalbeskrivelseNavn,
            kompetenceVurderingsmetoder: this.kompetenceVurderingsmetoder,
            kompetenceVurderingsmetoderLink: this.kompetenceVurderingsmetoderLink,
            kompetenceNummer: this.kompetenceNummer,
            kompetenceSortOrder: this.sortOrder,
            underoverskrift: this.underoverskrift,
            konkretisering: this.konkretisering,
            laegekompetenceId: this.laegekompetenceId,
            laegeroller: this.laegeroller,
            laeringsstrategi: this.laeringsstrategi,
            titel: this.titel,
            status: this.status,
            UserId: this.userId,
            UserNavn: this.userNavn,
            UserInitialer: this.userInitialer,
            notatCount: this.notatCount,
            evalueringCount: this.evalueringCount,
            forloebIds: this.forloebIds,
            hasActiveKvSkema: this.hasActiveKvSkema,
            fase: this.fase,

            klarmeldtAt: this.klarmeldtAt,
            godkendtDato: this.godkendtDato,
            godkendtAfNavn: this.godkendtAfNavn,
            godkendtAfAuthId: this.godkendtAfAuthId,
            godkendtAfRolleTitel: this.godkendtAfRolleTitel,
            klarmeldtTilNavn: this.klarmeldtTilNavn

        } as LaegekompetenceMetaState;
    }

    static FromResponseModel(responseModel: LogbogLaegekompetenceMetaResponseModel): LaegekompetenceMeta {
        const model = new LaegekompetenceMeta();
        model.laegekompetenceId = responseModel.laegekompetenceId;
        model.kompetenceId = responseModel.kompetenceId;
        model.brugerMaalbeskrivelseId = responseModel.brugerMaalbeskrivelseId;
        model.maalbeskrivelseNavn = responseModel.maalbeskrivelseNavn;
        model.kompetenceNummer = responseModel.kompetenceNummer;
        model.titel = responseModel.titel;
        model.konkretisering = responseModel.konkretisering;
        model.laeringsstrategi = responseModel.laeringsstrategi;
        model.laegeroller = responseModel.laegeroller;
        model.kompetenceVurderingsmetoder = responseModel.kompetenceVurderingsmetoder;
        model.kompetenceVurderingsmetoderLink = responseModel.kompetenceVurderingsmetoderLink;
        model.underoverskrift = responseModel.underoverskrift;
        model.isFavorite = responseModel.isFavorite;
        model.status = responseModel.status;
        model.notatCount = responseModel.notatCount;
        model.evalueringCount = responseModel.evalueringCount;
        model.userId = responseModel.userId;
        model.userNavn = responseModel.userNavn;
        model.userInitialer = responseModel.userInitialer;
        model.forloebIds = responseModel.forloebIds;
        model.godkendtAf = responseModel.godkendtAf;
        model.klarmeldtAt = responseModel.klarmeldtAt ? dateTimeStringFromApiToDate(responseModel.klarmeldtAt).dateWithoutTimeFormat(true) : undefined;
        model.godkendtDato = responseModel.godkendtDato ? dateTimeStringFromApiToDate(responseModel.godkendtDato) : undefined;
        model.godkendtAfNavn = responseModel.godkendtAfNavn;
        model.godkendtUddannelsessted = responseModel.godkendtUddannelsessted;
        model.godkendtAfRolleTitel = responseModel.godkendtAfRolleTitel;
        model.godkendtAfAuthId = responseModel.godkendtAfAuthId;
        model.hasActiveKvSkema = responseModel.hasActiveKvSkema;
        model.fase = responseModel.fase;
        model.sortOrder = responseModel.sortOrder;
        model.meritNotat = responseModel.meritNotat;
        model.klarmeldtTilNavn = responseModel.klarmeldtTilNavn;
        return model;
    }

    static ToRequestModel(model: LaegekompetenceMeta, selectedRole: RolleEnum, roleTitle: RolleTitelEnum, godkendtUserId?: string, klarmeldtTilBrugere?: KlarmeldingTilBrugerModel[], klarmeldtTilAlleMedAdgang?: boolean): LogbogLaegekompetenceRequestModel {
        const requestModel = new LogbogLaegekompetenceRequestModel();
        this.mapToRequestModel(requestModel, model, roleTitle, godkendtUserId, klarmeldtTilBrugere, klarmeldtTilAlleMedAdgang);
        return requestModel;
    }

    static ToRequestModelList(models: LaegekompetenceMeta[], selectedRole: RolleEnum, roleTitle: RolleTitelEnum, godkendtUserId?: string, klarmeldtTilBrugere?: KlarmeldingTilBrugerModel[], klarmeldtTilAlleMedAdgang?: boolean): LogbogLaegekompetenceRequestModel[] {
        return models.map(lk => {
            const requestModel = new LogbogLaegekompetenceRequestModel();
            this.mapToRequestModel(requestModel, lk, roleTitle, godkendtUserId, klarmeldtTilBrugere, klarmeldtTilAlleMedAdgang);
            return requestModel;
        });
    }

    private static mapToRequestModel(requestModel: LogbogLaegekompetenceRequestModel, model: LaegekompetenceMeta, roleTitle: RolleTitelEnum, godkendtUserId: string | undefined, klarmeldtTilBrugere?: KlarmeldingTilBrugerModel[], klarmeldtTilAlleMedAdgang?: boolean) {
        requestModel.id = model.laegekompetenceId;
        requestModel.kompetenceId = model.kompetenceId;
        requestModel.kompetenceNummer = model.kompetenceNummer;
        requestModel.kompetenceSortOrder = model.sortOrder;
        requestModel.isFavorite = model.isFavorite;
        requestModel.status = model.status;
        requestModel.roleTitle = roleTitle;
        requestModel.godkendtUserId = godkendtUserId;
        requestModel.godkendtUddannelsessted = model.godkendtUddannelsessted;
        requestModel.klarmeldtTilBrugere = klarmeldtTilBrugere?.map(x => ({ userId: x.UserId, rolleTitelEnums: x.RolleTitelEnums } as LogbogKlarmeldTilUserRequestModel)) ?? [];
        requestModel.klarmeldtTilAlleMedAdgang = klarmeldtTilAlleMedAdgang;
    }

    laegekompetenceId: string;
    brugerMaalbeskrivelseId: string;
    kompetenceId: string;
    forloebIds: string[];
    maalbeskrivelseNavn: string;
    kompetenceNummer: string;
    titel: string;
    konkretisering: string;
    laeringsstrategi: string;
    laegeroller: string;
    kompetenceVurderingsmetoder: string;
    kompetenceVurderingsmetoderLink: string;
    underoverskrift: string;
    isFavorite: boolean;
    status: LaegekompetenceStatusEnum;
    notatCount: number;
    evalueringCount: number;
    userId: string;
    userNavn: string;
    userInitialer: string;
    godkendtAf: string;
    klarmeldtAt?: string;
    godkendtDato?: Date;
    godkendtAfNavn:string;
    godkendtAfRolleTitel: string;
    godkendtAfAuthId: string;
    godkendtUddannelsessted: string;
    hasActiveKvSkema: boolean;
    fase?: string;
    sortOrder: number;
    meritNotat: string;
    klarmeldtTilNavn?: string[];

    get fullKompetenceTitle(): string {
        const faseTitleString = !!this.fase ? ` (${this.fase})` : "";
        return `${this.titel}${faseTitleString}`
    }

    get fullKompetenceTitleWithNumber(): string {
        return `${this.kompetenceNummer} ${this.fullKompetenceTitle}`
    }
}

import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {AccountService} from "../../account/accountService";
import {LogbogAnsoegningModel} from "./logbogAnsoegningCrudModel";
import {CrudAnsoegningInputModel} from "../../../pages/sendStpsAnsoegning/ansoegning/crudAnsoegningInputModel";
import {ObjectToQueryString} from "../objectToQueryString";
import {LogbogAnsoegningResponseModel} from "./logbogAnsoegningResponseModel";
import {FileDownloadHandler} from "../logbogFile/fileDownloadHandler";
import { AnsoegningModel } from "./logbogAnsoegningModel";

export class LogbogAnsoegningApi {
    constructor(httpClientService: HttpClientService, userService: AccountService, fileDownloadHandler: FileDownloadHandler) {
        this.httpClientService = httpClientService;
        this.userService = userService;
        this.fileDownloadHandler = fileDownloadHandler;
    }

    private httpClientService: HttpClientService;
    private userService: AccountService;
    private fileDownloadHandler: FileDownloadHandler;

    private baseUrl = () => config.logbogApiUrl + "api/ansoegning/";

    private getByBruger = () => this.baseUrl() + "byBruger/";
    private attestationPdf = () => this.baseUrl() + "attestation/pdf/";
    private getForAfsendelse = () => this.baseUrl() + "getForAfsendelse/"

    async createAnsoegning(inputModel: CrudAnsoegningInputModel): Promise<string> {
        const logbogAnsoegningModel = new LogbogAnsoegningModel(inputModel.brugerId, inputModel.brugerMaalbeskrivelseId);
        const url = this.baseUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogAnsoegningModel>(url, logbogAnsoegningModel);
        return result.data;
    }

    async getAnsoegningOnBruger(userId : string, brugerMaalbeskrivelseId : string): Promise<LogbogAnsoegningResponseModel | undefined> {
        const logbogAnsoegningModel = new LogbogAnsoegningModel(userId, brugerMaalbeskrivelseId);
        const url = this.getByBruger() + ObjectToQueryString(logbogAnsoegningModel);
        const result = await this.httpClientService.Get<ApiResponse<LogbogAnsoegningResponseModel>>(url);
        if (result.data) {
            return result.data;
        } else {
            return undefined;
        }
    }

    async getAnsoegningById(id : string): Promise<LogbogAnsoegningResponseModel> {
        const url = this.baseUrl() + id;
        const result = await this.httpClientService.Get<ApiResponse<LogbogAnsoegningResponseModel>>(url);
        return result.data;
    }

    async getAnsoegningForAfsendelse(id : string): Promise<AnsoegningModel> {
        const url = this.getForAfsendelse() + id;
        const result = await this.httpClientService.Get<ApiResponse<AnsoegningModel>>(url);
        return result.data;
    }

    async downloadAttestationPdf (brugerId: string, brugerMaalbeskrivelseId: string): Promise<void> {
        const url = this.attestationPdf() + brugerId + "/" + brugerMaalbeskrivelseId;
        const blob = await this.httpClientService.DownloadFile(url, "application/pdf");

        this.fileDownloadHandler.DownloadFile(blob, "Attestation");
    }
}

import {LogbogLedigStillingResponseModel} from "../../../services/api/logbogStilling/logbogLedigStillingResponseModel";

export class LedigStillingModel {

    public static FromResponseModel(responseModel: LogbogLedigStillingResponseModel): LedigStillingModel {
        const model = new LedigStillingModel();
        model.id = responseModel.id;
        model.nummer = responseModel.nummer;
        model.loebenummer = responseModel.loebenummer;
        model.overlappendeForloeb = responseModel.overlappendeForloeb;
        model.erAltidFravaer = responseModel.erAltidFravaer;

        return model;
    }

    id: string;
    nummer: string;
    loebenummer: string;
    overlappendeForloeb: boolean;
    erAltidFravaer: boolean;
}

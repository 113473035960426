import {VejledersamtaleModel} from "../vejledersamtaleModel";
import {Localizer} from "infrastructure/localization/localizer";
import React from "react";
import {ReactComponent as BlueCircle} from "../../../../content/icons/blueCircle.svg";
import {ReactComponent as YellowCircle} from "../../../../content/icons/yellowCircle.svg";
import {ReactComponent as GreenCircle} from "../../../../content/icons/greenCircle.svg";

type VejlederAppointmentProps = {
    vejledersamtale: VejledersamtaleModel;
}

const VejledersamtalePills = ({vejledersamtale}: VejlederAppointmentProps) => {

    return (
        <>
            {vejledersamtale.klarmeldtAt && !vejledersamtale.afholdtTilkendegivetAf && !vejledersamtale.afholdtTilkendegivetDato &&
                <div className="margin-right-m ">
                        <span className="badge badge-pill badge-default padding-xs bg-white border ">
                            <YellowCircle className="margin-right-xs"/>
                            {Localizer.global_klarmeldt()}
                        </span>
                </div>
            }

            {vejledersamtale.afholdtTilkendegivetAf && vejledersamtale.afholdtTilkendegivetDato &&
                <div className="margin-right-m ">
                        <span className="badge badge-pill badge-default padding-xs bg-white border ">
                            <GreenCircle className="margin-right-xs"/>
                            {Localizer.global_afholdt()}
                        </span>
                </div>
            }

            {!vejledersamtale.klarmeldtAt && !vejledersamtale.afholdtTilkendegivetAf && !vejledersamtale.afholdtTilkendegivetDato &&
                <div className="margin-right-m ">
                        <span className="badge badge-pill badge-default padding-xs bg-white border ">
                            <BlueCircle className="margin-right-xs"/>
                            {Localizer.kursusPage_udfyldt()}
                        </span>
                </div>
            }
        </>
    );
}

export default VejledersamtalePills;

import {NotatType} from "./notatType";
import {
    FileMetadata,
    FromFileMetaDataResponseModel
} from "../fileMetaData/fileMetaData";
import {dateTimeStringFromApiToDate} from "../../../index";
import {LogbogNotatResponseModel} from "../../../services/api/logbogNotat/logbogNotatResponseModel";
import {NotatCrudModel} from "./notatCrudModel";


export class BasicNotatModel {
    id: string;
    title: string;
    brugerId: string;
    brugerName: string;
    description: string;
    type: NotatType;
    isPrivate: boolean;
    createdAt: Date;
    createdById: string;
    createdByName: string;
    createdByInitials: string;
    fileMetadatas: FileMetadata[]
    visibleForUserIds : Array<string>;
    visibleToEveryone : boolean;
    notatReleationData : NotatReleationData[]

    public GetTitel = () : string => {
        if (this.notatReleationData?.length > 0){
            return this.notatReleationData[0].contextTitle;
        }

        return "";
    }

    public GetFirstContextId = () : string => {
        return this.notatReleationData[0].contextId;
    }
}

export class NotatReleationData {
    constructor(contextId : string, contextTitle : string, addionalContextId?: string) {
        this.contextId = contextId;
        this.contextTitle = contextTitle;
        this.addionalContextId = addionalContextId;
    }

    contextId : string;
    contextTitle : string;
    addionalContextId?: string;
}

export const FromResponseModel = (responseModel: LogbogNotatResponseModel) : BasicNotatModel => {
    const model = new BasicNotatModel();
    model.id = responseModel.id;
    model.title = responseModel.title;
    model.brugerId = responseModel.brugerId;
    model.brugerName = responseModel.brugerName;
    model.description = responseModel.description;
    model.type = responseModel.type;
    model.isPrivate = responseModel.isPrivate;
    model.createdAt = dateTimeStringFromApiToDate(responseModel.createdAt);
    model.createdById = responseModel.createdById;
    model.createdByName = responseModel.createdByName;
    model.createdByInitials = responseModel.createdByInitials;
    model.fileMetadatas = responseModel.fileMetadatas.map(x => FromFileMetaDataResponseModel(x, responseModel.type));
    model.visibleForUserIds = responseModel.visibleForUserIds;
    model.visibleToEveryone = responseModel.visibleToEveryone;

    model.notatReleationData = responseModel?.notatContexts?.map(x => new NotatReleationData(x.contextId, x.contextTitle, x.addionalContextId));

    return model;
}

export const BuildNewNotat = (crudModel : NotatCrudModel, fileMetadatas : FileMetadata[]): BasicNotatModel => {
    const model = new BasicNotatModel();

    model.id = crudModel.id;
    model.title = crudModel.title;
    model.brugerId = crudModel.brugerId;
    model.description = crudModel.description;
    model.type = crudModel.type;
    model.isPrivate = crudModel.isPrivate;
    model.createdAt = new Date();
    model.fileMetadatas = fileMetadatas;
    model.visibleForUserIds = crudModel.visibleForUserIds ?? [];
    model.visibleToEveryone = crudModel.visibleToEveryone;
    model.notatReleationData = crudModel.contextIds.map(x => new NotatReleationData(x, ""));

    return model;
}

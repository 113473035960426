import {NotatType} from "../../../core/sharedmodels/notat/notatType";
import {
    IsPrivateNote,
    IsVisibleForAllNote,
    NotatCrudModel,
    VisibleForUserIdsFilter
} from "../../../core/sharedmodels/notat/notatCrudModel";
import {StempelInputModel} from "../stempel/stempelInputModel";

export class LogbogNotatCrudPostModel {
    NotatInputModel: LogbogNotatPostModel;
    ContextIds: string[];

    public static Build(notat: NotatCrudModel,
                        filemetadataIds?: string[],
                        stempel?: StempelInputModel): LogbogNotatCrudPostModel {
        const model = new LogbogNotatCrudPostModel();
        model.NotatInputModel = new LogbogNotatPostModel(notat, filemetadataIds, stempel);
        model.ContextIds = notat.contextIds;
        return model;
    }
}

export class LogbogNotatPostModel {
    constructor(notatModel: NotatCrudModel, filemetadataIds?: string[], stempel?: StempelInputModel) {
        this.id = notatModel.id;
        this.brugerId = notatModel.brugerId;
        this.title = notatModel.title;
        this.description = notatModel.description;
        this.type = notatModel.type;
        this.fileMetadatas = filemetadataIds;
        this.stempelInputModel = stempel;


        if (notatModel.visibleForUserIds && notatModel.visibleForUserIds.length > 0) {
            this.isPrivate = IsPrivateNote(notatModel.visibleForUserIds);
            this.visibleToEveryone = IsVisibleForAllNote(notatModel.visibleForUserIds);
            this.visibleForUserIds = VisibleForUserIdsFilter(notatModel.visibleForUserIds);
        } else {
            this.isPrivate = notatModel.isPrivate;
            this.visibleToEveryone = notatModel.visibleToEveryone;
            this.visibleForUserIds = [];
        }
    }

    id: string;
    brugerId: string;
    title: string;
    description: string;
    type: NotatType;
    isPrivate: boolean;
    visibleToEveryone: boolean;
    visibleForUserIds?: string[];
    fileMetadatas?: string[];
    stempelInputModel?: StempelInputModel;
}

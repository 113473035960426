import React from "react";
import {YesNoRadio} from "../../kompetenceEvaluering/yesNoRadio";
import {SvarInputTypeProps} from "./svarInputTypeCompareMap";
import classNames from "classnames";
import {Datepicker} from "../../../core/components/datePicker/datePicker";
import {Localizer} from "../../../infrastructure/localization/localizer";

export function DatoOgGodkendtInputSvar ({spoergsmaal, svarNummerIndex, kompetenceSvarList, indsendt, dateGodkendtCallback, godkendtCallback, readOnly}: SvarInputTypeProps) {
    return (
        <div
            className={classNames("margin-bottom-m", spoergsmaal.antalSvar > 1 && "border padding-m datacard-border-radius")}
            key={`${spoergsmaal.id + svarNummerIndex}`}>
            <Datepicker
                id={`datepicker-${spoergsmaal.id + svarNummerIndex}`}
                label={Localizer.evalueringPage_datoForGodkendelsesstatus()}
                defaultValue={kompetenceSvarList.find(x => x.svarIndex === svarNummerIndex)?.dateSvar}
                disabled={indsendt || readOnly}
                additionalClasses={"margin-bottom-m"}
                setDate={(date: Date) => dateGodkendtCallback(svarNummerIndex, date)}
            />
            <YesNoRadio
                id={spoergsmaal.id + svarNummerIndex}
                yesNo={kompetenceSvarList.find(x => x.svarIndex === svarNummerIndex)?.booleanSvar}
                addBooleanSvar={x => godkendtCallback(x, svarNummerIndex)}
                disabled={indsendt || readOnly}/>
        </div>
    )
}

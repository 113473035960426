import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from "../../app/store";


export interface ForloebTableState {
    forloebIds: string[]
}

const initialState: ForloebTableState = {
    forloebIds: []
};

export const forloebTableSlice = createSlice({
    name: 'forloebTable',
    initialState,
    reducers: {
        setForloebTableState: (state, action: PayloadAction<string[]>) => {
            state.forloebIds = action.payload;
        }
    }
});

export const { setForloebTableState } = forloebTableSlice.actions;
export const forloebTableReducer = (state: RootState) => state.forloebTableReducer;
export default forloebTableSlice.reducer;

import { Form, Formik, FormikHelpers, FormikProps, FormikValues } from "formik";
import React, { ReactElement } from "react";
import {CreateValidationSchemaReturnType} from "../createValidationSchema";
import { ValidationWarningIndicator } from "../ValidationInputHelper";


interface ValidationFormProps<T> {
  initialValues: T,
  onSubmit: (values: T, formik: FormikHelpers<T>) => void;
  validationSchema: CreateValidationSchemaReturnType<T>,
  warningSchema?: CreateValidationSchemaReturnType<T>,
  children: (formik: FormikProps<T>) => ReactElement;
  id?: string;
}

export const ValidationForm = <T extends FormikValues,>(props: React.PropsWithChildren<ValidationFormProps<T>>) => {

  return (
    <Formik
      initialValues={props.initialValues}
      onSubmit={props.onSubmit}
      validationSchema={props.validationSchema}>
      {
        (formik) => {
          if(props.warningSchema) {
            props.warningSchema.validate(formik.values, {abortEarly: false})
              .catch((warnings) => {
                  if(warnings.inner.length > 0) {
                      warnings.inner.forEach((warning: { path: string; errors: string[]; }) => {
                        if(!formik.getFieldMeta(warning.path).error) {
                            formik.setFieldError(warning.path, ValidationWarningIndicator + warning.errors[0]);
                        }
                      })
                  }
              });
          }
          return (
            <Form id={props.id}>
              {props.children(formik)}
            </Form>
          );
        }
      }
    </Formik>
  );
}

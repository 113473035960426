import {RoutePath} from "./routePathConstants";

/**
 * Converts a given type into a query parameter for an URL.
 * - An property will become as "/nameof<property>/value".
 * - Undefined values wont be converted.
 */
export default function BuildQueryParams<T> (queryParams: T, routePath: RoutePath, tailName?: string, hash?: string, isPartial=false) {
    const separator = "/";
    const urlQueryParams = toUrlQueryParams(queryParams, separator);
    const tail = tailName ? separator+tailName : "";
    const hashes = hash ? `#${hash}` : "";
    const base = isPartial ? "" : `${routePath.basePath ?? ""}${separator}`;
    return base + urlQueryParams + tail + hashes
}

function toQuery<T>(obj: T, prop: string, separator = "/") {
    const propertyDescriptor = Object.getOwnPropertyDescriptor(obj, prop);
    const hasValue = Boolean(propertyDescriptor?.value);
    return !hasValue ? "" : `${prop}${separator}${propertyDescriptor?.value}`.toLowerCase();
}

function toUrlQueryParams<T> (obj: T, separator="/") : string {
    const props = Object.getOwnPropertyNames(obj);
    const queries = props.map(p => toQuery(obj, p, separator));
    return queries.filter(x => !!x).join(separator);
}

import {
    LogbogKompetenceSvarResponseModel
} from "../../../services/api/logbogEvaluering/logbogKompetenceSvarResponseModel";
import {SpoergsmaalType} from "./spoergsmaalType";
import {dateTimeStringFromApiToDate} from "../../../index";
import {LogbogSvarmulighedResponseModel} from "../../../services/api/logbogEvaluering/logbogSvarmulighedResponseModel";

export class KompetenceSvarModel {
    public static FromResponseModel(svarResponseModel: LogbogKompetenceSvarResponseModel, svarmuligheder: LogbogSvarmulighedResponseModel[]){
        let model = new KompetenceSvarModel();

        model.kommentar = svarResponseModel.kommentar;
        model.ikkeRelevant = svarResponseModel.ikkeRelevant;
        model.id = svarResponseModel.id;
        model.tekstSvar = svarResponseModel.tekstSvar;
        model.spoergsmaalId = svarResponseModel.spoergsmaalId;
        model.svarmulighedId = svarResponseModel.svarmulighedId;
        model.kompetenceEvalueringId = svarResponseModel.kompetenceEvalueringId;
        model.dateSvar = svarResponseModel.dateSvar ? dateTimeStringFromApiToDate(svarResponseModel.dateSvar) : undefined;
        model.nummerSvar = svarResponseModel.nummerSvar
        model.svarIndex = svarResponseModel.svarIndex
        model.booleanSvar = svarResponseModel.booleanSvar
        model.svarVaerdi = svarmuligheder.find(x => x.id === svarResponseModel.svarmulighedId)?.svarVaerdi;
        model.farvekode = svarmuligheder.find(x => x.id === svarResponseModel.svarmulighedId)?.farvekode;
        model.udeladSvarVaerdi = svarmuligheder.find(x => x.id === svarResponseModel.svarmulighedId)?.udeladSvarVaerdi;
        model.spoergsmaalType = svarResponseModel.spoergsmaalType;

        return model;
    }

    id: string;
    kompetenceEvalueringId: string;
    svarIndex: number;
    spoergsmaalId: string;
    svarmulighedId?: string;
    tekstSvar?: string;
    kommentar?: string;
    svarVaerdi?: number;
    farvekode?: string;
    udeladSvarVaerdi?: boolean;
    dateSvar?: Date;
    booleanSvar?: boolean;
    nummerSvar?: number;
    spoergsmaalType?: SpoergsmaalType;
    ikkeRelevant: boolean;
}

import {SpoergsmaalType} from "../../../core/sharedmodels/evaluering/spoergsmaalType";
import {KompetenceSvarModel} from "../../../core/sharedmodels/evaluering/KompetencesvarModel";
import {KompetenceSpoergsmaalModel} from "../../../core/sharedmodels/evaluering/kompetenceSpoergsmaalModel";
import React from "react";
import {DatoReadSvar} from "./datoReadSvar";
import {MultiReadSvar} from "./multiReadSvar";
import {TekstReadSvar} from "./tekstReadSvar";
import {NummerReadSvar} from "./nummerReadSvar";
import {BooleanReadSvar} from "./booleanReadSvar";
import {DatoOgGodkendtReadSvar} from "./datoOgGodkendtReadSvar";

export type SvarReadTypeProps = {
    evalueringSvar: KompetenceSvarModel;
    spoergsmaal: KompetenceSpoergsmaalModel;
    showScoreText?: boolean;
    excludeAnswerText?: boolean;
    renderId?: string; //used to render unique id for each yes/no radio, if we have both slidein and card shown on screen
}

export const svarReadTypeCompareMap: Record<SpoergsmaalType, React.ComponentType<SvarReadTypeProps>> = {
    [SpoergsmaalType.Multi]: MultiReadSvar,
    [SpoergsmaalType.Dato]: DatoReadSvar,
    [SpoergsmaalType.Tekst]: TekstReadSvar,
    [SpoergsmaalType.Nummer]: NummerReadSvar,
    [SpoergsmaalType.Boolean]: BooleanReadSvar,
    [SpoergsmaalType.DatoOgGodkendt]: DatoOgGodkendtReadSvar,
};

import {TidsbegraensetAdgangInputModel} from "../../../core/componentsPage/forloeb/tidsbegraensetAdgangInputModel";


export class ForloebAdgangCreatePostModel {
    constructor(inputModel: TidsbegraensetAdgangInputModel) {
        this.dato = inputModel.dato.dateToApiPostFormat();
        this.forloebId = inputModel.forloebId;
        this.brugerId = inputModel.brugerId;
    }

    public dato: string;
    public forloebId: string;
    public brugerId: string;
}


import useLogbogApi from "core/hooks/useLogbogApi";
import { NotifikationEventTypeEnum } from "../../models/notificationModel";
import { LogbogNotifikationEventIndstillingPatchModel } from "services/api/logbogNotifikationIndstilling/logbogNotifikationEventIndstillingPatchModel";
import { NotificationModule } from "ditmer-embla";
import { Localizer } from "infrastructure/localization/localizer";

const handleShowNotificationSuccess = () => {
  const message = Localizer.notifikationer_NotifikationsIndstillingerBlevOpdateret();

  const toastContainer = $("#toast-container");
  const toastMessages = toastContainer.children().map((index, element) => element.innerText).get();

  if (!toastMessages.some(x => x.includes(message))) {
    NotificationModule.timeout = 1000;
    NotificationModule.showSuccessSmall(message);
    NotificationModule.timeout = 3000;
  }
}

const useNotificationSettingMutations = () => {
  const { logbogNotifikationIndstillingApi } = useLogbogApi();

  const updateNotificationEventSetting = async (eventType: NotifikationEventTypeEnum, patchModel: LogbogNotifikationEventIndstillingPatchModel) => {
    const result = await logbogNotifikationIndstillingApi.updateNotificationEventSetting(eventType, patchModel);
    
    if(result) {
      handleShowNotificationSuccess();
    }
  }

  return {
    updateNotificationEventSetting
  }
}

export default useNotificationSettingMutations;
import {HttpClientService} from "../../httpClient/httpClientService";
import {ApiResponse} from "../apiResponse";
import config from "../../../config/config";
import {FileMetadata} from "../../../core/sharedmodels/fileMetaData/fileMetaData";
import {ObjectToQueryString} from "../objectToQueryString";
import { LogbogSpecialespecifikFileMetadataResponseModel } from "./LogbogSpecialespecifikFileMetadataResponseModel";
import { LogbogSpecialespecifikkeFilerByBrugerMaalbeskrivelseRequestModel } from "./logbogSpecialespecifikkeFilerByBrugerMaalbeskrivelseRequestModel";
import { LogbogSpecialespecifikkeFilerRequestModel } from "./logbogSpecialespecifikkeFilerRequestModel";

export class LogbogSpecialespecifikkeFilerApi {
    private httpClientService: HttpClientService;
    private specialespecifikkeFilerBaseUrl = () => config.logbogApiUrl + "api/SpecialespecifikkeFiler/";//SpecialespecifikkeFiler/";
    private getAllOnRegioner = () => this.specialespecifikkeFilerBaseUrl() + "GetAllOnRegion/";//"GetAllOnRegioner/regionId/"
    private getByRegionAndBrugerMaalbeskrivelseUrl = () => this.specialespecifikkeFilerBaseUrl() + "GetByRegionAndBrugerMaalbeskrivelse/";

    constructor(httpClientService: HttpClientService) {
        this.httpClientService = httpClientService;
    }

    async getSpecialespecifikkeFilerOnRegion(regionId: string, maalbeskrivelseId: string | undefined): Promise<FileMetadata[]> {
        const requestModel = new LogbogSpecialespecifikkeFilerRequestModel(regionId, maalbeskrivelseId);
        const url = this.getAllOnRegioner() + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.Get<ApiResponse<FileMetadata[]>>(url);
        return result.data;
    }

    async getSpecialespecifikkeFilerByRegionAndBrugerMaalbeskrivelse(regionId: string, brugerMaalbeskrivelseId: string | undefined): Promise<LogbogSpecialespecifikFileMetadataResponseModel[]> {
        const requestModel = { regionId, brugerMaalbeskrivelseId } as LogbogSpecialespecifikkeFilerByBrugerMaalbeskrivelseRequestModel;
        const url = this.getByRegionAndBrugerMaalbeskrivelseUrl() + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.Get<ApiResponse<LogbogSpecialespecifikFileMetadataResponseModel[]>>(url);
        return result.data;
    }
}

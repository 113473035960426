import { DateFilterType } from "core/components/forloebOverview/ForloebOverviewDateFilterModel";
import {LogbogFilterItemResponseModel} from "../../../services/api/logbogFilter/logbogFilterItemResponseModel";
import {nameof} from "ts-simple-nameof";

export class FilterModel {
    id : string;
    titel: string;
    filterItems: Array<LogbogFilterItemResponseModel>;
    regionRettigheder?: Array<string>;
    isPrivate: boolean;
    dateFilterType: DateFilterType;
}

export const PrivateNoteKey = nameof<FilterModel>(x => x.isPrivate);

export const IsPrivateFilter = (regionRettigheder?: Array<string>): boolean => {
    return  regionRettigheder != undefined ? regionRettigheder?.some(x => PrivateNoteKey == x) : false;
}

export const RegionRettighederFilter = (regionRettigheder?: Array<string>): Array<string> => {
    return regionRettigheder?.filter(x => PrivateNoteKey != x) ?? [];
}

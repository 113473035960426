import {useEffect, useState} from 'react'
import {useAppDispatch} from "../../../app/hooks";
import {Localizer} from '../../../infrastructure/localization/localizer';
import {
    BuildExisting,
    CrudForloebInputModel,
    crudForloebValidationSchema,
    crudForloebWarningSchema
} from './crudForloebInputModel';
import {ValidationForm} from 'core/components/validation/components/validationForm';
import {ForloebCrudFields} from "./forloebCrudFields";
import {NotificationModule} from 'ditmer-embla';
import {HandleError} from "../../infrastructure/errors/errorBoundary";
import {ForloebModel} from "../../sharedmodels/forloeb/forloebModel";
import {setSlideinState, SlideInStateEnum} from "../../components/slideIn/slideInSlice";
import {ValidationFormSubmitButton} from "../../components/validation/components/validationFormButton";
import {pingRefreshForloeb} from "../../forloebSlice";
import {FravaerFields} from "./fravaerFields";
import {ValidationTextarea} from "../../components/validation/components/validationTextarea";
import {nameof} from "ts-simple-nameof";
import Tooltip from "../../components/tooltips/Tooltip";
import useLogbogApi from 'core/hooks/useLogbogApi';
import useUser from 'core/hooks/useUser';
import {ForloebSkalIndgaaIEvalueringsstatistikInputModel} from "./forloebSkalEvalueresInputModel";
import { FravaerskodeEnum } from 'core/sharedmodels/forloeb/fravaerskoderEnum';

type EditForloebProps = {
    modalId: string,
    model: ForloebModel,
    forloebList?: ForloebModel[],
    afslutForloebClickedCallback: () => void,
    areYouSureAfslutModalId: string,
    deleteForloebClickedCallback: (forloebHasData?: boolean) => void,
    areYouSureDeleteModalId: string,
    areYouSuredeleteForloebEvalueringModalId: string,
    deleteForloebEvalueringClickedCallback: (inputModel: CrudForloebInputModel, godkendtAfsluttetEllerEvalueret: boolean) => void,
}

export function EditForloeb(props: EditForloebProps) {
    const dispatch = useAppDispatch();
    const [forloebListState, setForloebListState] = useState<ForloebModel[]>(props.forloebList ?? new Array<ForloebModel>());
    const { currentUser } = useUser();
    const forloebHasBeenManuallyEnded = props.model.manueltAfsluttet;
    const forloebIsAfsluttet = props.model.manueltAfsluttet;
    const forloebGodkendtDato = props.model.godkendtDen?.dateWithoutTimeFormat(true);
    const forloebIsGodkendt = (forloebGodkendtDato !== undefined && forloebGodkendtDato !== null);
    const forloebEvalueretDato = props.model.evalueretDato?.dateWithoutTimeFormat(true);
    const forloebIsEvalueret = (forloebEvalueretDato !== undefined && forloebEvalueretDato !== null);
    const cantDeleteForloeb = forloebHasBeenManuallyEnded || forloebIsGodkendt || forloebIsEvalueret;
    const [canDeleteForloeb, setCanDeleteForloeb] = useState(false);
    const [forloebHasData, setForloebHasData] = useState(false);
    const {forloebApi, forloebEvalueringApi} = useLogbogApi();

    useEffect(() => {
        const getForloebList = async () => {
            const forloebListByBruger = await forloebApi.getForloebByBruger(props.model.brugerId);
            setForloebListState(forloebListByBruger);
        }

        if (forloebListState === undefined || forloebListState.length === 0) {
            getForloebList();
        }
    }, [forloebApi, forloebListState, props.model.brugerId])

    useEffect(() => {
        if (cantDeleteForloeb) {
            setCanDeleteForloeb(false);
            setForloebHasData(true);
            return;
        }

        forloebApi.canDeleteForloeb({
            forloebId: props.model.id,
            brugerMaalbeskrivelseId: props.model.brugerMaalbeskrivelseId,
            brugerKursusraekkeId: props.model.brugerKursusraekkeId
        }).then((canDeleteForloebResponse) => {
                setCanDeleteForloeb(canDeleteForloebResponse || currentUser.IsAdmin())
                setForloebHasData(!canDeleteForloebResponse)
            }
        );
    }, [cantDeleteForloeb, currentUser, forloebApi, props.model.brugerKursusraekkeId, props.model.brugerMaalbeskrivelseId, props.model.id]);

    const SetEditForloebSuccess =  (successMessages: string[]) => {
        dispatch(setSlideinState({ state: SlideInStateEnum.Closing, slideInId: props.modalId }))
        dispatch(pingRefreshForloeb);
        successMessages.forEach((message) => {
            NotificationModule.showSuccess(message, "");
        })
    }

    function skalIndgaaIEvalueringsstatistikStatusChanged() {
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: props.modalId}))
        dispatch(pingRefreshForloeb);
        NotificationModule.showSuccess(Localizer.forloebpage_forloebSkalIndgaaIEvalueringsstatistikChanged(), "");
    }

    async function setSkalIndgaaIEvalueringsstatistikStatusOnForloeb(inputModel: ForloebSkalIndgaaIEvalueringsstatistikInputModel) {
        const skalIndgaaIEvalueringsstatistikSetForForloebId = await forloebApi.setForloebEvalueringsstatistikStatus(inputModel);
        if (skalIndgaaIEvalueringsstatistikSetForForloebId)
            SetEditForloebSuccess([Localizer.forloebpage_forloebEdited()]);
    }

    const editForloeb = async (inputModel: CrudForloebInputModel) => {
        if(!inputModel.erFravaer)
            inputModel.fravaerskode = undefined;

        const forloebevalueringExists = await forloebEvalueringApi.forloebEvalueringExists(props.model.id);
        const godkendtAfsluttetEllerEvalueret = forloebIsAfsluttet || forloebIsGodkendt || forloebIsEvalueret;

        const evalueringStatusChanged =
            props.model.skalIndgaaIEvalueringsstatistik !== inputModel.skalIndgaaIEvalueringsstatistik
            || (inputModel.skalIndgaaIEvalueringsstatistik && props.model.senesteDatoForEvaluering?.dateWithoutTimeFormat(true) !== inputModel.senesteDatoForEvaluering?.dateWithoutTimeFormat(true));

        const editForloebByInput = async () => {
            const editedForloebId = await forloebApi.editForloeb(inputModel);
            if (editedForloebId) {
                SetEditForloebSuccess([inputModel.erFravaer ? Localizer.forloebpage_fravaerEdited() : Localizer.forloebpage_forloebEdited()]);
            }
        }

        if (inputModel.skalIndgaaIEvalueringsstatistik && !inputModel.erFravaer) {

            dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))

            if (!forloebevalueringExists) {
                const forloebEvalueringCreated = await forloebEvalueringApi.createForloebEvaluering(props.model.id);
                if (!forloebEvalueringCreated)
                    NotificationModule.showError(Localizer.forloebpage_fejlVedForloebEvalueringOprettelse(), "")
            }

            if (!godkendtAfsluttetEllerEvalueret) {
                await editForloebByInput();
            } else if (evalueringStatusChanged)
                await setSkalIndgaaIEvalueringsstatistikStatusOnForloeb(new ForloebSkalIndgaaIEvalueringsstatistikInputModel(inputModel.id, inputModel.skalIndgaaIEvalueringsstatistik, inputModel.senesteDatoForEvaluering?.dateToApiPostFormat()));

        } else if (!inputModel.skalIndgaaIEvalueringsstatistik && forloebevalueringExists && evalueringStatusChanged && !inputModel.erFravaer) {
            props.deleteForloebEvalueringClickedCallback(inputModel, godkendtAfsluttetEllerEvalueret);
            let modalId = "#"+props.areYouSuredeleteForloebEvalueringModalId;
            $(modalId).modal('show');

        } else if (!inputModel.skalIndgaaIEvalueringsstatistik && !forloebevalueringExists && evalueringStatusChanged && !inputModel.erFravaer) {
            const skalIndgaaIEvalueringsstatistikChangedForloebId = await forloebApi.setForloebEvalueringsstatistikStatus(new ForloebSkalIndgaaIEvalueringsstatistikInputModel(inputModel.id, inputModel.skalIndgaaIEvalueringsstatistik, inputModel.senesteDatoForEvaluering?.dateToApiPostFormat()));
            if (skalIndgaaIEvalueringsstatistikChangedForloebId)
                skalIndgaaIEvalueringsstatistikStatusChanged();

        } else if (!godkendtAfsluttetEllerEvalueret) {
            await editForloebByInput();
        }
    }

    const createFravaer = async (inputModel: CrudForloebInputModel) => {

        const requiredFieldsIsDefined =
            inputModel.fravaerStartDato !== undefined &&
            inputModel.fravaerSlutDato !== undefined &&
            inputModel.startDato !== undefined &&
            inputModel.slutDato !== undefined &&
            inputModel.fravaerskode !== undefined && inputModel.fravaerskode.toString() !== "";

        if(!await crudForloebValidationSchema.isValid(inputModel) || !requiredFieldsIsDefined) {
            NotificationModule.showError(Localizer.forloebpage_errorFravaerCreate(), "");
            return;
        }

        dispatch(setSlideinState({ state: SlideInStateEnum.ActionOngoing, slideInId: props.modalId }))

        const cloneFravaer = Object.assign({}, inputModel);
        cloneFravaer.startDato = inputModel.fravaerStartDato;
        cloneFravaer.slutDato = inputModel.fravaerSlutDato;
        cloneFravaer.fravaerskode = inputModel.fravaerskode ? +inputModel.fravaerskode : 0;
        cloneFravaer.erFravaer = true;
        cloneFravaer.skalIndgaaIEvalueringsstatistik = false;
        cloneFravaer.senesteDatoForEvaluering = undefined;

        // Case 0: Midlertidig fravaer - Create Fravær without modifying existing forloeb:
        if (cloneFravaer.fravaerskode === FravaerskodeEnum.FravaerMidlertidig) {
            const createdForloeb = await forloebApi.createForloeb(cloneFravaer);
            if (createdForloeb) {
                SetEditForloebSuccess([Localizer.FravaerSaved()])
            }
        }
        // Case 1: Replace Forløb with Fravær
        else if (cloneFravaer.fravaerStartDato?.dateWithoutTimeFormat(false) === inputModel.startDato?.dateWithoutTimeFormat(false)) {
            const createdFravearForloebResponse = await forloebApi.createForloeb(cloneFravaer);

            if (createdFravearForloebResponse) {
                const deleteForloebResponse = await forloebApi.DeleteForloeb(inputModel.id, inputModel.brugerId);

                if (deleteForloebResponse) {
                    SetEditForloebSuccess([Localizer.FravaerSaved(), Localizer.forloebpage_forloebDeleted()])
                }
            }
        }
         // Case 2: Modify Forløb + Create Fravær
        else if (cloneFravaer.startDato! < inputModel.slutDato!) {
            const cloneForloeb = Object.assign({}, inputModel);

            const cloneForloebNewEndDate = new Date(cloneFravaer.startDato!);
            cloneForloebNewEndDate.setDate(cloneForloebNewEndDate.getDate() - 1);

            cloneForloeb.fravaerskode = undefined;
            cloneForloeb.slutDato = cloneForloebNewEndDate;

            let deleteResponse = undefined;
            if(inputModel.evalueretDato !== undefined){
                deleteResponse = await forloebApi.deleteEvalueringOnForloeb(inputModel.id);
            }

            if(inputModel.evalueretDato === undefined || deleteResponse !== undefined){
                const createdForloeb = await forloebApi.createForloeb(cloneFravaer);
                const editedForloebId = await forloebApi.editForloeb(cloneForloeb);

                if (createdForloeb && editedForloebId) {
                    SetEditForloebSuccess([Localizer.FravaerSaved(), Localizer.EndDateChanged()])
                }
            }
        }
        // Case 3: Create Fravær
        else {
            const createdForloeb = await forloebApi.createForloeb(cloneFravaer);
            if (createdForloeb) {
                SetEditForloebSuccess([Localizer.FravaerSaved()])
            }
        }
    }

    const render = (forloebListState.length > 0 &&
        <ValidationForm
            initialValues={BuildExisting(props.model.brugerId, props.model.stillingId, props.model.startDate, props.model.endDate, props.model.skalIndgaaIEvalueringsstatistik, props.model.irrelevant, props.model.seAlleKompetencer, props.model.id, forloebListState, props.model.erFravaer, props.model.fravaerskodeEnum, props.model.evalueretDato, undefined, undefined, props.model.attestationForTidInfoTekst, props.model.senesteDatoForEvaluering)}
            validationSchema={crudForloebValidationSchema}
            warningSchema={crudForloebWarningSchema}
            onSubmit={async (values) => {
                await editForloeb(values)
                    .catch(err => { // catch is needed to handle unhandeled exception (eg. api offline). Formik onsubmithandler is catching all exception and writing them as warnings
                        dispatch(setSlideinState({ state: SlideInStateEnum.Opened, slideInId: props.modalId }))
                        HandleError(err);
                    });
            }}>
            {
                (formik) => {
                    return (
                        <>
                            <ForloebCrudFields modalId={props.modalId}
                                               formik={formik}
                                               stillingReadonly={true}
                                               forloebIsAfsluttet={forloebIsAfsluttet}
                                               forloebIsGodkendt={forloebIsGodkendt}
                                               forloebGodkendtDato={forloebGodkendtDato}
                                               forloebFraVU={props.model.kommerFraVU}
                                               forloebIsEvalueret={forloebIsEvalueret}
                            />

                            {(currentUser.IsAdminOrVus() ||currentUser.IsHrMedarbejder()) &&
                                <div className="row">
                                    <div className="col-12">
                                        <ValidationTextarea
                                            model={{
                                                label: Localizer.forloebpage_BemaerkningerTilForloeb(),
                                                placeholder: Localizer.forloebpage_BemaerkningerTilForloebLabel(),
                                                htmlName: nameof<CrudForloebInputModel>(x => x.infoTekst)
                                            }}
                                            inputId={nameof<CrudForloebInputModel>(x => x.infoTekst)}
                                            formikProps={formik}
                                            readOnly={forloebIsAfsluttet || props.model.kommerFraVU || forloebIsGodkendt || forloebIsEvalueret}
                                        />
                                    </div>
                                </div>
                            }

                            {(!props.model.erFravaer && !forloebIsGodkendt) &&
                                <FravaerFields modalId={props.modalId}
                                                 formik={formik}
                                                 createFravaer={createFravaer}
                                                 forloebFraVU={props.model.kommerFraVU}
                                                 forloebHasEnded={forloebIsAfsluttet}
                                />
                            }
                            <div className="modal-actions">
                                {currentUser.CanDeleteForloeb() &&
                                    <Tooltip show={forloebHasData} title={currentUser.IsAdminOrVus() ? Localizer.forloebEdit_OBSDataPaaForloebDuSletterErDuSikkerDeleteForloeb() : Localizer.forloebEdit_CannotDeleteReason()}>
                                        <button className="btn btn-danger mr-2"
                                                disabled={!canDeleteForloeb && !currentUser.IsAdminOrVus()}
                                                onClick={() => props.deleteForloebClickedCallback(forloebHasData)}
                                                data-toggle="modal" data-target={`#${props.areYouSureDeleteModalId}`}
                                                data-dismiss="modal">
                                            {Localizer.forloebEdit_DeleteForloeb()}
                                        </button>
                                    </Tooltip>
                                }

                                <ValidationFormSubmitButton formikProps={formik} disabled={(forloebIsAfsluttet || forloebIsGodkendt || forloebIsEvalueret) && props.model.skalIndgaaIEvalueringsstatistik === formik.values.skalIndgaaIEvalueringsstatistik && props.model.senesteDatoForEvaluering?.dateWithoutTimeFormat(true) === formik.values.senesteDatoForEvaluering?.dateWithoutTimeFormat(true)}>
                                    {Localizer.forloebEdit_SaveForloeb()}
                                </ValidationFormSubmitButton>
                            </div>
                        </>
                    );
                }
            }
        </ValidationForm>
    )

    return <>{render}</>
}

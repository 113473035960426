import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {AccountService} from "../../account/accountService";
import {StamdataMaalbeskrivelseResponseModel} from "./stamdataMaalbeskrivelseResponseModel";
import {StamdataMaalbeskrivelseModel} from "./stamdataMaalbeskrivelseModel";
import {StamdataMaalbeskrivelserForSelectModel} from "./stamdataMaalbeskrivelserForSelectModel";
import {StamdataMaalbeskrivelserForSelectModelResponseModel} from "./stamdataMaalbeskrivelserForSelectModelResponseModel";
import {ObjectToQueryString} from "../objectToQueryString";
import {UddannelsesmaalbeskrivelseRequestModel} from "./uddannelsesmaalbeskrivelseRequestModel";
import {BrugerMaalbeskrivelseResponseModel} from "./brugerMaalbeskrivelseResponseModel";
import {BrugerMaalbeskrivelserForSelectModelResponseModel} from "./brugerMaalbeskrivelserForSelectModelResponseModel";
import {BrugerMaalbeskrivelserForSelectModel} from "./brugerMaalbeskrivelserForSelectModel";
import { UddannelseTypeEnum } from "core/sharedmodels/stilling/uddannelseTypeEnum";
import { stamdataCanSendStpsDocumentationRequestModel } from "./stamdataCanSendStpsDocumentationRequestModel";

export class StamdataMaalbeskrivelseApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = () => config.stamdataApiUrl + "api/maalbeskrivelse/";
    private aktivMaalbeskrivelseUrl = () => this.baseUrl() + "aktiv/";
    private aktivBrugerMaalbeskrivelseUrl = () => this.baseUrl() + "aktivBrugerMaalbeskrivelse/";
    private maalbeskrivelserForSelectUrl = () => this.baseUrl() + "maalbeskrivelserForSelect/";
    private maalbeskrivelserForSelectByBrugerUrl = () => this.baseUrl() + "maalbeskrivelserForSelectByBruger";
    private maalbeskrivelserForSelectByBrugerAndUddannelseUrl = () => this.baseUrl() + "maalbeskrivelserForSelectByBrugerAndUddannelse";
    private canSendStpsDocumentationUrl = () => this.baseUrl() + "canSendStpsDocumentation/";

    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    async getMaalbeskrivelse(brugerMaalbeskrivelseId: string): Promise<StamdataMaalbeskrivelseModel> {
        const url = this.aktivMaalbeskrivelseUrl() + brugerMaalbeskrivelseId;
        const result = await this.httpClientService.Get<ApiResponse<StamdataMaalbeskrivelseResponseModel>>(url);
        return new StamdataMaalbeskrivelseModel(result.data);
    }

    async getBrugerMaalbeskrivelse(brugerMaalbeskrivelseId: string): Promise<BrugerMaalbeskrivelseResponseModel> {
        const url = this.aktivBrugerMaalbeskrivelseUrl() + brugerMaalbeskrivelseId;
        const result = await this.httpClientService.Get<ApiResponse<BrugerMaalbeskrivelseResponseModel>>(url);
        return result.data;
    }

    async getMaalbeskrivelseSelect(stillingId: string): Promise<StamdataMaalbeskrivelserForSelectModel[]> {
        const url = this.maalbeskrivelserForSelectUrl() + stillingId;
        const result = await this.httpClientService.Get<ApiResponse<StamdataMaalbeskrivelserForSelectModelResponseModel[]>>(url);
        return result.data.map(x => new StamdataMaalbeskrivelserForSelectModel(x));
    }

    async getBrugerMaalbeskrivelseSelectByBruger(brugerId: string, brugerMaalbeskrivelseId?: string) {
        const requestModel = {brugerId, brugerMaalbeskrivelseId}
        const url = this.maalbeskrivelserForSelectByBrugerUrl() + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.Get<ApiResponse<BrugerMaalbeskrivelserForSelectModelResponseModel[]>>(url);
        return result.data.map(x => new BrugerMaalbeskrivelserForSelectModel(x));
    }

    async getBrugerMaalbeskrivelseSelectByUddannelse(brugerId: string, stillingId: string, brugerMaalbeskrivelseId?: string) {
        const requestModel = new UddannelsesmaalbeskrivelseRequestModel(brugerId, stillingId, brugerMaalbeskrivelseId)
        const url = this.maalbeskrivelserForSelectByBrugerAndUddannelseUrl() + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.Get<ApiResponse<BrugerMaalbeskrivelserForSelectModelResponseModel[]>>(url);
        return result.data.map(x => new BrugerMaalbeskrivelserForSelectModel(x));
    }

    async canUserSendStpsDocumentation(brugerId: string, brugerMaalbeskrivelseId: string, uddannelseType: UddannelseTypeEnum): Promise<boolean>
    {
        const requestModel = new stamdataCanSendStpsDocumentationRequestModel(brugerId, brugerMaalbeskrivelseId, uddannelseType)
        const url = this.canSendStpsDocumentationUrl() + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.Get<ApiResponse<boolean>>(url);
        return result.data;
    }
}

import config from "config/config";
import ForloebCrudFilterRestrictModel, { ToRequestModelFromForloebOverviewFilterModel } from "core/sharedmodels/restrictModel/ForloebCrudFilterRestrictModel";
import { HttpClientService } from "services/httpClient/httpClientService";
import { ApiResponse } from "../apiResponse";
import ForloebCrudFilterRestrictReponseModel from "./reponseModels/forloebCrudFilterRestrictReponseModel";
import ForloebCrudFilterRestrictRequestModel from "./requestModels/forloebCrudFilterRestrictRequestModel";
import { ForloebOverviewFilterModel } from "core/components/forloebOverview/ForloebOverviewFilterModel";

class StamdataRestrictApi {
    private baseUrl = config.stamdataApiUrl + "api/restrict/";
    private postRestrictForloeboverviewFilterUrl = this.baseUrl + "forloeboverview/filter";

    constructor(private httpClientService: HttpClientService) {}

    async getRestrictionForloebCrudFilter(fitler: ForloebOverviewFilterModel): Promise<ForloebCrudFilterRestrictModel> {
        const requestModel = ToRequestModelFromForloebOverviewFilterModel(fitler);
        const url = this.postRestrictForloeboverviewFilterUrl;
        const result = await this.httpClientService.Post<ApiResponse<ForloebCrudFilterRestrictReponseModel>, ForloebCrudFilterRestrictRequestModel>(url, requestModel);
        return result.data;
    }
}

export default StamdataRestrictApi;
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState,} from 'app/store';
import {StorageKeys} from "infrastructure/storage/storageKeys";

export interface LeftMenuState {
    topmenuTitle: {title: string, subtitle : string}
    rootMenuActive : boolean;
    collapsed: boolean;
}

const initialState: LeftMenuState = {
    topmenuTitle: {title: "", subtitle : ""},
    rootMenuActive : true,
    collapsed: sessionStorage.getItem(StorageKeys.leftMenuCollapsed) === "true",
};

export const InitLeftMenu = (isCollapsed: boolean) => {
    if (isCollapsed) {
        document.body.classList.add('menu-hidden');
    } else {
        document.body.classList.remove('menu-hidden');
    }
}

export const leftmenuSlice = createSlice({
    name: 'leftMenu',
    initialState,
    reducers: {
        toogleCollaped: (state) => {
            const currentCollapsedState = sessionStorage.getItem(StorageKeys.leftMenuCollapsed) === "true"
            const newCollapsedState = !currentCollapsedState;
            sessionStorage.setItem(StorageKeys.leftMenuCollapsed, (newCollapsedState).toString())
            state.collapsed = (newCollapsedState);

            InitLeftMenu(newCollapsedState);
        },
        setrootMenuActive: (state, action: PayloadAction<boolean>) => {
            state.rootMenuActive = action.payload;
        },
        setTopmenuTitle: (state, action: PayloadAction<{title: string, subtitle : string}>) => {
            state.topmenuTitle = action.payload;
        }
    }
});

export const {toogleCollaped, setrootMenuActive, setTopmenuTitle} = leftmenuSlice.actions;
export const leftMenuState = (state: RootState) => state.leftMenu;
export default leftmenuSlice.reducer;

import {KompetenceEvalueringModel} from "./kompetenceEvalueringModel";

export class KompetenceEvalueringsskemaModel {
    titel: string;
    kompetenceEvalueringsskemaId: string;
    aktiv: boolean;
    privat: boolean;
    laegerKanOprette: boolean;
    kompetenceEvalueringer: KompetenceEvalueringModel[];

    public static FromResponseModel(titel: string, kompetenceEvalueringsskemaId: string, kompetenceEvalueringModels: KompetenceEvalueringModel[], aktiv: boolean, privat: boolean, laegerKanOprette: boolean): KompetenceEvalueringsskemaModel{
        let model = new KompetenceEvalueringsskemaModel();

        model.titel = titel;
        model.aktiv = aktiv;
        model.privat = privat;
        model.laegerKanOprette = laegerKanOprette;
        model.kompetenceEvalueringsskemaId = kompetenceEvalueringsskemaId;
        model.kompetenceEvalueringer = kompetenceEvalueringModels;

        return model;
    }
}

import {dateTimeStringFromApiToDate} from "../../../index";
import {ForloebWithUserInfoReponseModel} from "../../../services/api/forloeb/forloebWithUserInfoReponseModel";

export class ForloebWithUserInfoModel {
    public static FromResponseModel(responseModel: ForloebWithUserInfoReponseModel): ForloebWithUserInfoModel {
        const model = new ForloebWithUserInfoModel();

        model.id = responseModel.id;
        model.brugerId = responseModel.brugerId;
        model.startDate = dateTimeStringFromApiToDate(responseModel.startDate);
        model.endDate = dateTimeStringFromApiToDate(responseModel.endDate);
        model.name = responseModel.name;
        model.email = responseModel.email;

        return model;
    }

    public id: string;
    public brugerId: string;
    public startDate: Date;
    public endDate: Date;
    public name: string;
    public email: string;
}

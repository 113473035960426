import { EducationFilterResult } from "core/components/filter/components/educationFilter";
import { PlaceFilterResult } from "core/components/filter/components/placeFilter";

type FileRelations = {
    regionIds: string[],
    omraadeIds: string[],
    praksisIds: string[],
    sygehusIds: string[],
    afdelingIds: string[],
    uddannelseIds: string[],
    specialeIds: string[]
}

const emptyFileRelations: FileRelations = {
    regionIds: [],
    omraadeIds: [],
    praksisIds: [],
    sygehusIds: [],
    afdelingIds: [],
    uddannelseIds: [],
    specialeIds: []
}

const filterResultsToFileRelations = (educationFilterResult: EducationFilterResult, placeFilterResult: PlaceFilterResult) : FileRelations => {
    return {
        uddannelseIds: educationFilterResult.uddannelser ?? [],
        specialeIds: educationFilterResult.specialer ?? [],
        regionIds: placeFilterResult.regioner ?? [],
        omraadeIds: placeFilterResult.omraader ?? [],
        praksisIds: placeFilterResult.praksiser ?? [],
        sygehusIds: placeFilterResult.sygehuse ?? [],
        afdelingIds: placeFilterResult.afdelinger ?? [] ,
    };
}

const validateFileUploadeOrEdit = (fileRelations: FileRelations) => {

    const hasEducationSelected = [
        fileRelations.specialeIds,
        fileRelations.uddannelseIds
    ].some(arr => arr.length > 0);

    const hasLocationSelected = [
        fileRelations.regionIds,
        fileRelations.omraadeIds,
        fileRelations.praksisIds,
        fileRelations.sygehusIds,
        fileRelations.afdelingIds
    ].some(arr => arr.length > 0);

    return hasEducationSelected && hasLocationSelected;
}

export default FileRelations;

export {
    emptyFileRelations,
    filterResultsToFileRelations,
    validateFileUploadeOrEdit
}
import {SvarModel} from "../../../core/sharedmodels/evaluering/svarModel";
import {UserSimpleModel} from "../../../core/sharedmodels/user/userSimpleModel";

export class ForloebEvalueringEditRequestModel {
    constructor(forloebEvalueringId: string, indsendt: boolean, svar: SvarModel[], hovedVejleder?: string, uddannelsesansvarlige?: UserSimpleModel[]) {
        this.forloebEvalueringId = forloebEvalueringId;
        this.indsendt = indsendt;
        this.svar = svar.map((svar) => new LogbogEvalueringSvarInputModel(svar));
        this.hovedVejleder = hovedVejleder;
        this.uddannelsesansvarlige = uddannelsesansvarlige;
    }

    forloebEvalueringId: string;
    indsendt: boolean;
    svar: LogbogEvalueringSvarInputModel[];
    hovedVejleder?: string;
    uddannelsesansvarlige?: UserSimpleModel[];
}

class LogbogEvalueringSvarInputModel {
    constructor(svarModel: SvarModel) {
        this.id = svarModel.id;
        this.forloebEvalueringId = svarModel.forloebEvalueringId;
        this.spoergsmaalId = svarModel.spoergsmaalId;
        this.spoergsmaalNummer = svarModel.spoergsmaalNummer;
        this.svarmulighedId = svarModel.svarmulighedId;
        this.svarVaerdi = svarModel.svarVaerdi;
        this.tekstSvar = svarModel.tekstSvar;
        this.kommentar = svarModel.kommentar;
        this.ikkeRelevant = svarModel.ikkeRelevant;
    }

    id: string;
    forloebEvalueringId: string;
    spoergsmaalId: string;
    spoergsmaalNummer: number;
    svarmulighedId?: string;
    svarVaerdi?: number;
    tekstSvar?: string;
    kommentar?: string;
    ikkeRelevant: boolean;
}

import {LogbogNyhedResponseModel} from "../../../services/api/logbogNyheder/logbogNyhedResponseModel";
import {dateTimeStringFromApiToDate} from "../../../index";
import { RolleEnum } from "infrastructure/enums/userRole";

export class NyhedModel {
    public static FromResponseModel(responseModel: LogbogNyhedResponseModel) : NyhedModel
    {
        const model = new NyhedModel();
        model.id = responseModel.id;
        model.titel = responseModel.titel;
        model.markdown = responseModel.markdown;
        model.publiceringsdato = dateTimeStringFromApiToDate(responseModel.publiceringsdato);
        model.skalNotificere = responseModel.skalNotificere;
        model.maalrettetTilRoller = responseModel.maalrettetTilRoller;
        return model;
    }

    public id: string;
    public titel: string;
    public publiceringsdato: Date;
    public markdown: string;
    public skalNotificere: boolean;
    public maalrettetTilRoller: RolleEnum[];
}

import { RolleTitelEnum } from "infrastructure/enums/rolleTitelEnum";
import { RolleEnum } from "infrastructure/enums/userRole";

export class RolleTitelDto {
    public Rolle: RolleEnum;
    public RolleTitel: RolleTitelEnum;

    public static CreateDto(rolle: RolleEnum, rolleTitel: RolleTitelEnum) {
        let dto = new RolleTitelDto();
        dto.Rolle = rolle;
        dto.RolleTitel = rolleTitel;

        return dto;
    }
}

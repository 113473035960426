import { mobileMaxWidthQuery } from "core/layout/responsive";
import { useMediaQuery } from "react-responsive";

const useScreenResolution = () => {

    return {
        isMobile: useMediaQuery(mobileMaxWidthQuery),
    }
};

export default useScreenResolution;
import {LogbogEvalueringResponseModel} from "../../../services/api/logbogEvaluering/logbogEvalueringResponseModel";
import {EvalueringModel} from "./evalueringModel";

export class ForloebEvalueringModel {
    public static FromResponseModel(forloebEvalueringId: string, indsendt: boolean, evaluering: LogbogEvalueringResponseModel): ForloebEvalueringModel{
        let model = new ForloebEvalueringModel();

        model.forloebEvalueringId = forloebEvalueringId;
        model.indsendt = indsendt;
        model.evaluering = EvalueringModel.FromResponseModel(evaluering);

        return model;
    }

    forloebEvalueringId: string;
    indsendt: boolean;
    evaluering: EvalueringModel;
}


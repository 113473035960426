import {FilterType} from "../../../core/sharedmodels/filter/filterType";

export class LogbogFilterItemGetModel {
    filterType : FilterType;
    filterId : string;

    constructor(filterType : FilterType, filterId : string) {
        this.filterType = filterType;
        this.filterId = filterId;
    }
}

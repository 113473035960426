import {EvalueringsstatistikFilterRequestModel} from "./evalueringsstatistikFilterRequestModel";
import {UddannelseTypeEnum} from "../../../core/sharedmodels/stilling/uddannelseTypeEnum";

export class EvalueringsstatistikRequestModel extends EvalueringsstatistikFilterRequestModel{
    constructor(startDato?: Date, slutDato?: Date, uddannelsesstedIds?: string[], specialeIds?: string[], uddannelsestyper?: UddannelseTypeEnum[], includeInactive: boolean = false) {
        super(startDato, slutDato, specialeIds, uddannelsestyper, includeInactive)
        this.uddannelsesstedIds = uddannelsesstedIds ?? [];
    }
    uddannelsesstedIds?: string[];
}

export class EvalueringsstatistikEnkelteRequestModel extends EvalueringsstatistikFilterRequestModel {
    constructor(startDato?: Date, slutDato?: Date, specialeIds?: string[], uddannelsestyper?: UddannelseTypeEnum[], uddannelsesstedIds?: string[]) {
        super(startDato, slutDato, specialeIds, uddannelsestyper)
        this.uddannelsesstedIds = uddannelsesstedIds ?? [];
    }
    uddannelsesstedIds?: string[];
}

export class EvalueringsstatistikEnkelteMedKommentarerRequestModel extends EvalueringsstatistikFilterRequestModel {
    constructor(startDato?: Date, slutDato?: Date, specialeIds?: string[], uddannelsestyper?: UddannelseTypeEnum[], evalueringIds?: string[]) {
        super(startDato, slutDato, specialeIds, uddannelsestyper)
        this.evalueringIds = evalueringIds ?? [];
    }
    evalueringIds?: string[];
}

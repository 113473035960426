import { DropdownOption } from "core/components/dropdown/dropdown";
import {ForSelectResponseModel} from "../../services/api/forSelectResponseModel";

export type ForSelectModelType = {
    id: string;
    text: string;
    groupText: string;
    subText?: string;
}

export class ForSelectModel implements ForSelectModelType {

    constructor(responseModel : ForSelectResponseModel) {
        this.id = responseModel.id;
        this.text = responseModel.text;
        this.groupText = responseModel.groupText;
        this.subText = responseModel.subText;
    }

    public id : string;
    public text : string;
    public groupText : string;
    public subText?: string;
}

export const ToSelectOptions_Deprecated = (items : Array<ForSelectModel>, selectedkey? : Array<string>) : Array<{key : string, value : string, selected : boolean}> => {
    return items
        .sort((a: ForSelectModel, b: ForSelectModel) => a.text.localeCompare(b.text))
        .map(sub => ({
        key: sub.id,
        value: sub.text,
        selected: selectedkey ? selectedkey.includes(sub.id) : false
    }));
}

export const ToSelectOptionsNoSorting_Deprecated = (items : Array<ForSelectModel>, selectedkey? : Array<string>) : Array<{key : string, value : string, selected : boolean}> => {
    return items
        .map(sub => ({
        key: sub.id,
        value: sub.text,
        selected: selectedkey ? selectedkey.includes(sub.id) : false
    }));
}

export const toDropdownOptions = (items : Array<ForSelectModel>) : DropdownOption<string>[] => {
    return items.map(sub => ({ label: sub.text, value: sub.id, subLabel: sub.subText }));
}

import useLogbogApi from "core/hooks/useLogbogApi";
import { useEffect, useState } from "react";
import { NotifikationIndstillingModel } from "../models/notifikationIndstillingModel";

const useNotificationSettings = () => {
  const { logbogNotifikationIndstillingApi } = useLogbogApi();
  const [ notifikationsIndstillinger, setNotifikationsIndstillinger ] = useState<NotifikationIndstillingModel[]>([]);
  const [ isLoading, setIsLoading ] = useState(false);

  useEffect(() => {
    const fetchIndstillinger = async () => {
      setIsLoading(true);
      const notifikationsIndstillinger = await logbogNotifikationIndstillingApi.getNotifikationIndstillinger();
      setNotifikationsIndstillinger(notifikationsIndstillinger);
    }
    fetchIndstillinger().finally(() => setIsLoading(false))
  }, [logbogNotifikationIndstillingApi]);

  return {
    notifikationsIndstillinger,
    isLoading
  }
}

export default useNotificationSettings;
import { ReactNode, createContext, useCallback, useContext, useReducer } from "react";
import { getCookieConsentValue } from "react-cookie-consent";

export type CookieConsentContextType = {
    userConsents: boolean;
    hasGivenAnswer: boolean;
}

const CookieConsentContext = createContext<{
    cookieConsent: CookieConsentContextType,
    setCookieConsent: (payload: boolean) => void,
} | undefined>(undefined);

enum CookieConsentActionType {
    setCookieConsent,
}

const cookieConsentReducer = (
    cookieConsentState: CookieConsentContextType,
    action: { type: CookieConsentActionType, payload: Partial<CookieConsentContextType> }
): CookieConsentContextType => {
    switch (action.type) {
      case CookieConsentActionType.setCookieConsent:
        return {
            ...cookieConsentState,
            userConsents: action.payload.userConsents ?? cookieConsentState.userConsents,
            hasGivenAnswer: true
        }
    }
}

export const useCookieConsentContext = () => {
    const context = useContext(CookieConsentContext);
    if (!context) throw new Error("useCookieConsentContext must be used in CookieConsentContextProvider");
    return context;
};

const retrieveCookieConsentInitialValue = (): CookieConsentContextType => {
  const consentStringValue = getCookieConsentValue();
  const consentIsDefined = consentStringValue !== undefined;
  const hasGivenConsent = !!consentStringValue && consentStringValue === "true";

  return {
    userConsents: hasGivenConsent,
    hasGivenAnswer: consentIsDefined,
  };
}

type CookieConsentContextProviderProps = { children?: ReactNode; }

const CookieConsentContextProvider = ({children}: CookieConsentContextProviderProps) => {
    const [cookieConsent, dispatch] = useReducer(cookieConsentReducer, retrieveCookieConsentInitialValue());

    const setCookieConsent = useCallback((payload: boolean) => {
        dispatch({ type: CookieConsentActionType.setCookieConsent, payload: { userConsents: payload }});
    }, []);

    return (
        <CookieConsentContext.Provider
            value={{
                    cookieConsent,
                    setCookieConsent,
                }}>
            {children}
        </CookieConsentContext.Provider>
    );
};

export default CookieConsentContextProvider;

import {EvalueringType} from "./evalueringType";
import {SektionModel} from "./sektionModel";
import {
    StamdataEvalueringResponseModel
} from "../../../services/api/evalueringStatistik/stamdataEvalueringResponseModel";

export class StamdataForloebEvalueringModel {
    id: string;
    evalueringTypeEnum: EvalueringType;
    sektioner: SektionModel[];

    public static FromResponseModel(stamdataModel: StamdataEvalueringResponseModel): StamdataForloebEvalueringModel{
        let model = new StamdataForloebEvalueringModel();
        model.id = stamdataModel.id;
        model.evalueringTypeEnum = stamdataModel.evalueringTypeEnum;
        model.sektioner = stamdataModel.sektioner.map(s => SektionModel.FromResponseModel(s));
        return model;
    }
}

import {RolleTitelEnum} from "../../../infrastructure/enums/rolleTitelEnum";

export class StempelInputModel {
    id: string;
    navn: string;
    authorizationId: string;
    rolleTitel: RolleTitelEnum;
    initialer: string;

    constructor(id: string, navn: string, authorizationId: string, rolleTitel: RolleTitelEnum, initialer:string) {
        this.id = id;
        this.navn = navn;
        this.authorizationId = authorizationId;
        this.rolleTitel = rolleTitel;
        this.initialer = initialer;
    }
}

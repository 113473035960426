import {LaegekompetenceStatusEnum} from "core/sharedmodels/kompetence/laegekompetenceStatusEnum";
import {RolleTitelEnum} from "../../../infrastructure/enums/rolleTitelEnum";

export type LogbogKlarmeldTilUserRequestModel = {
    userId: string;
    rolleTitelEnums: RolleTitelEnum[];
}

export class LogbogLaegekompetenceRequestModel {
    id: string;
    kompetenceId: string;
    kompetenceNummer: string;
    kompetenceSortOrder: number;
    godkendtUserId?: string;
    isFavorite: boolean;
    status: LaegekompetenceStatusEnum;
    roleTitle: RolleTitelEnum;
    godkendtUddannelsessted: string;
    klarmeldtTilBrugere: LogbogKlarmeldTilUserRequestModel[];
    klarmeldtTilAlleMedAdgang?: boolean;
}

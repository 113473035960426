import dayjs from "dayjs";
import { CookieConstants } from "infrastructure/cookieConstants";
import { DateFullTimeIsoWithoutTimeZoneFormat } from "infrastructure/date/dateFormats";
import Cookies from "js-cookie";

/** Incapsulates logic for keeping track of if the browser-session has ended (Browser has been closed) */ 
class ValidBrowserSessionHandler {

  /** Creates a session-based cookie, which only is destroyed if the browser closes */ 
  public static CreateValidSessionCookie() {
    const validSessionCreatedAtDateTimeString = dayjs().format(DateFullTimeIsoWithoutTimeZoneFormat);
    Cookies.set(CookieConstants.isValidBrowserSessionKey, validSessionCreatedAtDateTimeString);
  }

  public static InvalidateSessionCookie() {
    Cookies.remove(CookieConstants.isValidBrowserSessionKey);
  }

  /** Run cleanup if the browser has been closed (e.g. session cookie doesn't exist) */ 
  public static HandleCleanupIfInvalid(cleanupFunction: () => void, shouldCleanup: boolean = true) {
    const isValidBrowserSession = !!Cookies.get(CookieConstants.isValidBrowserSessionKey);
    
    if(!isValidBrowserSession && shouldCleanup) {
        console.log("Session not valid --> doing cleanup");
        cleanupFunction()
    }
  }
}

export default ValidBrowserSessionHandler;

import {UddannelseTypeEnum} from "../../../core/sharedmodels/stilling/uddannelseTypeEnum";

export class EvalueringsstatistikFilterRequestModel {
    constructor(startDato?: Date, slutDato?: Date, specialeIds?: string[], uddannelsestyper?: UddannelseTypeEnum[], includeInactive: boolean = false) {
        this.startDato = startDato?.dateToApiPostFormat();
        this.slutDato = slutDato?.dateToApiPostFormat();
        this.includeInactive = includeInactive;
        this.specialeIds = specialeIds ?? [];
        this.uddannelsestyper = uddannelsestyper ?? [];
    }

    startDato?: string;
    slutDato?: string;
    specialeIds?: string[];
    uddannelsestyper?: UddannelseTypeEnum[];
    includeInactive: boolean;
}

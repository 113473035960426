export default class StringBuilder {
    private separator?: string;
    private content: string;

    constructor(initialContent: string = "", separator?: string) {
        this.content = initialContent;
        this.separator = separator;
    }

    append(text: string, shouldAdd: boolean=true): void {
        if(!shouldAdd) return;

        const isFirstInput = !!this.content;
        this.content += `${isFirstInput ? this.separator ?? "" : ""}${text}`;
    }

    toString(): string {
        return this.content;
    }
}

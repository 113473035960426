import {RolleEnum} from "../../../infrastructure/enums/userRole";
import {RolleTitelEnum} from "../../../infrastructure/enums/rolleTitelEnum";
import {LogbogUserRolleResponseModel} from "../../../services/api/logbogUser/logbogUserInfoResponse";

export class UserRolleModel {
    public static FromResponseModel(responseModel: LogbogUserRolleResponseModel): UserRolleModel {
        const model = new UserRolleModel();
        model.id = responseModel.id;
        model.rolleType = responseModel.rolleType;
        model.rolleTitel = responseModel.rolleTitel;
        model.regioner = responseModel.regioner;
        model.omraader = responseModel.omraader;
        model.praksis = responseModel.praksis;
        model.sygehuse = responseModel.sygehuse;
        model.afdelinger = responseModel.afdelinger;
        model.specialer = responseModel.specialer;
        model.userId = responseModel.userId;
        return model;
    }

    id: string;
    rolleType: RolleEnum;
    rolleTitel: RolleTitelEnum;
    regioner?: string[];
    omraader?: string[];
    praksis?: string[];
    sygehuse?: string[];
    afdelinger?: string[];
    specialer?: string[];
    userId: string;
}

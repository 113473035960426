import {Localizer} from "../../../infrastructure/localization/localizer";

// These keys also exist in ObligatoriskDokumentationTypeEnum. If you delete or add one here, do the same in ObligatoriskDokumentationTypeEnum.ts
export enum LaegekursusGruppeTypeEnum {
    Hoveduddannelse = 1,
    Forskningstraening = 2,
    FaellesInternMedicin = 3,
    Specialespecifikke = 4,
    GrundkursusIPsykoterapi = 5,
    Introduktionsuddannelsen = 6,
    KliniskBasisuddannelse = 7,
    SpecialespecifikkeIntrouddannelsen = 8,
    AlmenMedicin = 9
}

export function GetLaegekursusGruppeTranslation(type: LaegekursusGruppeTypeEnum) {
    switch (type) {
        case LaegekursusGruppeTypeEnum.Hoveduddannelse:
            return Localizer.global_hoveduddannelse();
        case LaegekursusGruppeTypeEnum.Forskningstraening:
            return Localizer.global_forskningstraening();
        case LaegekursusGruppeTypeEnum.FaellesInternMedicin:
            return Localizer.global_faellesInternMedicin();
        case LaegekursusGruppeTypeEnum.Specialespecifikke:
            return Localizer.global_specialespecifikke();
        case LaegekursusGruppeTypeEnum.GrundkursusIPsykoterapi:
            return Localizer.global_grundkursusIPsykoterapi();
        case LaegekursusGruppeTypeEnum.Introduktionsuddannelsen:
            return Localizer.global_introduktionsuddannelsen();
        case LaegekursusGruppeTypeEnum.KliniskBasisuddannelse:
            return Localizer.global_kliniskBasisuddannelse();
        case LaegekursusGruppeTypeEnum.SpecialespecifikkeIntrouddannelsen:
            return Localizer.global_specialespecifikkeIntrouddannelsen();
        case LaegekursusGruppeTypeEnum.AlmenMedicin:
            return Localizer.global_almenMedicin();
        default:
            return "";
    }
}

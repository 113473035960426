import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import BrevskabelonDto, { toCreateRequest } from "./models/brevskabelonDto";
import BrevskabelonFilterDto from "./models/brevskabelonFilterDto";
import BrevskabelonCreateRequestModel from "./models/brevskabelonCreateRequestModel";
import { AsyncResponseModel } from "../asyncResponseModel";
import BrevskabelonMergeFieldRequestModel from "./models/brevskabelonMergeFieldRequestModel";
import { BrevForloeb, BrevForloebDto, BuildFromDto } from "core/sharedmodels/forloeb/brevForloebDto";
import { ObjectToQueryString } from "../objectToQueryString";
import { PromiseWithCancel } from "services/httpClient/promiseWithCancel";
import { MergeFieldDataResponse, MergeFieldDatas } from "./models/MergeFieldDataResponse";

class StamdataBrevskabelonApi {

    private stamdataBaseUrl = () => config.stamdataApiUrl + "api/brevskabelon/";
    private listUrl = () => this.stamdataBaseUrl() + "list/";
    private updateUrl = () => this.stamdataBaseUrl() + "update";
    private deleteUrl = () => this.stamdataBaseUrl() + "delete/";
    private mergeFieldDataUrl = () => this.stamdataBaseUrl() + "mergefield/data";

    private getListWithCancelOption: PromiseWithCancel<ApiResponse<AsyncResponseModel<BrevskabelonDto>>>;

    constructor(private httpClientService: HttpClientService) {
        this.httpClientService = httpClientService;
    }

    async get(id: string): Promise<BrevskabelonDto> {
        const url = this.stamdataBaseUrl() + id;
        const result = await this.httpClientService.Get<ApiResponse<BrevskabelonDto>>(url);
        return result.data;
    }

    async getList(dto: BrevskabelonFilterDto): Promise<AsyncResponseModel<BrevskabelonDto>> {
        if (this.getListWithCancelOption)
            this.getListWithCancelOption.cancel();

        const url = this.listUrl() + ObjectToQueryString(dto);
        
        this.getListWithCancelOption = this.httpClientService.GetWithCancellation<ApiResponse<AsyncResponseModel<BrevskabelonDto>>>(url);

        return (await this.getListWithCancelOption).data;
    }

    async getMergeFieldData(request: BrevskabelonMergeFieldRequestModel): Promise<MergeFieldDatas> {
        const url = this.mergeFieldDataUrl();
        const result = await this.httpClientService.Post<ApiResponse<MergeFieldDataResponse>, BrevskabelonMergeFieldRequestModel>(url, request);
        
        const {forloebMergeFields, tekstMergeFields} = result.data;

        return {
            forloebMergeFields: forloebMergeFields.map(x => ({ key: x.key, data: x.data.map(f => BuildFromDto(f)) })),
            tekstMergeFields: tekstMergeFields
        }
    }

    async create(dto: BrevskabelonDto): Promise<string> {
        const url = this.stamdataBaseUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, BrevskabelonCreateRequestModel>(url, toCreateRequest(dto));
        return result.data;
    }

    async update(dto: BrevskabelonDto): Promise<BrevskabelonDto> {
        const url = this.updateUrl();
        const result = await this.httpClientService.Patch<ApiResponse<BrevskabelonDto>, BrevskabelonDto>(url, dto);
        return result.data;
    }

    async delete(id: string): Promise<string> {
        const url = this.stamdataBaseUrl() + id;
        const result = await this.httpClientService.Delete<ApiResponse<string>>(url);
        return result.data;
    }
}

export default StamdataBrevskabelonApi;

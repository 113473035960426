import React, { ButtonHTMLAttributes, PropsWithChildren } from "react";
import {FormikProps} from "formik";
import {ModalAcceptType} from "../../modalMessage/modalSubmitMessage";
import DynamicButton from "core/components/button/dynamicButton";
import { EmblaIconsType } from "core/emblaIcons";

type IValidationFormSubmitButtonProps = {
    formikProps: React.PropsWithChildren<FormikProps<any>>;
    additionalClasses?: string;
    buttonType?: ModalAcceptType;
    onClickSubmitOverride?: () => void;
}

export const ValidationFormSubmitButton = ({
    buttonType = ModalAcceptType.primary,
    additionalClasses = "",
    onClickSubmitOverride,
    ...props
}: PropsWithChildren<IValidationFormSubmitButtonProps & ButtonHTMLAttributes<HTMLButtonElement>>) => {
    return (
        <div>
            <button disabled={props.formikProps?.isSubmitting || props?.disabled === true}
                    type={!!onClickSubmitOverride ? "button" : "submit" }
                    onClick={onClickSubmitOverride}
                    className={`${additionalClasses} btn btn-` + (buttonType)}>
                { props.children }
            </button>
        </div>
    );
}

type DynamicValidationFormSubmitButtonProps = {
    iconName: EmblaIconsType;
    formId?: string;
    text?: string;
    disabled?: boolean;

    additionalClasses?: string;
    buttonType?: ModalAcceptType;
};

export const DynamicValidationFormSubmitButton = ({iconName, formId, text, buttonType=ModalAcceptType.primary, additionalClasses, disabled}: DynamicValidationFormSubmitButtonProps) => {

    return (
        <DynamicButton
            formId={formId}
            disabled={disabled}
            additionalClasses={additionalClasses}
            type="submit"
            isPrimary={buttonType === ModalAcceptType.primary}
        >

            <DynamicButton.TextIconItem
                iconName={iconName}
                text={text}
            />

        </DynamicButton>
    );
}

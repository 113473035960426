import { FormikErrors } from "formik";

export interface ValidationInputResult{
    IsWarning: boolean,
    Message: string
}

export const ProcessValidationInput = (error: string | undefined) => {
    let errorMessage = error;
    let isWarning = false;

    if(!!error && error.indexOf(ValidationWarningIndicator) > -1) {
        isWarning = true;
        errorMessage = error.replace(ValidationWarningIndicator, "");
    }

    return {IsWarning: isWarning, Message: errorMessage} as ValidationInputResult;
}

export const FormIsValidIgnoreWarnings = <T extends object> (errors: FormikErrors<T>) => {
    const errorMessages = Object
        .values(errors)
        .map((v) => v as string)
        .filter((v) => !v.includes(ValidationWarningIndicator));

    return errorMessages.length === 0;
}

export const ValidationWarningIndicator: string = "__Warning__";
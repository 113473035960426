import {LaegekursusModel} from "./laegekursusModel";
import {LaegekursusGruppeResponseModel} from "./laegekursusGruppeResponseModel";
import {LaegekursusGruppeTypeEnum} from "../../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";
import {GodkendelseInfoModel} from "../../../core/sharedmodels/godkendelse/godkendelseInfoModel";

export class LaegekursusGruppeModel {
    public id: string;
    public brugerId: string;
    public harSamletGodkendelse: boolean;
    public godkendtPaaAndetGrundlag: boolean;
    public kursusGruppeType: LaegekursusGruppeTypeEnum;
    public kurser: LaegekursusModel[];
    public godkendelsesInfo?: GodkendelseInfoModel;
    public brugerKursusraekkeId: string;

    constructor(responseModel: LaegekursusGruppeResponseModel) {
        this.id = responseModel.id;
        this.brugerId = responseModel.brugerId;
        this.brugerKursusraekkeId = responseModel.brugerKursusraekkeId;
        this.harSamletGodkendelse = responseModel.harSamletGodkendelse;
        this.godkendtPaaAndetGrundlag = responseModel.godkendtPaaAndetGrundlag;
        this.kursusGruppeType = responseModel.kursusGruppeType;
        this.godkendelsesInfo = responseModel.godkendelseInfo !== null && responseModel.godkendelseInfo !== undefined ? new GodkendelseInfoModel(responseModel.godkendelseInfo) : undefined;
        this.kurser = responseModel.laegekurser.map(kursus =>  new LaegekursusModel(kursus));
    }

    public isGroupWithSamletGodkendelse = () => {
        return this.kursusGruppeType === LaegekursusGruppeTypeEnum.FaellesInternMedicin
            || this.kursusGruppeType === LaegekursusGruppeTypeEnum.Specialespecifikke
            || this.kursusGruppeType === LaegekursusGruppeTypeEnum.Forskningstraening
            || this.kursusGruppeType === LaegekursusGruppeTypeEnum.GrundkursusIPsykoterapi;
    }
}

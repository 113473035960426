import { useEffect, useRef } from "react";

const useScrollTo = <T extends Element> ( scrollWhenChangedToTrue: boolean, options?: Partial<ScrollIntoViewOptions>) => {
  const scrolltoRef = useRef<T>(null);

  useEffect(() => {
      if(scrollWhenChangedToTrue && scrolltoRef.current) {
          scrolltoRef.current.scrollIntoView({...options ?? { behavior: 'smooth', block: 'nearest' }})
      }
  }, [options, scrollWhenChangedToTrue]);

  return {
      scrolltoRef
  }
}

export default useScrollTo;

import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {AccountService} from "../../account/accountService";
import {ForSelectModel} from "../../../core/sharedmodels/forSelectModel";
import {ForSelectResponseModel} from "../forSelectResponseModel";

export class StamdataRegionApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = () => config.stamdataApiUrl + "api/Region/";
    private regionerForSelectUrl = () =>  this.baseUrl() + "regionerForSelect"
    private regionerInDenmark = () => this.baseUrl() + "regionerInDenmark";

    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    async getRegionerSelect(): Promise<ForSelectModel[]> {
        const url = this.regionerForSelectUrl();
        const result = await this.httpClientService.Get<ApiResponse<ForSelectResponseModel[]>>(url);
        return result.data.map(x => new ForSelectModel(x));
    }

    async getRegionerInDenmark() : Promise<string[]> {
        const url = this.regionerInDenmark();
        const result = await this.httpClientService.Get<ApiResponse<string[]>>(url);
        return result.data;
    }
}

import React, {useEffect, useState} from 'react';
import useStamdataApi from "../../hooks/useStamdataApi";
import {Loading} from "../loading/loading";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {EmblaIcons} from "../../emblaIcons";
import {ConfirmButton} from "../button/confirmButton";
import './user-profile.scss';

export type ImageWithAuthProps = {
    profileImageFileMetadataId: string;
    profileImageData?: Uint8Array[];
    showDelete: boolean;
    deleteImageCallback?: () => void
    onFetchCallback: (succeded: boolean) => void
}

const ImageWithAuth = ({
                           profileImageFileMetadataId,
                           showDelete,
                           deleteImageCallback,
                           onFetchCallback,
                           profileImageData
                       }: ImageWithAuthProps) => {
    const {stamdataFileApi} = useStamdataApi();
    const [profileImage, setProfileImage] = useState<Uint8Array[] | undefined>(profileImageData);
    const [isLoading, setIsLoading] = useState(!profileImageData);

    const timeoutValue = 5000; //Wait 5 seconds before timing out when getting images

    useEffect(() => {
        if (profileImageData) return;
        const fetchImage = async () => {
            return await stamdataFileApi.getProfileImage(profileImageFileMetadataId)
        }

        const timeout = new Promise<Uint8Array[]>((_, reject) =>
            setTimeout(() => reject(), timeoutValue)
        );

        Promise.race([fetchImage(), timeout])
            .then(image => {
                setProfileImage(image);
                onFetchCallback(true);
            })
            .catch(error => {
                setIsLoading(false);
                onFetchCallback(false);
            })
            .finally(() => setIsLoading(false));
    }, [onFetchCallback, profileImageData, profileImageFileMetadataId, stamdataFileApi]);

    return <Loading isLoading={isLoading}>
        {profileImage &&
            <>
                <img alt={"profileImage"}
                     src={'data:image/jpeg;base64,' + profileImage}/>
                <div className="flex-row-center">
                    {(deleteImageCallback && showDelete) &&
                        <ConfirmButton
                            additionalClasses={"margin-top-m position-absolute"}
                            additionalConfirmDenyClasses={"confirm-deny-image"}
                            icon={EmblaIcons.Delete}
                            isDanger
                            asButton
                            buttonTekst={Localizer.global_delete()}
                            confirmCallback={deleteImageCallback}
                        />
                    }
                </div>
            </>
        }
    </Loading>
};

export default ImageWithAuth;

import {
    EvalueringStatistikForloebModel,
    EvalueringStatistikForloebResponseModel
} from "./evalueringStatistikForloebModel";
import {EvalueringGruppeScoreModel} from "./EvalueringGruppeScoreModel";

export class EvalueringStatistikEnkeltReponseModel {
    public uddannelsesstedId: string;
    public uddannelsesstedNavn: string;
    public forloebEvalueringer: EvalueringStatistikForloebResponseModel[];
}

export class EvalueringStatistikEnkeltModel {
    public uddannelsesstedId: string;
    public uddannelsesstedNavn: string;
    public forloebEvalueringer: EvalueringStatistikForloebModel[];

    public static FromResponseModel(responseModel : EvalueringStatistikEnkeltReponseModel) {
        let model = new EvalueringStatistikEnkeltModel();
        model.uddannelsesstedId = responseModel.uddannelsesstedId;
        model.uddannelsesstedNavn = responseModel.uddannelsesstedNavn;
        model.forloebEvalueringer = responseModel.forloebEvalueringer.map(x => EvalueringStatistikForloebModel.FromResponseModel(x));
        return model;
    }
}

import {RolleTitelEnum} from "../../../infrastructure/enums/rolleTitelEnum";

export class GodkendPostModel {
    constructor(contextId: string, godkendtUserId: string, roleTitle: RolleTitelEnum, uddannelsessted?: string, kommentar?: string, godkendtPaaAndetGrundlag?: boolean, brugerMaalbeskrivelseId?: string) {
        this.contextId = contextId;
        this.godkendtUserId = godkendtUserId;
        this.roleTitle = roleTitle;
        this.godkendtPaaAndetGrundlag = godkendtPaaAndetGrundlag ?? false;
        this.uddannelsessted = uddannelsessted ?? "";
        this.kommentar = kommentar;
        this.brugerMaalbeskrivelseId = brugerMaalbeskrivelseId;
    }

    contextId: string;
    godkendtUserId: string;
    roleTitle: RolleTitelEnum;
    uddannelsessted: string;
    godkendtPaaAndetGrundlag: boolean;
    kommentar?: string;
    brugerMaalbeskrivelseId?: string;
}

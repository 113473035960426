import {Localizer} from "../localization/localizer";

export enum RolleEnum {
    Systemadministrator = 1,
    Administrator = 2,
    Uddannelseslaege = 3,
    Vejleder = 4,
    Uddannelsesansvarlig = 5,
    VusMedarbejder = 6,
    HrMedarbejder = 7,
    Sekretaer = 8,
    Ledelse = 9,
    KonsulentForDenLaegeligeVidereuddannelse = 10,
    Kursusleder = 11,
    Kursusudbyder = 12,
    Forskningstraeningsvejleder = 13,
}

export const UserRoleIsAdmin = (currentRolle : RolleEnum) : boolean  =>  {
    const roles = [RolleEnum.Systemadministrator, RolleEnum.Administrator];
    return roles.includes(currentRolle);
}

export const UserRoleIsLaege = (currentRolle : RolleEnum) : boolean  =>  {
    const roles = [RolleEnum.Uddannelseslaege];
    return roles.includes(currentRolle);
}

export const UserRoleIsKursusleder = (currentRolle : RolleEnum) : boolean  =>  {
    const roles = [RolleEnum.Kursusleder];
    return roles.includes(currentRolle);
}

export const UserRoleIsKursusBruger = (currentRolle : RolleEnum) : boolean  =>  {
    const roles = [RolleEnum.Kursusleder, RolleEnum.Kursusudbyder];
    return roles.includes(currentRolle);
}

export const UserRoleIsKursusudbyder= (currentRolle : RolleEnum) : boolean  =>  {
    const roles = [RolleEnum.Kursusudbyder];
    return roles.includes(currentRolle);
}

export const UserRoleIsKonsulentForLaegeligVidereuddannelse= (currentRolle : RolleEnum) : boolean  =>  {
    const roles = [RolleEnum.KonsulentForDenLaegeligeVidereuddannelse];
    return roles.includes(currentRolle);
}

export const UserRoleIsHrMedarbejder= (currentRolle : RolleEnum) : boolean  =>  {
    const roles = [RolleEnum.HrMedarbejder];
    return roles.includes(currentRolle);
}

export const UserRoleIsUddannelsesansvarlig = (currentRolle : RolleEnum) : boolean  =>  {
    const roles = [RolleEnum.Uddannelsesansvarlig];
    return roles.includes(currentRolle);
}

export const UserRoleIsLedelse = (currentRolle : RolleEnum) : boolean  =>  {
    const roles = [RolleEnum.Ledelse];
    return roles.includes(currentRolle);
}

export const UserRoleIsSekretaer = (currentRolle : RolleEnum) : boolean  =>  {
    const roles = [RolleEnum.Sekretaer];
    return roles.includes(currentRolle);
}

export const UserRoleIsVusMedarbejeder = (currentRolle : RolleEnum) : boolean  =>  {
    const roles = [RolleEnum.VusMedarbejder];
    return roles.includes(currentRolle);
}

export const UserRoleIsVejleder = (currentRolle : RolleEnum) : boolean  =>  {
    const roles = [RolleEnum.Vejleder];
    return roles.includes(currentRolle);
}

export const UserRoleIsForskningstraeningsvejleder = (currentRolle : RolleEnum) : boolean  =>  {
    const roles = [RolleEnum.Forskningstraeningsvejleder];
    return roles.includes(currentRolle);
}

export const StringToUserRole = (userRole : string) : RolleEnum => {
    switch (userRole) {
        case "Systemadministrator":
            return RolleEnum.Systemadministrator;
        case "Administrator":
            return RolleEnum.Administrator;
        case "Uddannelseslaege":
            return RolleEnum.Uddannelseslaege;
        case "Vejleder":
            return RolleEnum.Vejleder;
        case "Uddannelsesansvarlig":
            return RolleEnum.Uddannelsesansvarlig;
        case "VusMedarbejder":
            return RolleEnum.VusMedarbejder;
        case "HrMedarbejder":
            return RolleEnum.HrMedarbejder;
        case "Sekretaer":
            return RolleEnum.Sekretaer;
        case "Ledelse":
            return RolleEnum.Ledelse;
        case "KonsulentForDenLaegeligeVidereuddannelse":
            return RolleEnum.KonsulentForDenLaegeligeVidereuddannelse;
        case "Kursusleder":
            return RolleEnum.Kursusleder;
        case "Kursusudbyder":
            return RolleEnum.Kursusudbyder;
        case "Forskningstraeningsvejleder":
            return RolleEnum.Forskningstraeningsvejleder;
        default:
            return RolleEnum.Uddannelseslaege;
    }
}

export const UserRoleToString = (userRole : RolleEnum) : string => {
    switch(userRole){
        case RolleEnum.Systemadministrator:
            return Localizer.global_systemAdmin();
        case RolleEnum.Administrator:
            return Localizer.global_admin();
        case RolleEnum.Uddannelseslaege:
            return Localizer.global_uddannelseslaege();
        case RolleEnum.Uddannelsesansvarlig:
            return Localizer.global_uddannelsesansvarligOverlaege();
        case RolleEnum.Kursusleder:
            return Localizer.global_kursusleder();
        case RolleEnum.Kursusudbyder:
            return Localizer.global_kursusudbyder();
        case RolleEnum.Forskningstraeningsvejleder:
            return Localizer.global_forskningstraeningsvejleder();
        case RolleEnum.HrMedarbejder:
            return Localizer.global_hrMedarbejder();
        case RolleEnum.KonsulentForDenLaegeligeVidereuddannelse:
            return Localizer.global_konsulentForDenLaegeligeVidereuddannelse();
        case RolleEnum.Ledelse:
            return Localizer.global_ledelse();
        case RolleEnum.Vejleder:
            return Localizer.global_vejleder()
        case RolleEnum.Sekretaer:
            return Localizer.global_sekretaer();
        case RolleEnum.VusMedarbejder:
            return Localizer.global_vusMedarbejder();
        default:
            return userRole;
    }
}

export const UserRoleToColorClass = (userRole: RolleEnum | undefined) : string => {
    switch(userRole){
        case RolleEnum.Uddannelseslaege:
            // return "userrole-blue"; //Anvend dette, når/hvis kunden vil skifte til blå ikoner for uddannelseslæger - jf. originalt design på Figma
            return "userrole-green";
        //Every other role is green as for now
        default:
            return "userrole-green";
    }
}

export const IsLaegeToColorClass = (isLaege: boolean | undefined) : string => {
    if(isLaege)
        // return "userrole-blue"; //Anvend dette, når/hvis kunden vil skifte til blå ikoner for uddannelseslæger - jf. originalt design på Figma
        return "userrole-green";
    else
        return "userrole-green";
}

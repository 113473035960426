import {VejlederEditResponseModel} from "../../../services/api/stamdataVejlederApi/vejlederEditResponseModel";
import {dateTimeStringFromApiToDate} from "../../../index";
import {RootTypeEnum} from "../../../core/sharedmodels/laegeInstitution/rootTypeEnum";

export class VejlederEditModel {

    constructor(vejlederResponseModel : VejlederEditResponseModel) {
        this.BrugerId = vejlederResponseModel.brugerId;
        this.UddannelsesstedTilknytningId = vejlederResponseModel.vejlederTilknytningId;
        this.SygehusId = vejlederResponseModel.sygehusId;
        this.AfdelingId = vejlederResponseModel.afdelingId;
        this.PraksisId = vejlederResponseModel.praksisId;
        this.CreateNewUser = false;
        this.UddannelseId = vejlederResponseModel.UddannelseId;
        this.NavnAndEmail = vejlederResponseModel.navnAndEmail;
        this.LaegeinstitutionsType = vejlederResponseModel.laegeinstitutionsType;
        this.LaegerIds = vejlederResponseModel.laegerIds;
        this.AlleLaeger = vejlederResponseModel.alleLaeger;
        this.StartDatoUtc = vejlederResponseModel.startDatoUtc ? dateTimeStringFromApiToDate(vejlederResponseModel.startDatoUtc) : undefined;
        this.SlutDatoUtc = vejlederResponseModel.slutDatoUtc ? dateTimeStringFromApiToDate(vejlederResponseModel.slutDatoUtc) : undefined;
        this.alleKbuLaeger = vejlederResponseModel.alleKbuLaeger;
        this.alleIntroduktionsLaeger = vejlederResponseModel.alleIntroduktionsLaeger;
        this.alleHoveduddannelsesLaeger = vejlederResponseModel.alleHoveduddannelsesLaeger;
    }

    public BrugerId : string;
    public UddannelsesstedTilknytningId : string;
    public SygehusId : string;
    public PraksisId : string;
    public UddannelseId : string;
    public AfdelingId : string;
    public LaegeinstitutionsType : RootTypeEnum;
    public NavnAndEmail : string;
    public LaegerIds : Array<string>;
    public AlleLaeger : boolean;
    public CreateNewUser : boolean;
    public StartDatoUtc? : Date;
    public SlutDatoUtc? : Date;
    public alleKbuLaeger : boolean;
    public alleIntroduktionsLaeger : boolean;
    public alleHoveduddannelsesLaeger : boolean;
}

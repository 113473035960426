import { HTMLAttributeAnchorTarget } from "react";

class LinkTargets {
    public static Self: HTMLAttributeAnchorTarget = "_self";
    public static Blank: HTMLAttributeAnchorTarget = "_blank";
    public static Parent: HTMLAttributeAnchorTarget = "_parent";
    public static Top: HTMLAttributeAnchorTarget = "_top";
}

export default LinkTargets;

/** Prevents the security vulnerability (tabnabbing). */
export const SecureLinkReferrerPolicy = "noopener noreferrer";

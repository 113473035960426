import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {AccountService} from "../../account/accountService";
import {CrudBeskaeftigelseInputModel} from "../../../pages/sendStpsAnsoegning/beskaeftigelse/crudBeskaeftigelseInputModel";
import {LogbogBeskaeftigelseCrudModel} from "./logbogBeskaeftigelseCrudModel";
import {LogbogBeskaeftigelseResponseModel} from "./logbogBeskaeftigelseResponseModel";
import {
    BeskaeftigelseModel,
    FromResponseModel
} from "../../../pages/sendStpsAnsoegning/beskaeftigelse/beskaeftigelseModel";
import {FileDownloadHandler} from "../logbogFile/fileDownloadHandler";
import { LogbogBeskaeftigelseGetModel } from "./logbogBeskaeftigelseGetModel";
import {ObjectToQueryString} from "../objectToQueryString";

export class LogbogBeskaeftigelseApi {
    constructor(httpClientService: HttpClientService, userService: AccountService, fileDownloadHandler: FileDownloadHandler) {
        this.httpClientService = httpClientService;
        this.userService = userService;
        this.fileDownloadHandler = fileDownloadHandler;
    }

    private httpClientService: HttpClientService;
    private userService: AccountService;
    private fileDownloadHandler: FileDownloadHandler;

    private baseUrl = () => config.logbogApiUrl + "api/beskaeftigelse/";
    private beskaeftigelseOnAnsoegning = () => this.baseUrl() + "byAnsoegning/";
    private editBeskaeftigelseOnAnsoegning = () => this.baseUrl() + "editBeskaeftigelse";

    async createBeskaeftigelse(inputModel: CrudBeskaeftigelseInputModel): Promise<string> {
        const postModel = new LogbogBeskaeftigelseCrudModel(inputModel);
        const url = this.baseUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogBeskaeftigelseCrudModel>(url, postModel);
        return result.data;
    }

    async getBeskaeftigelserByAnsoegningsId(ansoegningId : string): Promise<Array<BeskaeftigelseModel>> {
        const url = this.beskaeftigelseOnAnsoegning() + ansoegningId;
        const result = await this.httpClientService.Get<ApiResponse<Array<LogbogBeskaeftigelseResponseModel>>>(url);

        if (result.data){
            return result.data.map(x => FromResponseModel(x));
        }
        else {
            return [];
        }
    }

    async editBeskaeftigelse(inputModel: CrudBeskaeftigelseInputModel) {
        const patchModel = new LogbogBeskaeftigelseCrudModel(inputModel);
        const url = this.editBeskaeftigelseOnAnsoegning();
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogBeskaeftigelseCrudModel>(url, patchModel);
        return result.data;
    }

    async deleteBeskaeftigelse(beskaeftigelseId: string, ansoegningId: string): Promise<string>{
        const url = this.baseUrl() + ObjectToQueryString(new LogbogBeskaeftigelseGetModel(beskaeftigelseId, ansoegningId));
        const result = await this.httpClientService.Delete<ApiResponse<string>>(url);
        return result.data;
    }
}

import {StamdataAfdelingResponseModel} from "../../../services/api/stamdataAfdeling/stamdataAfdelingResponseModel";

export class AfdelingModel {
    public id: string;
    public navn: string;

    static FromResponseModel(responseModel: StamdataAfdelingResponseModel) : AfdelingModel {
        const model = new AfdelingModel();
        model.id = responseModel.id;
        model.navn = responseModel.navn;
        return model;
    }
}

import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {AccountService} from "../../account/accountService";
import {UserProfileModel} from "../../../core/components/userProfile/userProfileModel";
import {LogbogUserInfoResponse} from "./logbogUserInfoResponse";
import {LaegeWithForloebModel, LaegeWithSimpleForloebModel} from "core/sharedmodels/forloeb/laegeWithForloebModel";
import {LaegeWithForloebListResponseModel} from "../forloeb/laegeWithForloebListResponseModel";
import {ForSelectModel} from "../../../core/sharedmodels/forSelectModel";
import {ForSelectResponseModel} from "../forSelectResponseModel";
import {ObjectToQueryString} from "../objectToQueryString";
import {ForloebOverviewFilterModel} from "../../../core/components/forloebOverview/ForloebOverviewFilterModel";
import {FilterModel} from "../../../core/sharedmodels/filterModel";
import {forloebOverviewFilterRequestModel} from "../forloeb/forloebOverviewFilterRequestModel";
import {AsyncResponseModel} from "../asyncResponseModel";
import {LogbogLaegerRequest} from "./logbogLaegerRequest";
import {LogbogUserWithForloebAccessDateResponseModel} from "./logbogUserWithForloebAccessDateResponseModel";
import {UserWithForloebAccessDateModel} from "../../../core/sharedmodels/user/userWithForloebAccessDateModel";
import {FilterParamsModel} from "../../../core/sharedmodels/filter/FilterParamsModel";
import {LogbogPersonaleRequest} from "./logbogPersonaleRequest";
import {PromiseWithCancel} from "../../httpClient/promiseWithCancel";
import {LogbogPersonaleUserSimpleResponseModel} from "./logbogPersonaleUserSimpleResponseModel";
import {UddannelseTypeEnum} from "../../../core/sharedmodels/stilling/uddannelseTypeEnum";
import {LaegeForloebWithKursusGrupperModel} from "../../../core/sharedmodels/user/laegeForloebWithKursusGrupperModel";
import {LaegeForloebWithKursusGrupperResponseModel} from "../stamdataUser/laegeForloebWithKursusGrupperResponseModel";
import {UserWithKursusGrupperRequestModel} from "./userWithKursusGrupperRequestModel";
import UserNameInfoSimpleModel from "core/components/userProfile/userNameInfoSimpleModel";
import {nameof} from "ts-simple-nameof";
import dayjs from "dayjs";
import {MapFromPersonaleUserResponsModel, PersonaleUserSimpleModel} from "core/sharedmodels/user/userPersonaleModel";
import {LogbogPersonaleUserSelectResponseModel} from "./logbogPersonaleUserSelectResponseModel";
import {UddannelseslaegerFilterModel} from "pages/uddannelseslaegeOverview/uddannelseslaegeOverviewContextProvider";
import LaegerWithForloebRequestModel from "./forloebForUddannelseslaegerRequestModel";
import {LaegeWithSimpleForloebResponseModel} from "../forloeb/laegeWithSimpleForloebResponseModel";
import {TotalAndFilteredResponseModel} from "../totalAndFilteredResponseModel";

export class LogbogUserInfoApi {
    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.accountService = userService;
    }

    private httpClientService: HttpClientService;
    private accountService: AccountService;

    private baseUrl = () => config.logbogApiUrl + "api/userInfo/";
    private forloebStamdataBaseUrl = () => config.stamdataApiUrl + "api/forloeb/"
    private userInfoWithRolesUrl = () => this.baseUrl() + "userInfoWithRoles/";
    public forloebForUddannelseslaegerUrl = () => this.baseUrl() + "forloebForUddannelseslaeger";
    public laegerWithKurserUrl = () => this.baseUrl() + "laegerWithKurser/";
    public laegerWithForloebAsyncUrl = () => this.baseUrl() + "laegerWithForloebAsync";
    public filteredAndTotalAsyncUrl = () => this.forloebStamdataBaseUrl() + "filteredAndTotal";
    public laegerPersonaleByForloebUrl = () => this.baseUrl() + "laegerPersonale/byForloebId/";
    public deleteLaegePersonaleOnForloebUrl = (userId: string, forloebId: string) => this.baseUrl() + `laegePersonale/delete/forloebAdgang/${userId}/${forloebId}`;
    public personaleSelectByForloebAccessUrl = () => this.baseUrl() + "personaleSelect/byForloebId/";
    public personaleSelectByMultipleForloebAccessUrl = () => this.baseUrl() + "personaleSelect/byForloebIds/";
    public personaleSelectByBrugerIdAccessUrl = () => this.baseUrl() + "personaleSelect/byBrugerId/";
    public laegerWithAccessToForloebUrl = () => this.baseUrl() + "laegerWithAccess/byForloebId/";
    public laegerWithAccessToForloebByBrugerMaalbeskrivelseUrl = () => this.baseUrl() + "laegerWithAccess/byBrugerMaalbeskrivelse/";
    public laegerSelectForCreateForloebUrl = () => this.baseUrl() + "laegerSelectForCreateForloeb/";
    private uddannelsesansvarligeAndVejledereSelectUrl = () => this.baseUrl() + "uddannelsesansvarligeAndVejledereSelect/";
    public laegerSelectByAfdelingAndUddannelseUrl = () => this.baseUrl() + "laegerSelectByAfdelingAndUddannelse/";
    public laegerSelectByPraksisAndUddannelseUrl = () => this.baseUrl() + "laegerSelectByPraksisAndUddannelse/";
    public getUserNamesInfoUrl = () => this.baseUrl() + "GetUserNamesInfo/";

    private laegeWithForloebRequestWithCancelOption: PromiseWithCancel<ApiResponse<AsyncResponseModel<LaegeWithForloebListResponseModel>>>;
    private totalAndFilteredRequestWithCancelOption: PromiseWithCancel<ApiResponse<AsyncResponseModel<TotalAndFilteredResponseModel>>>;

    cancelPendingRequests(): void {
        if(this.laegeWithForloebRequestWithCancelOption) {
            this.laegeWithForloebRequestWithCancelOption.cancel();
        }
        if(this.totalAndFilteredRequestWithCancelOption) {
            this.totalAndFilteredRequestWithCancelOption.cancel();
        }
    }

    async getUserInfo(userId?: string): Promise<UserProfileModel> {
        if (!userId){
            userId = this.accountService.getUser().UserId;
        }
        const url = this.baseUrl() + userId;
        const result = await this.httpClientService.Get<ApiResponse<LogbogUserInfoResponse>>(url);
        return new UserProfileModel(result.data);
    }

    async getUserInfoWithRoles(userId?: string): Promise<UserProfileModel> {
        if (!userId){
            userId = this.accountService.getUser().UserId;
        }
        const url = this.userInfoWithRolesUrl() + userId;
        const result = await this.httpClientService.Get<ApiResponse<LogbogUserInfoResponse>>(url);
        return new UserProfileModel(result.data);
    }

    async getUserInfoById(userId: string): Promise<UserProfileModel> {
        const url = this.baseUrl() + userId;
        const result = await this.httpClientService.Get<ApiResponse<LogbogUserInfoResponse>>(url);
        return new UserProfileModel(result.data);
    }

    async getLaegerWithForloeb(filter: UddannelseslaegerFilterModel, page: number, pageLength: number): Promise<AsyncResponseModel<LaegeWithSimpleForloebModel>> {

        const url = this.forloebForUddannelseslaegerUrl();

        const request: LaegerWithForloebRequestModel = {
            UddannelseSted: filter.uddannelseSted?.undefinedIfEmpty(),
            Speciale: filter.speciale?.undefinedIfEmpty(),
            UddannelseslaegeStatuser: filter.status,
            Page: page,
            PageLength: pageLength,
            Search: filter.search
        };

        const result = await this.httpClientService.Post<ApiResponse<AsyncResponseModel<LaegeWithSimpleForloebResponseModel>>, LaegerWithForloebRequestModel>(url, request);

        return {
            recordsTotal: result.data.recordsTotal,
            recordsFiltered: result.data.recordsFiltered,
            data: result.data.data.map(info => LaegeWithSimpleForloebModel.FromResponseModel(info)),
        };
    }

    async getLaegerWithKurser(searchLaege: string, searchKursus: string, page: number): Promise<FilterModel<LaegeForloebWithKursusGrupperModel>> {
        const requestModel = new UserWithKursusGrupperRequestModel(searchLaege, searchKursus, page);
        const url = this.laegerWithKurserUrl() + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.Get<ApiResponse<AsyncResponseModel<LaegeForloebWithKursusGrupperResponseModel>>>(url);
        const laegerWithKurser = result.data.data.map(x => {
            return LaegeForloebWithKursusGrupperModel.FromResponseModel(x)
        }).sort((a, b) => a.laegeNavn.localeCompare(b.laegeNavn));
        return new FilterModel<LaegeForloebWithKursusGrupperModel>(laegerWithKurser, result.data.recordsFiltered, result.data.recordsTotal)
    }

    //Not used at the moment
    async getLaegerWithForloebAsync(filter: ForloebOverviewFilterModel, filterParamsModel: FilterParamsModel): Promise<FilterModel<LaegeWithForloebModel>> {
        const requestModel = new forloebOverviewFilterRequestModel(filter, filterParamsModel);
        const url = this.laegerWithForloebAsyncUrl() + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.Get<ApiResponse<AsyncResponseModel<LaegeWithForloebListResponseModel>>>(url);

        let laegeWithForloebModel = result.data.data.map(laegeWithForloeb => {
            return LaegeWithForloebModel.FromResponseModel(laegeWithForloeb)
        });

        return new FilterModel<LaegeWithForloebModel>(laegeWithForloebModel, result.data.recordsFiltered, result.data.recordsTotal);
    }

    async getLaegerWithForloebAsyncWithCancellation(filter: ForloebOverviewFilterModel, filterParamsModel: FilterParamsModel): Promise<FilterModel<LaegeWithForloebModel>> {
        const requestModel = new forloebOverviewFilterRequestModel(filter, filterParamsModel);
        const url = this.laegerWithForloebAsyncUrl() + ObjectToQueryString(requestModel);
        if (this.laegeWithForloebRequestWithCancelOption)
            this.laegeWithForloebRequestWithCancelOption.cancel();

        this.laegeWithForloebRequestWithCancelOption = this.httpClientService.GetWithCancellation<ApiResponse<AsyncResponseModel<LaegeWithForloebListResponseModel>>>(url);

        let laegeWithForloebModel = await this.laegeWithForloebRequestWithCancelOption.then(x => x.data.data.map(laegeWithForloeb => {
                return LaegeWithForloebModel.FromResponseModel(laegeWithForloeb)
        }));

        return new FilterModel<LaegeWithForloebModel>(laegeWithForloebModel, await this.laegeWithForloebRequestWithCancelOption.then(x => x.data.recordsFiltered), await this.laegeWithForloebRequestWithCancelOption.then(x => x.data.recordsTotal));
    }

    async getXofYLaegerWithForloeb(filter: ForloebOverviewFilterModel, filterParamsModel: FilterParamsModel): Promise<TotalAndFilteredResponseModel> {
        const requestModel = new forloebOverviewFilterRequestModel(filter, filterParamsModel);
        const url = this.filteredAndTotalAsyncUrl() + ObjectToQueryString(requestModel);
        if (this.totalAndFilteredRequestWithCancelOption)
            this.totalAndFilteredRequestWithCancelOption.cancel();

        this.totalAndFilteredRequestWithCancelOption = this.httpClientService.GetWithCancellation<ApiResponse<AsyncResponseModel<TotalAndFilteredResponseModel>>>(url);

        let totalAndFilteredModel = await this.totalAndFilteredRequestWithCancelOption;
        return totalAndFilteredModel.data;
    }

    async getLaegerPersonaleOnForloeb(forloebId: string) : Promise<PersonaleUserSimpleModel[]> {
        const url = this.laegerPersonaleByForloebUrl() + forloebId;
        const result = await this.httpClientService.Get<ApiResponse<LogbogPersonaleUserSimpleResponseModel[]>>(url);

        const models = result.data.map(MapFromPersonaleUserResponsModel);
        models.SortByThenBy(nameof<PersonaleUserSimpleModel>(x => x.isFavorite), nameof<PersonaleUserSimpleModel>(x => x.navn), false);

        return models;
    }

    async deleteLaegePersonaleFromForloeb(userId: string, forloebId: string) : Promise<boolean> {
        const url = this.deleteLaegePersonaleOnForloebUrl(userId, forloebId);
        const result = await this.httpClientService.Delete<ApiResponse<boolean>>(url);

        return result.data;
    }

    async getPersonaleSelectOnForloeb (forloebId: string): Promise<ForSelectModel[]> {
        const url = this.personaleSelectByForloebAccessUrl() + forloebId;
        const result = await this.httpClientService.Get<ApiResponse<LogbogPersonaleUserSelectResponseModel[]>>(url);

        const selectOptions = result.data;
        selectOptions.SortByThenBy(nameof<LogbogPersonaleUserSelectResponseModel>(x => x.isFavorite), nameof<LogbogPersonaleUserSelectResponseModel>(x => x.text), false);

        return selectOptions.map(x => ({ id: x.id, text: x.text, groupText: "" } as ForSelectModel));
    }

    async getPersonaleSelectOnMultipleForloeb (forloebIds : string[]): Promise<ForSelectModel[]> {
        const url = this.personaleSelectByMultipleForloebAccessUrl()
        const request = new LogbogPersonaleRequest(forloebIds);
        const result = await this.httpClientService.Post<ApiResponse<LogbogPersonaleUserSelectResponseModel[]>, LogbogPersonaleRequest>(url, request);

        const selectOptions = result.data;
        selectOptions.SortByThenBy(nameof<LogbogPersonaleUserSelectResponseModel>(x => x.isFavorite), nameof<LogbogPersonaleUserSelectResponseModel>(x => x.text), false);

        return selectOptions.map(x => ({ id: x.id, text: x.text, groupText: "" } as ForSelectModel));
    }

    async getPersonaleSelectByBruger (brugerId : string): Promise<ForSelectModel[]> {
        const url = this.personaleSelectByBrugerIdAccessUrl() + brugerId;
        const result = await this.httpClientService.Get<ApiResponse<ForSelectResponseModel[]>>(url);
        return result.data.map(info => {
            return new ForSelectModel(info)
        });
    }

    async getUsersWithAccessToForloeb(forloebId: string, includeOutdated: boolean) : Promise<UserWithForloebAccessDateModel[]> {
        const url = this.laegerWithAccessToForloebUrl() + forloebId;
        const result = await this.httpClientService.Get<ApiResponse<LogbogUserWithForloebAccessDateResponseModel[]>>(url);

        const models = result.data
            .map(bruger => UserWithForloebAccessDateModel.FromLogbogResponseModel(bruger))
            .filter(bruger => !!includeOutdated || dayjs(bruger.adgangTil).isSameOrAfter(dayjs()))
            .sortBy(x => x.navn)

        return models;
    }

    async getUsersWithAccessToBrugerMaalbeskrivelseForloeb(brugerMaalbeskrivelseId : string) : Promise<UserWithForloebAccessDateModel[]> {
        const url = this.laegerWithAccessToForloebByBrugerMaalbeskrivelseUrl() + brugerMaalbeskrivelseId;
        const result = await this.httpClientService.Get<ApiResponse<LogbogUserWithForloebAccessDateResponseModel[]>>(url);

        return result.data.map(bruger => {
            return UserWithForloebAccessDateModel.FromLogbogResponseModel(bruger)
        }).sort((a, b) => a.navn.localeCompare(b.navn));
    }

    async getLaegerSelectForCreateForloeb(search: string) : Promise<ForSelectModel[]> {
        const queryStringModel = {
            search: search
        }

        const url = this.laegerSelectForCreateForloebUrl() + ObjectToQueryString(queryStringModel);
        const result = await this.httpClientService.Get<ApiResponse<ForSelectResponseModel[]>>(url);

        return result.data.map(info => {
            return new ForSelectModel(info)
        });
    }

    async getBrugerForTidsbegraensetAdgang(search: string, brugerId: string) : Promise<ForSelectModel[]> {
        const queryStringModel = {
            search: search,
            brugerId: brugerId
        }

        const url = this.uddannelsesansvarligeAndVejledereSelectUrl() + ObjectToQueryString(queryStringModel);
        const result = await this.httpClientService.Get<ApiResponse<ForSelectResponseModel[]>>(url);

        return result.data.map(info => {
            return new ForSelectModel(info)
        });
    }

    async getLaegerSelectByAfdelingAndUddannelse(afdelingId: string, vejlederId?: string, uddannelseType?: UddannelseTypeEnum): Promise<ForSelectModel[]> {
        const requestModel = new LogbogLaegerRequest(afdelingId, vejlederId, uddannelseType,);
        const url = this.laegerSelectByAfdelingAndUddannelseUrl() + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.Get<ApiResponse<ForSelectResponseModel[]>>(url);
        return result.data.map(info => {
            return new ForSelectModel(info)
        });
    }

    async getLaegerSelectByPraksisAndUddannelse(praksisId: string, vejlederId?: string, uddannelseType?: UddannelseTypeEnum): Promise<ForSelectModel[]> {
        const requestModel = new LogbogLaegerRequest(praksisId, vejlederId, uddannelseType);
        const url = this.laegerSelectByPraksisAndUddannelseUrl() + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.Get<ApiResponse<ForSelectResponseModel[]>>(url);
        return result.data.map(info => {
            return new ForSelectModel(info)
        });
    }

    async getUserNamesInfo(userIds: string[]): Promise<UserNameInfoSimpleModel[]> {
        const url = this.getUserNamesInfoUrl() + ObjectToQueryString(userIds);
        const result = await this.httpClientService.Get<ApiResponse<UserNameInfoSimpleModel[]>>(url);
        return result.data;
    }
}

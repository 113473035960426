import {SpoergsmaalModel} from "./spoergsmaalModel";
import {LogbogSektionResponseModel} from "../../../services/api/logbogEvaluering/logbogSektionResponseModel";

export class SektionModel {

    public static FromResponseModel(sektionResponseModel: LogbogSektionResponseModel): SektionModel{
        let newModel = new SektionModel();

        newModel.id = sektionResponseModel.id;
        newModel.title = sektionResponseModel.title;
        newModel.includedInProgress = sektionResponseModel.includedInProgress;
        newModel.tekst = sektionResponseModel.tekst;
        newModel.sortOrder = sektionResponseModel.sortOrder;
        newModel.subTitle = sektionResponseModel.subTitle;
        newModel.spoergsmaal = sektionResponseModel.spoergsmaal.map(s => SpoergsmaalModel.FromResponseModel(s));

        return newModel;
    }

    public AllQuestionsAnswered = () : boolean => {
        if (!this.spoergsmaal){
            return false;
        }

        return this.spoergsmaal.every(x => x.svar);
    }

    id: string;
    title: string;
    subTitle: string;
    tekst: string;
    includedInProgress: boolean;
    sortOrder: number;
    spoergsmaal: SpoergsmaalModel[];
}

import {BasicNotatModel, FromResponseModel} from "./basicNotatModel";
import {LogbogNotatResponseModel} from "../../../services/api/logbogNotat/logbogNotatResponseModel";

export class NotaterContainerModel {
    public static FromResponseModel(alleNotaterResponseModel: LogbogNotatResponseModel[], totalRecords: number): NotaterContainerModel {
        const model = new NotaterContainerModel();
        model.alleNotaterModels = alleNotaterResponseModel.map(n => {return FromResponseModel(n)});
        model.totalRecords = totalRecords;
        return model;
    }

    alleNotaterModels: BasicNotatModel[];
    totalRecords: number;
}

import {Localizer} from "../../../infrastructure/localization/localizer";

export enum NotatType {
    TekstNotat = 0,
    Kompetence = 1,
    AttestationForTid = 2,
    Vejledersamtale = 3,
    Uddannelsesplan = 4,
    Uddannelse = 5,
    Merit = 6,
    Laegekursus = 7,
    LaegekursusGruppe = 8,
    Ansoegning = 9,
    Dokumentation = 10,
    LogbogLegacyNotat = 11,
    Uddannelsesprogram = 12
}

export function GetNotatTypeEnumTranslation(type?: NotatType) {
    switch (type) {
        case NotatType.TekstNotat:
            return Localizer.notatPage_tekstNotater();
        case NotatType.Kompetence:
            return Localizer.notatPage_kompetenceNotater();
        case NotatType.AttestationForTid:
            return Localizer.notatPage_attestationForTidNotater();
        case NotatType.Vejledersamtale:
            return Localizer.notatPage_vejledersamtaleNotater();
        case NotatType.Uddannelsesplan:
            return Localizer.notatPage_uddannelsesplanNotater();
        case NotatType.Uddannelse:
            return Localizer.notatPage_uddannelseNotater();
        case NotatType.Merit:
            return Localizer.notatPage_meritNotater();
        case NotatType.Laegekursus:
            return Localizer.notatPage_laegekursusNotater();
        case NotatType.LaegekursusGruppe:
            return Localizer.notatPage_laegekursusGruppeNotater();
        case NotatType.LogbogLegacyNotat:
            return Localizer.notatPage_logbogLegacyNotat();
        case NotatType.Uddannelsesprogram:
            return Localizer.notatPage_uddannelsesprogramNotater();
        //Ansøgnings- og dokumentationsnotater skal ikke populeres i dropdown options, de skal kun vises specifikke steder (ses også i query "NotaterQuery")
        default:
            return "";
    }
}

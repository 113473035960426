import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {AccountService} from "../../account/accountService";
import {LogbogVejledersamtaleCrudModel} from "./logbogVejledersamtaleCrudModel";
import {LogbogVejledersamtaleResponseModel} from "./logbogVejledersamtaleResponseModel";
import {
    CrudVejledersamtaleInputModel
} from "../../../pages/vejledning/vejledersamtaler/components/crudVejledersamtaleInputModel";
import {VejledersamtaleModel} from "../../../pages/vejledning/vejledersamtaler/vejledersamtaleModel";
import {GodkendPostModel} from "../forloeb/godkendPostModel";

export class LogbogVejledersamtaleApi {
    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = () => config.logbogApiUrl + "api/vejledersamtale/";
    private vejledersamtaleAfholdt = () => this.baseUrl() + "afholdt/";
    private vejledersamtaleOnForloeb = () => this.baseUrl() + "byForloeb/";
    private vejlederSamtaleDeleteGodkendelse = () => this.baseUrl() + "deleteGodkendelse/";
    private editVejledersamtaleUrl = () => this.baseUrl() + "editVejledersamtale/";
    private toggleKlarmeldtAtUrl = () => this.baseUrl() + "toggleKlarmeldtAt/";
    public getKlarmeldteVejledersamtalerUrl = () => this.baseUrl() + "getKlarmeldteVejledersamtaler/";

    async createVejledersamtale(inputModel: CrudVejledersamtaleInputModel): Promise<string> {
        inputModel = this.setDate(inputModel);
        const postModel = new LogbogVejledersamtaleCrudModel(inputModel);
        const url = this.baseUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogVejledersamtaleCrudModel>(url, postModel);
        return result.data;
    }

    async getVejledersamtalerByForloeb(forloebId: string): Promise<VejledersamtaleModel[]> {
        const url = this.vejledersamtaleOnForloeb() + forloebId;
        const result = await this.httpClientService.Get<ApiResponse<LogbogVejledersamtaleResponseModel[]>>(url);
        return result.data.map(vejledersamtale => {
            return VejledersamtaleModel.FromResponseModel(vejledersamtale)
        });
    }

    async editVejledersamtale(inputModel: CrudVejledersamtaleInputModel): Promise<string> {
        inputModel = this.setDate(inputModel);
        const postModel = new LogbogVejledersamtaleCrudModel(inputModel);
        const url = this.editVejledersamtaleUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogVejledersamtaleCrudModel>(url, postModel);
        return result.data;
    }

    setDate(inputModel: CrudVejledersamtaleInputModel): CrudVejledersamtaleInputModel {
        if(inputModel.starttidspunkt){
            inputModel.starttidspunkt.setFullYear(inputModel.dato.getFullYear());
            inputModel.starttidspunkt.setMonth(inputModel.dato.getMonth());
            inputModel.starttidspunkt.setDate(inputModel.dato.getDate());
        }
        if(inputModel.sluttidspunkt){
            inputModel.sluttidspunkt.setFullYear(inputModel.dato.getFullYear());
            inputModel.sluttidspunkt.setMonth(inputModel.dato.getMonth());
            inputModel.sluttidspunkt.setDate(inputModel.dato.getDate());
        }
        return inputModel;
    }

    async deleteVejledersamtale(id: string): Promise<string> {
        const url = this.baseUrl() + id;
        const result = await this.httpClientService.Delete<ApiResponse<string>>(url);
        return result.data;
    }

    async godkendVejledersamtale(id: string, uddannelsessted: string, kommentar?: string): Promise<string> {
        const user = this.userService.getUser();
        const body = new GodkendPostModel(id, user.UserId, user.CurrentRolleTitel, uddannelsessted, kommentar);
        const url = this.vejledersamtaleAfholdt();
        const result = await this.httpClientService.Post<ApiResponse<string>, any>(url, body);
        return result.data;
    }

    async fjernGodkendelseVejlederSamtale(id: string): Promise<string> {
        const url = this.vejlederSamtaleDeleteGodkendelse() + id;
        const result = await this.httpClientService.Delete<ApiResponse<string>>(url);
        return result.data;
    }

    async toggleKlarmeldtAt(id: string): Promise<string> {
        const url = this.toggleKlarmeldtAtUrl() + id;
        const result = await this.httpClientService.Post<ApiResponse<string>, any>(url, []);
        return result.data;
    }
}

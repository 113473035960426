import { ForloebOverviewFilterModel } from "./ForloebOverviewFilterModel";
import { ForloebOverviewFiltersState } from "./forloebOverviewFiltersSlice";
import { ForloebOverviewChartScrollInput, Month, November } from "./scrollHelper";
import { toDate, toDateFromApiFormat } from "index";

enum DateFilterType
{
    Year = 1,
    Date = 2,
    Period = 3
}

export type Period = {
    from: Date;
    to: Date;
}

export type DateFilterInput = {
    dateFilterType: DateFilterType;
    year: number;
    date: Date;
    periodFrom: Date;
    periodTo: Date;
};

class ForloebOverviewDateFilterPostModel
{
    constructor (model: ForloebOverviewFilterModel)
    {
        this.dateFilterType = model.dateFilterType;
        this.year = model.year;
        this.date = toDate(model.date).dateToApiPostFormat();
        this.periodFrom = toDate(model.periodFrom).dateToApiPostFormat();
        this.periodTo = toDate(model.periodTo).dateToApiPostFormat();

    }

    dateFilterType: DateFilterType;
    year: number;
    date: string;
    periodFrom: string;
    periodTo: string;
}

class ForloebOverviewDateFilterBodyPostModel
{
    constructor (model: ForloebOverviewFilterModel)
    {
        this.dateFilterType = model.dateFilterType;
        this.year = model.year;
        this.date = toDate(model.date);
        this.period = {
            from: toDate(model.periodFrom),
            to: toDate(model.periodTo)
        }
    }

    dateFilterType: DateFilterType;
    year: number;
    date: Date;
    period: Period;
}

function getScrollForloebDateChartInput(dateFilter: DateFilterInput, minYear: number, containerRef: React.RefObject<HTMLDivElement>) : ForloebOverviewChartScrollInput {
    switch(dateFilter.dateFilterType) {
        case DateFilterType.Year: {
            return {
                minYear,
                refObject: containerRef,
                year: dateFilter.year-1,
                month: November
            };
        } 

        case DateFilterType.Date: { 
            const displayDate = new Date(dateFilter.date);
            displayDate.setMonth(displayDate.getMonth()-1);
            return {
                minYear,
                refObject: containerRef,
                year: displayDate.getFullYear(),
                month: displayDate.getMonth() as Month
            };
        } 

        case DateFilterType.Period: { 
            const displayDate = new Date(dateFilter.periodFrom);
            displayDate.setMonth(displayDate.getMonth()-1);
            return {
                minYear,
                refObject: containerRef,
                year: displayDate.getFullYear(),
                month: displayDate.getMonth() as Month
            };
        } 
    } 
}

function getFilterStartDate(dateFilter: DateFilterInput) {
    switch (dateFilter.dateFilterType) {
        case DateFilterType.Year:
            return new Date(dateFilter.year, 0, 1);
        case DateFilterType.Date:
            return dateFilter.date;
        case DateFilterType.Period:
            return dateFilter.periodFrom;
    }
}

function getFilterEndDate(dateFilter: DateFilterInput) {
    switch (dateFilter.dateFilterType) {
        case DateFilterType.Year:
            return new Date(dateFilter.year+1, 0, 1);
        case DateFilterType.Date:
            return dateFilter.date;
        case DateFilterType.Period:
            return dateFilter.periodTo;
    }
}

function getDateFilterInput(input: ForloebOverviewFilterModel | ForloebOverviewFiltersState) : DateFilterInput {
    const toReturn : DateFilterInput = {
        dateFilterType: input.dateFilterType,
        year: input.year,
        date: toDate(input.date),
        periodFrom: toDate(input.periodFrom),
        periodTo: toDate(input.periodTo),
    };
    
    return toReturn;
}

function isPeriodValid(from: Date, to: Date) : boolean {
    const toValue = to.getUtcWithoutTime().valueOf();
    const fromValue = from.getUtcWithoutTime().valueOf();
    const isFromAndToTheSame = toValue === fromValue;
    const isToBeforeFrom = toValue < fromValue;
    return !isFromAndToTheSame && !isToBeforeFrom;
}

function periodFromJson(jsonString: string) : Period {
    const jsonPeriod = JSON.parse(jsonString);
    return { 
        from: toDateFromApiFormat(jsonPeriod.From as string),
        to: toDateFromApiFormat(jsonPeriod.To as string)
    };
}

export
{
    ForloebOverviewDateFilterBodyPostModel,
    ForloebOverviewDateFilterPostModel,
    DateFilterType,
    periodFromJson,
    isPeriodValid,
    getFilterEndDate,
    getFilterStartDate,
    getScrollForloebDateChartInput,
    getDateFilterInput,
}

import { Cookies, SAME_SITE_OPTIONS, defaultCookieConsentName } from "react-cookie-consent"

const configCookieConsentCookieName = defaultCookieConsentName;

const daysInYear = 365;

const cookieOptions = {
  expires: daysInYear * 2,
  sameSite: SAME_SITE_OPTIONS.LAX,
}

const setCookieConsentCookie = (userConsentValue: boolean) => {
  const valueString = `${userConsentValue}`;
  Cookies.set(configCookieConsentCookieName, valueString, cookieOptions);
}

const useCookieConsentConfig = () => {
  return {
    /** Cookie-name for setting up CookieConsent */
    cookieName: configCookieConsentCookieName,
    /** Cookie-options for setting up CookieConsent */
    cookieOptions: cookieOptions,
    /** Programmatically set "cookie-consent"-cookie value */
    setCookieConsentCookie
  }
}

export default useCookieConsentConfig;

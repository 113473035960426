import {LogbogUserForloebAdgangeResponseModel} from "../../../services/api/logbogUser/logbogUserInfoResponse";
import {dateTimeStringFromApiToDate} from "../../../index";
import {GetUddannelsestypeTranslation} from "../../sharedmodels/stilling/uddannelseTypeEnum";

export class ForloebAdgangeModel {
    public static FromResponseModel(responseModel: LogbogUserForloebAdgangeResponseModel): ForloebAdgangeModel {
        const model = new ForloebAdgangeModel();
        model.userId = responseModel.userId;
        model.id = responseModel.id;
        model.forloebId = responseModel.forloebId;
        model.adgangTilDato = dateTimeStringFromApiToDate(responseModel.adgangTilDato).dateWithoutTimeFormat(false);
        model.stillingsnummer = responseModel.stillingsnummer;
        model.uddannelsesType = GetUddannelsestypeTranslation(responseModel.uddannelsesType);
        model.Uddannelsessted = responseModel.uddannelsessted;
        return model;
    }

    id: string;
    userId: string;
    forloebId: string;
    adgangTilDato: string;
    stillingsnummer: string;
    uddannelsesType: string;
    Uddannelsessted: string;
}

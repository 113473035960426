import {CrudVejlederInputModel} from "../../../pages/vejledere/components/crudVejlederInputModel";
import {StamdataVejlederAfdelingPostModel} from "./stamdataVejlederAfdelingPostModel";

export class StamdataVejlederCrudPostModel {
    constructor(inputModel : CrudVejlederInputModel) {
        this.brugerId = inputModel.brugerId;
        this.authId = inputModel.authId;
        this.mobilePhone = inputModel.mobilePhone;
        this.email = inputModel.email;
        this.alternativEmailToSendTo = inputModel.alternativEmail;
        this.uddannelsessteder = [new StamdataVejlederAfdelingPostModel(inputModel.uddannelsessted)];
    }

    public brugerId?: string;
    public authId: string;
    public email?: string;
    public mobilePhone?: string;
    public alternativEmailToSendTo?: string;
    public uddannelsessteder: StamdataVejlederAfdelingPostModel[];
}

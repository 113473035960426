// Client formats
export const DateWithoutTimeFormat = "DD/MM/YYYY";
export const DateWithTimeFormat = "DD/MM/YYYY HH:mm";
export const TimeFormat = "HH:mm";

// Client to api post formats
export const DateAndTimePostFormat = "DD/MM/YYYY HH:mm";
export const DatePostFormat = "DD/MM/YYYY";

// From api formats (ISO)
export const DateTimeIsoFormatWithoutZone = "YYYY-MM-DDTHH:mm";
export const DateFullTimeIsoWithoutTimeZoneFormat = "YYYY-MM-DDTHH:mm:ss"
export const DateFormatWithoutZoneAndTime = "DD-MM-YYYY";


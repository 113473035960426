import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {StamdataUddannelseResponseModel} from "./stamdataUddannelseResponseModel";
import {StamdataUddannelseModel} from "./stamdataUddannelseModel";

export class StamdataUddannelseApi {
    private httpClientService: HttpClientService;

    private baseUrl = () => config.stamdataApiUrl + "api/uddannelse/";

    constructor(httpClientService: HttpClientService) {
        this.httpClientService = httpClientService;
    }

    async getUddannelser(): Promise<StamdataUddannelseModel[]> {
        const url = this.baseUrl() + "uddannelserForSelect";
        const result = await this.httpClientService.Get<ApiResponse<StamdataUddannelseResponseModel[]>>(url);
        return result.data.map(x => new StamdataUddannelseModel(x));
    }

    async getUddannelse(uddannelseId: string): Promise<StamdataUddannelseModel> {
        const url = this.baseUrl() + uddannelseId;
        const result = await this.httpClientService.Get<ApiResponse<StamdataUddannelseResponseModel>>(url);
        return new StamdataUddannelseModel(result.data);
    }
}

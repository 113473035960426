import { ReactInstance, Ref, useImperativeHandle, useRef } from "react";

/**
 * Hook that creates normal ref and shares it with forwarded ref.
 * Useful when components needs to forward ref but at the same time needs to use it internally.
 * @param forwardedRef - reference forwarded from parent component
 * @returns reference object that can be used by component internally
 *
 * @example
 *
 * const Card = forwardRef((props: CardProps, ref: Ref<HTMLDivElement>) => { 
 *   const cardRef = useForwardedRef(ref);
 *   useOnClickOutside(cardRef, props.close);
 *   return <div ref={cardRef} {...props}/>
 * }
 */
const useForwardedRef = <T extends ReactInstance | any>(forwardedRef: Ref<T>) => {
  const internalRef = useRef<T>(null);
  useImperativeHandle<T | null, T | null>(
    forwardedRef,
    () => internalRef.current,
  );
  return internalRef;
};

export default useForwardedRef;

import {HttpClientService} from "../../httpClient/httpClientService";
import config from "../../../config/config";
import {ApiResponse} from "../apiResponse";
import {NyhedModel} from "../../../core/sharedmodels/nyheder/nyhedModel";
import {LogbogNyhedResponseModel} from "./logbogNyhedResponseModel";
import {NyhederOverviewFilterModel} from "../../../core/components/nyheder/nyhederOverviewFilterModel";
import {FilterModel} from "../../../core/sharedmodels/filterModel";
import {AsyncResponseModel} from "../asyncResponseModel";
import {ObjectToQueryString} from "../objectToQueryString";
import {CrudNyhedInputModel} from "../../../core/components/nyheder/crudNyhedInputModel";
import {LogbogNyhederCrudPostModel} from "./logbogNyhederCrudPostModel";

export class LogbogNyhederApi{
    constructor(httpClientService: HttpClientService) {
        this.httpClientService = httpClientService;
    }

    private httpClientService: HttpClientService;

    private baseUrl = () => config.logbogApiUrl + "api/nyheder";
    private editNyhed = (id: string) => `${this.baseUrl()}/${id}`;
    private deleteNyhed = (id: string) => `${this.baseUrl()}/${id}`;

    async getNyheder(filter: NyhederOverviewFilterModel): Promise<FilterModel<NyhedModel>> {
        const url = this.baseUrl() + ObjectToQueryString(filter);
        const result = await this.httpClientService.Get<ApiResponse<AsyncResponseModel<LogbogNyhedResponseModel>>>(url);
        let nyheder = result.data.data.map(nyhed => {
            return NyhedModel.FromResponseModel(nyhed)
        });

        return new FilterModel<NyhedModel>(nyheder, result.data.recordsFiltered, result.data.recordsTotal)
    }

    async CreateNyhed (inputModel: CrudNyhedInputModel): Promise<string> {
        const postModel = LogbogNyhederCrudPostModel.buildNew(inputModel);
        const url = this.baseUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogNyhederCrudPostModel>(url, postModel);
        return result.data;
    }

    async EditNyhed (nyhedId: string, inputModel: CrudNyhedInputModel): Promise<string> {
        const postModel = LogbogNyhederCrudPostModel.buildExisting(inputModel);
        const url = this.editNyhed(nyhedId);
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogNyhederCrudPostModel>(url, postModel);
        return result.data;
    }

    async DeleteNyhed (nyhedId: string): Promise<string> {
        const url = this.deleteNyhed(nyhedId);
        const result = await this.httpClientService.Delete<ApiResponse<string>>(url);
        return result.data;
    }
}



export class GetSelectOptionPostModel {
    constructor(regionIds?: string[], sygehusIds?: string[], includeInactive?: boolean, omraadeIds?: string[]) {
        this.regionIds = regionIds;
        this.sygehusIds = sygehusIds;
        this.omraadeIds = omraadeIds;
        this.includeInactive = includeInactive ?? false;
    }

    regionIds?: string[];
    sygehusIds?: string[];
    omraadeIds?: string[];
    includeInactive: boolean;
}

export class PaginationModel {
    constructor(page: number, pageLength: number, totalRecords : number) {
        this.page = page;
        this.pageLength = pageLength;
        this.recordCount = totalRecords;
    }

    public page : number;
    public pageLength : number;
    public recordCount : number;
}

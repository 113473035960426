import { components, GroupBase, ValueContainerProps } from "react-select";
import React from "react";
import { CustomComponentsCommonSelectProps, DropdownOption } from "../../dropdown";
import { Localizer } from "infrastructure/localization/localizer";

type MaxItemsValueContainerProps<Option extends DropdownOption, IsMulti extends boolean> = ValueContainerProps<Option, IsMulti, GroupBase<Option>>;

const MultiValuesAsTextValueContainer = <Option extends DropdownOption, IsMulti extends boolean>({
  children,
  ...props
}: MaxItemsValueContainerProps<Option, IsMulti>) => {

  const { valueContainerAppendTextOverride } = props.selectProps as CustomComponentsCommonSelectProps;
  const multipleValuesSelectedAppendText = valueContainerAppendTextOverride ?? Localizer.global_valgte();

  // eslint-disable-next-line prefer-const
  let [values, input] = children as [React.ReactNode, HTMLInputElement];

  if (Array.isArray(values)) {
    const count = values.length;
    let valuesDisplay;

    if (values && count === 1) {
      // @ts-ignore: Object is possibly 'null'.
      valuesDisplay = values[0].props.children;
    } else {
      valuesDisplay = `${count} ${multipleValuesSelectedAppendText}`;
    }

    values = <span className="font-weight-bold">{valuesDisplay}</span>;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

export default MultiValuesAsTextValueContainer;

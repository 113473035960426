import {HttpClientService} from "../../httpClient/httpClientService";
import {AccountService} from "../../account/accountService";
import config from "../../../config/config";
import {ApiResponse} from "../apiResponse";
import { BrugerRolleSimpleResponseModel } from "./stamdataBrugerRolleSimpleResponseModel";

export class StamdataBrugerRolleApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = () => config.stamdataApiUrl + "api/brugerrolle";
    private getBrugerRolleSimpleUrl = (id: string) => `${this.baseUrl()}/simple/${id}`;
    private getBrugerRolleInfoUrl = (id: string) => `${this.baseUrl()}/info/${id}`;

    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    async GetBrugerRolleSimple(brugerRolleId: string): Promise<BrugerRolleSimpleResponseModel> {
        const url = this.getBrugerRolleSimpleUrl(brugerRolleId);
        const result = await this.httpClientService.Get<ApiResponse<BrugerRolleSimpleResponseModel>>(url);
        return result.data;
    }
}

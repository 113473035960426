import {useCallback, useEffect, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {KompetenceEvalueringModel} from "../../core/sharedmodels/evaluering/kompetenceEvalueringModel";
import useLogbogApi from "../../core/hooks/useLogbogApi";
import {KompetenceSpoergsmaalModel} from "../../core/sharedmodels/evaluering/kompetenceSpoergsmaalModel";
import {KompetenceSvarModel} from "../../core/sharedmodels/evaluering/KompetencesvarModel";
import {DeleteEvalueringSvarModel} from "../../services/api/logbogEvaluering/deleteEvalueringSvarModel";
import {KompetenceSektionModel} from "../../core/sharedmodels/evaluering/kompetenceSektionModel";

export type KompetenceEvalueringHookType = {
    kompetenceEvaluering: KompetenceEvalueringModel;
    indsendt: boolean;
    setIndsendtStatus: (indsendt: boolean) => void;
    questionHasAnswer: (question: KompetenceSpoergsmaalModel) => boolean;
    answerUpdateLoading: boolean;
    totalQuestions: number;
    evalueringSections: KompetenceSektionModel[];
    getSpoergsmaalIndex: (sektionIndexState: number) => number;
    updateSvar: (svarModel: KompetenceSvarModel) => Promise<void>;
    deleteSvar: (deleteSvarModel: DeleteEvalueringSvarModel) => Promise<void>;
}
const useKompetenceEvaluering = () => {
    const routeMatch = useRouteMatch();
    const [kompetenceEvaluering, setKompetenceEvaluering] = useState<KompetenceEvalueringModel>();
    const {evalueringApi} = useLogbogApi()

    const [indsendt, setIndsendt] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [answerUpdateLoading, setAnswerUpdateLoading] = useState(false);

    const editRouteParams = (routeMatch.params as { kompetenceEvalueringId: string, kompetenceId: string });

    useEffect(() => {
        const fetch = async () => {
            return await evalueringApi.getKompetenceEvaluering(editRouteParams.kompetenceEvalueringId)
        }
        fetch().then(kompetenceEvaluering => {
                setIndsendt(kompetenceEvaluering.indsendt);
                setKompetenceEvaluering(kompetenceEvaluering);
                setIsLoading(false);
            }
        )
    }, [editRouteParams.kompetenceEvalueringId, evalueringApi])

    const updateSvar = useCallback(async (svarModel: KompetenceSvarModel) => {
        const spoergsmaal = kompetenceEvaluering?.sektioner?.flatMap(x => x.spoergsmaal).find(x => x.id === svarModel.spoergsmaalId);
        if (spoergsmaal && kompetenceEvaluering?.laegekompetenceEvalueringId) {
            await evalueringApi.patchlaegeKompetenceEvalueringSvar(kompetenceEvaluering?.laegekompetenceEvalueringId, svarModel, spoergsmaal.antalSvar, spoergsmaal.laegerKanBesvare)
                .then(response => {
                    svarModel.id = response;
                    setKompetenceEvaluering((prev) => {
                        if (prev === undefined) return prev;
                        const sektion = prev.sektioner.find(x => x.spoergsmaal.some(y => y.id === svarModel.spoergsmaalId));
                        const spoergsmaal = sektion?.spoergsmaal.find(x => x.id === svarModel.spoergsmaalId);

                        if (!spoergsmaal || !sektion) return prev;

                        return {
                            ...prev,
                            sektioner: [...prev.sektioner.filter(x => x.id !== sektion?.id), {
                                ...sektion,
                                spoergsmaal: [...sektion.spoergsmaal.filter(x => x.id !== spoergsmaal?.id), {
                                    ...spoergsmaal,
                                    evalueringSvar: [
                                        ...(spoergsmaal.antalSvar > 1
                                            ? spoergsmaal.evalueringSvar.filter(x => x.id !== svarModel.id)
                                            : []),
                                        svarModel
                                    ]
                                }]
                            }]
                        } as KompetenceEvalueringModel;
                    });
                    return response
                });
        }
    }, [evalueringApi, kompetenceEvaluering?.laegekompetenceEvalueringId, kompetenceEvaluering?.sektioner])

    const deleteSvar = useCallback(async (deleteSvarModel: DeleteEvalueringSvarModel) => {
        const spoergsmaal = kompetenceEvaluering?.sektioner?.flatMap(x => x.spoergsmaal).find(x => x.id === deleteSvarModel.spoergsmaalId);
        if (spoergsmaal && kompetenceEvaluering?.laegekompetenceEvalueringId){
            setAnswerUpdateLoading(true);
            await evalueringApi.removeKompetenceEvalueringSvar(deleteSvarModel.kompetenceEvalueringId, deleteSvarModel.svarmulighedId, spoergsmaal.id, spoergsmaal.laegerKanBesvare)
                .then(response => {
                const evalueringSvarId = response;
                setKompetenceEvaluering((prev) => {
                    if (prev === undefined) return prev;
                    const sektion = prev.sektioner.find(x => x.spoergsmaal.some(y => y.id === deleteSvarModel.spoergsmaalId));
                    const spoergsmaal = sektion?.spoergsmaal.find(x => x.id === deleteSvarModel.spoergsmaalId);

                    if (!spoergsmaal || !sektion) return prev;

                    return {
                        ...prev,
                        sektioner: [...prev.sektioner.filter(x => x.id !== sektion?.id), {
                            ...sektion,
                            spoergsmaal: [...sektion.spoergsmaal.filter(x => x.id !== spoergsmaal?.id), {
                                ...spoergsmaal,
                                evalueringSvar: [...spoergsmaal.evalueringSvar.filter(x => x.id !== evalueringSvarId)]
                            }]
                        }]
                    } as KompetenceEvalueringModel;
                });
            })
            setAnswerUpdateLoading(false);
        }

    }, [evalueringApi, kompetenceEvaluering?.laegekompetenceEvalueringId, kompetenceEvaluering?.sektioner])


    const setIndsendtStatus = useCallback((indsendt: boolean) => {
        setIndsendt(indsendt);
    }, [])

    const questionHasAnswer = useCallback((question: KompetenceSpoergsmaalModel) => {
        return !!question.evalueringSvar?.length;
    }, [])

    const getSpoergsmaalIndex = useCallback((sektionIndexState: number) => {
        return kompetenceEvaluering?.sektioner?.slice(0, sektionIndexState + 1).flatMap(s => s.spoergsmaal)?.length ?? 0;
    }, [kompetenceEvaluering?.sektioner]);

    const kompetenceEvalueringHookValues = {
        kompetenceEvaluering: kompetenceEvaluering,
        indsendt,
        setIndsendtStatus,
        questionHasAnswer,
        totalQuestions: kompetenceEvaluering?.sektioner?.flatMap(s => s.spoergsmaal)?.length,
        evalueringSections: kompetenceEvaluering?.sektioner ?? [],
        getSpoergsmaalIndex,
        updateSvar,
        deleteSvar,
        answerUpdateLoading
    } as KompetenceEvalueringHookType

    return { isLoading, kompetenceEvalueringHookValues }
}
export default useKompetenceEvaluering;

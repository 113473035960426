export class FilterModel<T>{

    constructor(data : Array<T>, recordsFiltered : number, recordsTotal : number) {
        this.data = data;
        this.recordsTotal = recordsTotal;
        this.recordsFiltered = recordsFiltered;
    }

    public data : Array<T>;
    public recordsTotal : number;
    public recordsFiltered : number;
}

import {ForloebOverviewDateFilterBodyPostModel} from "core/components/forloebOverview/ForloebOverviewDateFilterModel";
import {ForloebOverviewFilterModel} from "../../../core/components/forloebOverview/ForloebOverviewFilterModel";
import {IsPrivateFilter, RegionRettighederFilter} from "../../../core/sharedmodels/filter/filterModel";

export class LogbogForloebFilterCrudModel {
    constructor(inputModel: ForloebOverviewFilterModel) {
        this.regioner = inputModel.regioner;
        this.regionRettigheder = RegionRettighederFilter(inputModel.regionRettigheder);
        this.specialer = inputModel.specialer;
        this.uddannelsetyper = inputModel.uddannelsetyper;
        this.hospitaler = inputModel.hospitaler;
        this.praksis = inputModel.praksis;
        this.omraader = inputModel.omraader;
        this.afdelinger = inputModel.afdelinger;
        this.titel = inputModel.titel;
        this.filterId = inputModel.filterId;
        this.isPrivate = IsPrivateFilter(inputModel.regionRettigheder);
        this.alleRegionerValgt = inputModel.alleRegionerValgt;
        this.forloebOverviewDateFilterModel = new ForloebOverviewDateFilterBodyPostModel(inputModel);
        this.kbuPeriod = inputModel.kbuPeriod;
        this.kbuPeriodYear = inputModel.kbuPeriodYear;
    }

    public regioner?: string[];
    public regionRettigheder?: string[];
    public specialer?: string[];
    public uddannelsetyper?: number[];
    public hospitaler?: string[];
    public praksis?: string[];
    public omraader?: string[];
    public afdelinger?: string[];
    public titel: string;
    public filterId?: string;
    public isPrivate?: boolean;
    public alleRegionerValgt?: boolean;
    public forloebOverviewDateFilterModel?: ForloebOverviewDateFilterBodyPostModel;
    public kbuPeriod?: string;
    public kbuPeriodYear?:string;
}


import dayjs from "dayjs";
import { defaultMonthColPixelWidth } from "../forloebOverviewContainer";

type LineVariables = {
    leftOffset: number;
    width: number;
};

type StyleVariables = {
    Line1: LineVariables,
    Line2: LineVariables
};

export default StyleVariables;

export const createVariables = (leftOffset: number, width: number) => ({ leftOffset, width });

export const asPixelString = (n: number) => `${n}px`;

export const calculateDayPixel = (day: dayjs.Dayjs, maxPixelsPerDiv: number) => {
    const dateInMonthProcentage = day.date() / day.daysInMonth() * 100;
    return Math.floor(dateInMonthProcentage * maxPixelsPerDiv / 100);
};

export const calculatePillColPaddings = (endDate: dayjs.Dayjs, startDate: dayjs.Dayjs) => {
    const dayPixels = calculateDayPixel(endDate, defaultMonthColPixelWidth);    
    return {
        left: asPixelString(calculateDayPixel(startDate, defaultMonthColPixelWidth)),
        right: asPixelString(Math.abs(dayPixels - defaultMonthColPixelWidth))
    }
}
import {StamdataMaalbeskrivelseResponseModel} from "./stamdataMaalbeskrivelseResponseModel";
import {UddannelseTypeEnum} from "../../../core/sharedmodels/stilling/uddannelseTypeEnum";
import {dateTimeStringFromApiToDate} from "../../../index";

export class StamdataMaalbeskrivelseModel {
    public id: string;
    public navn: string;
    public uddannelseType: UddannelseTypeEnum;
    public versionsdato: Date;
    constructor(responseModel : StamdataMaalbeskrivelseResponseModel) {
        this.id = responseModel.id;
        this.navn = responseModel.navn;
        this.uddannelseType = responseModel.uddannelseType;
        this.versionsdato = dateTimeStringFromApiToDate(responseModel.versionsdato);
    }
}

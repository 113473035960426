import {PaginationModel} from "./PaginationModel";

export class FilterParamsModel {
    constructor(paginationModel: PaginationModel, search?: string, advancedSearch?: boolean, showUnoccupiedStillinger = false) {
        this.paginationModel = paginationModel;
        this.search = search;
        this.advancedSearch = advancedSearch ?? false;
        this.showUnoccupiedStillinger = showUnoccupiedStillinger;
    }

    paginationModel: PaginationModel;
    search?: string;
    advancedSearch: boolean;
    showUnoccupiedStillinger: boolean;
}

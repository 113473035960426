import {ForSelectModel} from "../../../core/sharedmodels/forSelectModel";
import {StamdataMaalbeskrivelserForSelectModelResponseModel} from "./stamdataMaalbeskrivelserForSelectModelResponseModel";

export class StamdataMaalbeskrivelserForSelectModel extends ForSelectModel{
    public versionsdato: Date;

    constructor(responseModel : StamdataMaalbeskrivelserForSelectModelResponseModel) {
        super(responseModel.selectModel);
        this.versionsdato = responseModel.versionsdato;
    }
}

import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {AccountService} from "../../account/accountService";
import {ForloebRichResponseModel} from "./forloebRichResponseModel";
import {ForloebCreatePostModel} from "./forloebCreatePostModel";
import {ForloebEditPostModel} from "./forloebEditPostModel";
import {ForloebModel} from "../../../core/sharedmodels/forloeb/forloebModel";
import {CrudForloebInputModel} from "../../../core/componentsPage/forloeb/crudForloebInputModel";
import {GodkendPostModel} from "./godkendPostModel";
import {TidsbegraensetAdgangInputModel} from "../../../core/componentsPage/forloeb/tidsbegraensetAdgangInputModel";
import {ForloebAdgangCreatePostModel} from "./forloebAdgangCreatePostModel";
import {SeAlleKompetencerInputModel} from "../../../core/componentsPage/forloeb/SeAlleKompetencerInputModel";
import {SeAlleKompetencerPostModel} from "./seAlleKompetencerPostModel";
import {ObjectToQueryString} from "../objectToQueryString";
import {DeleteGodkendelseModel} from "./deleteGodkendelseModel";
import {addAttestationForTidTekstPatchModel} from "./addAttestationForTidTekstPatchModel";
import {ForloebSimpleInfoModel} from "../../../core/sharedmodels/forloeb/forloebSimpleInfoModel";
import {
    LaegeinstitutionWithStillingerModel
} from "../../../core/sharedmodels/forloeb/laegeinstitutionWithStillingerModel";
import {LaegeinstitutionWithStillingerResponseModel} from "./laegeinstitutionWithStillingerResponseModel";
import {AsyncResponseModel} from "../asyncResponseModel";
import {FilterModel} from "../../../core/sharedmodels/filterModel";
import {PromiseWithCancel} from "../../httpClient/promiseWithCancel";
import {ForloebOverviewFilterModel} from "../../../core/components/forloebOverview/ForloebOverviewFilterModel";
import {FilterParamsModel} from "../../../core/sharedmodels/filter/FilterParamsModel";
import {forloebOverviewFilterRequestModel} from "./forloebOverviewFilterRequestModel";
import DeleteForloeb from "core/componentsPage/forloeb/deleteForloeb";
import {
    ForloebSkalIndgaaIEvalueringsstatistikInputModel
} from "../../../core/componentsPage/forloeb/forloebSkalEvalueresInputModel";
import {SynkroniseredeForloebRequestModel} from "./synkroniseredeForloebRequestModel";
import {SynkroniseredeForloebResponseModel} from "./synkroniseredeForloebResponseModel";
import {ForloebSimpleInfoModelResponseModel} from "./forloebSimpleInfoModelResponseModel";
import {SynkroniseredeForloebModel} from "../../../core/sharedmodels/forloeb/synkroniseredeForloebModel";
import {TransferForloebDataRequestModel} from "./transferForloebDataRequestModel";
import {DeaktiveretForloebDataModel} from "../../../core/sharedmodels/forloeb/deaktiveretForloebDataModel";
import {DeaktiveretForloebDataResponseModel} from "./deaktiveretForloebDataResponseModel";
import UdeladForloebMedKommentarModel from "./udeladForloebMedKommentarModel";
import {TotalAndFilteredResponseModel} from "../totalAndFilteredResponseModel";

export class ForloebApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;

    private stamdataBaseUrl = () => config.stamdataApiUrl + "api/forloeb/";
    private forloebAdgangUrl = () => this.stamdataBaseUrl() + "forloebAdgang/";
    private markerHaandteretUrl = () => this.stamdataBaseUrl() + "markerHaandteret/";
    private setSkalIndgaaIEvalueringsstatistikStatusUrl = () => this.stamdataBaseUrl() + "setSkalIndgaaIEvalueringsstatistikStatus/";
    public getActiveForloebWhichEndsWithin30DaysUrl = () => this.stamdataBaseUrl() + "getActiveForloebWhichEndsWithin30Days/";

    private logbogBaseUrl = () => config.logbogApiUrl + "api/forloeb/";
    private forloebEditUrl = () => this.logbogBaseUrl() + "editForloeb/";
    private godkendForloebUrl = () => this.logbogBaseUrl() + "Godkend/";
    private afslutForloebUrl = () => this.logbogBaseUrl() + "Afslut/";
    private forloebByBrugerUrl = () => this.logbogBaseUrl() + "forloebByBrugerId/";
    private forloebSimpleInfoByBrugerIdUrl = () => this.logbogBaseUrl() + "forloebSimpleInfoByBrugerId/";
    private forloebByBrugerMaalbeskrivelseUrl = () => this.logbogBaseUrl() + "forloebByBrugerMaalbeskrivelseId/";
    private updateForloebLaegekurserUrl = () => this.logbogBaseUrl() + "updateForloebLaegekurser/";
    private fjernGodkendelseUrl = () => this.logbogBaseUrl() + "DeleteGodkendelse/";
    private setSeAlleKompetencerUrl = () => this.stamdataBaseUrl() + "setSeAlleKompetencer/";
    private addAttestationForTidUrl = () => this.stamdataBaseUrl() + "addAttestationForTidInforTekst";
    private deleteForloebEvalueringUrl = () => this.logbogBaseUrl() + "DeleteForloebEvaluering/";
    private getStillingOverviewUrl = () => this.logbogBaseUrl() + "StillingOverview/";
    private canDeleteForloebUrl = () => this.logbogBaseUrl() + "canDeleteForloeb/";
    private synkroniseredeForloebUrl = () => this.logbogBaseUrl() + "synkroniseredeForloeb/";
    private transferForloebDataUrl = () => this.logbogBaseUrl() + "transferForloebData/";
    private deaktiveretForloebDataUrl = () => this.logbogBaseUrl() + "deaktiveretForloebData/";
    public filteredAndTotalAsyncUrl = () => this.stamdataBaseUrl() + "stillingerFilteredAndTotal";
    private udeladForloebFraKlarmeldingerGodkendTidUrl = (forloebId: string) => this.logbogBaseUrl() + forloebId + "/udeladForloebFraKlarmeldingerGodkendTid";


    private laegeinstitutionerWithStilingerWithCancelOption: PromiseWithCancel<ApiResponse<AsyncResponseModel<LaegeinstitutionWithStillingerResponseModel>>>;
    private totalAndFilteredRequestWithCancelOption: PromiseWithCancel<ApiResponse<AsyncResponseModel<TotalAndFilteredResponseModel>>>;

    cancelPendingRequests(): void {
        if(this.laegeinstitutionerWithStilingerWithCancelOption) {
            this.laegeinstitutionerWithStilingerWithCancelOption.cancel();
        }
        if(this.totalAndFilteredRequestWithCancelOption) {
            this.totalAndFilteredRequestWithCancelOption.cancel();
        }
    }

    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    async getForloebById(id: string): Promise<ForloebModel> {
        const url = this.logbogBaseUrl() + id;
        const result = await this.httpClientService.Get<ApiResponse<ForloebRichResponseModel>>(url);
        return ForloebModel.FromResponseModel(result.data);
    }

    async canDeleteForloeb(deleteForloeb: DeleteForloeb): Promise<boolean> {
        const url = this.canDeleteForloebUrl() + ObjectToQueryString(deleteForloeb);
        const result = await this.httpClientService.Get<ApiResponse<boolean>>(url);
        return result.data;
    }

    async getForloebByBruger(brugerId: string): Promise<ForloebModel[]> {
        const url = this.forloebByBrugerUrl() + brugerId;
        const result = await this.httpClientService.Get<ApiResponse<ForloebRichResponseModel[]>>(url);
        return result.data.map(forloeb => {
            return ForloebModel.FromResponseModel(forloeb)
        });
    }

    async getAllForloebSimpleByBruger(brugerId: string): Promise<ForloebSimpleInfoModel[]> {
        const url = this.forloebSimpleInfoByBrugerIdUrl() + brugerId;
        const result = await this.httpClientService.Get<ApiResponse<ForloebSimpleInfoModelResponseModel[]>>(url);
        let forloeb = result.data.map(forloeb => {
            return ForloebSimpleInfoModel.FromResponseModel(forloeb)
        });

        forloeb.sort((a, b) => b.startDate.getTime() - a.startDate.getTime() || b.endDate.getTime() - a.endDate.getTime());
        return forloeb;
    }

    async getForloebByBrugerMaalbeskrivelse(brugerMaalbeskrivelseId: string): Promise<ForloebModel[]> {
        const url = this.forloebByBrugerMaalbeskrivelseUrl() + brugerMaalbeskrivelseId;
        const result = await this.httpClientService.Get<ApiResponse<ForloebRichResponseModel[]>>(url);
        let forloeb = result.data.map(forloeb => {
            return ForloebModel.FromResponseModel(forloeb)
        });
        forloeb.sort((a, b) => b.startDate.getTime() - a.startDate.getTime() || b.endDate.getTime() - a.endDate.getTime());
        return forloeb
    }

    async createForloeb(inputModel: CrudForloebInputModel): Promise<string> {
        const postModel = new ForloebCreatePostModel(inputModel);
        const result = await this.httpClientService
            .Post<ApiResponse<string>, ForloebCreatePostModel>(this.logbogBaseUrl(), postModel);

        return result.data;
    }

    async editForloeb(inputModel: CrudForloebInputModel): Promise<string> {
        const postModel = new ForloebEditPostModel(inputModel);
        const result = await this.httpClientService
            .Post<ApiResponse<string>, ForloebEditPostModel>(this.forloebEditUrl(), postModel);
        return result.data;
    }

    async setForloebEvalueringsstatistikStatus(inputModel: ForloebSkalIndgaaIEvalueringsstatistikInputModel): Promise<string> {
        const result = await this.httpClientService
            .Post<ApiResponse<string>, ForloebSkalIndgaaIEvalueringsstatistikInputModel>(this.setSkalIndgaaIEvalueringsstatistikStatusUrl(), inputModel);
        return result.data;
    }

    async DeleteForloeb(id: string, forloebUserId: string): Promise<string> {
        const url = `${this.logbogBaseUrl()}${id}/${forloebUserId}`;
        const result = await this.httpClientService.Delete<ApiResponse<string>>(url);

        return result.data;
    }

    async GodkendForloeb(forloebId: string, uddannelsessted: string, kommentar?: string): Promise<string> {
        const postModel = new GodkendPostModel(forloebId, this.userService.getUser().UserId, this.userService.getUser().CurrentRolleTitel, uddannelsessted, kommentar);
        const result = await this.httpClientService.Post<ApiResponse<string>, GodkendPostModel>(this.godkendForloebUrl(), postModel);

        return result.data;
    }

    async AfslutForloebManuelt(forloebId: string): Promise<string> {
        let url = this.afslutForloebUrl() + forloebId;
        const result = await this.httpClientService.Post<ApiResponse<string>, string>(url, forloebId);

        return result.data;
    }

    async createForloebAdgang(inputModel: TidsbegraensetAdgangInputModel): Promise<string> {
        const postModel = new ForloebAdgangCreatePostModel(inputModel);
        const result = await this.httpClientService
            .Post<ApiResponse<string>, ForloebAdgangCreatePostModel>(this.forloebAdgangUrl(), postModel);

        return result.data;
    }

    async setSeAlleKompetencer(inputModel: SeAlleKompetencerInputModel): Promise<string> {
        const postModel = new SeAlleKompetencerPostModel(inputModel);
        const result = await this.httpClientService
            .Post<ApiResponse<string>, SeAlleKompetencerPostModel>(this.setSeAlleKompetencerUrl(), postModel);

        return result.data;
    }

    async fjernGodkendelse(forloebId: string, godkendelseId: string): Promise<string> {
        const deleteModel = new DeleteGodkendelseModel(forloebId, godkendelseId);

        const url = this.fjernGodkendelseUrl() + ObjectToQueryString(deleteModel);
        const result = await this.httpClientService.Delete<ApiResponse<string>>(url);

        return result.data;
    }

    async addAttestationForTidTekst(patchModel: addAttestationForTidTekstPatchModel): Promise<string> {
        const url = this.addAttestationForTidUrl();
        const result = await this.httpClientService
            .Post<ApiResponse<string>, addAttestationForTidTekstPatchModel>(url, patchModel);

        return result.data;
    }

    async deleteEvalueringOnForloeb(forloebId: string): Promise<string> {
        let url = this.deleteForloebEvalueringUrl() + forloebId;
        const result = await this.httpClientService.Post<ApiResponse<string>, string>(url, forloebId);
        return result.data;
    }

    async getLaegeinstitutionerWithStillingerAsyncWithCancellation(filter: ForloebOverviewFilterModel, filterParamsModel: FilterParamsModel): Promise<FilterModel<LaegeinstitutionWithStillingerModel>> {
        const requestModel = new forloebOverviewFilterRequestModel(filter, filterParamsModel);

        if (this.laegeinstitutionerWithStilingerWithCancelOption)
            this.laegeinstitutionerWithStilingerWithCancelOption.cancel();

        this.laegeinstitutionerWithStilingerWithCancelOption = this.httpClientService.GetWithCancellation<ApiResponse<AsyncResponseModel<LaegeinstitutionWithStillingerResponseModel>>>(this.getStillingOverviewUrl() + ObjectToQueryString(requestModel));

        let laegeinstitutionerWithStilinger = await this.laegeinstitutionerWithStilingerWithCancelOption.then(x => x.data.data.map(laegeinstitutionerWithStilinger => {
            return LaegeinstitutionWithStillingerModel.FromResponseModel(laegeinstitutionerWithStilinger)
        }));

        return new FilterModel<LaegeinstitutionWithStillingerModel>(laegeinstitutionerWithStilinger, await this.laegeinstitutionerWithStilingerWithCancelOption.then(x => x.data.recordsFiltered), await this.laegeinstitutionerWithStilingerWithCancelOption.then(x => x.data.recordsTotal));
    }

    async getXofYLaegeinstitutionerWithStillinger(filter: ForloebOverviewFilterModel, filterParamsModel: FilterParamsModel): Promise<TotalAndFilteredResponseModel>{
        const requestModel = new forloebOverviewFilterRequestModel(filter, filterParamsModel);
        const url = this.filteredAndTotalAsyncUrl() + ObjectToQueryString(requestModel);
        if (this.totalAndFilteredRequestWithCancelOption)
            this.totalAndFilteredRequestWithCancelOption.cancel();

        this.totalAndFilteredRequestWithCancelOption = this.httpClientService.GetWithCancellation<ApiResponse<AsyncResponseModel<TotalAndFilteredResponseModel>>>(url);

        let totalAndFilteredModel = await this.totalAndFilteredRequestWithCancelOption;
        return totalAndFilteredModel.data;
    }

    async getSynkroniseredeForloeb(page: number, searchLaege?: string, specialer?: string[], regioner?: string[]): Promise<FilterModel<SynkroniseredeForloebModel>> {
        // Listen skal hentes ud fra de forløb i stamdata, som har "Kommer fra VU" sat til "Ja/true"
        // OG hvor der ligger andet/andre deaktiveret/deaktiverede forløb på brugeren, som har samme speciale og uddannelse
        // OG hvor der ligger data på et af disse (DETTE TJEKKES IKKE I STAMDATA - DA RELATIONSDATA LIGGER I UDDANNELSESLÆGE DB)
            // Data dækker over:
            // 1. Vejledersamtaler
            // 2. Uddannelsesplaner
            // 3. Kompetence planlagt i forløb
            // 4. Godkendelse på forløbet (Att. for tid) --> note: godkendelser har context id, som skal flippes (ikke nok at flippe godkendelse på forløb)
            // 5. TekstNotater

        const requestModel = new SynkroniseredeForloebRequestModel(page, searchLaege, specialer, regioner);
        const url = this.synkroniseredeForloebUrl();
        const result = await this.httpClientService.Post<ApiResponse<AsyncResponseModel<SynkroniseredeForloebResponseModel>>,SynkroniseredeForloebRequestModel>(url, requestModel);
        const synkroniseredeForloeb = result.data.data.map(x => {
            return SynkroniseredeForloebModel.FromResponseModel(x)
        }).sort((a, b) => a.uddannelseslaegeNavn.localeCompare(b.uddannelseslaegeNavn));
        return new FilterModel<SynkroniseredeForloebModel>(synkroniseredeForloeb, result.data.recordsFiltered, result.data.recordsTotal)

    }

    async transferForloebData(selectedDeaktiveretForloeb: string, forloebToUpdateId: string): Promise<string> {
        const requestModel = new TransferForloebDataRequestModel(selectedDeaktiveretForloeb, forloebToUpdateId);
        const url = this.transferForloebDataUrl() + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.Get<ApiResponse<string>>(url);
        return result.data;
    }

    async setDeaktiveredeForloebHaandteret(deaktiveredeForloebIds: string[]): Promise<string[]> {
        const url = this.markerHaandteretUrl();
        const result = await this.httpClientService.Post<ApiResponse<string[]>, string[]>(url, deaktiveredeForloebIds);
        return result.data;
    }

    async getDeaktiveretForloebData(selectedDeaktiveretForloebId: string): Promise<DeaktiveretForloebDataModel> {
        const url = this.deaktiveretForloebDataUrl() + selectedDeaktiveretForloebId;
        const result = await this.httpClientService.Get<ApiResponse<DeaktiveretForloebDataResponseModel>>(url);
        return DeaktiveretForloebDataModel.FromResponseModel(result.data);
    }

    async setUdeladForloebFraKlarmeldingerGodkendTid(forloebId: string, kommentar: string): Promise<string> {
        const url = this.udeladForloebFraKlarmeldingerGodkendTidUrl(forloebId);
        const postmodel: UdeladForloebMedKommentarModel = { kommentar };
        const result = await this.httpClientService.Post<ApiResponse<string>, UdeladForloebMedKommentarModel>(url, postmodel);
        return result.data;
    }
}

import {SpoergsmaalType} from "../../../core/sharedmodels/evaluering/spoergsmaalType";
import React from "react";
import {KompetenceSpoergsmaalModel} from "../../../core/sharedmodels/evaluering/kompetenceSpoergsmaalModel";
import {KompetenceSvarModel} from "../../../core/sharedmodels/evaluering/KompetencesvarModel";
import {NummerInputSvar} from "./nummerInputSvar";
import {TekstInputSvar} from "./tekstInputSvar";
import {DatoInputSvar} from "./datoInputSvar";
import {BooleanInputSvar} from "./booleanInputSvar";
import {DatoOgGodkendtInputSvar} from "./datoOgGodkendtInputSvar";

export type SvarInputTypeProps = {
    spoergsmaal: KompetenceSpoergsmaalModel;
    svarNummerIndex: number;
    sektionId: string;
    kompetenceSvarList: KompetenceSvarModel[];
    indsendt: boolean;
    readOnly: boolean;
    tekstCallback: (e: React.FocusEvent<HTMLTextAreaElement>, svarIndex: number) => void;
    nummerCallback: (nummer: number, svarIndex: number) => void;
    booleanCallback: (checked: boolean, svarIndex: number) => void;
    dateCallback: (svarIndex: number, dateSvar?: Date) => void;
    removeSvarCallback: (svarmulighedId: string, isChecked: boolean, svarIndex?: number) => void;
    godkendtCallback: (checked: boolean, svarIndex: number) => void;
    dateGodkendtCallback: (svarIndex: number, datoSvar: Date) => void;
}

export const SvarInputTypeCompareMap: Record<SpoergsmaalType, React.ComponentType<SvarInputTypeProps>> = {
    [SpoergsmaalType.Multi]: React.Component, //default tom component, da vi ikke må rendere for hver "antal svar" på spørgsmålet. Dette håndteres explicit udenfor for-loop i kompetenceEvalueringSvar.tsx
    [SpoergsmaalType.Dato]: DatoInputSvar,
    [SpoergsmaalType.Tekst]: TekstInputSvar,
    [SpoergsmaalType.Nummer]: NummerInputSvar,
    [SpoergsmaalType.Boolean]: BooleanInputSvar,
    [SpoergsmaalType.DatoOgGodkendt]: DatoOgGodkendtInputSvar,
};

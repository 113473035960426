import {KompetenceSpoergsmaalModel} from "../../../core/sharedmodels/evaluering/kompetenceSpoergsmaalModel";
import React from "react";
import {KompetenceSvarModel} from "../../../core/sharedmodels/evaluering/KompetencesvarModel";
import {DeleteEvalueringSvarModel} from "../../../services/api/logbogEvaluering/deleteEvalueringSvarModel";
import {KompetenceEvalueringSvar} from "../kompetenceEvalueringSvar";
import classNames from "classnames";
import {Localizer} from "../../../infrastructure/localization/localizer";

type KompetenceEvalueringSpoergsmaalProps = {
    spoergsmaal: KompetenceSpoergsmaalModel;
    updateSvarCallback: (svar: KompetenceSvarModel) => void;
    deleteSvarCallback: (svar: DeleteEvalueringSvarModel) => void;
    kompetenceEvalueringId: string;
    indsendt: boolean;
    sektionId: string;
    readOnly: boolean;
}
export function KompetenceEvalueringSpoergsmaal({spoergsmaal, indsendt, kompetenceEvalueringId, sektionId, updateSvarCallback, deleteSvarCallback, readOnly}: KompetenceEvalueringSpoergsmaalProps) {
    return (
        <div className={classNames("border padding-m datacard-border-radius", readOnly && "disabled" )}>
            <div className={classNames("flex-container-row flex-space align-items-center margin-bottom-s")}>
                <h5 className={"pre-line"}>{spoergsmaal?.titel}</h5>
                { spoergsmaal.isRequired &&
                    <span className="align-self-start badge badge-pill badge-info-light padding-xs margin-left-s">{Localizer.global_required()}</span>
                }
            </div>

            {spoergsmaal.tekst &&
                <p className={"pre-line margin-bottom-m"}>{spoergsmaal.tekst}</p>
            }
            <KompetenceEvalueringSvar kompetenceEvalueringId={kompetenceEvalueringId}
                                      spoergsmaal={spoergsmaal}
                                      updateSvarCallback={updateSvarCallback}
                                      readOnly={readOnly}
                                      deleteSvarCallback={deleteSvarCallback}
                                      indsendt={indsendt}
                                      sektionId={sektionId}
            />
        </div>
    );
}

import { RolleEnum } from "infrastructure/enums/userRole";
import {CrudNyhedInputModel} from "../../../core/components/nyheder/crudNyhedInputModel";

export class LogbogNyhederCrudPostModel {
    public id: string;
    public titel: string;
    public publiceringsdato: string;
    public markdown: string;
    public skalNotificere: boolean;
    public maalrettetTilRoller: RolleEnum[];

    public static buildNew (crudModel: CrudNyhedInputModel)
    {
        let model = new LogbogNyhederCrudPostModel();
        model.publiceringsdato = crudModel.publiceringsdato.dateToApiPostFormat();
        model.titel = crudModel.titel;
        model.markdown = crudModel.markdown;
        model.skalNotificere = crudModel.skalNotificere;
        model.maalrettetTilRoller = crudModel.maalrettetTilRoller;
        return model;
    }

    public static buildExisting (crudModel: CrudNyhedInputModel)
    {
        let model = new LogbogNyhederCrudPostModel();
        model.publiceringsdato = crudModel.publiceringsdato.dateToApiPostFormat();
        model.titel = crudModel.titel;
        model.markdown = crudModel.markdown;
        model.skalNotificere = crudModel.skalNotificere;
        model.maalrettetTilRoller = crudModel.maalrettetTilRoller;
        return model;
    }
}

import React from "react";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {ActionIcon} from "../actionIcon/actionIcon";
import "./dropdownButton.scss";
import classNames from "classnames";
import useScreenResolution from "core/hooks/useScreenResolution";

interface DropdownButtonProps {
    id: string;
    selectOptions: { key: string; value: string; }[];
    dropdownHeader: string;
    emptyDropdownHeader: string;
    objectClicked: (key: string) => void;
    showEditButton: boolean;
    selectedObjectName: string;
    editButtonClickedCallback?: (id: string) => void;
    seperatedSelectOptions?: { key: string; value: string; }[];
    dropdownSeperator?: string;
    buttonSelected?: boolean;
    highlightSelectedItem?: boolean;
}

export function DropdownButton(props: DropdownButtonProps) {
    
    const {isMobile} = useScreenResolution();

    function openEditModal(id: string) {
        if (props.editButtonClickedCallback)
            props.editButtonClickedCallback(id);
    }

    return (
        <>
            <button id={`dropdown-menu-with-header-${props.id}`} type="button"
                    className={`${props.buttonSelected ? "btn-primary" : " btn-default"} btn min-w-150 d-flex `}
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                    aria-label="Default dropdown menu with header">
                <div className="max-w-150 truncate text-align-left">
                    {props.selectedObjectName}
                </div>
                <div className="flex-align-r">
                    <EmblaIcon iconName="arrow-down"/>
                </div>
            </button>
            <ul className="dropdown-menu card-y-list-scroll btn-group-max-height" aria-labelledby="dropdown-menu-with-header">
                <li>
                    <h5 className="dropdown-header">{props.selectOptions.length > 0 ? props.dropdownHeader : props.emptyDropdownHeader}</h5>
                </li>
                {props.selectOptions.map((option) =>
                    <li role={"button"}  
                        className={classNames("dropdown-item d-flex margin-right-m", isMobile && "dropdown-item-mobile", !!props.highlightSelectedItem && option.value === props.selectedObjectName && "item-selected")}  
                        onClick={() => props.objectClicked(option.key)} 
                        key={option.key}
                        >
                        <div role={"button"} className={"w-100 d-flex dropdown-group-btn-item-min-height"}>
                            {option.value}
                        </div>
                        {props.showEditButton &&
                            <ActionIcon addtionalClassNames="flex-align-r"
                                        iconName="edit"
                                        showCircle={false}
                                        action={() => openEditModal(option.key)}/>
                        }
                    </li>
                )}
                {(props.dropdownSeperator && props.seperatedSelectOptions && props.seperatedSelectOptions.length > 0) &&
                <>
                    <li>
                        <h5 className="dropdown-header margin-top-m">{props.dropdownSeperator}</h5>
                    </li>
                    {props.seperatedSelectOptions.map((option) =>
                        <li role="button" className={classNames("dropdown-item d-flex margin-right-m", isMobile && "dropdown-item-mobile", !!props.highlightSelectedItem && option.value === props.selectedObjectName && "item-selected")} key={option.key}>
                            <div className={"w-100 dropdown-group-btn-item-min-height"} role={"button"}
                                 onClick={() => props.objectClicked(option.key)}>
                                {option.value}
                            </div>
                            {props.showEditButton &&
                            <ActionIcon addtionalClassNames="flex-align-r"
                                        iconName="edit"
                                        showCircle={false}
                                        action={() => openEditModal(option.key)}/>
                            }
                        </li>
                    )}
                </>
                }
            </ul>
        </>

    );
}

import config from "../../../config/config";
import {RolleEnum} from "../../../infrastructure/enums/userRole";
import { RoutePaths } from "../../../infrastructure/routes";
import { HttpClientService } from "../../httpClient/httpClientService";
import {RolleTitelEnum} from "../../../infrastructure/enums/rolleTitelEnum";

export class IdentityServerApi {
    constructor() {
        this.httpClientService = new HttpClientService();
    }

    private httpClientService: HttpClientService;

    private setCurrentUserRolleAndTitelUrl = (userId : string, rolle : RolleEnum, rolleTitel: RolleTitelEnum, returnUrl : string) =>
        config.idpUrl +
        `account/changecurrentrole?userid=${userId}&rolle=${rolle}&rolleTitel=${rolleTitel}&returnurl=${returnUrl}`;

    setCurrentRolleAndTitel(userId: string, rolle: RolleEnum, rolleTitel: RolleTitelEnum) {
        const url = this.setCurrentUserRolleAndTitelUrl(userId, rolle, rolleTitel, config.baseUrl + RoutePaths.ChangeRole.url);
        window.location.replace(url);
    }
}

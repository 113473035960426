import classNames from "classnames";
import { Localizer } from "infrastructure/localization/localizer";
import { useEffect, useRef, useState } from "react";

export type PrintButtonProps = {
  text?: string;
  show?: boolean;
  onBeforePrint?: () => void;
  onAfterPrint?: () => void;
  hideButtonOnPrint?: boolean;
  additionalClassNames?: string;
}

const timeInMillisecondsBeforePrint = 400;

const handlePrintScreen = (btnElement?: HTMLButtonElement | null, hideButton: boolean = true) => {

  if(hideButton)
    btnElement?.setAttribute("hidden", "hidden");

  window.print();

  if(hideButton)
    btnElement?.removeAttribute("hidden");
}

const PrintButton = ({
  text = Localizer.global_print(),
  show = true,
  hideButtonOnPrint = true,
  onBeforePrint,
  onAfterPrint,
  additionalClassNames
}: PrintButtonProps) => {
  const [printingReady, setPrintingReady] = useState(false);
  const printButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if(printingReady) {
      window.setTimeout(() => {
        handlePrintScreen(printButtonRef.current, hideButtonOnPrint)
        setPrintingReady(false);
      }, timeInMillisecondsBeforePrint);
    }
  }, [printingReady, hideButtonOnPrint]);

  const onButtonClicked = () => {
    // Setup "AfterPrint-handler" to trigger after print is done ("Print" | "Cancel")
    // Docs: https://developer.mozilla.org/en-US/docs/Web/API/Window/afterprint_event
    window.onafterprint = onAfterPrint ?? null;

    if(onBeforePrint) {
      // Synchronously call "BeforePrint-handler" (makes sure we trigger state-changes before starting printing)
      onBeforePrint();

      // Trigger that "printing is ready", so that we only start printing after state-changes has taken effect:
      setPrintingReady(true);
      return;
    }

    handlePrintScreen(printButtonRef.current, hideButtonOnPrint);
  }

  if(!show) return <></>;

  return (
    <button
      ref={printButtonRef}
      className={classNames("btn btn-default", additionalClassNames)}
      onClick={onButtonClicked}
    >
      <span>{text}</span>
    </button>
  )

};

export default PrintButton;

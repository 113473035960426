import { HttpClientService } from "../../httpClient/httpClientService";
import { ApiResponse } from "../apiResponse";
import config from "../../../config/config";
import {ObjectToQueryString} from "../objectToQueryString";
import {NotaterContainerModel} from "../../../core/sharedmodels/notat/notaterContainerModel";
import {NotaterContainerResponseModel} from "./notaterContainerResponseModel";
import {LogbogNotatResponseModel} from "./logbogNotatResponseModel";
import {BasicNotatModel, FromResponseModel} from "../../../core/sharedmodels/notat/basicNotatModel";
import {NotatType} from "../../../core/sharedmodels/notat/notatType";
import {LogbogNotatCrudPostModel} from "./logbogNotatCrudPostModel";
import {LogbogNotatGetModel} from "./LogbogNotatGetModel";
import {NotatCrudModel} from "../../../core/sharedmodels/notat/notatCrudModel";
import {NotatFilterModel} from "../../../core/componentsPage/notat/notatFilterModel";
import {NotatFilterRequestModel} from "./notatFilterRequestModel";
import {LogbogNotatByLaegekompetenceIdsGetModel} from "./LogbogNotatByLaegekompetenceIdsGetModel";
import {StempelInputModel} from "../stempel/stempelInputModel";
import {AccountService} from "../../account/accountService";
import {LogbogAnsoegningsnotaterResponseModel} from "./logbogAnsoegningsnotaterResponseModel";
import {
    AnsoegningsnotatFromResponseModel,
    AnsoegningsnotatModel
} from "../../../core/sharedmodels/notat/ansoegningsnotatModel";
import { UddannelseTypeEnum } from "core/sharedmodels/stilling/uddannelseTypeEnum";
import NotatSimpleResponseModel from "core/sharedmodels/notat/notatSimpleResponseModel";
import ForloebNotesContainer from "core/sharedmodels/forloeb/forloebNotesContainer";

export class LogbogNotatApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;
    private notatBaseUrl = () => config.logbogApiUrl + "api/notat/";

    private getAll = () => this.notatBaseUrl() + "GetAll"
    private getAllOnContext = () => this.notatBaseUrl() + "GetAllOnContext"
    private getAllOnForloeb = () => this.notatBaseUrl() + "GetAllOnForloeb/ForloebId/"
    private getAllOnForloebForPdf = () => this.notatBaseUrl() + "all/pdf/"
    private getAllOnBruger = () => this.notatBaseUrl() + "GetAllOnBruger/brugerId/"
    private getAllOnBrugerByLaegekursusGruppe = (brugerid : string, laegekursusGruppeId : string) => this.notatBaseUrl() + "GetAllOnBrugerByLaegekursusgruppe/brugerId/" + brugerid + "/laegekursusGruppeId/" + laegekursusGruppeId;
    private getAnsoegningsnotaterOnUddannelseUrl = () => this.notatBaseUrl() + "GetAnsoegningNotaterOnUddannelse";
    private getLogbogLegacyNotaterUrl = () => this.notatBaseUrl() + "GetLogbogLegacyNotater";
    private getMeritNotaterByBrugerMaalbeskrivelseIdUrl = () => this.notatBaseUrl() + "GetMeritNotaterByBrugerMaalbeskrivelse";
    private getKursusGruppeNotaterByBrugerKursusraekkeIdUrl = () => this.notatBaseUrl() + "GetKursusGruppeNotaterByBrugerKursusraekke";

    private postNotatUrl = () => this.notatBaseUrl() + "postNotat"
    private editNotatDataUrl = () => this.notatBaseUrl() + "editNotatData"
    private editNotatFilesUrl = () => this.notatBaseUrl() + "editNotatFiles"
    private deleteNotatUrl = () => this.notatBaseUrl() + "deleteNotat"


    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    async getAllAccessibleNotater(filter?: NotatFilterModel): Promise<NotaterContainerModel> {
        const notatFilterRequestModel = new NotatFilterRequestModel(filter ?? new NotatFilterModel());
        const url = this.getAll() + ObjectToQueryString(notatFilterRequestModel);
        const result = await this.httpClientService.Get<ApiResponse<NotaterContainerResponseModel>>(url);
        return NotaterContainerModel.FromResponseModel(result.data.alleNotaterResponseModel, result.data.totalRecords);
    }

    async getNotaterByForloebId(forloebId: string, filter?: NotatFilterModel): Promise<NotaterContainerModel> {
        const notatFilterRequestModel = new NotatFilterRequestModel(filter ?? new NotatFilterModel());
        const url = this.getAllOnForloeb() + forloebId + ObjectToQueryString(notatFilterRequestModel);
        const result = await this.httpClientService.Get<ApiResponse<NotaterContainerResponseModel>>(url);
        return NotaterContainerModel.FromResponseModel(result.data.alleNotaterResponseModel, result.data.totalRecords);
    }

    async getNotesForPdf(forloebId: string): Promise<ForloebNotesContainer[]> {
        const url = this.getAllOnForloebForPdf() + forloebId;
        const result = await this.httpClientService.Get<ApiResponse<ForloebNotesContainer[]>>(url);
        return result.data;
    }

    async getNotaterByBrugerId(notatType: NotatType, brugerId: string) : Promise<Array<BasicNotatModel>> {
        const url = this.getAllOnBruger() + brugerId + ObjectToQueryString(new LogbogNotatByLaegekompetenceIdsGetModel(notatType, brugerId));
        const result = await this.httpClientService.Get<ApiResponse<Array<LogbogNotatResponseModel>>>(url);

        if (result.data){
            return result.data.map(x => FromResponseModel(x));
        }
        else {
            return [];
        }
    }

    async getAnsoegningsnotaterOnUddannelse(brugerId: string, brugerMaalbeskrivelseId: string, ansoegningId: string) : Promise<Array<AnsoegningsnotatModel>> {
        const queryString = ObjectToQueryString({brugerId, brugerMaalbeskrivelseId, ansoegningId})
        const url = this.getAnsoegningsnotaterOnUddannelseUrl() + queryString;
        const result = await this.httpClientService.Get<ApiResponse<Array<LogbogAnsoegningsnotaterResponseModel>>>(url);

        if (result.data){
            return result.data.map(x => AnsoegningsnotatFromResponseModel(x));
        }
        else {
            return [];
        }
    }

    async getNotaterOnKursusGruppe(brugerId: string, kursusGruppeId : string) : Promise<Array<BasicNotatModel>> {
        const url = this.getAllOnBrugerByLaegekursusGruppe(brugerId, kursusGruppeId);
        const result = await this.httpClientService.Get<ApiResponse<Array<LogbogNotatResponseModel>>>(url);

        if (result.data){
            return result.data.map(x => FromResponseModel(x));
        }
        else {
            return [];
        }
    }

    async getNotaterOnContext(notatType : NotatType, contextUserId : string, contextId : string): Promise<Array<BasicNotatModel>> {
        const url = this.getAllOnContext() + ObjectToQueryString(new LogbogNotatGetModel(notatType, contextUserId, contextId));
        const result = await this.httpClientService.Get<ApiResponse<Array<LogbogNotatResponseModel>>>(url);

        if (result.data){
            return result.data.map(x => FromResponseModel(x));
        }
        else {
            return [];
        }
    }

    async getNotatOnContext(notatType : NotatType, contextUserId : string, contextId : string): Promise<BasicNotatModel | null> {
        const url = this.getAllOnContext() + ObjectToQueryString(new LogbogNotatGetModel(notatType, contextUserId, contextId));
        const result = await this.httpClientService.Get<ApiResponse<Array<LogbogNotatResponseModel>>>(url);

        if (result.data){
            return FromResponseModel(result.data[0]);
        }
        else {
            return null;
        }
    }

    async createNotat(inputModel: NotatCrudModel, fileMetadaIds?: string[]): Promise<string> {
        const userInfo = this.userService.getUser()
        const inputStempel = new StempelInputModel(userInfo.UserId, userInfo.Name, userInfo.AuthorizationId, userInfo.CurrentRolleTitel, userInfo.Initialer);
        const notatPostModel = LogbogNotatCrudPostModel.Build(inputModel, fileMetadaIds, inputStempel);
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogNotatCrudPostModel>(this.postNotatUrl(), notatPostModel);
        return result.data;
    }

    async editNotatData(NotatModel: NotatCrudModel): Promise<string> {
        const notatPostModel = LogbogNotatCrudPostModel.Build(NotatModel);
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogNotatCrudPostModel>(this.editNotatDataUrl(), notatPostModel);
        return result.data;
    }

    async editNotatFiles(NotatModel: NotatCrudModel, fileMetadaIds?: string[]): Promise<string> {
        const notatPostModel = LogbogNotatCrudPostModel.Build(NotatModel, fileMetadaIds);
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogNotatCrudPostModel>(this.editNotatFilesUrl(), notatPostModel);
        return result.data;
    }

    async deleteNotat(NotatModel: NotatCrudModel, fileMetadaIds?: string[]): Promise<string> {
        const notatPostModel = LogbogNotatCrudPostModel.Build(NotatModel, fileMetadaIds);
            const result = await this.httpClientService.Post<ApiResponse<string>, LogbogNotatCrudPostModel>(this.deleteNotatUrl(), notatPostModel);
        return result.data;
    }

    async getLogbogLegacyNotater(brugerId: string, uddannelseType: UddannelseTypeEnum) : Promise<Array<BasicNotatModel>> {
        const queryParams = {
            brugerId: brugerId,
            uddannelseType: uddannelseType
        }
        const url = this.getLogbogLegacyNotaterUrl() + ObjectToQueryString(queryParams);
        const result = await this.httpClientService.Get<ApiResponse<Array<LogbogNotatResponseModel>>>(url);

        if (result.data){
            return result.data.map(x => FromResponseModel(x));
        }
        else {
            return [];
        }
    }

    async getMeritNotaterByBrugerMaalbeskrivelse(brugerId: string, brugerMaalbeskrivelseId: string) : Promise<Array<BasicNotatModel>> {
        const queryParams = {
            brugerId: brugerId,
            brugerMaalbeskrivelseId: brugerMaalbeskrivelseId
        }
        const url = this.getMeritNotaterByBrugerMaalbeskrivelseIdUrl() + ObjectToQueryString(queryParams);
        const result = await this.httpClientService.Get<ApiResponse<Array<LogbogNotatResponseModel>>>(url);

        if (result.data){
            return result.data.map(x => FromResponseModel(x));
        }
        else {
            return [];
        }
    }

    async getKursusGruppeNotaterByBrugerKursusraekke(brugerId: string, brugerKursusraekkeId: string) : Promise<Array<BasicNotatModel>> {
        const queryParams = {
            brugerId: brugerId,
            brugerKursusraekkeId: brugerKursusraekkeId
        }
        const url = this.getKursusGruppeNotaterByBrugerKursusraekkeIdUrl() + ObjectToQueryString(queryParams);
        const result = await this.httpClientService.Get<ApiResponse<Array<LogbogNotatResponseModel>>>(url);

        if (result.data){
            return result.data.map(x => FromResponseModel(x));
        }
        else {
            return [];
        }
    }
}

import {LaegekursusGruppeTypeEnum} from "../../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";
import {LaegekursusGruppeSimpelResponseModel} from "./laegekursusGruppeSimpelResponseModel";

export class LaegekursusGruppeSimpelModel {
    public id: string;
    public brugerId: string;
    public kursusGruppeType: LaegekursusGruppeTypeEnum;
    public brugerKursusraekkeId: string;

    constructor(responseModel: LaegekursusGruppeSimpelResponseModel) {
        this.id = responseModel.id;
        this.brugerId = responseModel.brugerId;
        this.kursusGruppeType = responseModel.kursusGruppeType;
        this.brugerKursusraekkeId = responseModel.brugerKursusraekkeId;
    }
}

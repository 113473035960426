import CookieConsent, { POSITION_OPTIONS } from "react-cookie-consent"
import "./cookieConsentBanner.scss";
import { Link } from "react-router-dom";
import { RoutePaths } from "infrastructure/routes";
import { Localizer } from "infrastructure/localization/localizer";
import { useCookieConsentContext } from "./context/CookieConsentContextProvider";
import useCookieConsentConfig from "./hooks/useCookieConsentConfig";
import classNames from "classnames";
import useScreenResolution from "core/hooks/useScreenResolution";

const CookieConsentBanner = () => {
  const { isMobile } = useScreenResolution();
  const { setCookieConsent } = useCookieConsentContext();
  const { cookieName, cookieOptions } = useCookieConsentConfig();

  return (
    <CookieConsent
      cookieName={cookieName}
      sameSite={cookieOptions.sameSite}
      expires={cookieOptions.expires}
      location={POSITION_OPTIONS.BOTTOM}
      containerClasses={classNames("cookie-consent-banner-container", !isMobile && "padding-left-xl padding-right-xl")} 
      contentClasses="cookie-consent-banner-content"
      buttonWrapperClasses="cookie-consent-banner-buttons-wrapper"
      disableButtonStyles
      buttonClasses={classNames("accept-button", "btn btn-default bg-white")}
      declineButtonClasses={classNames("decline-button", "btn btn-default bg-white mr-4")}
      enableDeclineButton
      buttonText={Localizer.CookieConsentApprove()}
      declineButtonText={Localizer.CookieConsentDecline()}
      onAccept={() => setCookieConsent(true)}
      onDecline={() => setCookieConsent(false)}
    >
      <h2 className="content-title">Cookies</h2>
      <div className="content-section">
        {Localizer.CookieConsentInfoWeUseCookiesFor()}
      </div>
      <div className="content-section">
        <div>
          {Localizer.CookieConsentInfoDeclineMeans()}
        </div>
        <div>
          {Localizer.CookieConsentInfoApproveMeans()}
        </div>
      </div>
      <div className="content-section">
        {Localizer.CookieConsentTilbagekaldInfo()}
      </div>
      <div className="content-link-container">
        <div className="mr-2">
          {">"}
        </div>
        <Link to={RoutePaths.Cookies.path} className="content-link">
          {Localizer.CookieConsentLinkToCookiesPage()}
        </Link>
      </div>
    </CookieConsent>
  )
}

export default CookieConsentBanner;
import {useCallback, useEffect, useState} from "react";
import {KompetenceEvalueringsskemaModel} from "../../../core/sharedmodels/evaluering/kompetenceEvalueringsskemaModel";
import useLogbogApi from "../../../core/hooks/useLogbogApi";

const useKompetenceEvalueringsskemaHook = (kompetenceId: string, laegekompetenceId: string) => {
    const [kompetenceEvalueringsskemaerState, setKompetenceEvalueringsskemaer] = useState<KompetenceEvalueringsskemaModel[]>();
    const {evalueringApi} = useLogbogApi()

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setIsLoading(true);
        const fetchKompetenceEvalueringsskemaer = async (): Promise<KompetenceEvalueringsskemaModel[]> => {
            return await evalueringApi.getKompetenceEvalueringsskemaer(kompetenceId, laegekompetenceId);
        }
        fetchKompetenceEvalueringsskemaer().then(kompetenceEvalueringsskemaer => {
            kompetenceEvalueringsskemaer.sortBy(kes => kes.titel).forEach(kes => kes.kompetenceEvalueringer.sortBy(ke => ke.indsendt));
            setKompetenceEvalueringsskemaer(kompetenceEvalueringsskemaer);
            setIsLoading(false);
        });
    }, [evalueringApi, kompetenceId, laegekompetenceId])

    const deleteKompetenceEvaluering = useCallback(async (kompetenceEvalueringId: string) => {
        await evalueringApi.deleteKompetenceEvaluering(kompetenceEvalueringId).then(dke => {
            const deletedKompetenceEvalueringId = dke;
            setKompetenceEvalueringsskemaer((prev) => {
                if (!prev) return prev;
                return prev.map(kes => {
                    kes.kompetenceEvalueringer = kes.kompetenceEvalueringer.filter(ke => ke.laegekompetenceEvalueringId !== deletedKompetenceEvalueringId);
                    return kes;
                });
            });
        });
    }, [evalueringApi])

    return {kompetenceEvalueringsskemaerState, isLoading, deleteKompetenceEvaluering}
}
export default useKompetenceEvalueringsskemaHook;

import React  from 'react';
import { Link } from 'react-router-dom';
import { EmblaIcon } from "../emblaIcon/emblaIcon";
import { EmblaIconsType } from 'core/emblaIcons';

interface LinkIconProps {
    iconName: EmblaIconsType
    routeUrl?: string
    action?: () => void
    addtionalClassNames?: string
    showCircle?: boolean //SEND NOTHING IF YOU WANT TO SHOW THE CIRCLE!
}

export function ActionIcon(props: LinkIconProps) {
    const content = (
        <div role="button" className={`${props.showCircle ?? "round"} ${props.addtionalClassNames ?? ""}`} onClick={props.action}>
            <EmblaIcon iconName={props.iconName}/>
        </div>
    )

    return (
        <>
            {props.routeUrl &&
                <Link to={props.routeUrl} onClick={props.action}>
                    {content}
                </Link>
            }

            {!props.routeUrl &&
                content
            }
        </>
    );
}

import { PropsWithChildren } from "react";
import LinkTargets, { SecureLinkReferrerPolicy } from "./linkAttributs"

type ExternalLinkProps = {
    text?: string;
    link: string;
    classes?: string;
}

const ExternalLink = ({text, link, classes="", children}: PropsWithChildren<ExternalLinkProps>) => {
    return (
        <a
            className={classes}
            href={link}
            target={LinkTargets.Blank}
            rel={SecureLinkReferrerPolicy}
        >
            {text &&
                text
            }

            { children }
        </a>
    )
}

export default ExternalLink;
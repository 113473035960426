import { Localizer } from "infrastructure/localization/localizer";

enum BrevskabelonVisibilityTypeEnum {
    Region = 1,
    Global = 2,
    Private = 3
}

const toBrevskabelonVisibilityTypeEnum = (val: number) => {
    switch (val) {
        case BrevskabelonVisibilityTypeEnum.Global:
            return BrevskabelonVisibilityTypeEnum.Global;

        case BrevskabelonVisibilityTypeEnum.Private:
            return BrevskabelonVisibilityTypeEnum.Private;

        case BrevskabelonVisibilityTypeEnum.Region:
            return BrevskabelonVisibilityTypeEnum.Region;

        default:
            return undefined;
    }
}

const getBrevskabelonVisibilityTypeTranslation = (type?: BrevskabelonVisibilityTypeEnum) => {
    switch (type) {
        case BrevskabelonVisibilityTypeEnum.Global:
            return Localizer.global_wholeDenmark();
        case BrevskabelonVisibilityTypeEnum.Private:
            return Localizer.global_mine();
        case BrevskabelonVisibilityTypeEnum.Region:
            return Localizer.global_region();
        default:
            return "";
    }
}

export default BrevskabelonVisibilityTypeEnum;

export {
    getBrevskabelonVisibilityTypeTranslation,
    toBrevskabelonVisibilityTypeEnum
}

var mime = require('mime')

export const STPSDokumentationUploadAllowedFileTypes = [
    // Documents
   ".pdf",
    // Text
    ".txt",
    // Images
    ".bmp", ".gif", ".ico", ".jpeg", ".jpg", ".png", ".psd", ".tiff",
]


// Note: This list is also maintained in the backend. Any changes should be implemented in both lists
export const AllowedFileTypes = [
    // Documents
    ".doc", ".docx", ".pdf", ".xls", ".xlsx",

    // Text
    ".txt",

    // Images
    ".bmp", ".gif", ".ico", ".jpeg", ".jpg", ".png", ".psd", ".tiff",
]

export const GetAllowedFileTypes = (allowedTypes: string[]): string => {

    const allowedFileTypes = allowedTypes.map(ft => `${ft},${mime.getType(ft)}`);

    return allowedFileTypes.join(",");
}

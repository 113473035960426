import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import { FileMetadata } from "core/sharedmodels/fileMetaData/fileMetaData";
import FileCountRequestModel from "./fileCountRequestModel";
import IdAndCount from "core/sharedmodels/IdAndCount";
import FileMetaRequestModel from "./fileMetaRequestModel";

export class StamdataKompetenceApi {

    private baseUrl = config.stamdataApiUrl + "api/kompetence/";
    private specialespecifikkeFilerByKompetence = this.baseUrl + "SpecialeSpecifikkeFiler/";
    private specialespecifikkeFilerCount = this.baseUrl + "SpecialeSpecifikkeFiler/count/";

    constructor(private httpClientService: HttpClientService) {
        this.httpClientService = httpClientService;
    }

    async getSpecialeSpecificFiles(requestModel: FileMetaRequestModel): Promise<FileMetadata[]> {
        const url = this.specialespecifikkeFilerByKompetence;
        const result = await this.httpClientService.Post<ApiResponse<FileMetadata[]>, FileMetaRequestModel>(url, requestModel);
        return result.data;
    }

    async getSpecialeSpecificFilesCount(requestModel: FileCountRequestModel): Promise<IdAndCount[]> {
        const url = this.specialespecifikkeFilerCount;
        const result = await this.httpClientService.Post<ApiResponse<IdAndCount[]>, FileCountRequestModel>(url, requestModel);
        return result.data;
    }
}

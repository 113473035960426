import {ApiQueryParams} from "../apiQueryParams";
import {NotifikationEventTypeEnum} from "../../../core/components/notifications/models/notificationModel";

export class NotifikationHistorikRequestModel extends ApiQueryParams
{
    constructor(page: number, search?: string, pagelength?: number, typeEnums?: NotifikationEventTypeEnum[]) {
        super(page, pagelength ?? 10, search);
        this.typeEnums = typeEnums;
    }
    public typeEnums?: NotifikationEventTypeEnum[]
}

import {GodkendelseResponseModel} from "../../../services/api/godkendelse/godkendelseResponseModel";
import {dateTimeStringFromApiToDate} from "../../../index";

export class GodkendelseInfoModel {
    constructor(godkendelseResponseModel: GodkendelseResponseModel) {
        this.godkendtAfNavn = godkendelseResponseModel.navn;
        this.rolle = godkendelseResponseModel.rolle;
        this.rolleTitel = godkendelseResponseModel.rolleTitel;
        this.kommentar = godkendelseResponseModel.kommentar;
        this.godkendtDen = dateTimeStringFromApiToDate(godkendelseResponseModel.godkendelseDato);
        this.authorisationsId = godkendelseResponseModel.authorizationId;
    }

    godkendtAfNavn: string;
    rolle: string;
    rolleTitel: string;
    godkendtDen: Date;
    authorisationsId?: string;
    kommentar?: string;
}

import {AccountService} from "services/account/accountService";
import {useUserContext} from "../components/user/userContextProvider";

const accountService = new AccountService();

const useUser = () => {
    const {currentUser, setUser } = useUserContext();

    return {
        currentUser: currentUser.user,
        updateCurrentUserInitials: (Initialer: string) => {
            const updatedModel = accountService.updateUser({Initialer: Initialer});
            setUser(updatedModel);
        },
        updateCurrentUserProfileImage: (profileImageId?: string) => {
            const updatedModel = accountService.updateUser({ProfileImageId: profileImageId});
            setUser(updatedModel);
        },
    }
};

export default useUser;


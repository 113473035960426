import { toInt } from "index";
import {CrudBeskaeftigelseInputModel} from "../../../pages/sendStpsAnsoegning/beskaeftigelse/crudBeskaeftigelseInputModel";

export class LogbogBeskaeftigelseCrudModel {
    constructor(inputModel: CrudBeskaeftigelseInputModel) {
        this.id = inputModel.id;
        this.stillingsbetegnelse = inputModel.stillingsbetegnelse;
        this.stillingsnummer = inputModel.stillingsnummer;
        this.ansaettelsessted = inputModel.ansaettelsessted;
        this.afdeling = inputModel.afdeling;
        this.ansoegningId = inputModel.ansoegningId;
        this.startdato = inputModel.startdato !== undefined
            ? inputModel.startdato.dateAndTimeToApiPostFormat()
            : undefined;
        this.slutdato = inputModel.slutdato !== undefined
            ? inputModel.slutdato.dateAndTimeToApiPostFormat()
            : undefined;
        this.maaneder = inputModel.maaneder;
        this.nedsatTidAntalTimer = toInt(inputModel.nedsatTidAntalTimer);
    }

    public id: string;
    public stillingsbetegnelse: string;
    public stillingsnummer: string;
    public ansoegningId: string;
    public ansaettelsessted: string;
    public afdeling: string;
    public startdato?: string;
    public slutdato?: string;
    public maaneder: string;
    public nedsatTidAntalTimer?: number;

}


import {LogbogStillingWithUsersResponseModel} from "../../../services/api/logbogStilling/logbogStillingWithUsersResponseModel";
import {ForloebWithUserInfoModel} from "../forloeb/forloebWithUserInfoModel";

export class StillingDetailModel {

    public static FromResponseModel(responseModel: LogbogStillingWithUsersResponseModel): StillingDetailModel {
        const model = new StillingDetailModel();
        model.id = responseModel.id;
        model.ForloebWithUserInfoModels = responseModel.forloebWithUserInfoResponseModels.map(f => {
            return ForloebWithUserInfoModel.FromResponseModel(f);
        });
        return model;
    }

    id: string;
    ForloebWithUserInfoModels : Array<ForloebWithUserInfoModel> = [];
}

import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {AccountService} from "../../account/accountService";
import {LogbogUddannelsesplanCrudModel} from "./logbogUddannelsesplanCrudModel";
import {UddannelsesplanModel} from "../../../pages/vejledning/uddannelsesplan/uddannelsesplanModel";
import {LogbogUddannelsesplanResponseModel} from "./logbogUddannelsesplanResponseModel";
import {CrudUddannelsesplanInputModel} from "../../../pages/vejledning/uddannelsesplan/components/crudUddannelsesplanInputModel";
import {GodkendPostModel} from "../forloeb/godkendPostModel";

export class LogbogUddannelsesplanApi {
    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = () => config.logbogApiUrl + "api/Uddannelsesplan/";
    private udannelsesplanGodkend = () => this.baseUrl() + "godkendt";
    private uddannelsesplanOnForloeb = () => this.baseUrl() + "byForloeb/";
    private uddannelsesplanDeleteGodkendelse = () => this.baseUrl() + "deleteGodkendelse/";
    private uddannelsesplanEditUrl = () => this.baseUrl() + "editUddannelsesplan/";
    private toggleKlarmeldtAtUrl = () => this.baseUrl() + "toggleKlarmeldtAt/";
    public getKlarmeldteUddannelsesplanerUrl = () => this.baseUrl() + "getKlarmeldteUddannelsesplaner/";

    async createUddannelsesplan(inputModel: CrudUddannelsesplanInputModel): Promise<string> {
        const postModel = new LogbogUddannelsesplanCrudModel(inputModel);
        const url = this.baseUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogUddannelsesplanCrudModel>(url, postModel);
        return result.data;
    }

    async getUddannelsesplanerByForloeb(forloebId: string): Promise<UddannelsesplanModel[]> {
        const url = this.uddannelsesplanOnForloeb() + forloebId;
        const result = await this.httpClientService.Get<ApiResponse<LogbogUddannelsesplanResponseModel[]>>(url);
        return result.data.map(uddannelsesplan => {
            return UddannelsesplanModel.FromResponseModel(uddannelsesplan)
        });
    }

    async editUddannelsesplan(inputModel: CrudUddannelsesplanInputModel): Promise<string> {
        const patchModel = new LogbogUddannelsesplanCrudModel(inputModel);
        const url = this.uddannelsesplanEditUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogUddannelsesplanCrudModel>(url, patchModel);
        return result.data;
    }

    async deleteUddannelsesplan(id: string): Promise<string> {
        const url = this.baseUrl() + id;
        const result = await this.httpClientService.Delete<ApiResponse<string>>(url);
        return result.data;
    }

    async godkendUddannelsesplan(id: string, uddannelsessted: string, kommentar?: string): Promise<string> {
        const body = new GodkendPostModel(id, this.userService.getUser().UserId, this.userService.getUser().CurrentRolleTitel, uddannelsessted, kommentar);
        const url = this.udannelsesplanGodkend();
        const result = await this.httpClientService.Post<ApiResponse<string>, any>(url, body);
        return result.data;
    }

    async fjernGodkendelseUddannelsesplan(id: string): Promise<string> {
        const url = this.uddannelsesplanDeleteGodkendelse() + id;
        const result = await this.httpClientService.Delete<ApiResponse<string>>(url);
        return result.data;
    }

    async toggleKlarmeldtAt(id: string): Promise<string> {
        const url = this.toggleKlarmeldtAtUrl() + id;
        const result = await this.httpClientService.Post<ApiResponse<string>, any>(url, []);
        return result.data;
    }
}

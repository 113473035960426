import {EvalueringType} from "../../../core/sharedmodels/evaluering/evalueringType";

export class KompetenceEvalueringCreateRequestModel {
    constructor(kompetenceEvalueringsskemaId: string, evalueringstype: EvalueringType, brugerMaalbeskrivelseId: string, isPrivate: boolean) {
        this.kompetenceEvalueringsskemaId = kompetenceEvalueringsskemaId;
        this.evalueringTypeEnum = evalueringstype;
        this.brugerMaalbeskrivelseId = brugerMaalbeskrivelseId;
        this.isPrivate = isPrivate;
    }

    kompetenceEvalueringsskemaId: string;
    brugerMaalbeskrivelseId: string;
    isPrivate: boolean;
    evalueringTypeEnum: EvalueringType;
}

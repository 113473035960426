import React, {useRef, useState} from 'react'
import {Localizer} from "../../../infrastructure/localization/localizer";
import {EmblaIcon} from "../../../core/components/emblaIcon/emblaIcon";
import {ReactComponent as GreenCircle} from "../../../content/icons/greenCircle.svg";
import {ReactComponent as BlueCircle} from "../../../content/icons/blueCircle.svg";
import {ReactComponent as YellowCircle} from "../../../content/icons/yellowCircle.svg";
import {LaegekursusModel} from "../../../services/api/laegekursus/laegekursusModel";
import {LaegekursusStatusEnum} from "../../../core/sharedmodels/kursus/laegekursusStatusEnum";
import {SlideIn} from "../../../core/components/slideIn/slideIn";
import {EditLaegekursus} from "./editLaegekursus";
import {ModalAcceptType, ModalSubmitMessage} from "../../../core/components/modalMessage/modalSubmitMessage";
import {setSlideinState, SlideInStateEnum} from "../../../core/components/slideIn/slideInSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {AccountService} from "../../../services/account/accountService";
import {LaegekursusGruppeTypeEnum} from "../../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";
import {NotificationModule} from "ditmer-embla";
import {EmblaIcons} from 'core/emblaIcons';
import {GodkendKursusButton} from "./godkendKursusButton";
import DynamicButton from "../../../core/components/button/dynamicButton";
import {ModalSize} from "../../../core/components/slideIn/modalSize";
import {ModalType} from "../../../core/components/slideIn/modalType";
import {CreateOrRemoveKlarmelding} from "../../klarmeldinger/components/createOrRemoveKlarmelding";
import {KlarmeldingInputModel} from "../../klarmeldinger/components/klarmeldingInputModel";
import useLogbogApi from "../../../core/hooks/useLogbogApi";
import {EditKlarmelding} from "../../klarmeldinger/components/editKlarmelding";
import {KlarmeldingTypeEnum} from "../../../infrastructure/enums/klarmeldingTypeEnum";
import {forloebReducer, ForloebSliceState} from "../../../core/forloebSlice";
import useScreenResolution from "../../../core/hooks/useScreenResolution";
import {
    UserProfileAvatar,
    UserProfileAvatarSizeEnum
} from "../../../core/components/userProfileAvatar/userProfileAvatar";
import {UserProfileAvatarModel} from "../../../core/components/userProfileAvatar/userProfileAvatarModel";
import {GetUddannelsestypeFromIdTranslation} from "../../../core/sharedmodels/stilling/uddannelseTypeEnum";

type KursusCardProps = {
    laegekursus: LaegekursusModel;
    laegekursusEditCallback: () => void;
    laegekursusUdaladKlarmeldingCallback?: () => void;
    onCardClickCallback?: (model: LaegekursusModel) => void;
    gruppeKategoriTekst: string;
    laegekursusGruppeGodkendt: boolean;
    isReadonly?: boolean;
    hideUdfyldDetails?: boolean;
    renderApproveButton?: boolean;
    renderUdeladKlarmeldingButton?: boolean;
    hideStatusBtn?: boolean;
    brugerMaalbeskrivelseId?: string;
    forloebId?: string;
    showUserInfo?: boolean;
    hideNotatInfoIfNone?: boolean;
}
export function LaegekursusCard({laegekursus, laegekursusGruppeGodkendt, laegekursusEditCallback, onCardClickCallback, laegekursusUdaladKlarmeldingCallback, renderApproveButton = false, renderUdeladKlarmeldingButton = false, hideNotatInfoIfNone = false, showUserInfo = false, hideStatusBtn = false, ...props}: KursusCardProps) {
    const dispatch = useAppDispatch();
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;
    const {isMobile} = useScreenResolution();

    const hasAnyNotater = laegekursus.notatFileCount > 0;
    const manglerHandling = (laegekursus.status === LaegekursusStatusEnum.ManglerHandling);
    const _currentUser = useRef(new AccountService().getUser());
    const { logbogLaegekursusApi, klarmeldingApi } = useLogbogApi();

    const [laegekursusModalIsOpenedByModal, setLaegekursusModalOpenedByModal] = useState(false);
    const [laegekursusModalOpen, setLaegekursusModalOpen] = useState(false);
    const [showFjernGodkendelseModalOpen, setShowFjernGodkendelseModalOpen] = useState<boolean>(false);
    const [showKlarmeldingModal, setShowKlarmeldingModal] = useState<boolean>(false);
    const [showDeleteModalOpen, setShowDeleteModalOpen] = useState(false);
    const [showEditKlarmeldingModal, setShowEditKlarmeldingModal] = useState(false);

    const laegekursusModalTarget = "laegekursus-modal";
    const fjernGodkendelseModalTarget = "fjern-godkendelse";
    const confirmDeleteModalId = "laegekursus-delete-confirm-modal";
    const klarmeldKursusModalId = "laegekursus-klarmeld-modal";
    const editKlarmeldingModalTarget = "edit-laegekursus-klarmelding-modal";

    const kursusErGrundkursusIPsykoterapi = laegekursus.gruppeType === LaegekursusGruppeTypeEnum.GrundkursusIPsykoterapi;

    function openUdfyldModal() {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: laegekursusModalTarget}))
        setLaegekursusModalOpen(true)
    }

    const removeGodkendelse = async () => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: laegekursusModalTarget}))
        const godkendelseFjernetLaegekursusId = await logbogLaegekursusApi.removeGodkendelseLaegekursus(laegekursus.id, props.brugerMaalbeskrivelseId);
        if (godkendelseFjernetLaegekursusId) {
            dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: laegekursusModalTarget}))
            NotificationModule.showSuccess(Localizer.kursusPage_godkendelseFjernet(), "");
            setLaegekursusModalOpen(false);
        } else
            dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: laegekursusModalTarget}))

        laegekursusEditCallback();
    }

    function getHeaderTitle(): string {
        if (manglerHandling)
            return Localizer.kursusPage_kursusEditTitelTekst(Localizer.kursusPage_udfyld(), props.gruppeKategoriTekst)
        else
            return Localizer.kursusPage_kursusEditTitelTekst(Localizer.global_edit(), props.gruppeKategoriTekst)
    }

    const deleteLaegekursus = async () => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: laegekursusModalTarget}))
        let deletedLaegekursus = await logbogLaegekursusApi.deleteLaegekursus(laegekursus.id);
        if (deletedLaegekursus) {
            dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: laegekursusModalTarget}))
            NotificationModule.showSuccess(Localizer.kursusPage_kursusDeleted(), "");
        } else {
            dispatch(setSlideinState({state: SlideInStateEnum.Opened, slideInId: laegekursusModalTarget}))
        }
        laegekursusEditCallback();
    }

    function modalClosed() {
        setLaegekursusModalOpen(false);
        laegekursusEditCallback();
    }

    function modalFjernGodkendelseCancel() {
        setShowFjernGodkendelseModalOpen(false);
        setLaegekursusModalOpen(!laegekursusModalIsOpenedByModal);
        setLaegekursusModalOpenedByModal(false);
    }

    function modalDeleteCancel() {
        setShowDeleteModalOpen(false);
        setLaegekursusModalOpen(true);
    }

    function laegekursusEditFinishCallback() {
        setLaegekursusModalOpen(false);
        laegekursusEditCallback();
    }

    const updateLaegekursusStatus = async (inputModel: KlarmeldingInputModel, removeKlarmelding: boolean) => {
        dispatch(setSlideinState({state: SlideInStateEnum.ActionOngoing, slideInId: laegekursusModalTarget}))
        if (removeKlarmelding) {
            const laegekursusId = await logbogLaegekursusApi.removeKlarmelding(laegekursus.id)
            if (laegekursusId) {
                NotificationModule.showSuccess(Localizer.kursusPage_klarmeldRemovedSuccess(), "");
            }
        }
        else {
            inputModel.contextId = laegekursus.id;
            const laegekursusId = await klarmeldingApi.editKlarmeldinger(inputModel)
            if (laegekursusId) {
                NotificationModule.showSuccess(Localizer.kursusPage_klarmeldSuccess(), "");
            }
        }
        laegekursusEditCallback();
        dispatch(setSlideinState({state: SlideInStateEnum.Closing, slideInId: laegekursusModalTarget}))
    }

    return (
        <>
            <div className="flex-container" onClick={() => onCardClickCallback ? onCardClickCallback(laegekursus) : {}}>
                <div className="flex-space flex-column flex-grow">
                    <div className="flex-align-c flex-row">
                        <div className="flex-grow d-flex">
                            <h5 className={"margin-right-m"}>{laegekursus.titel}
                            </h5>
                            {(manglerHandling && !props.hideUdfyldDetails && !props.isReadonly) &&
                                <button className="btn btn-default rounded-pill flex-align-r"
                                        onClick={() => openUdfyldModal()}>{Localizer.kursusPage_udfyld()}
                                </button>
                            }
                        </div>

                        {!manglerHandling &&
                            <div className="flex-row flex-nowrap">
                                {laegekursusUdaladKlarmeldingCallback && renderUdeladKlarmeldingButton &&
                                    <DynamicButton rounded additionalClasses="stop-event"
                                                   onClick={(event) => {
                                                       event.stopPropagation();
                                                       laegekursusUdaladKlarmeldingCallback();
                                                   }}>
                                        <DynamicButton.TextIconItem applyDatatableStopEvent
                                                                    iconName={EmblaIcons.Remove}/>
                                    </DynamicButton>
                                }

                                {renderApproveButton && laegekursus.status !== LaegekursusStatusEnum.Godkendt &&
                                    <GodkendKursusButton
                                        additionalClasses={"flex-align-r mr-1"}
                                        laegekursusId={laegekursus.id}
                                        doneCallBack={laegekursusEditCallback}
                                        useIconWithNoText={true}
                                        laegeinstitutionName={forloebSliceState.forloebState.laegeInstitutionName}
                                        afdelingName={forloebSliceState.forloebState.afdelingName}
                                        brugerMaalbeskrivelseId={props.brugerMaalbeskrivelseId}
                                    />
                                }

                                {_currentUser.current.IsAdminOrVus() && laegekursus.status === LaegekursusStatusEnum.Godkendt &&
                                    <DynamicButton
                                        additionalClasses='mr-2 flex-align-r'
                                        disabled={laegekursusGruppeGodkendt}
                                        shouldRender={!laegekursusGruppeGodkendt}
                                        rounded={true}
                                        onClick={() => {
                                            setLaegekursusModalOpenedByModal(true);
                                            setShowFjernGodkendelseModalOpen(true);
                                        }}
                                        modal={{
                                            dataTarget: `#${fjernGodkendelseModalTarget + laegekursus.id}`,
                                        }}
                                    >
                                        <DynamicButton.TextIconItem iconName={EmblaIcons.Remove}/>
                                    </DynamicButton>
                                }

                                {!props.hideUdfyldDetails &&
                                    <div
                                        className={`laegekursus-edit flex-align-r btn-default ${kursusErGrundkursusIPsykoterapi ? "btn rounded-pill" : "round"}`}
                                        onClick={() => openUdfyldModal()}
                                        role="button">
                                        {kursusErGrundkursusIPsykoterapi && laegekursus.notatFileCount > 0
                                            ? Localizer.kursusPage_seFiler()
                                            : kursusErGrundkursusIPsykoterapi
                                                ? Localizer.kursusPage_tilfoejFiler()
                                                : <EmblaIcon iconName={EmblaIcons.Edit}/>
                                        }
                                    </div>
                                }
                            </div>
                        }

                    </div>
                    {!manglerHandling &&
                        <React.Fragment key={laegekursus.id}>
                            {showUserInfo &&
                                <div className="d-flex margin-top-s">
                                    {!isMobile &&
                                        <UserProfileAvatar
                                            userProfileAvatarModel={new UserProfileAvatarModel(laegekursus.uddannelseslaegeNavn, laegekursus.uddannelseslaegeInitialer, true)}
                                            size={UserProfileAvatarSizeEnum.extraSmall}
                                        />
                                    }
                                    <div className="margin-left-s">
                                        {laegekursus.uddannelseslaegeNavn }
                                    </div>
                                </div>
                            }
                            {laegekursus.specialeNavn && laegekursus.uddannelseId &&
                                <h6 className={"margin-top-s"}>
                                    {`${GetUddannelsestypeFromIdTranslation(laegekursus.uddannelseId)}: ${laegekursus.specialeNavn} ${Localizer.global_speciale().toLowerCase()}`}
                                </h6>
                            }
                            { laegekursus.eventuelSpecifikation !== "" &&
                                <div className="margin-top-s subtle small text-truncate">
                                    {laegekursus.eventuelSpecifikation}
                                </div>
                            }
                            <div className="flex-align-c subtle margin-top-s flex-wrap">
                                {!hideStatusBtn &&
                                    <>
                                        {laegekursus.status === LaegekursusStatusEnum.Udfyldt && !kursusErGrundkursusIPsykoterapi &&
                                            <div className="margin-right-m ">
                                    <span className="badge badge-pill badge-default padding-xs bg-white border ">
                                        <BlueCircle className="margin-right-xs"/>
                                        {Localizer.kursusPage_udfyldt()}
                                    </span>
                                            </div>
                                        }
                                        {laegekursus.status === LaegekursusStatusEnum.Godkendt &&
                                            <div className="margin-right-m">
                                    <span className="badge badge-pill badge-default padding-xs">
                                        <GreenCircle/> {Localizer.global_godkendt()}
                                    </span>
                                            </div>
                                        }
                                        {laegekursus.status === LaegekursusStatusEnum.Klarmeldt &&
                                            <div className="margin-right-m">
                                        <span
                                            className="badge badge-pill badge-default padding-xs"> <YellowCircle/> {Localizer.global_klarmeldt()}</span>
                                            </div>
                                        }
                                    </>
                                }
                                {laegekursus.startDato && laegekursus.startDato && !kursusErGrundkursusIPsykoterapi &&
                                    <>
                                        <div className="card-list-calender-icon">
                                            <EmblaIcon iconName="calendar"/>
                                        </div>
                                        <div>
                                            {laegekursus.startDato?.dateWithoutTimeFormat(false)}
                                        </div>
                                        <div
                                            className="margin-right-m">&nbsp;- {laegekursus.slutDato?.dateWithoutTimeFormat(false)}
                                        </div>
                                    </>
                                }
                                {(!hideNotatInfoIfNone || hasAnyNotater) &&
                                    <div
                                        className={"margin-right-xs card-list-icon " + (hasAnyNotater ? "" : "subtle")}>
                                        <EmblaIcon iconName="comment"/>
                                        <span className={"margin-left-xs " + (hasAnyNotater ? "" : "subtle")}>{laegekursus?.notatFileCount}</span>
                                    </div>
                                }
                            </div>
                            {laegekursus.status === LaegekursusStatusEnum.Klarmeldt && laegekursus.klarmeldtAt !== undefined &&
                                <div className="margin-top-s flex-row flex-container-row wrap align-items-center">
                                    <h6 className={"margin-right-s font-weight-bold d-flex"}>
                                        {Localizer.global_klarmeldtden() + laegekursus.klarmeldtAt} {Localizer.global_to().toLowerCase()}:
                                    </h6>
                                    {laegekursus.klarmeldtTilNavn?.map(navn =>
                                        <span
                                            className="badge badge-pill badge-default padding-xs margin-right-s">{navn}</span>
                                    )}
                                </div>
                            }
                        </React.Fragment>
                    }
                </div>
            </div>

            {laegekursusModalOpen &&
                <SlideIn id={laegekursusModalTarget}
                         title={getHeaderTitle()}
                         bodyContent={<EditLaegekursus modalId={laegekursusModalTarget}
                                              laegekursus={laegekursus}
                                              forloebId={props.forloebId}
                                              brugerMaalbeskrivelseId={props.brugerMaalbeskrivelseId}
                                              laegekursusGruppeGodkendt={laegekursusGruppeGodkendt}
                                              areYouSureFjernGodkendelseModalId={fjernGodkendelseModalTarget + laegekursus.id}
                                              areYouSureDeleteModalId={confirmDeleteModalId + laegekursus.id}
                                              openAreYouSureFjernGodkendelseModal={() => setShowFjernGodkendelseModalOpen(true)}
                                              openShowKlarmeldingModal={() => setShowKlarmeldingModal(true)}
                                              openAreYouSureDeleteModal={() => setShowDeleteModalOpen(true)}
                                              laegekursusStatusEditCallback={() => laegekursusEditCallback()}
                                              editKlarmeldingModelOpen={() => setShowEditKlarmeldingModal(true)}
                             />
                         }
                         defaultOpen={true}
                         actionOnCloseCallback={modalClosed}
                         actionFinishedCallback={laegekursusEditFinishCallback}
                />
            }

            {showFjernGodkendelseModalOpen &&
                <ModalSubmitMessage modalId={fjernGodkendelseModalTarget + laegekursus.id}
                                    title={Localizer.kursusPage_fjernGodkendelse()}
                                    description={<p>{Localizer.kursusPage_areYourSureFjernGodkendelse()}</p>}
                                    cancelAction={modalFjernGodkendelseCancel}
                                    acceptAction={removeGodkendelse}
                                    modalAcceptType={ModalAcceptType.danger}
                                    acceptButtonText={Localizer.kursusPage_fjernGodkendelse()}
                />
            }

            {showDeleteModalOpen &&
                <ModalSubmitMessage modalId={confirmDeleteModalId + laegekursus.id}
                                    title={Localizer.areYouSure()}
                                    description={
                                        <p>{Localizer.kursusPage_laegekursusAreYouSureDelete(laegekursus.titel)}</p>}
                                    cancelAction={modalDeleteCancel}
                                    acceptAction={deleteLaegekursus}
                                    modalAcceptType={ModalAcceptType.danger}
                                    acceptButtonText={Localizer.global_delete()}
                />
            }


            {showKlarmeldingModal &&
                <SlideIn id={klarmeldKursusModalId}
                         title={!laegekursus.klarmeldtAt ? Localizer.global_klarmeld() : Localizer.global_removeKlarmeld()}
                         actionOnCloseCallback={() => {
                             setShowKlarmeldingModal(false)
                         }}
                         actionFinishedCallback={() => {
                             setShowKlarmeldingModal(false)
                         }}
                         defaultOpen={true}
                         modalSize={ModalSize.md}
                         modalType={ModalType.originalModal}
                         bodyContent={
                             <CreateOrRemoveKlarmelding modalId={klarmeldKursusModalId}
                                                        klarmeldToDelkursusledere={true}
                                                        isKlarmeldState={laegekursus.klarmeldtAt === undefined}
                                                        updateStatusCallback={(inputModel: KlarmeldingInputModel) => updateLaegekursusStatus(inputModel, laegekursus.klarmeldtAt !== undefined)}
                                                        klarmeldingType={KlarmeldingTypeEnum.Laegekursus}>

                                 {(laegekursus.klarmeldtAt === undefined)
                                     ?
                                     <>
                                         <p>{Localizer.kursusPage_klarmeldChosenKursus()}:</p>
                                         {laegekursus.titel}
                                     </>
                                     :
                                     <>
                                         <p>{Localizer.kursusPage_removeKlarmeldChosenKursus()}:</p>
                                         {laegekursus.titel}
                                     </>
                                 }
                             </CreateOrRemoveKlarmelding>}
                />
            }

            {showEditKlarmeldingModal &&
                <SlideIn id={editKlarmeldingModalTarget}
                         title={Localizer.kompetencePage_editKlarmelding()}
                         actionFinishedCallback={() => {
                             setShowEditKlarmeldingModal(false)
                             dispatch(setSlideinState({
                                 state: SlideInStateEnum.Closing,
                                 slideInId: laegekursusModalTarget
                             }))
                         }}
                         actionOnCloseCallback={() => setShowEditKlarmeldingModal(false)}
                         bodyContent={<EditKlarmelding modalId={editKlarmeldingModalTarget} contextId={laegekursus.id}
                                                       klarmeldingType={KlarmeldingTypeEnum.Laegekursus}/>}
                         defaultOpen={true}
                         modalSize={ModalSize.md}
                         modalType={ModalType.originalModal}
                />
            }
        </>
    );
}

import {HttpClientService} from "../../httpClient/httpClientService";
import config from "../../../config/config";
import {FileDownloadHandler} from "./fileDownloadHandler";
import {FileArchiveEnum} from "../../../core/sharedmodels/fileMetaData/fileArchiveEnum";
import {ApiResponse} from "../apiResponse";
import {
    FileMetadata,
    FileMetadataResponseModel,
    FromFileMetaDataResponseModel
} from "core/sharedmodels/fileMetaData/fileMetaData";
import {UddannelseTypeEnum} from "../../../core/sharedmodels/stilling/uddannelseTypeEnum";

export class LogbogFileApi {
    private httpClientService: HttpClientService;
    private fileDownloadHandler: FileDownloadHandler;

    private fileBaseUrl = () => config.logbogApiUrl + "api/file/";
    private fileQuickguideBaseUrl = () => config.logbogApiUrl + "api/quickguide/";

    constructor(httpClientService: HttpClientService, fileDownloadHandler: FileDownloadHandler) {
        this.httpClientService = httpClientService;
        this.fileDownloadHandler = fileDownloadHandler;
    }

    async uploadFile(file: File, fileType: FileArchiveEnum): Promise<FileMetadata> {
        const postModel = new FormData();
        postModel.append("FormFile", file, file.name);
        postModel.append("FileArchiveEnum", fileType.toString());

        const url = this.fileBaseUrl();
        const result = await this.httpClientService.UploadFormdata<ApiResponse<FileMetadataResponseModel>>(url, postModel);
        return FromFileMetaDataResponseModel(result.data);
    }

    async downloadFile(fileMetadata: FileMetadata): Promise<void> {
        const apiUrl = this.fileBaseUrl() + fileMetadata.id;
        let blob = await this.httpClientService.DownloadFile(apiUrl, fileMetadata.fileContentType);
        //Hack to force browser to show AJAX blob file as downloaded file
        this.fileDownloadHandler.DownloadFile(blob, fileMetadata.fileName);
    }

    async deleteFileWithRelations(fileMetadataId: string): Promise<string> {
        const apiUrl = this.fileBaseUrl() + fileMetadataId;
        let result = await this.httpClientService.Delete<ApiResponse<string>>(apiUrl);
        return result.data;
    }

    async downloadQuickguideFile(uddannelseType : UddannelseTypeEnum): Promise<void> {
        const apiUrl = this.fileQuickguideBaseUrl() + uddannelseType;
        let blob = await this.httpClientService.DownloadQuickguideFile(apiUrl, uddannelseType.toString());
        const fileName = uddannelseType === UddannelseTypeEnum.KBU ? "KBUQuickguide" : "HoveduddannelseQuickguide";
        //Hack to force browser to show AJAX blob file as downloaded file
        this.fileDownloadHandler.DownloadFile(blob, fileName);
    }


}

import {UddannelseTypeEnum} from "../../../core/sharedmodels/stilling/uddannelseTypeEnum";

export class LogbogLaegerRequest {
    constructor(uddannelsesstedId: string, vejlederId?: string, uddannelseType?: UddannelseTypeEnum) {
        this.uddannelsesstedId = uddannelsesstedId;
        this.vejlederId = vejlederId;
        this.uddannelseType = uddannelseType;
    }
    public uddannelsesstedId: string;
    public vejlederId?: string;
    public uddannelseType?: UddannelseTypeEnum;
}

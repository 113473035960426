import {CrudUddannelsesplanInputModel} from "../../../pages/vejledning/uddannelsesplan/components/crudUddannelsesplanInputModel";

export class LogbogUddannelsesplanCrudModel {
    constructor(inputModel: CrudUddannelsesplanInputModel) {
        this.id = inputModel.id;
        this.forloebId = inputModel.forloebId;
        this.titel = inputModel.titel;
        this.beskrivelse = inputModel.beskrivelse;
        this.klarmeldtAt = inputModel.klarmeldtAt;
    }

    public id?: string;
    public forloebId: string;
    public titel: string;
    public beskrivelse: string;
    public klarmeldtAt?: string;
}


import { dateTimeStringFromApiToDate } from "index";
import { LogbogEvalueringPdfResponseModel } from "services/api/logbogEvaluering/logbogEvalueringPdfResponseModel";

export class EvalueringPdfModel {
    public static FromResponseModel(evaluering: LogbogEvalueringPdfResponseModel): EvalueringPdfModel{
        let evalueringPdfModel = new EvalueringPdfModel();

        evalueringPdfModel.uddannelsestype = evaluering.uddannelsestype;
        evalueringPdfModel.speciale = evaluering.speciale;
        evalueringPdfModel.laegeinstitution = evaluering.laegeinstitution;
        evalueringPdfModel.afdeling = evaluering.afdeling;
        evalueringPdfModel.forloebStart = evaluering.forloebStart ? dateTimeStringFromApiToDate(evaluering.forloebStart) : undefined;
        evalueringPdfModel.forloebSlut = evaluering.forloebSlut ? dateTimeStringFromApiToDate(evaluering.forloebSlut) : undefined;
        evalueringPdfModel.hovedvejleder = evaluering.hovedvejleder;
        evalueringPdfModel.uddannelsesansvarlige = evaluering.uddannelsesansvarlige;
        evalueringPdfModel.svar = evaluering.svar.map(s => new EvalueringSvarModel({spoergsmaal: s.spoergsmaal, svarState: s.svar, kommentar: s.kommentar, ikkeRelevant: s.ikkeRelevant}));

        return evalueringPdfModel;
    }

    uddannelsestype: string;
    speciale: string;
    laegeinstitution: string;
    afdeling: string;
    forloebStart?: Date;
    forloebSlut?: Date;
    hovedvejleder: string;
    uddannelsesansvarlige: string[];
    svar: EvalueringSvarModel[];
}

export class EvalueringSvarModel {
    spoergsmaal: string;
    svarState: string;
    kommentar?: string;
    ikkeRelevant?: boolean;

    public constructor(init?: Partial<EvalueringSvarModel>) {
        Object.assign(this, init);
    }
}

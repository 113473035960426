import {FilterParamsModel} from "../../sharedmodels/filter/FilterParamsModel";
import {PaginationModel} from "../../sharedmodels/filter/PaginationModel";
import {NotatType} from "../../sharedmodels/notat/notatType";
import {NotatSynlighedEnum} from "../../sharedmodels/notat/notatSynlighedEnum";

export class NotatFilterModel extends FilterParamsModel{
    constructor(paginationModel?: PaginationModel,
                synligFor?: NotatSynlighedEnum,
                kategori?: NotatType,
                laegeId?: string,
                search?: string) {
        super(paginationModel ?? new PaginationModel(1, 5, 0), search ?? "")
        this.synligfor = synligFor ?? undefined;
        this.type = kategori ?? undefined;
        this.laegeId = laegeId ?? undefined;
    }
    synligfor?: NotatSynlighedEnum;
    type?: NotatType;
    laegeId?: string;
}


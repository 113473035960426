import {RolleTitelEnum} from "../infrastructure/enums/rolleTitelEnum";
import {RolleEnum} from "../infrastructure/enums/userRole";

export const RolleTitelToRolle = (rolleTitelEnum: RolleTitelEnum): RolleEnum => {
    switch (rolleTitelEnum) {
        case RolleTitelEnum.Systemadministrator:
            return RolleEnum.Systemadministrator;
        case RolleTitelEnum.Administrator:
            return RolleEnum.Administrator;
        case RolleTitelEnum.Uddannelseslaege:
            return RolleEnum.Uddannelseslaege;
        case RolleTitelEnum.Vejleder:
            return RolleEnum.Vejleder;
        case RolleTitelEnum.Tutorlaege:
        case RolleTitelEnum.Uao:
        case RolleTitelEnum.Cheflaege:
        case RolleTitelEnum.LedendeOverlaege:
            return RolleEnum.Uddannelsesansvarlig
        case RolleTitelEnum.VusMedarbejder:
            return RolleEnum.VusMedarbejder;
        case RolleTitelEnum.HrMedarbejder:
            return RolleEnum.HrMedarbejder;
        case RolleTitelEnum.Sekretaer:
            return RolleEnum.Sekretaer;
        case RolleTitelEnum.Hospitalsledelse:
        case RolleTitelEnum.Uko:
            return RolleEnum.Ledelse;
        case RolleTitelEnum.Pkl:
        case RolleTitelEnum.Lektor:
        case RolleTitelEnum.Amu:
        case RolleTitelEnum.Puk:
        case RolleTitelEnum.Dynamu:
        case RolleTitelEnum.Ukyl:
            return RolleEnum.KonsulentForDenLaegeligeVidereuddannelse;
        case RolleTitelEnum.Hovedkursusleder:
        case RolleTitelEnum.Delkursusleder:
        case RolleTitelEnum.SekretaerForHovedkursusleder:
            return RolleEnum.Kursusleder;
        case RolleTitelEnum.Kursusudbyder:
            return RolleEnum.Kursusudbyder;
        case RolleTitelEnum.Forskningstraeningsvejleder:
            return RolleEnum.Forskningstraeningsvejleder;

        default:
            throw Error("Rolle titel could not be mapped (input:" + rolleTitelEnum + ")")
    }
}

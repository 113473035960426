import {CSSProperties, PropsWithChildren, useEffect} from "react";
import { hideAllTooltips, initTooltip} from "../../utils";
import './Tooltip.scss';
import { UUID } from "index";
import useScreenResolution from "core/hooks/useScreenResolution";
import classNames from "classnames";

export enum TooltipPlacement {
    Right = "right",
    Left = "left",
    Top = "top",
    Bottom = "bottom"
}

type TooltipProps = {
    show?: boolean;
    title?: string;
    className?: string;
    dataHtml?: string;
    customStyle?: CSSProperties;
    tipClassName?: string;
    placement?: TooltipPlacement;
};

const Tooltip = (({
    children,
    title = "",
    className = "",
    show = true,
    dataHtml = "true",
    customStyle,
    tipClassName,
    placement,
}: PropsWithChildren<TooltipProps>) => {

    const {isMobile} = useScreenResolution();

    const id = UUID();
    const selector =`#${id}`;

    // So that tooltip-component rerenders, when showing/hiding the tooltip:
    const tooltipKey = show ? "tooltip-show" : "tooltip-hidden";

    useEffect(() => {
        initTooltip(selector, isMobile);

        return () => {
            hideAllTooltips();
        }
    }, [selector, isMobile]);

    useEffect(() => {
        if(!tipClassName) return;

        const $this = $(selector);
        const observer = new MutationObserver((mutationsList, observer) => {
            mutationsList.forEach(mutation => {
                if (mutation.type !== "attributes" || mutation.attributeName !== "aria-describedby") return;

                const tip = $this.tooltip().data('bs.tooltip')?.tip;
                if(!tip) return;

                $(tip).addClass(tipClassName);

                observer.disconnect();
            });
        });

        const htmlElement = $this[0];
        observer.observe(htmlElement, { attributes: true });

    }, [selector, tipClassName]);

    return (
        <>
            {show
                ?
                    <div
                        key={tooltipKey}
                        id={id}
                        style={customStyle}
                        className={classNames("tooltip-wrapper", className)}
                        title={title}
                        data-tooltip={true}
                        data-html={dataHtml}
                        data-placement={placement}
                    >
                        {children}
                    </div>
                :
                    <div
                        key={tooltipKey}
                        id={id}
                        className={className}
                    >
                        {children}
                    </div>
            }
        </>
    );
});

export default Tooltip;

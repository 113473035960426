import {HttpClientService} from "../../httpClient/httpClientService";
import config from "../../../config/config";
import {ApiResponse} from "../apiResponse";
import {AccountService} from "../../account/accountService";

export class StamdataUserAccountApi {
    constructor(httpClientService: HttpClientService, accountService: AccountService) {
        this.httpClientService = httpClientService;
        this.accountService = accountService;
    }

    private httpClientService: HttpClientService;
    private accountService: AccountService;

    private userAccountBaseUrl = () => config.stamdataApiUrl + "api/UserAccount/";
    private userAccountResetPasswordUrl = () => config.stamdataApiUrl + "api/userAccount/forgotpassword";

    async resetPassword(): Promise<string> {
        const email = this.accountService.getUser().Email;
        let url = this.userAccountResetPasswordUrl() + "?email=" + email ;
        let result = await this.httpClientService.Get<ApiResponse<string>>(url);
        return result.data;
    }
}

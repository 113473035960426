import {ForSelectModel} from "../../../core/sharedmodels/forSelectModel";
import {StamdataUddannelseResponseModel} from "./stamdataUddannelseResponseModel";

export class StamdataUddannelseModel extends ForSelectModel{
    public uddannelseType: number;

    constructor(responseModel : StamdataUddannelseResponseModel) {
        super(responseModel.selectModel);
        this.uddannelseType = responseModel.uddannelseType;
    }
}

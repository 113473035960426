import useLogbogApi from "core/hooks/useLogbogApi";
import { useCallback, useEffect, useState } from "react";
import { useNotifikationContext } from "../context/notifikationContextProvider";

const useNotifications = (defaultFetch = true) => {
  const { logbogNotifikationApi } = useLogbogApi();

  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ hasFetched, setHasFetched ] = useState<boolean>(false);
  const { state, setNotifikationer } = useNotifikationContext();

  const fetchNotifications = useCallback(async () => {
    setIsLoading(true);

    const notifications = await logbogNotifikationApi.getNotifikationer().finally(() => {
        setHasFetched(true);
        setIsLoading(false);
      });

      setNotifikationer(notifications);

  }, [logbogNotifikationApi, setNotifikationer]);

  useEffect(() => {
    if(defaultFetch && !hasFetched && !isLoading && state.notifikationer.length <= 0) {
      fetchNotifications();
    }
  }, [fetchNotifications, hasFetched, isLoading, defaultFetch, state.notifikationer.length]);

  return {
    notifications: state.notifikationer,
    isLoading,
    refetchNotifications: fetchNotifications
  }
}

export default useNotifications;

import {
    LaegeinstitutionWithStillingerResponseModel
} from "../../../services/api/forloeb/laegeinstitutionWithStillingerResponseModel";
import {StillingForloebOverviewModel} from "../stilling/stillingForloebOverviewModel";
import {AfdelingMedStillingModel} from "../stilling/afdelingMedStillingModel";

export class LaegeinstitutionWithStillingerModel {
    public static FromResponseModel(responseModel: LaegeinstitutionWithStillingerResponseModel): LaegeinstitutionWithStillingerModel {
        const model = new LaegeinstitutionWithStillingerModel();
        model.uddannelsesstedId = responseModel.laegeinstitutionId;
        model.uddannelsesstedNavn = responseModel.laegeinstitutionNavn;
        model.praksisStillinger = responseModel.praksisStillinger.map(s => StillingForloebOverviewModel.FromResponseModel(s));
        model.afdelingerMedStillinger = responseModel.afdelingerMedStillinger.map(s => AfdelingMedStillingModel.FromResponseModel(s));
        return model;
    }

    public uddannelsesstedId: string;
    public uddannelsesstedNavn: string;
    public praksisStillinger: StillingForloebOverviewModel[];
    public afdelingerMedStillinger: AfdelingMedStillingModel[];
}

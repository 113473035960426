export class KvSkemaConstants {
    public static InitialKbuSkemaIds: string[] =
    [
        "4b98c713-3714-44b9-9260-1d7cbb4aedf0",
        "ff110732-aee5-484c-b002-20049a60740c",
        "ae78b762-7e97-4d02-bb29-2cdce2facaa8",
        "24ba2fe5-fd5b-447e-bb7e-2d3eac7936d7",
        "f5b6cd82-34d2-4b93-b76f-368203966565",
        "434e11e7-f844-424b-bd4f-480300bc09ae",
        "7c978c25-8581-4e7e-99c9-5d0edfe69f50",
        "6f77bae1-5de5-4dc8-a8a8-6c38c6c50690",
        "aac980b1-24b1-4442-9ef2-6dfaa1c70454",
        "f8fe849f-ecf5-440a-a244-7a158bf539fa",
        "c35355b9-2f46-4538-b3d7-9a5f225bfc8f",
        "e56353e9-b20f-4bf1-ad7c-bdabd854c1b5",
        "5e0dd575-b8ab-4147-929f-d250d1f56fa0",
        "4b19cb89-23b0-48f9-bca8-d3b61b156804",
        "7bf663b2-1e69-40cc-a035-d554c01afb67",
        "962cecdc-629a-444c-9dea-d7932a60183c",
        "3a750cdb-661b-4279-bcda-dd30f16847c3"
    ];
}

import {FravaerskodeEnum} from "../../../core/sharedmodels/forloeb/fravaerskoderEnum";
import {dateTimeStringFromApiToDate} from "../../../index";
import {LaegekursusGruppeSimpelModel} from "../laegekursus/laegekursusGruppeSimpelModel";
import {ForloebWithKursusGrupperSimpelResponseModel} from "./forloebWithKursusGrupperSimpelResponseModel";

export class ForloebWithKursusGrupperModel {
    public brugerId: string;
    public forloebId: string;
    public brugerMaalbeskrivelseId: string;
    public fravaerskodeEnum: FravaerskodeEnum;
    public specialeName: string;
    public laegeInstitutionName: string;
    public afdelingName: string;
    public stillingName: string;
    public brugerKursusraekkeId: string;
    public erFravaer: boolean;
    public startDate: Date;
    public endDate: Date;
    public laegekursusGrupper: LaegekursusGruppeSimpelModel[] ;


    constructor(responseModel: ForloebWithKursusGrupperSimpelResponseModel) {
        this.forloebId = responseModel.forloebId;
        this.brugerId = responseModel.brugerId;
        this.brugerMaalbeskrivelseId = responseModel.brugerMaalbeskrivelseId;
        this.brugerKursusraekkeId = responseModel.brugerKursusraekkeId;
        this.afdelingName = responseModel.afdelingName;
        this.fravaerskodeEnum = responseModel.fravaerskodeEnum;
        this.specialeName = responseModel.specialeName;
        this.laegeInstitutionName = responseModel.laegeInstitutionName;
        this.stillingName = responseModel.stillingName;
        this.erFravaer = responseModel.erFravaer;
        this.startDate = dateTimeStringFromApiToDate(responseModel.startDate);
        this.endDate = dateTimeStringFromApiToDate(responseModel.endDate);
        this.laegekursusGrupper = responseModel.laegekursusGrupper?.map(lkg => {
            return new LaegekursusGruppeSimpelModel(lkg);
        });
    }
}

import { NotificationModel, NotifikationEventTypeEnum } from "core/components/notifications/models/notificationModel";
import { UserNameInfoModel } from "core/components/userProfile/userNameInfoModel";
import { dateTimeStringFromApiToDate } from "index";
import { RolleTitelEnum } from "infrastructure/enums/rolleTitelEnum";

export type LogbogNotifikationResponseModel = {
  id: string;
  type: NotifikationEventTypeEnum;
  contextId: string;
  message: string;
  erSet: boolean;
  timeStamp: string;
  afsenderInfo: LogbogNotifikationAfsenderInfoResponseModel;
}

export type LogbogNotifikationAfsenderInfoResponseModel = {
  info?: UserNameInfoModel;
  rolleTitelEnum?: RolleTitelEnum;
}

export const FromNotifikationResponseModel = (responseModel : LogbogNotifikationResponseModel): NotificationModel => {

  const model = {
    id: responseModel.id,
    type: responseModel.type,
    contextId: responseModel.contextId,
    message: responseModel.message,
    erSet: responseModel.erSet,
    timestamp: dateTimeStringFromApiToDate(responseModel.timeStamp),
    afsender: {
      info: responseModel.afsenderInfo.info,
      rolletitelEnum: responseModel.afsenderInfo.rolleTitelEnum
    }
  } as NotificationModel;

  return model;
}


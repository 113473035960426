import { EducationFilterRestrictModel } from "core/components/filter/components/educationFilter";
import { PlaceFilterRestrictModel } from "core/components/filter/components/placeFilter";
import { ForloebOverviewFilterModel } from "core/components/forloebOverview/ForloebOverviewFilterModel";
import ForloebCrudFilterRestrictReponseModel from "services/api/stamdataRestrict/reponseModels/forloebCrudFilterRestrictReponseModel";
import ForloebCrudFilterRestrictRequestModel from "services/api/stamdataRestrict/requestModels/forloebCrudFilterRestrictRequestModel";

type ForloebCrudFilterRestrictModel = & PlaceFilterRestrictModel & EducationFilterRestrictModel;

const EmptyForloebCrudFilterRestrictModel: ForloebCrudFilterRestrictModel = {
    specialer: [],
    uddannelser: [],
    regioner: [],
    omraader: [],
    afdelinger: [],
    praksiser: [],
    sygehuse: [],
    filterHierarchicalEnums: []
}

const ToRequestModelFromForloebOverviewFilterModel = (filterModel: ForloebOverviewFilterModel) : ForloebCrudFilterRestrictRequestModel => {
    return {
        specialer: filterModel.specialer ?? [],
        uddannelser: filterModel.uddannelser ?? [],
        regioner: filterModel.regioner ?? [],
        omraader: filterModel.omraader ?? [],
        afdelinger: filterModel.afdelinger ?? [],
        praksiser: filterModel.praksis ?? [],
        sygehuse: filterModel.hospitaler ?? [],
    }
};

const ForloebCrudFilterRestrictModelFromResponseModel = (reponseModel: ForloebCrudFilterRestrictReponseModel) : ForloebCrudFilterRestrictModel => reponseModel;

export default ForloebCrudFilterRestrictModel;

export {
    ForloebCrudFilterRestrictModelFromResponseModel,
    ToRequestModelFromForloebOverviewFilterModel,
    EmptyForloebCrudFilterRestrictModel
}
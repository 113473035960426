import {StamdataUserWithForloebAccessDateResponseModel} from "../../../services/api/stamdataUser/stamdataUserWithForloebAccessDateResponseModel";
import {LogbogUserWithForloebAccessDateResponseModel} from "../../../services/api/logbogUser/logbogUserWithForloebAccessDateResponseModel";
import {dateTimeStringFromApiToDate} from "../../../index";
import {RolleTitelEnum} from "../../../infrastructure/enums/rolleTitelEnum";

export class UserWithForloebAccessDateModel {
    public static FromLogbogResponseModel(responseModel : LogbogUserWithForloebAccessDateResponseModel) {
        let model = new UserWithForloebAccessDateModel();

        model.userId = responseModel.userId;
        model.navn = responseModel.navn;
        model.email = responseModel.email;
        model.titel = responseModel.titel;
        model.initialer = responseModel.initialer;
        model.adgangTil = dateTimeStringFromApiToDate(responseModel.adgangTil);
        model.rolleTitelEnums = responseModel.rolleTitelEnums;

        return model;
    }

    public userId: string;
    public navn: string;
    public initialer: string;
    public email: string;
    public titel: string;
    public adgangTil: Date;
    public rolleTitelEnums: RolleTitelEnum[];
}

import {NotatType} from "../../../core/sharedmodels/notat/notatType";

export class LogbogNotatGetModel {
    notatType : NotatType;
    contextUserId : string;
    contextId: string;

    constructor(notatType : NotatType, contextUserId : string, contextId: string) {
        this.notatType = notatType;
        this.contextUserId = contextUserId;
        this.contextId = contextId;
    }
}

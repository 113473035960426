import {
    EvalueringStatistikForloebModel,
    EvalueringStatistikForloebResponseModel
} from "./evalueringStatistikForloebModel";
import {EvalueringGruppeScoreModel} from "./EvalueringGruppeScoreModel";

export class EvalueringStatistikResponseModel {
    public uddannelsesstedId: string;
    public uddannelsesstedNavn: string;
    public sygehusNavn: string;
    public omraadeNavn: string;
    public antalForloeb: number;
    public antalEvalueringerMedAdgangTil: number;
    public antalKommentarer: number;
    public antalForloebEvalueret: number;
    public gruppeScore: EvalueringGruppeScoreModel[];
    public singleAverageScore: number[];
    public forloebEvalueringer: EvalueringStatistikForloebResponseModel[];
    public evalueringMedKommentarerIdsMedAdgangTil: string[];
    public hasAccessToUddannelsessted: boolean;
    public maxAntalEvalueringer?: number;
    public antalForloebEvalueretTotal?: number;
}

export class EvalueringStatistikModel {
    public uddannelsesstedId: string;
    public uddannelsesstedNavn: string;
    public sygehusNavn: string;
    public omraadeNavn: string;
    public antalForloeb: number;
    public antalEvalueringerMedAdgangTil: number;
    public antalKommentarer: number;
    public antalForloebEvalueret: number;
    public gruppeScore: EvalueringGruppeScoreModel[];
    public singleAverageScore: number[];
    public forloebEvalueringer: EvalueringStatistikForloebModel[];
    public evalueringMedKommentarerIdsMedAdgangTil: string[];
    public hasAccessToUddannelsessted: boolean;
    public maxAntalEvalueringer?: number;
    public antalForloebEvalueretTotal?: number;

    public static FromResponseModel(responseModel : EvalueringStatistikResponseModel) {
        let model = new EvalueringStatistikModel();
        model.uddannelsesstedId = responseModel.uddannelsesstedId;
        model.uddannelsesstedNavn = responseModel.uddannelsesstedNavn;
        model.sygehusNavn = responseModel.sygehusNavn;
        model.omraadeNavn = responseModel.omraadeNavn;
        model.antalForloeb = responseModel.antalForloeb;
        model.antalEvalueringerMedAdgangTil = responseModel.antalEvalueringerMedAdgangTil;
        model.antalKommentarer = responseModel.antalKommentarer;
        model.antalForloebEvalueret = responseModel.antalForloebEvalueret;
        model.gruppeScore = responseModel.gruppeScore.sort((a, b) => a.svarGruppeEnum > b.svarGruppeEnum ? 1 : -1);
        model.singleAverageScore = responseModel.singleAverageScore;
        model.forloebEvalueringer = []; //Sætter denne værdi i forbindelse med pagination - hvor vi henter enkelte evalueringer til paginering - vi sætter aldrig dette object fra responsmodellen
        model.evalueringMedKommentarerIdsMedAdgangTil = responseModel.evalueringMedKommentarerIdsMedAdgangTil ?? [];
        model.hasAccessToUddannelsessted = responseModel.hasAccessToUddannelsessted;
        model.maxAntalEvalueringer = responseModel.maxAntalEvalueringer;
        model.antalForloebEvalueretTotal = responseModel.antalForloebEvalueretTotal;
        return model;
    }
}

import React, { useEffect } from "react";
import useNotificationSettings from "./hooks/useNotificationSettings";
import NotificationSettingListItem from "./notificationSettingListItem/notificationSettingListItem";
import { Loading } from "core/components/loading/loading";
import { Localizer } from "infrastructure/localization/localizer";
import useNotificationCount from "../hooks/useNotificationCount";
import useNotifications from "../hooks/useNotifications";

const useRefetchNotificationsOnUnmount = () => {
  const { refetchNotifikationerCount } = useNotificationCount(false);
  const { refetchNotifications } = useNotifications(false);

  useEffect(() => {
    return () => {
      refetchNotifikationerCount();
      refetchNotifications();
    }
  }, [refetchNotifications, refetchNotifikationerCount]);
}

const NotificationSettingsOverview = () => {
  
  useRefetchNotificationsOnUnmount();
  const { notifikationsIndstillinger, isLoading } = useNotificationSettings();
  const noIndstillinger = notifikationsIndstillinger.length <= 0;

  return (
    <Loading timeout={100} isLoading={isLoading} spinnerClasses={"padding-top-l"}>
      <div>
        {noIndstillinger &&
          <div className="d-flex justify-content-center">
            <p className="subtle text-center">{Localizer.notifikationer_IngenNotifikationIndstillinger()}</p>
          </div>
        }
        {notifikationsIndstillinger.map(x => (
          <div key={x.eventType}>
            <NotificationSettingListItem settingModel={x}/>
            <hr />
          </div>
        ))}
      </div>
    </Loading>
  );
}

export default NotificationSettingsOverview;
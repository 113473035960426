import CookieConsentBanner from "core/components/cookies/cookieConsentBanner";
import React from "react";
import { PropsWithChildren } from "react";

type BodyContainerProps = {
  hideCookieBanner?: boolean;
};

/**
 * Main Body-container for the app
 * @param hideCookieBanner Set to "true" if never wanting to show cookie-banner. Default: "false".
 */
const BodyContainer = ({ 
  children, 
  hideCookieBanner = false 
}: PropsWithChildren<BodyContainerProps>) => {

  return (
    <React.Fragment>
      {!hideCookieBanner && <CookieConsentBanner/> }
      <div className={"body-container"}>
        {children}
      </div>
    </React.Fragment>
  );
}

export default BodyContainer;

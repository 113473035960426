import {Localizer} from "../../../infrastructure/localization/localizer";
import './modalSubmitMessage.scss'
import React, {useEffect} from "react";

export enum ModalAcceptType {default = "default", primary = "primary", danger = "danger", success = "success"}

export interface ModalSubmitMessageProps {
    title: string;
    modalAcceptType: ModalAcceptType;
    description?: JSX.Element;
    cancelButtonText?: string;
    acceptButtonText?: string;
    modalId: string;
    overrideModalFooter?: React.ReactNode;
    defaultOpen?: boolean;

    acceptAction?: () => void;
    cancelAction?: () => void;
}


export function ModalSubmitMessage(props: ModalSubmitMessageProps) {

    useEffect(() => {
        if (props.defaultOpen === true){
            $(`#${props.modalId}`).modal('show');
        }
    }, [props.defaultOpen, props.modalId])

    return (
        <>
            <div className="modal modal-message" id={props.modalId} role="dialog"
                 aria-labelledby="mySmallModalLabel">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3>
                                {props.title}
                            </h3>
                        </div>
                        {props.description !== undefined &&
                        <div className="modal-body">
                            {props.description}
                        </div>
                        }
                        {!props.overrideModalFooter &&
                            <div className={`modal-footer ${props.description !== undefined ? "border-top" : ""}`}>
                                <button type="button" className="btn btn-default " data-dismiss="modal"
                                        onClick={props.cancelAction}>{props.cancelButtonText ?? Localizer.global_cancel()}</button>
                                <button type="button" className={`btn btn-${ModalAcceptType[props.modalAcceptType]}`}
                                        data-dismiss="modal"
                                        onClick={props.acceptAction}>{props.acceptButtonText ?? Localizer.global_accept()}</button>
                            </div>
                        }
                        {props.overrideModalFooter &&
                            props.overrideModalFooter
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from "../../../app/store";

export enum SlideInStateEnum {
    Opened,
    ActionOngoing,
    Closing,
    LoadingContent
}

export class SlideInStateModel {
    public state: SlideInStateEnum;
    public slideInId: string;
}

export interface SlideInState {
    slideInState : Array<SlideInStateModel>
}

const initialState: SlideInState = {
    slideInState: []
};

export const slideInSlice = createSlice({
    name: 'slideIn',
    initialState,
    reducers: {
        setSlideinState: (state, action: PayloadAction<SlideInStateModel>) => {
            let modal = state.slideInState.find(x => x.slideInId == action.payload.slideInId);
            if (modal){
                state.slideInState[state.slideInState.indexOf(modal)] = action.payload;
            }
            else {
                state.slideInState.push({state: action.payload.state, slideInId : action.payload.slideInId})
            }
        },
        removeSlideinFromState: (state, action: PayloadAction<string>) => {
            let slideInIndex = state.slideInState.findIndex(x => x.slideInId == action.payload);

            if(slideInIndex > -1)
                state.slideInState.splice(slideInIndex, 1);

        }
    }
});

export const {setSlideinState, removeSlideinFromState} = slideInSlice.actions;
export const slideInReducer = (state: RootState) => state.slideInReducer;
export default slideInSlice.reducer;

import {forwardRef, MutableRefObject, Ref, useEffect, useState} from "react";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {ReactFileUploader, ReactFileUploaderFile, ReactFileUploaderOptions} from "./reactFileUploader";
import {FileArchiveEnum} from "core/sharedmodels/fileMetaData/fileArchiveEnum";
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import {FileLocationEnum} from "../../sharedmodels/file/fileLocationEnum";
import {AllowedFileTypes, GetAllowedFileTypes} from "./allowedFileTypes";
import useForwardedRef from "core/hooks/useForwardedRef";
import {FileMetadata} from "core/sharedmodels/fileMetaData/fileMetaData";
import classNames from "classnames";
import {EmblaIcons, EmblaIconsType} from "../../emblaIcons";
import { Loading } from "../loading/loading";

type FileUploaderProps = {
    filesUploadedCallback: (result: ReactFileUploaderFile[]) => void;
    fileType: FileArchiveEnum;
    fileLocation: FileLocationEnum;
    overrideFileTypes?: string[];
    overrideTekst?: string;
    additionalLabelClasses?: string;
    hideText?: boolean;
    defaultUploadedFileMetadatas?: FileMetadata[];
    overrideIcon?: EmblaIconsType;
    skipAutomaticUpload?: boolean;
}

export const FileUploader = forwardRef<ReactFileUploader,FileUploaderProps>(({
    hideText = false,
    skipAutomaticUpload = false,
    ...props
}: FileUploaderProps, forwardedRef: Ref<ReactFileUploader>) => {

    const selectorId = "file-uploader-input";
    const selectorIdString = `#${selectorId}`;
    const fileuploader = useForwardedRef<ReactFileUploader>(forwardedRef);

    const [isUploading, setIsUploading] = useState(false);

    useEffect(() => {
        if (!fileuploader.current) {
            const options: ReactFileUploaderOptions = {
                filesUploadedCallback: props.filesUploadedCallback,
                filesAreUploadingCallback: setIsUploading,
                fileType: props.fileType,
                shouldSkipUploadFile: skipAutomaticUpload,
            };

            const defaultUploadedFiles = props.defaultUploadedFileMetadatas?.map(fm => ({ fileMetadata: fm, fileName: fm.fileName } as ReactFileUploaderFile));
            (fileuploader as MutableRefObject<ReactFileUploader>).current = new ReactFileUploader(selectorIdString, options, props.fileLocation, defaultUploadedFiles);
        }
    }, [fileuploader, props.defaultUploadedFileMetadatas, props.fileLocation, props.fileType, props.filesUploadedCallback, selectorIdString, skipAutomaticUpload])

    return (
        <>
            <input type="file" id={selectorId} className={classNames("inputfile subtle")} accept={props.overrideFileTypes ? GetAllowedFileTypes(props.overrideFileTypes) : GetAllowedFileTypes(AllowedFileTypes)}/>
            <label htmlFor={selectorId} className={props.additionalLabelClasses}>
                {!hideText &&
                    <div className="text">
                        {props.overrideTekst ?? Localizer.global_vaelgFil()}
                    </div>
                }
                <div className={classNames("icon", hideText && "border-left-0")}>
                    <Loading isLoading={isUploading} spinnerSize="extra-medium-small" hideContentOnPreload={false} >
                        <EmblaIcon iconName={props.overrideIcon ?? EmblaIcons.Plus} />
                    </Loading>
                </div>
            </label>
        </>
    )
});


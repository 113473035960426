import useLogbogApi from "core/hooks/useLogbogApi";
import useNotificationCount from "./useNotificationCount";
import { useNotifikationContext } from "../context/notifikationContextProvider";

const useNotificationMutations = () => {
  const { logbogNotifikationApi } = useLogbogApi();

  const { setIsSeen, setIsSeenOnAll, setIsSeenStatusOnIds } = useNotifikationContext();
  const { refetchNotifikationerCount } = useNotificationCount(false);

  const setNotificationIsSeen = async (notificationId: string) => {
    const result = await logbogNotifikationApi.setNotificationIsSeen(notificationId);

    if(result) {
      await refetchNotifikationerCount();
      setIsSeen(notificationId);
    }
    return result;
  }

  const markAllUserRoleNotificationsAsSeen = async () => {
    const result = await logbogNotifikationApi.markAllUserRoleNotificationsAsSeen();

    if(result) {
      await refetchNotifikationerCount();
      setIsSeenOnAll();
    }
    return result;
  }

    const setIsSeenStatusOnNotificationIds = async (notificationIds : string[], markAsSeen : boolean) => {
        const result = await logbogNotifikationApi.markSeenStatus(notificationIds, markAsSeen)

        if(result) {
            await refetchNotifikationerCount();
            setIsSeenStatusOnIds(notificationIds, markAsSeen);
        }
        return result;
    }

  return {
    setNotificationIsSeen,
    markAllUserRoleNotificationsAsSeen,
    setIsSeenStatusOnNotificationIds
  }
}

export default useNotificationMutations;

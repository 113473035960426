import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {EvalueringType} from "../../../core/sharedmodels/evaluering/evalueringType";
import {KompetenceEvalueringModel} from "../../../core/sharedmodels/evaluering/kompetenceEvalueringModel";
import {KompetenceEvalueringCreateRequestModel} from "./kompetenceEvalueringCreateRequestModel";
import {KompetenceEvalueringResponseModel} from "./kompetenceEvalueringResponseModel";
import {
    LaegekompetenceEvalueringEditRequestModel,
    LogbogEvalueringSvarInputModel
} from "./laegekompetenceEvalueringEditRequestModel";
import {KompetenceSvarModel} from "../../../core/sharedmodels/evaluering/KompetencesvarModel";
import {KompetenceEvalueringsskemaResponseModel} from "./kompetenceEvalueringsskemaResponseModel";
import {KompetenceEvalueringsskemaModel} from "../../../core/sharedmodels/evaluering/kompetenceEvalueringsskemaModel";
import {ObjectToQueryString} from "../objectToQueryString";
import {KompetenceEvalueringsskemaRequestModel} from "./kompetenceEvalueringsskemaRequestModel";
import {DeleteEvalueringSvarModel} from "./deleteEvalueringSvarModel";
import IdAndModelsContainer from "../../../core/sharedmodels/containers/IdAndModelsContainer";

export class LogbogEvalueringApi {
    private httpClientService: HttpClientService;

    private evalueringBaseUrl = () => config.logbogApiUrl + "api/Evaluering/";
    private kompetenceEvalueringBaseUrl = () => config.logbogApiUrl + "api/KompetenceEvaluering/";
    private kompetenceEvalueringRemoveSvarUrl = () => this.evalueringBaseUrl() + "removeAnswer/";
    private kompetenceEvalueringEditUrl = () => this.evalueringBaseUrl() + "edit/";
    private kompetenceEvalueringPatchSvarUrl = () => this.evalueringBaseUrl() + "patchAnswer/";
    private kompetencerEvalueringsskemaerUrl = () => this.evalueringBaseUrl() + "skemaer/";
    private getAllKompetenceEvalueringerByBrugerId = () => this.kompetenceEvalueringBaseUrl() + "kompetencer/all/";

    constructor(httpClientService: HttpClientService) {
        this.httpClientService = httpClientService;
    }

    async createKompetenceEvaluering(brugerMaalbeskrivelse: string, kompetenceEvalueringsskemaId: string, isPrivate: boolean) : Promise<string>{
        return await this.postKompetenceEvaluering(kompetenceEvalueringsskemaId, EvalueringType.Kompetence, brugerMaalbeskrivelse, isPrivate);
    }

    async getKompetenceEvaluering(kompetenceEvalueringId: string) : Promise<KompetenceEvalueringModel>{
        const url = this.kompetenceEvalueringBaseUrl() + kompetenceEvalueringId;
        const result = await this.httpClientService.Get<ApiResponse<KompetenceEvalueringResponseModel>>(url);
        return KompetenceEvalueringModel.FromResponseModel(result.data);
    }

    async getKompetenceEvalueringsskemaer(kompetenceId: string, laegeKompetenceId: string): Promise<KompetenceEvalueringsskemaModel[]> {
        const requestModel = new KompetenceEvalueringsskemaRequestModel(kompetenceId, laegeKompetenceId);
        const url = this.kompetencerEvalueringsskemaerUrl() + ObjectToQueryString(requestModel);
        const result = await this.httpClientService.Get<ApiResponse<KompetenceEvalueringsskemaResponseModel[]>>(url);
        return result?.data?.map(x => {
            return KompetenceEvalueringsskemaModel.FromResponseModel(x.titel, x.kompetenceEvalueringId, x.kompetenceEvalueringer.map(y => KompetenceEvalueringModel.FromResponseModel(y)), x.aktiv, x.privat, x.laegerKanOprette);
        })
    }

    private async postKompetenceEvaluering(kompetenceEvalueringsskemaId: string, evalueringstype: EvalueringType, brugermaalbeskrivelseId: string, isPrivate: boolean): Promise<string> {
        const requestModel = new KompetenceEvalueringCreateRequestModel(kompetenceEvalueringsskemaId, evalueringstype, brugermaalbeskrivelseId, isPrivate);
        const url = this.evalueringBaseUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, KompetenceEvalueringCreateRequestModel>(url, requestModel);
        return result.data;
    }

    async patchlaegeKompetenceEvalueringSvar(laegekompetenceEvalueringId: string, svar: KompetenceSvarModel, antalSvarPaaSpoergsmaal: number, laegerKanBesvare: boolean): Promise<string> {
        const requestModel = new LogbogEvalueringSvarInputModel(svar, antalSvarPaaSpoergsmaal, laegerKanBesvare);
        const url = this.kompetenceEvalueringPatchSvarUrl() + laegekompetenceEvalueringId;
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogEvalueringSvarInputModel>(url, requestModel);
        return result.data;
    }

    async patchlaegeKompetenceEvaluering(laegekompetenceEvalueringId: string, indsendt: boolean, svar: KompetenceSvarModel[]): Promise<string> {
        const requestModel = new LaegekompetenceEvalueringEditRequestModel(laegekompetenceEvalueringId, indsendt, svar);
        const url = this.kompetenceEvalueringEditUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, LaegekompetenceEvalueringEditRequestModel>(url, requestModel);
        return result.data;
    }

    async getEvalueringsskema(id: string): Promise<KompetenceEvalueringModel> {
        const url = this.evalueringBaseUrl() + id;
        const result = await this.httpClientService.Get<ApiResponse<KompetenceEvalueringResponseModel>>(url);
        return KompetenceEvalueringModel.FromResponseModel(result.data);
    }

    async deleteKompetenceEvaluering(kompetenceEvalueringId: string): Promise<string> {
        const url = this.evalueringBaseUrl() + kompetenceEvalueringId;
        const result = await this.httpClientService.Delete<ApiResponse<string>>(url);
        return result.data;
    }

    async removeKompetenceEvalueringSvar(kompetenceEvalueringId: string, svarmulighedId: string, spoergsmaalId: string, laegerKanBesvare: boolean): Promise<string> {
        const deleteModel = new DeleteEvalueringSvarModel(kompetenceEvalueringId, svarmulighedId, spoergsmaalId, laegerKanBesvare);
        const url = this.kompetenceEvalueringRemoveSvarUrl() + ObjectToQueryString(deleteModel);
        const result = await this.httpClientService.Delete<ApiResponse<string>>(url);
        return result.data;
    }
    async getAllKompetenceEvalueringerForPdf(brugerId: string): Promise<IdAndModelsContainer<KompetenceEvalueringModel>[]> {
        const url = this.getAllKompetenceEvalueringerByBrugerId() + brugerId;
        const result = await this.httpClientService.Get<ApiResponse<IdAndModelsContainer<KompetenceEvalueringResponseModel>[]>>(url);

        return result.data.map(c => ({
            id: c.id,
            models: c.models.map(x => KompetenceEvalueringModel.FromResponseModel(x))
        }));
    }
}

import {RolleEnum} from "../../../infrastructure/enums/userRole";
import './userTitleAndSubtitle.scss';

export interface IUserTitleAndSubtitle {
    Title: string;
    Subtitle: string;
}

export function UserTitleAndSubtitle(props: IUserTitleAndSubtitle) {
    return (
        <>
            <div className="user-name-and-role">
                <h5 className='title'>{props.Title}</h5>
                <p className='subtitle'>{props.Subtitle}</p>
            </div>
        </>
    )
}

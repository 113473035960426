import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {LogbogEvalueringResponseModel} from "./logbogEvalueringResponseModel";
import {EvalueringModel} from "../../../core/sharedmodels/evaluering/evalueringModel";
import {ForloebEvalueringEditRequestModel} from "./forloebEvalueringEditRequestModel";
import {SvarModel} from "../../../core/sharedmodels/evaluering/svarModel";
import {ForloebEvalueringModel} from "../../../core/sharedmodels/evaluering/forloebEvalueringModel";
import {UserSimpleModel} from "../../../core/sharedmodels/user/userSimpleModel";
import {LogbogUserSimpleResponseModel} from "../logbogUser/logbogUserSimpleResponseModel";
import {EvalueringPdfModel} from "../../../core/sharedmodels/evaluering/evalueringPdfModel";
import {LogbogEvalueringPdfResponseModel} from "./logbogEvalueringPdfResponseModel";

export class LogbogForloebEvalueringApi {
    private httpClientService: HttpClientService;

    private forloebEvalueringBaseUrl = () => config.logbogApiUrl + "api/forloebevaluering/";
    private forloebEvalueringExistsUrl = () => this.forloebEvalueringBaseUrl() + "exists/";
    private editEvalueringSvar = () => this.forloebEvalueringBaseUrl() + "editEvalueringSvar";
    private getForloebEvalueringForPdfUrl = () => this.forloebEvalueringBaseUrl() + "pdf/";
    private getUddannelsesansvarligeByForloebIdUrl = () => this.forloebEvalueringBaseUrl() + "getUddannelsesansvarligeByForloebId/";
    private getForloebEvalueringIdByForloebIdUrl = () => this.forloebEvalueringBaseUrl() + "forloeEvalueringId/";


    constructor(httpClientService: HttpClientService) {
        this.httpClientService = httpClientService;
    }

    async getOrCreateForloebEvaluering(forloebId: string) : Promise<ForloebEvalueringModel>{
        let uddEvaluering = await this.getForloebEvaluering(forloebId);
        if(!uddEvaluering.forloebEvalueringId){
            uddEvaluering.forloebEvalueringId = await this.createForloebEvaluering(forloebId);
        }

        return ForloebEvalueringModel.FromResponseModel(uddEvaluering.forloebEvalueringId, uddEvaluering.indsendt, uddEvaluering);
    }
    private async getForloebEvaluering(forloebId: string): Promise<EvalueringModel> {
        const url = this.forloebEvalueringBaseUrl() + forloebId;
        const result = await this.httpClientService.Get<ApiResponse<LogbogEvalueringResponseModel>>(url);
        return EvalueringModel.FromResponseModel(result.data);
    }

    async forloebEvalueringExists(forloebId: string): Promise<boolean> {
        const url = this.forloebEvalueringExistsUrl() + forloebId;
        const result = await this.httpClientService.Get<ApiResponse<boolean>>(url);
        return result.data;
    }

    async getForloebEvalueringForPdf(forloebEvalueringId: string): Promise<EvalueringPdfModel> {
        const url = this.getForloebEvalueringForPdfUrl() + forloebEvalueringId;
        const result = await this.httpClientService.Get<ApiResponse<LogbogEvalueringPdfResponseModel>>(url);

        return EvalueringPdfModel.FromResponseModel(result.data);
    }
    
    async createForloebEvaluering(forloebId: string): Promise<string> {
        const result = await this.httpClientService.Post<ApiResponse<string>, string>(this.forloebEvalueringBaseUrl(), forloebId);
        return result.data;
    }
    
    async patchForloebEvalueringSvar(forloebEvalueringId: string, indsendt: boolean, svar: SvarModel[], hovedVejleder?: string, uddannelsesansvarlige?: UserSimpleModel[]): Promise<string> {
        const requestModel = new ForloebEvalueringEditRequestModel(forloebEvalueringId, indsendt, svar, hovedVejleder, uddannelsesansvarlige);
        const url = this.editEvalueringSvar();
        const result = await this.httpClientService.Post<ApiResponse<string>, ForloebEvalueringEditRequestModel>(url, requestModel);
        return result.data;
    }
    
    async getUddannelsesansvarligeByForloebId(forloebId: string): Promise<UserSimpleModel[]> {
        const url = this.getUddannelsesansvarligeByForloebIdUrl() + forloebId;
        const result = await this.httpClientService.Get<ApiResponse<LogbogUserSimpleResponseModel[]>>(url);
        
        return result.data.map(bruger => {
            return UserSimpleModel.FromLogbogResponseModel(bruger)
        }).sort((a, b) => a.navn.localeCompare(b.navn));
    }

    async getForloebEvalueringIdByForloebId(forloebId: string): Promise<string> {
        const url = this.getForloebEvalueringIdByForloebIdUrl() + forloebId;
        const result = await this.httpClientService.Get<ApiResponse<string>>(url);

        return result.data;
    }
}

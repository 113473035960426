import {FileMetadata} from "../../../core/sharedmodels/fileMetaData/fileMetaData";
import {CreateMailInputModel} from "../../../core/components/mail/createMailInputModel";
import {UserWithForloebAndStillingId} from "../../../core/sharedmodels/email/UserWithForloebAndStillingId";
import { EmailTypeEnum } from "core/sharedmodels/email/emailTypeEnum";

export class LogbogEmailRequestModel {
    constructor(inputModel : CreateMailInputModel, userWithForloebAndStilling : Array<UserWithForloebAndStillingId>, emailTypeEnum: EmailTypeEnum) {
        this.userWithForloebAndStilling = userWithForloebAndStilling;
        this.emailTypeEnum = emailTypeEnum;
        this.bodyMarkdown = inputModel.message;
        this.subjectText = inputModel.subject;
        this.FileMetadata = inputModel.fileMetadata;
    }

    public userWithForloebAndStilling: UserWithForloebAndStillingId[];
    public subjectText: string;
    public emailTypeEnum: EmailTypeEnum;
    public bodyMarkdown: string;
    public FileMetadata: FileMetadata[];
}

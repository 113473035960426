import {KompetenceSvarModel} from "../../../core/sharedmodels/evaluering/KompetencesvarModel";
import {SpoergsmaalType} from "../../../core/sharedmodels/evaluering/spoergsmaalType";

export class LaegekompetenceEvalueringEditRequestModel {
    constructor(laegekompetenceEvalueringId: string, indsendt: boolean, svar: KompetenceSvarModel[]) {
        this.laegekompetenceEvalueringId = laegekompetenceEvalueringId;
        this.indsendt = indsendt;
        this.svar = svar.map((svar) => new LogbogEvalueringSvarInputModel(svar, 0, false));
    }

    laegekompetenceEvalueringId: string;
    indsendt: boolean;
    svar: LogbogEvalueringSvarInputModel[];
}

export class LogbogEvalueringSvarInputModel {
    constructor(svarModel: KompetenceSvarModel, antalSvarPaaSpoergsmaal: number, laegerKanBesvare: boolean) {
        this.id = svarModel.id;
        this.kompetenceEvalueringId = svarModel.kompetenceEvalueringId;
        this.spoergsmaalId = svarModel.spoergsmaalId;
        this.svarmulighedId = svarModel.svarmulighedId;
        this.tekstSvar = svarModel.tekstSvar;
        this.booleanSvar = svarModel.booleanSvar;
        this.nummerSvar = svarModel.nummerSvar;
        this.tekstSvar = svarModel.tekstSvar;
        this.antalSvarPaaSpoergsmaal = antalSvarPaaSpoergsmaal;
        this.svarIndex = svarModel.svarIndex;
        this.svarDate = svarModel.dateSvar ? svarModel.dateSvar?.dateToApiPostFormat() : undefined;
        this.kommentar = svarModel.kommentar;
        this.ikkeRelevant = svarModel.ikkeRelevant;
        this.spoergsmaalType = svarModel.spoergsmaalType;
        this.laegerKanBesvare = laegerKanBesvare;
    }

    id: string;
    kompetenceEvalueringId: string;
    spoergsmaalId: string;
    svarmulighedId?: string;
    tekstSvar?: string;
    nummerSvar?: number;
    antalSvarPaaSpoergsmaal: number;
    svarIndex: number;
    booleanSvar?: boolean;
    svarDate?: string;
    kommentar?: string;
    ikkeRelevant: boolean;
    laegerKanBesvare: boolean;
    spoergsmaalType?: SpoergsmaalType;
}

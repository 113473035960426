import dayjs from 'dayjs';

const toReduxIsoDateString = (date: Date) => {
    return dayjs(date).toISOString();
}

const toReduxIsoDateUtcString = (date : Date) : string => {
    return dayjs(date).utc(true).toISOString();
}

export {
    toReduxIsoDateString,
    toReduxIsoDateUtcString
}

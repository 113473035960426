export class DeleteEvalueringSvarModel {
    constructor(evalueringId: string, svarmulighedId: string, spoergsmaalId: string, laegerKanBesvare: boolean, evalueringSvarId?: string) {
        this.kompetenceEvalueringId = evalueringId;
        this.svarmulighedId = svarmulighedId;
        this.spoergsmaalId = spoergsmaalId;
        this.evalueringSvarId = evalueringSvarId;
        this.laegerKanBesvare = laegerKanBesvare;
    }

    public kompetenceEvalueringId: string;
    public svarmulighedId: string;
    public spoergsmaalId: string;
    public evalueringSvarId?: string;
    public laegerKanBesvare: boolean;
}

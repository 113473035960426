import {HttpClientService} from "../../httpClient/httpClientService";
import config from "../../../config/config";
import {ApiResponse} from "../apiResponse";
import {AccountService} from "../../account/accountService";
import {dateTimeStringFromApiToDate, nullableDateTimeStringFromApiToDate} from "index";
import {EditUserEmailModel} from "./editUserEmailModel";
import {EditUserPhoneModel} from "./editUserPhoneModel";
import {StamdataUserCreateRequestModel} from "./stamdataUserCreateRequestModel";
import {StamdataUserSimpleResponseModel} from "./stamdataUserSimpleResponseModel";
import {EditUserAddress} from "./editUserAddress";
import {EditUserPostnummer} from "./editUserPostnummer";
import UserFilterPermissions from "core/sharedmodels/userPermissions/userFilterPermissions";
import {FavoriserPersonaleModel} from "./favoriserPersonaleModel";
import {EditUserInitialsPostModel} from "./editUserInitials";
import EditUserGdprPostModel from "./editUserGdprPostModel";
import {userListExportRequestModel} from "../logbogUser/logbogUserListExportRequest";
import {BrugerListFilter} from "../../../pages/bruger/brugerListFilter";
import {UserSendEmailResponseModel} from "./UserSendEmailResponseModel";
import {UserSimpleModel} from "../../../core/sharedmodels/user/userSimpleModel";


export class StamdataUserApi {
    constructor(httpClientService: HttpClientService, accountService: AccountService) {
        this.httpClientService = httpClientService;
        this.accountService = accountService;
    }

    private httpClientService: HttpClientService;
    private accountService: AccountService;

    private userBaseUrl = () =>  config.stamdataApiUrl + "api/userinfo/";
    private editUserEmailUrl = () => this.userBaseUrl() + "editUserEmail/";
    private editUserProfileImageUrl = () => this.userBaseUrl() + "profileImage/";
    private editUserPhoneUrl = () => this.userBaseUrl() + "editUserPhone/";
    private editUserWorkEmailUrl = () => this.userBaseUrl() + "editUserWorkEmail/";
    private editUserWorkPhoneUrl = () => this.userBaseUrl() + "editUserWorkPhone/";
    private editUserAddressUrl = () => this.userBaseUrl() + "editUserAddress/";
    private editUserPostnummerUrl = () => this.userBaseUrl() + "editUserPostnummer/";
    private editInitialsUrl = () => this.userBaseUrl() + "editUserInitials/";
    private editGdprLastFetchAtUrl = () => this.userBaseUrl() + "gdpr/fetched";
    private setValidateVejledereUrl = () => this.userBaseUrl() + "setValidateVejledere/";
    private getValidatedVejledereDateUrl = () => this.userBaseUrl() + "validateDate/";
    private getUserByCprUrl = () => this.userBaseUrl() + "byCpr/";
    private getUserByAuthIdUrl = () => this.userBaseUrl() + "byAuthId/";
    private getUserFilterPermissionUrl = () => this.userBaseUrl() + "permissions/filters";
    public getAllUserUrl = () => this.userBaseUrl() + "getAllUsersUrl/";
    public getAllUsersUrlNoPaginationUrl = () => this.userBaseUrl() + "getAllUsersNoPagination/";
    public favoriserVejlederUrl = () => this.userBaseUrl() + "favoriserPersonale/";
    public delkursusledereBySpecialeUrl = () => this.userBaseUrl() + "delkursusledereBySpeciale/";

    async editUserEmail(userId: string, email: string): Promise<ApiResponse<boolean>> {
        let postModel = new EditUserEmailModel(userId, email);
        return await this.httpClientService.Post<ApiResponse<boolean>, EditUserEmailModel>(this.editUserEmailUrl(), postModel, true);
    }

    async getAllUsersUrlNoPagination(filter: BrugerListFilter, search?: string, order?: string): Promise<ApiResponse<UserSendEmailResponseModel[]>> {
        let postModel = new userListExportRequestModel(filter, search,order);
        return await this.httpClientService.Post<ApiResponse<UserSendEmailResponseModel[]>, userListExportRequestModel>(this.getAllUsersUrlNoPaginationUrl(), postModel, true);
    }

    async editUserPhone(userId: string, phoneNumber: string): Promise<ApiResponse<boolean>> {
        let postModel = new EditUserPhoneModel(userId, phoneNumber);
        return await this.httpClientService.Post<ApiResponse<boolean>, EditUserPhoneModel>(this.editUserPhoneUrl(), postModel, true);
    }

    async editUserWorkEmail(userId: string, email: string): Promise<ApiResponse<boolean>> {
        let postModel = new EditUserEmailModel(userId, email);
        return await this.httpClientService.Post<ApiResponse<boolean>, EditUserEmailModel>(this.editUserWorkEmailUrl(), postModel, true);
    }

    async editUserWorkPhone(userId: string, phoneNumber: string): Promise<ApiResponse<boolean>> {
        let postModel = new EditUserPhoneModel(userId, phoneNumber);
        return await this.httpClientService.Post<ApiResponse<boolean>, EditUserPhoneModel>(this.editUserWorkPhoneUrl(), postModel, true);
    }

    async editUserAddress(userId: string, address: string) {
        let postModel = new EditUserAddress(userId, address);
        return await this.httpClientService.Post<ApiResponse<boolean>, EditUserAddress>(this.editUserAddressUrl(), postModel, true);
    }

    async editPostnummer(userId: string, zipCode: string) {
        let postModel = new EditUserPostnummer(userId, zipCode);
        return await this.httpClientService.Post<ApiResponse<boolean>, EditUserPostnummer>(this.editUserPostnummerUrl(), postModel, true);
    }

    async editInitials(userId: string, initialer: string) {
        const postModel: EditUserInitialsPostModel = {userId, initialer}
        return await this.httpClientService.Post<ApiResponse<boolean>, EditUserInitialsPostModel>(this.editInitialsUrl(), postModel, true);
    }

    async updateGdprFetchedAt (userId: string) : Promise<Date | undefined> {
        const result = await this.httpClientService.Post<ApiResponse<string | undefined>, EditUserGdprPostModel>(this.editGdprLastFetchAtUrl(), {userId});
        return nullableDateTimeStringFromApiToDate(result.data);
    }

    async setVejledereValidated(userId: string): Promise<ApiResponse<boolean>> {
        return await this.httpClientService.Post<ApiResponse<boolean>, string>(this.setValidateVejledereUrl(), userId, true);
    }

    async getValidatedDate(): Promise<Date | null> {
        const validatedDate = (await this.httpClientService.Get<ApiResponse<string>>(this.getValidatedVejledereDateUrl())).data;
        return validatedDate ? dateTimeStringFromApiToDate(validatedDate) : null;
    }

    async getProfileImageId(): Promise<string | null> {
        const result = await this.httpClientService.Get<ApiResponse<string | null>>(this.editUserProfileImageUrl());
        return result.data;
    }

    async updateSamtykke(approve: boolean): Promise<string | null> {
        const validateUrl = this.userBaseUrl() + "samtykke";
        return (await this.httpClientService.Post<ApiResponse<string>, boolean>(validateUrl, approve)).data;
    }

    async getUserByCpr(cpr: string): Promise<StamdataUserSimpleResponseModel> {
        const cprUrl = this.getUserByCprUrl() + cpr;
        let result = await this.httpClientService.Get<ApiResponse<StamdataUserSimpleResponseModel>>(cprUrl);
        return result.data;
    }

    async getUserByAuthId(authId: string): Promise<StamdataUserSimpleResponseModel> {
        const authIdUrl = this.getUserByAuthIdUrl() + authId;
        let result = await this.httpClientService.Get<ApiResponse<StamdataUserSimpleResponseModel>>(authIdUrl);
        return result.data;
    }

    async createUser(userRequest: StamdataUserCreateRequestModel): Promise<string> {
        const cprUrl = this.userBaseUrl();
        let result = await this.httpClientService.Post<ApiResponse<string>, StamdataUserCreateRequestModel>(cprUrl, userRequest);
        return result.data;
    }

    async getUserFilterPermissions(): Promise<UserFilterPermissions> {
        const url = this.getUserFilterPermissionUrl();
        const result = await this.httpClientService.Get<ApiResponse<UserFilterPermissions>>(url);
        return result.data;
    }

    async updateFavoritPersonale(favoritPersonaleUserId: string, uddannelseslaegeId: string, forloebId: string, isFavorite: boolean): Promise<string> {
        let postModel: FavoriserPersonaleModel = {
            favoritPersonaleUserId: favoritPersonaleUserId,
            forloebId: forloebId,
            uddannelseslaegeId: uddannelseslaegeId,
            isFavorite: isFavorite
        }
        const result = await this.httpClientService.Post<ApiResponse<string>, FavoriserPersonaleModel>(this.favoriserVejlederUrl(), postModel)
        return result.data;
    }

    async getDelkursuslederePaaSpeciale(specialeId: string): Promise<UserSimpleModel[]> {
        const url = this.delkursusledereBySpecialeUrl() + specialeId;
        const result = await this.httpClientService.Get<ApiResponse<StamdataUserSimpleResponseModel[]>>(url);
        return result.data.map(bruger => {
            return UserSimpleModel.FromStamdataResponseModel(bruger)
        }).sortBy(x => x.navn);
    }
}

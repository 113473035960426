import { nullableDateTimeStringFromApiToDate } from "index";

type StillingDisplayInfoResponseModel = {
    stillingId: string;
    stillingNr: string;
    uddannelsessted: string;
    activeFrom?: string;
    activeTo?: string;
}

type StillingDisplayInfo = {
    stillingId: string;
    stillingNr: string;
    uddannelsessted: string;
    activeFrom?: Date;
    activeTo?: Date;
}

const BuildStillingDisplayInfo = (model: StillingDisplayInfoResponseModel) : StillingDisplayInfo => {
    return {
        stillingId: model.stillingId,
        stillingNr: model.stillingNr,
        uddannelsessted: model.uddannelsessted,
        activeFrom: nullableDateTimeStringFromApiToDate(model.activeFrom),
        activeTo: nullableDateTimeStringFromApiToDate(model.activeTo),
    }
}

const getStillingDurationDisplay = (info: StillingDisplayInfo) => {
    var from = info.activeFrom?.dateWithoutTimeFormat(false);
    var to = info.activeTo?.dateWithoutTimeFormat(false);
    return to ? `${from} - ${to}` : from;
}

export default StillingDisplayInfo;

export type {
    StillingDisplayInfoResponseModel
}

export {
    BuildStillingDisplayInfo,
    getStillingDurationDisplay
}

import {DataTableHeader} from "./DataTableHeader";
import {DataTableHeaderAction} from "./DataTableHeaderAction";
import {TableOutputInfo} from "./useUpdateTableOutputInfo";

export interface TableProps {
    tableIdentifier: string;
    tableHeader?: string;
    columnHeaders: DataTableHeader[];
    columnDefaultOrder?: Array<(number | string)> | Array<Array<(number | string)>> | undefined;
    renderTableFilters?: () => JSX.Element;
    renderHeaderActions?: {renderMethod : () => {}, actions: DataTableHeaderAction[]};
    renderTableBody: () => JSX.Element;

    /** Enables select on the table - use in combination with objectSelectedCallback and initialSelectedRows  */
    useSelectActions?: boolean;
    objectSelectedCallback?: (ids: string[]) => void;
    initialSelectedRows?: string[];
    renderTableSelectActions?: () => JSX.Element;
    hideSelectActionsRow?: boolean;
    actionsSelectedTextSingle?: string;
    actionsSelectedTextPlural?: string;

    showLengthChange?: boolean;
    responsive?: boolean;
    showSearching?: boolean;
    showFilter?: boolean;
    showPaging?: boolean;
    hideHeaderBodies? : boolean;
}

export interface TableAsyncProps {
    filtersInModal?: "auto" | "always" | "never";
    tableIdentifier: string;
    tableHeader: string;
    searchPlaceholder?: string;
    renderHeaderActions?: { renderMethod: () => {}, actions: DataTableHeaderAction[]};
    renderTableFilters?: () => {};
    renderTableActions?: () => {};
    renderTableHead: () => {};
    asyncUrl : string;
    columns : DataTables.ColumnSettings[];
    getCustomPostData? : () => any;
    rowClickFunction?: (data : any) => void;
    onDraw?: () => void;
    rowCallback?: ((row: Node, data: (any[] | object), index: number) => void) | undefined;
    showFilteredRecordsInfo?: boolean;
    isResponsive?: boolean;
    initOrder?: Array<(number | string)>;
    initCompleteCallback?: () => void;
    clearFilterFuntion?: () => void;
    filteredCountCallback?: (response: TableOutputInfo) => void;
    primaryModalFooterButton?: PrimaryModalFooterButtonType;
}

export const tableTitleId = (tableId : string) => tableId + "-title";
export const tableFilterId = (tableId : string) => tableId + "-filter";
export const tableActionsId = (tableId : string) => tableId + "-actions";
export const tableWrapperId = (tableId : string) => tableId + "_wrapper";

export type PrimaryModalFooterButtonType = {
    name: string;
    onClick: () => void;
}

import {LogbogUserVejledertilknytningResponseModel} from "../../../services/api/logbogUser/logbogUserInfoResponse";
import {dateTimeStringFromApiToDate} from "../../../index";

export class VejledertilknytningerModel {
    public static FromResponseModel(responseModel: LogbogUserVejledertilknytningResponseModel): VejledertilknytningerModel {
        const model = new VejledertilknytningerModel();
        model.userId = responseModel.userId;
        model.vejledertilknytningId = responseModel.vejledertilknytningId;
        model.sygehus = responseModel.sygehus;
        model.afdeling = responseModel.afdeling;
        model.laegeinstitution = responseModel.laegeinstitution;
        model.laeger = responseModel.laeger;
        model.alleLaeger = responseModel.alleLaeger;
        model.slutDatoUtc = responseModel.slutDatoUtc ? dateTimeStringFromApiToDate(responseModel.slutDatoUtc).dateWithoutTimeFormat(false) : undefined;
        return model;
    }

    userId: string;
    vejledertilknytningId: string;
    sygehus: string;
    afdeling: string[];
    laegeinstitution: string;
    laeger: string[];
    alleLaeger: boolean;
    slutDatoUtc?: string;
}

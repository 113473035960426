import React, {useRef, useState} from "react";
import {useRouteMatch} from "react-router-dom";
import {AccountService} from "../../services/account/accountService";
import {HttpClientService} from "../../services/httpClient/httpClientService";
import {mobileMaxWidthQuery} from "../../core/layout/responsive";
import {useMediaQuery} from "react-responsive";
import {Localizer} from "../../infrastructure/localization/localizer";
import useEffectAsync from "../../infrastructure/effect";
import {Loading} from "../../core/components/loading/loading";
import {LaegekursusGruppeCard} from "./components/laegekursusGruppeCard";
import {Tab, Tabs} from "../../core/components/Tabs/Tabs";
import {LaegekursusGruppeModel} from "../../services/api/laegekursus/laegekursusGruppeModel";
import {GetLaegekursusGruppeTranslation} from "../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";
import {LogbogLaegekursusGruppeApi} from "../../services/api/laegekursusGruppe/logbogLaegekursusGruppeApi";
import {ForloebHeader} from "../../core/components/headerCards/forloebHeader";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {forloebReducer, ForloebSliceState} from "../../core/forloebSlice";
import {InitForloebState} from "../forloeb/forloebApi";
import {Alert} from "../../core/components/alert/alert";
import {AlertType} from "../../core/components/alert/alertType";
import {CheckboxSwitch} from "../../core/components/checkbox/checkboxSwitch";
import {DeletedLaegekurserCard} from "./components/deletedKurserComponents/deletedLaegekurserCard";
import {LaegekursusModel} from "../../services/api/laegekursus/laegekursusModel";
import {LogbogLaegekursusApi} from "../../services/api/laegekursus/logbogLaegekursusApi";
import ForloebSpecificPageWrapper from "core/componentsPage/forloeb/forloebSpecificPageWrapper";

export function LaegekurserPage() {
    const routeMatch = useRouteMatch();
    const dispatch = useAppDispatch();
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);
    const userService = new AccountService();
    const _currentUser = useRef(userService.getUser());

    const [isLoading, setIsLoading] = useState(true);
    const routeParams = routeMatch.params as { id: string, brugerKursusraekkeId: string };
    const uddannelseslaegeId = routeParams.id;
    const brugerKursusraekkeId = routeParams.brugerKursusraekkeId;
    const logbogLaegekursusGruppeApi = new LogbogLaegekursusGruppeApi(new HttpClientService(), new AccountService());
    const laegekursusApi = new LogbogLaegekursusApi(new HttpClientService(), new AccountService());

    const [kursusGrupper, setKursusGrupper] = useState<LaegekursusGruppeModel[]>(new Array<LaegekursusGruppeModel>());
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const [kursusGrupperFirstHalf, setKursusGrupperFirstHalf] = useState<LaegekursusGruppeModel[]>(new Array<LaegekursusGruppeModel>());
    const [kursusGrupperSecondHalf, setKursusGrupperSecondHalf] = useState<LaegekursusGruppeModel[]>(new Array<LaegekursusGruppeModel>());
    const [showDeletedKurser, setShowDeletedKurser] = useState<boolean>(false);
    const [deletedLaegekurserForUser, setDeletedLaegekurserForUser] = useState<LaegekursusModel[]>([]);
    const [activeKursusGruppeTab, setActiveKursusGruppeTab] = useState<string>("");

    const userIsAdmin = _currentUser.current.IsAdminOrVus();

    const tabs: Tab[] = [];

    useEffectAsync(async () => {
        await InitForloebState(dispatch, forloebSliceState.forloebState, uddannelseslaegeId);
        if (forloebSliceState.forloebState.id || brugerKursusraekkeId){
            const kursusgrupper = await loadKursusGrupper();
            setActiveKursusGruppeTab(kursusgrupper[0]?.id);
        }
        setIsLoading(false);
    }, []);

    async function loadKursusGrupper() {
        let kursusGrupper;
        if (brugerKursusraekkeId)
            kursusGrupper = await logbogLaegekursusGruppeApi.getKursusGrupperByBrugerKursusraekke(brugerKursusraekkeId);
        else
            kursusGrupper = await logbogLaegekursusGruppeApi.getKursusGrupperByBrugerKursusraekke(forloebSliceState.forloebState.brugerKursusraekkeId);

        sortKurserBySortOrder(kursusGrupper);
        setKursusGrupper(kursusGrupper);

        //Dette gøres for udseende - så de to grupper kan indeles i col-sm-6
        const half = Math.ceil(kursusGrupper.length / 2);
        const firstHalf = kursusGrupper.slice(0, half)
        const secondHalf = kursusGrupper.slice(half)
        setKursusGrupperFirstHalf(firstHalf);
        setKursusGrupperSecondHalf(secondHalf);

        if (showDeletedKurser)
            await loadDeletedLaegekurser();

        return kursusGrupper;
    }

    async function loadDeletedLaegekurser() {
        const deletedLaegekurserForUser = await laegekursusApi.getDeletedKurserForUser(uddannelseslaegeId, forloebSliceState.forloebState.uddannelseId);
        setDeletedLaegekurserForUser(deletedLaegekurserForUser);
    }

    const sortKurserBySortOrder = (kursusGrupper: LaegekursusGruppeModel[]) => {
        return kursusGrupper.forEach(kg => kg.kurser.sort((a, b) => a.sortOrder > b.sortOrder ? 1 : -1));
    }

    kursusGrupper.map(kg => tabs.push({
        id: kg.id,
        title: GetLaegekursusGruppeTranslation(kg.kursusGruppeType),
        defaultActive: activeKursusGruppeTab === kg.id,
        content: <LaegekursusGruppeCard kursusGruppe={kg}
                                        kurserUpdatedCallback={() => loadKursusGrupper()}
                                        brugerMaalbeskrivelseId={forloebSliceState.forloebState.brugerMaalbeskrivelseId}/>
    }))

    async function showAndGetDeletedLaegekurser() {
        setShowDeletedKurser(!showDeletedKurser);
        await loadDeletedLaegekurser();
    }

    return (
        <Loading isLoading={isLoading} text={Localizer.kursusPage_getKurser()}>
            <ForloebSpecificPageWrapper>
                {!IsMobile &&
                    <div>
                        <ForloebHeader hideSeUddannelseAndForloebDetails={true}/>

                        <Alert alertType={AlertType.Info}
                            message={Localizer.kursusPage_laegekursusBeskedOmDobbeltKurser()}/>

                        <div className="row">
                            {kursusGrupper.length === 0 &&
                                <div className="col-sm-12">
                                    <div className="alert alert-info">
                                        {forloebSliceState.forloebState.manglerKursusOpdatering &&
                                            <>
                                                <b>{Localizer.vejlederpage_afventerKursusopdatering()}</b>
                                            </>
                                        }
                                        {!forloebSliceState.forloebState.manglerKursusOpdatering &&
                                            <>
                                                <b>{Localizer.vejlederpage_noKurser()}</b> <br/>
                                                <b> <i>{Localizer.global_kursusledere()}</i></b>
                                                {Localizer.vejlederpage_noKurserForKursusleder()} <br/>
                                                <b> <i>{Localizer.global_kursusudbydere()}</i></b>
                                                {Localizer.vejlederpage_noKurserForKursusUdbyder()}
                                            </>
                                        }
                                    </div>
                                </div>
                            }
                            <div className="col-sm-6 padding-right-s">
                                {kursusGrupperFirstHalf?.map((kursusGruppe, index) =>
                                        <div key={index}>
                                            <LaegekursusGruppeCard kursusGruppe={kursusGruppe}
                                                                kurserUpdatedCallback={() => loadKursusGrupper()}
                                                                brugerMaalbeskrivelseId={forloebSliceState.forloebState.brugerMaalbeskrivelseId}/>
                                        </div>
                                    )
                                }
                            </div>
                            <div className="col-sm-6 padding-left-s">
                                {kursusGrupperSecondHalf?.map((kursusGruppe, index) =>
                                        <div key={index}>
                                            <LaegekursusGruppeCard kursusGruppe={kursusGruppe}
                                                                kurserUpdatedCallback={() => loadKursusGrupper()}
                                                                brugerMaalbeskrivelseId={forloebSliceState.forloebState.brugerMaalbeskrivelseId}/>
                                        </div>
                                    )
                                }
                            </div>

                            {userIsAdmin &&
                                <div className="col-sm-12 margin-left-s margin-top-l">
                                    <CheckboxSwitch
                                        id={"vis-slettede-kurser-id"}
                                        label={Localizer.kursusPage_visSlettedeKurser()}
                                        onChange={() => showAndGetDeletedLaegekurser()}
                                        value={showDeletedKurser}/>
                                </div>
                            }

                            {showDeletedKurser && deletedLaegekurserForUser.length > 0 && userIsAdmin &&
                                <div className="col-sm-6 margin-top-m">
                                    <DeletedLaegekurserCard
                                        kurserUpdatedCallback={() => loadKursusGrupper()}
                                        deletedLaegekurserForUser={deletedLaegekurserForUser}
                                        brugerMaalbeskrivelseId={forloebSliceState.forloebState.brugerMaalbeskrivelseId}/>
                                </div>
                            }
                        </div>
                    </div>
                }

                {IsMobile && tabs.length > 0 &&
                <div className="top-level-row">
                    <Tabs tabs={tabs}
                          setActiveCallback={setActiveKursusGruppeTab}/>
                </div>
                }
            </ForloebSpecificPageWrapper>
        </Loading>
    );
}

import {useEffect, useRef} from 'react';

export default function useEffectAsync(effect : any, inputs : any) {
    useEffect(() => {
        effect();
    }, inputs);
}

export function useAsync(asyncFn: any, onSuccess: any) {
    useEffect(() => {
        let isActive = true;
        asyncFn().then((data:any) => {
            if (isActive) onSuccess(data);
        });
        return () => { isActive = false };
    }, [asyncFn, onSuccess]);
}

// Does not execute the effect when the component mounts, only on all subsequent changes to the dependencies
export function useEffectOnUpdate(effect: any, dependencies: any) {
    const mounted = useRef(false);
    useEffect(() => {
        if (mounted.current) effect();
        else mounted.current = true;
    }, dependencies)
}
import {LogbogSvarResponseModel} from "../../../services/api/logbogEvaluering/logbogSvarResponseModel";

export class SvarModel {
    public static FromResponseModel(svarResponseModel?: LogbogSvarResponseModel){
        if(svarResponseModel){
            let model = new SvarModel();

            model.kommentar = svarResponseModel.kommentar;
            model.ikkeRelevant = svarResponseModel.ikkeRelevant;
            model.id = svarResponseModel.id;
            model.tekstSvar = svarResponseModel.tekstSvar;
            model.spoergsmaalId = svarResponseModel.spoergsmaalId;
            model.spoergsmaalNummer = svarResponseModel.spoergsmaalNummer;
            model.svarmulighedId = svarResponseModel.svarmulighedId;
            model.svarVaerdi = svarResponseModel.svarVaerdi;
            model.forloebEvalueringId = svarResponseModel.forloebEvalueringId;

            return model;
        }

        return undefined;
    }

    id: string;
    forloebEvalueringId: string;
    spoergsmaalId: string;
    spoergsmaalNummer: number;
    svarmulighedId?: string;
    svarVaerdi?: number;
    tekstSvar?: string;
    kommentar?: string;
    ikkeRelevant: boolean;
}

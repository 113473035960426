import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {ForSelectModel} from "../../../core/sharedmodels/forSelectModel";
import {ForSelectResponseModel} from "../forSelectResponseModel";
import {SingleUddannelsesstedResponseModel} from "./singleUddannelsesstedResponseModel";

export class StamdataLaegeinstitutionApi {
    private httpClientService: HttpClientService;

    private baseUrl = () => config.stamdataApiUrl + "api/laegeinstitution/";
    public singleLaegeinstitutionRootTypeUrl = () => this.baseUrl() + "singleLaegeinstitutionRootType";

    private allSygeSelectUrl = this.baseUrl() + "sygehusForSelect/all";

    constructor(httpClientService: HttpClientService) {
        this.httpClientService = httpClientService;
    }

    async getSygehuseSelect(): Promise<ForSelectModel[]> {
        const url = this.baseUrl() + "sygehusForSelect";
        const result = await this.httpClientService.Get<ApiResponse<ForSelectResponseModel[]>>(url);
        return result.data.map(x => new ForSelectModel(x));
    }

    async getAllSygehuseSelect(): Promise<ForSelectModel[]> {
        const url = this.allSygeSelectUrl;
        const result = await this.httpClientService.Get<ApiResponse<ForSelectResponseModel[]>>(url);
        return result.data.map(x => new ForSelectModel(x));
    }

    async getPraksisSelect(): Promise<ForSelectModel[]> {
        const url = this.baseUrl() + "praksisForSelect";
        const result = await this.httpClientService.Get<ApiResponse<ForSelectResponseModel[]>>(url);
        return result.data.map(x => new ForSelectModel(x));
    }

    async GetSingleLaegeinstitutionRootType(): Promise<SingleUddannelsesstedResponseModel> {
        return (await this.httpClientService.Get<ApiResponse<SingleUddannelsesstedResponseModel>>(this.singleLaegeinstitutionRootTypeUrl())).data;
    }
}

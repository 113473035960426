import { UserManager, UserManagerSettings, WebStorageStateStore } from "oidc-client";
import config from "./config";
import { AccountService } from "../services/account/accountService";

export let userManager: UserManager;

const loadOidcConfig = () => {
    const oidcConfig: UserManagerSettings = {
        // the URL of our identity server
        authority: config.idpUrl,
        // this ID maps to the client ID in the identity client configuration
        client_id: "logbogclient",
        // URL to redirect to after login
        redirect_uri: config.baseUrl + "signin-oidc",
        response_type: "code",
        // the scopes or resources we would like access to
        scope: "required openid profile offline_access stamdataapi logbogapi",
        // URL to redirect to after logout
        post_logout_redirect_uri: config.baseUrl,
        //automatically renew the token in the background before it expires, if it is supported by the server. For this mechanism to work, the browser must have third-party cookies support enabled.
        automaticSilentRenew: true,
        userStore: new WebStorageStateStore({ store: window.localStorage }),
    };

    userManager = new UserManager(oidcConfig);

    userManager.events.addSilentRenewError(async () => {
        await new AccountService().Logout();
    });
};

export { loadOidcConfig };


import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LaegekompetenceStatusEnum } from 'core/sharedmodels/kompetence/laegekompetenceStatusEnum';
import { RootState } from "../../../app/store";
import { Nullable } from 'index';

export interface LaegekompetenceMetaState {
    laegekompetenceId: string;
    kompetenceId: string;
    forloebIds: string[];
    brugerMaalbeskrivelseId: string;
    maalbeskrivelseNavn: string;
    kompetenceNummer: string;
    kompetenceSortOrder: number;
    titel: string;
    konkretisering: string;
    laeringsstrategi: string;
    laegeroller: string;
    kompetenceVurderingsmetoder: string;
    kompetenceVurderingsmetoderLink: string;
    underoverskrift: string;
    isFavorite: boolean;
    status: LaegekompetenceStatusEnum;
    UserId: string;
    UserNavn: string;
    UserInitialer: string;
    notatCount: number;
    evalueringCount: number;
    hasActiveKvSkema: boolean;
    fase?: string;
    klarmeldtAt?: string;
    godkendtDato?: Date;
    godkendtAfNavn:string;
    godkendtAfRolleTitel: string;
    godkendtAfAuthId: string;
    klarmeldtTilNavn?: string[];
}

export interface KompetenceCardState {
    showCheckboxOnIds: string[];
    activeLaegekompetenceMeta: LaegekompetenceMetaState;
    selectedLaegekompetenceMetas: LaegekompetenceMetaState[];
    laegekompetenceNotatChangedPing: boolean;
    laegekompetenceListRefreshPing: boolean;
    laegekompetenceNotatCount: number;
    laegekompetenceEvalueringCount: number;
    activeKlarmeldingPageTabId: Nullable<string>;
    scrollToActiveKompetence: boolean;
    isLoadingList: boolean;
}

const initialState: KompetenceCardState = {
    showCheckboxOnIds: [],
    activeLaegekompetenceMeta: {} as LaegekompetenceMetaState,
    selectedLaegekompetenceMetas: [],
    laegekompetenceNotatChangedPing: false,
    laegekompetenceListRefreshPing: false,
    laegekompetenceNotatCount: 0,
    laegekompetenceEvalueringCount: 0,
    activeKlarmeldingPageTabId: null,
    scrollToActiveKompetence: false,
    isLoadingList: false,
};

export const kompetenceCardSlice = createSlice({
    name: 'kompetenceCard',
    initialState,
    reducers: {
        addSelectedLaegekompetenceMeta: (state, action: PayloadAction<LaegekompetenceMetaState>) => {
            state.selectedLaegekompetenceMetas.push(action.payload);
        },
        removeSelectedLaegekompetenceMeta: (state, action: PayloadAction<LaegekompetenceMetaState>) => {
            let index = state.selectedLaegekompetenceMetas.findIndex(d => d.kompetenceId === action.payload.kompetenceId);
            state.selectedLaegekompetenceMetas.splice(index, 1);
        },
        clearSelectedLaegekompetenceMetas: (state, action: PayloadAction<LaegekompetenceMetaState[] | undefined>) => {
            state.selectedLaegekompetenceMetas = action?.payload ?? [];
        },

        setActiveLaegekompetenceMeta: (state, action: PayloadAction<Partial<LaegekompetenceMetaState>>) => {
            state.activeLaegekompetenceMeta = { ...state.activeLaegekompetenceMeta, ...action.payload };
        },

        /** Use when wanting to redirect to kompetencer (and we want to preselect a specific kompetence)
         * E.g. we are not sure if the laegeKompetence exists in the specific context (e.g. klarmeldinger-page, kompetencer-page) */
        setActiveLaegeKompetenceFromOtherContext: (state, action: PayloadAction<LaegekompetenceMetaState>) => {
            state.activeLaegekompetenceMeta = action.payload; //New possible laegeKompetence
            state.scrollToActiveKompetence = true;
            state.isLoadingList = true; //Make sure we dont show cardOverview with laegeKompetence-info (before we are sure it exists in list)
            state.activeKlarmeldingPageTabId = null; //Klarmeldinger-page: make sure we always is on "klarmeldinger-tab"
            state.laegekompetenceListRefreshPing = !state.laegekompetenceListRefreshPing;
        },

        removeActiveLaegekompetenceMeta: (state) => {
            state.activeLaegekompetenceMeta = { } as LaegekompetenceMetaState;
        },

        setKompetenceCardStateShowCheckboxes: (state, action: PayloadAction<string[]>) => {
            state.showCheckboxOnIds = action.payload;
        },

        toggleLaegekompetenceNotatChangedPing: (state) => {
            state.laegekompetenceNotatChangedPing = !state.laegekompetenceNotatChangedPing;
        },

        toggleLaegekompetenceListRefreshPing: (state) => {
            state.laegekompetenceListRefreshPing = !state.laegekompetenceListRefreshPing;
        },

        setLaegekompetenceNotatCount: (state, action: PayloadAction<number>) => {
            state.laegekompetenceNotatCount = action.payload;
        },

        setLaegekompetenceEvalueringCount: (state, action: PayloadAction<number>) => {
            state.laegekompetenceEvalueringCount = action.payload;
        },

        setActiveKlarmeldingPageTab: (state, action: PayloadAction<Nullable<string>>) => {
            state.activeKlarmeldingPageTabId = action.payload;
        },

        scrollToActiveKompetence: (state, action: PayloadAction<boolean>) => {
            state.scrollToActiveKompetence = action.payload;
        },

        setIsLoadingList: (state, action: PayloadAction<boolean>) => {
            state.isLoadingList = action.payload;
        }
    }
});

export const {
    toggleLaegekompetenceListRefreshPing,
    setLaegekompetenceNotatCount,
    toggleLaegekompetenceNotatChangedPing,
    setKompetenceCardStateShowCheckboxes,
    setActiveLaegekompetenceMeta,
    setActiveLaegeKompetenceFromOtherContext,
    removeActiveLaegekompetenceMeta,
    addSelectedLaegekompetenceMeta,
    removeSelectedLaegekompetenceMeta,
    clearSelectedLaegekompetenceMetas,
    setLaegekompetenceEvalueringCount,
    setActiveKlarmeldingPageTab,
    scrollToActiveKompetence,
    setIsLoadingList
} = kompetenceCardSlice.actions;

export const kompetenceCardReducer = (state: RootState) => state.kompetenceCardReducer;
export default kompetenceCardSlice.reducer;

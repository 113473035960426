import BrevskabelonVisibilityTypeEnum from "infrastructure/enums/brevskabelonVisibilityTypeEnum";
import BrevskabelonCreateRequestModel from "./brevskabelonCreateRequestModel";

type BrevskabelonDto = {
    id: string;
    titel: string;
    subject: string;
    bodyMarkdown: string;
    visibilityType: BrevskabelonVisibilityTypeEnum;
    regionId?: string;
}

const emptyBrev: BrevskabelonDto = {
    id: "",
    titel: "",
    subject: "",
    bodyMarkdown: "",
    visibilityType: BrevskabelonVisibilityTypeEnum.Private
}

const toCreateRequest = (dto: BrevskabelonDto ) : BrevskabelonCreateRequestModel => ({
    titel: dto.titel,
    subject: dto.subject,
    bodyMarkdown: dto.bodyMarkdown,
    visibilityType: dto.visibilityType,
    regionId: dto.regionId
});

export default BrevskabelonDto

export {
    emptyBrev,
    toCreateRequest
}

import './forloebHeader.scss';
import React, { useEffect, useState } from "react";
import { ReactComponent as BuildingSvg } from "../../../content/images/building.svg";
import { SlideIn } from '../slideIn/slideIn';
import { Localizer } from 'infrastructure/localization/localizer';
import { ForloebModel } from "../../sharedmodels/forloeb/forloebModel";
import { EmblaIcon } from '../emblaIcon/emblaIcon';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { forloebReducer, ForloebSliceState, pingRefreshForloeb, setCurrentForloeb } from 'core/forloebSlice';
import { ForloebDetails } from '../../componentsPage/forloeb/forloebDetails';
import { GodkendTid } from '../../componentsPage/forloeb/godkendtTid/godkendTid';
import useEffectAsync from 'infrastructure/effect';
import { UddannelseTypeEnum } from "../../sharedmodels/stilling/uddannelseTypeEnum";
import { useHistory, useRouteMatch } from "react-router-dom";
import { RoutePaths } from "../../../infrastructure/routes";
import { UddannelseOverview } from "./uddannelseOverview";
import { ModalSize } from "../slideIn/modalSize";
import { TidsbegraensetAdgang } from "../../componentsPage/forloeb/tidsbegraensetAdgang";
import { ModalType } from "../slideIn/modalType";
import { ForloebCardInfo } from "../../componentsPage/forloeb/forloebCardInfo";
import { SkiftMaalbeskrivelse } from "../../componentsPage/forloeb/skiftMaalbeskrivelse";
import { ModalAcceptType, ModalSubmitMessage } from '../modalMessage/modalSubmitMessage';
import { NotificationModule } from 'ditmer-embla';
import { ForloebPageQueryParam } from 'pages/forloeb/forloebPage';
import { tryToBool } from "index";
import useScreenResolution from 'core/hooks/useScreenResolution';
import useUser from 'core/hooks/useUser';
import useLogbogApi from 'core/hooks/useLogbogApi';
import Tooltip from "../tooltips/Tooltip";
import {EmblaIcons} from "../../emblaIcons";
import classNames from "classnames";

type ForloebHeaderProps = {
    hideSeUddannelseAndForloebDetails?: boolean;
    hideForloebDetails?: boolean;
}

const attestationForTidmodalId = "forloeb-attestationForTid";
const godkendTidmodalId = "forloeb-godkendTid";
const tildelTidsbegraensetAdgang = "tildelTidsbegraensetAdgang";
const skiftMaalbeskrivelseId = "skiftMaalbeskrivelse";
const confirmModalId = "forloeb-confirm-modal";
const forloebsHistorikTabId = "forloebshistorikTabId";
export const uddannelseOverviewModalId = "uddannelse-overview-modal";

export const ForloebHeader = ({ hideSeUddannelseAndForloebDetails = false, hideForloebDetails = false }: ForloebHeaderProps) => {

    const dispatch = useAppDispatch();
    const { isMobile } = useScreenResolution();
    const history = useHistory();
    const { params } = useRouteMatch<ForloebPageQueryParam>();
    const { currentUser } = useUser();
    const { forloebApi } = useLogbogApi();

    const [selectedForloeb, setSelectedForloeb] = useState<ForloebModel>();
    const [isUddannelseModalShown, setIsUddannelseModalShown] = useState(false);
    const [isForloebDetaljerShown, setIsForloebDetaljerShown] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [isGodkendTidShown, setIsGodkendTidShown] = useState(false);
    const [openTidsbegraensetAdgangModal, setOpenTidsbegraensetAdgangModal] = useState(false);
    const [openSkiftMaalbeskrivelseModal, setOpenSkiftMaalbeskrivelseModal] = useState(false);
    const [activeChildTabId, setActiveChildTabId] = useState<string|undefined>(undefined);
    const forloebSliceState = useAppSelector(forloebReducer) as ForloebSliceState;

    const todayDate = new Date();
    const brugerId = forloebSliceState.forloebState?.brugerId;

    useEffect(() => {
        if(tryToBool(params.showForloebHistorik) === true) {
            setIsUddannelseModalShown(true);
            setActiveChildTabId(forloebsHistorikTabId);
        }
    }, [params.showForloebHistorik])

    useEffect(() => {
        const forloebModel = ForloebModel.FromState(forloebSliceState.forloebState);
        setSelectedForloeb(forloebModel);
    }, [forloebSliceState.forloebState]);

    useEffectAsync(async () => {
        const forloeb = await forloebApi.getForloebByBruger(forloebSliceState.forloebState.brugerId)
        const currentForloeb = forloeb.find(f => f.id === forloebSliceState.forloebState.id);

        if (currentForloeb) {
            dispatch(setCurrentForloeb(currentForloeb.ToState()))
        }
    }, [forloebSliceState.refreshForloebPing]);

    function handleClickSeUddannelse(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        setIsUddannelseModalShown(true);
    }

    function handleClickForloebDetaljer(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        setIsForloebDetaljerShown(true);
    }

    function handleClickGodkendTid(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation();
        setIsGodkendTidShown(true);
    }

    const updateCurrentForloeb = (editedForloeb: ForloebModel) => {
        dispatch(setCurrentForloeb(editedForloeb.ToState()))
    }

    const fjernGodkendelse = async () => {
        let godkendelseFjernet = await forloebApi.fjernGodkendelse(selectedForloeb?.id!, selectedForloeb?.godkendelseId!);

        if (godkendelseFjernet) {
            NotificationModule.showSuccess(Localizer.forloebpage_fjernGodkendelseSuccess(), "");
            dispatch(pingRefreshForloeb());
        }
    }

    const forloebIsActive = selectedForloeb && selectedForloeb.IsActiveForloeb();

    return (
        <>
            {selectedForloeb &&
                <>
                    {selectedForloeb.id &&
                        <div className={classNames(
                                "card header-card forloeb-header top-level-container-margins",
                                forloebIsActive && !selectedForloeb.irrelevant && "active-forloeb",
                                selectedForloeb.irrelevant && "irrelevant-forloeb",
                                selectedForloeb.harTidligereMidlertidigtFravaer && "color-shaded-forloeb"
                            )}>
                            <div className="flex-container-column">
                                <div className="flex-space">
                                    <h3 className='forloeb-header-txt'>
                                        {selectedForloeb.specialeName}, {UddannelseTypeEnum[selectedForloeb.uddannelseType]}
                                    </h3>
                                    {(!hideSeUddannelseAndForloebDetails) &&
                                        <button type="button" className="btn btn-transparent btn-round btn-no-right-border" onClick={handleClickSeUddannelse}>
                                            <EmblaIcon iconName="content-view" />
                                            {!isMobile &&
                                                Localizer.global_seUddannelse()
                                            }
                                        </button>
                                    }
                                </div>
                                <div className={"row"}>
                                    <div className={"col-12 col-lg-5"}>
                                        <ForloebCardInfo forloeb={selectedForloeb}/>
                                    </div>
                                    <div className={"col-12 col-lg-7 flex-align-bot"}>
                                        <div className={`text-right ${isMobile ? "margin-top-s" : ""}`}>
                                            {(currentUser.IsAdminOrVus() || currentUser.IsVusMedarbejeder()) && selectedForloeb.godkendelseId &&
                                                <button type="button" className={`btn btn-danger btn-round margin-right-m ${isMobile ? "float-left" : ""}`} aria-aria-label='primary'
                                                    onClick={() => setConfirmModalOpen(true)}
                                                    data-toggle="modal" data-target={`#${confirmModalId}`}
                                                    data-dismiss="modal" >
                                                    {Localizer.forloebpage_fjernGodkendelse()}
                                                </button>
                                            }

                                            {(currentUser.IsUddannelsesansvarlig() || currentUser.IsAdminOrVus() || currentUser.IsVusMedarbejeder()) && (currentUser.UserId != selectedForloeb.brugerId) && !selectedForloeb.godkendelseId &&
                                                <button type="button"
                                                        disabled={selectedForloeb.DaysLeft() > 30 || selectedForloeb.irrelevant}
                                                        className={`btn btn-success btn-round margin-right-m ${selectedForloeb.DaysLeft() > 30 ? "disabled-button" : ""}`}
                                                        aria-label="Primary"
                                                        onClick={handleClickGodkendTid}>
                                                    {Localizer.forloebpage_godkendTid()}
                                                </button>
                                            }

                                            {currentUser.IsLaege() && !selectedForloeb.evalueretDato && selectedForloeb.skalIndgaaIEvalueringsstatistik && selectedForloeb.senesteDatoForEvaluering &&
                                                <Tooltip
                                                    show={true}
                                                    title={(selectedForloeb.senesteDatoForEvaluering.getWithoutTime() >= todayDate.getWithoutTime()) ? "" : Localizer.evalueringsfristenErOverskredet()}>
                                                    <button type="button"
                                                        disabled={selectedForloeb.DaysLeft() > 30 || selectedForloeb.senesteDatoForEvaluering.getWithoutTime() < todayDate.getWithoutTime()}
                                                        className={`btn btn-success btn-round margin-right-m ${selectedForloeb.DaysLeft() > 30 ? "disabled-button" : ""}`}
                                                        onClick={() => history.push(RoutePaths.Evaluering(forloebSliceState.forloebState.brugerId).url)}>
                                                        {Localizer.global_evaluer()}
                                                    </button>
                                                </Tooltip>
                                            }
                                            {isMobile && currentUser.HasRole(RoutePaths.Uddannelseslaege().roles) &&
                                                <button type="button"
                                                        className={`btn btn-transparent btn-round margin-right-m`}
                                                        aria-label="Primary"
                                                        onClick={() => history.push(RoutePaths.Uddannelseslaege(selectedForloeb?.brugerId).url)}>
                                                    <EmblaIcon iconName={EmblaIcons.User}/>
                                                </button>
                                            }
                                            {(!hideSeUddannelseAndForloebDetails && !hideForloebDetails) &&
                                                <button type="button" className={`btn btn-transparent btn-round margin-right-m`} aria-label="Primary" onClick={handleClickForloebDetaljer}>
                                                    <EmblaIcon iconName='info' />
                                                    {!isMobile && Localizer.global_forloeb()}
                                                </button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <BuildingSvg className={"water-mark-svg margin-left-m"} />
                        </div>
                    }

                    {isUddannelseModalShown &&
                        <SlideIn
                            id={uddannelseOverviewModalId}
                            title={Localizer.global_uddannelse()}
                            actionText={Localizer.global_editing()}
                            modalSize={ModalSize.md}
                            additionalClasses={"no-height"}
                            defaultOpen={true}
                            actionOnCloseCallback={() => setIsUddannelseModalShown(false)}
                            actionFinishedCallback={() => updateCurrentForloeb}
                            bodyContent={<UddannelseOverview forloeb={selectedForloeb} modalId={uddannelseOverviewModalId} initialActiveTabId={activeChildTabId}/>}
                        />
                    }

                    {isForloebDetaljerShown &&
                        <SlideIn
                            id={attestationForTidmodalId}
                            title={Localizer.global_forloeb()}
                            actionText={Localizer.global_editing()}
                            actionOnCloseCallback={() => setIsForloebDetaljerShown(false)}
                            defaultOpen={true}
                            modalSize={ModalSize.md}
                            bodyContent={<ForloebDetails
                                userId={selectedForloeb.brugerId}
                                modalId={attestationForTidmodalId}
                                forloebId={selectedForloeb.id}
                                openTildelTidsbegraensetAdgangModal={() => setOpenTidsbegraensetAdgangModal(true)}
                                openSkiftMaalbeskrivelseModal={() => setOpenSkiftMaalbeskrivelseModal(true)}
                                initialActiveTabId={activeChildTabId}
                            />}
                        />
                    }

                    {isGodkendTidShown &&
                        <SlideIn
                            id={godkendTidmodalId}
                            title={Localizer.forloebpage_godkendTid()}
                            actionText={Localizer.global_editing()}
                            actionOnCloseCallback={() => setIsGodkendTidShown(false)}
                            defaultOpen={true}
                            bodyContent={<GodkendTid modalId={godkendTidmodalId} forloebId={selectedForloeb.id} userId={selectedForloeb.brugerId} attestationForTidInfoTekst={selectedForloeb.attestationForTidInfoTekst}
                                uddannelsessted={(selectedForloeb.afdelingName === null || selectedForloeb.afdelingName === undefined || selectedForloeb.afdelingName === "")
                                    ? selectedForloeb.laegeInstitutionName
                                    : selectedForloeb.laegeInstitutionName + ", " + selectedForloeb.afdelingName}
                                brugerMaalbeskrivelseId={selectedForloeb.brugerMaalbeskrivelseId}
                                />}
                        />
                    }

                    {confirmModalOpen &&
                        <ModalSubmitMessage modalId={confirmModalId}
                            title={Localizer.areYouSure()}
                            description={<p>{Localizer.forloebpage_fjernGodkendelseDescription()}</p>}
                            acceptAction={() => fjernGodkendelse()}
                            modalAcceptType={ModalAcceptType.danger}
                            acceptButtonText={Localizer.forloebpage_fjernGodkendelse()}
                        />
                    }

                    {openTidsbegraensetAdgangModal &&
                        <SlideIn id={tildelTidsbegraensetAdgang}
                            title={Localizer.forloebpage_tildelTidsbegraensetAdgang()}
                            additionalClasses={ModalType.originalModal}
                            actionFinishedCallback={() => dispatch(pingRefreshForloeb())}
                            defaultOpen={true}
                            bodyContent={<TidsbegraensetAdgang forloebId={selectedForloeb.id} brugerId={selectedForloeb.brugerId} modalId={tildelTidsbegraensetAdgang} attestationForTidModalId={attestationForTidmodalId} />}
                        />
                    }

                    {openSkiftMaalbeskrivelseModal &&
                        <SlideIn id={skiftMaalbeskrivelseId}
                            modalSize={ModalSize.md}
                            title={Localizer.forloebpage_skiftMaalbeskrivelse()}
                            additionalClasses={ModalType.originalModal}
                            actionFinishedCallback={() => dispatch(pingRefreshForloeb())}
                            actionOnCloseCallback={() => setOpenSkiftMaalbeskrivelseModal(false)}
                            defaultOpen={true}
                            bodyContent={<SkiftMaalbeskrivelse modalId={skiftMaalbeskrivelseId} attestationForTidModalId={attestationForTidmodalId} brugerId={brugerId} />}
                        />
                    }
                </>
            }
        </>
    );
}

export enum FileArchiveEnum {
    EmailAttachment = 1,
    ProfileImage = 2,
    /** Files which are attached to "notater" */
    NoteAttachment = 3,
    /** Files which relates to a "maalbeskrivelse" on a "speciale". Only uploaded directly trhough Stamdata.Web right now. */
    SpecialespecifikFil = 4,
    /** Files which relate to an education-place (file-relations: speciale, region, sygehus, etc.). Only used in Stamdata */
    UddannelsesstedFil = 5,
}

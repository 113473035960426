import config from "config/config";
import {AccountService} from "services/account/accountService";
import {HttpClientService} from "services/httpClient/httpClientService";
import {ApiResponse} from "../apiResponse";
import {LogbogStillingWithUsersResponseModel} from "./logbogStillingWithUsersResponseModel";
import {StillingDetailModel} from "../../../core/sharedmodels/stilling/stillingDetailModel";
import {ObjectToQueryString} from "../objectToQueryString";
import {LedigStillingModel} from "../../../core/sharedmodels/stilling/ledigStillingModel";
import {LogbogLedigStillingResponseModel} from "./logbogLedigStillingResponseModel";
import { ForSelectModel } from "core/sharedmodels/forSelectModel";
import { ForSelectResponseModel } from "../forSelectResponseModel";
import { PromiseWithCancel } from "services/httpClient/promiseWithCancel";

export class LogbogStillingApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;

    private stillingBaseUrl = () => config.logbogApiUrl + "api/Stilling/";
    private stillingLedigeUrl = () => this.stillingBaseUrl() + "ledige";
    private stillingLedigUrl = () => this.stillingBaseUrl() + "ledig";
    private stillingInfoUrl = () => this.stillingBaseUrl() + "info/";

    private getLedigeStillingerForSelectRequestWithCancel: PromiseWithCancel<ApiResponse<ForSelectResponseModel[]>>;

    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    async getLedigStilling(id: string, startDate: Date, endDate: Date): Promise<LedigStillingModel> {
        const url = this.stillingLedigUrl() + ObjectToQueryString({id : id, startDate : startDate.dateToApiPostFormat(), endDate: endDate.dateToApiPostFormat()});
        const result = await this.httpClientService.Get<ApiResponse<LogbogLedigStillingResponseModel>>(url);
        return LedigStillingModel.FromResponseModel(result.data);
    }

    async getLedigeStillingerForSelect(startDate: Date, endDate: Date, search: string): Promise<ForSelectModel[]> {
        if (this.getLedigeStillingerForSelectRequestWithCancel)
            this.getLedigeStillingerForSelectRequestWithCancel.cancel();

        const queryStringModel = {
            startDate: startDate?.dateToApiPostFormat(), 
            endDate: endDate?.dateToApiPostFormat(),
            search: search
        }
        const url = this.stillingLedigeUrl() + ObjectToQueryString(queryStringModel);

        this.getLedigeStillingerForSelectRequestWithCancel = this.httpClientService.GetWithCancellation<ApiResponse<ForSelectResponseModel[]>>(url);

        const result = await this.getLedigeStillingerForSelectRequestWithCancel;

        return result.data.map(x => new ForSelectModel(x));
    }

    async getStillingInfo(id: string): Promise<StillingDetailModel> {
        const url = this.stillingInfoUrl() + id;
        const result = await this.httpClientService.Get<ApiResponse<LogbogStillingWithUsersResponseModel>>(url);

        return StillingDetailModel.FromResponseModel(result.data);
    }
}

import {HttpClientService} from "../../httpClient/httpClientService";
import {FileDownloadHandler} from "../logbogFile/fileDownloadHandler";
import config from "../../../config/config";
import {FileMetadata} from "../../../core/sharedmodels/fileMetaData/fileMetaData";
import {ApiResponse} from "../apiResponse";
import FileRelations, {emptyFileRelations} from "core/sharedmodels/file/fileRelations";
import FileEditModel from "./fileEditModel";
import {AsyncResponseModel} from "../asyncResponseModel";
import FileAdministrationRequestModel from "./fileAdministrationRequestModel";
import {BuildFileData, FileData} from "../stamdataMappe/FilesAndFoldersModel";
import {FileArchiveEnum} from "../../../core/sharedmodels/fileMetaData/fileArchiveEnum";

export class StamdataFileApi {

    private fileBaseUrl = () => config.stamdataApiUrl + "api/file/"
    private editFileUrl = this.fileBaseUrl() + "edit";
    private filesOnUddannelsesstederUrl = this.fileBaseUrl() + "uddannelsessteder/";
    private cropFileUrl = this.fileBaseUrl() + "crop";
    private filesOnUddannelsesstederByForloebUrl = this.fileBaseUrl() + "uddannelsessteder/byforloeb/";
    public profileImageUrl = (id: string) => this.fileBaseUrl() + "profileImage/" + id;

    constructor (private httpClientService: HttpClientService, private fileDownloadHandler: FileDownloadHandler) {
        this.httpClientService = httpClientService;
        this.fileDownloadHandler = fileDownloadHandler;
    }

    async uploadFile(file: File, fileTypeEnum: FileArchiveEnum, fileRelations: FileRelations=emptyFileRelations, mappeId?: string): Promise<FileData> {
        const form = new FormData();

        form.append("FormFile", file, file.name);
        form.append("FileRelationsJson", JSON.stringify(fileRelations));
        form.append("fileArchiveEnum", fileTypeEnum.toString());

        if(mappeId) form.append("MappeId", mappeId);

        const url = this.fileBaseUrl();
        const result = await this.httpClientService.UploadFormdata<ApiResponse<FileData>>(url, form);

        return BuildFileData(result.data);
    }

    async uploadCroppedFile(userId: string, file: File, fullScaleFileId: string): Promise<string> {
        const form = new FormData();
        form.append("FormFile", file, file.name);
        form.append("UserId", userId);
        form.append("FullScaleFileId", fullScaleFileId);
        const url = this.cropFileUrl;
        const result = await this.httpClientService.UploadFormdata<ApiResponse<string>>(url, form);
        return result.data;
    }

    async editFile(fileMetaDataId: string, fileRelations: FileRelations, mappeId?: string): Promise<FileData> {
        const fileEditModel: FileEditModel = { fileMetaDataId,  mappeId, ...fileRelations };
        const url = this.editFileUrl;
        const result = await this.httpClientService.Post<ApiResponse<FileData>, FileEditModel>(url, fileEditModel);
        return BuildFileData(result.data);
    }

    async deleteFile(fileMetaDataId: string): Promise<FileData> {
        const url = this.fileBaseUrl() + fileMetaDataId;
        const result = await this.httpClientService.Delete<ApiResponse<FileData>>(url);
        return BuildFileData(result.data);
    }

    async downloadFile(fileMetadata: FileMetadata): Promise<void> {
        const apiUrl = this.fileBaseUrl() + fileMetadata.id;
        let blob = await this.httpClientService.DownloadFile(apiUrl, fileMetadata.fileContentType);
        //Hack to force browser to show AJAX blob file as downloaded file
        this.fileDownloadHandler.DownloadFile(blob, fileMetadata.fileName);
    }

    async getUddannelsesstedFiles(search: string, page: number, pageLength: number): Promise<AsyncResponseModel<FileData>> {
        const url = this.filesOnUddannelsesstederUrl;

        const requestModel: FileAdministrationRequestModel = {
            ApiQueryParams : {
                page: page,
                pageLength: pageLength,
                search: search
            }
        };

        const result = await this.httpClientService.Post<ApiResponse<AsyncResponseModel<FileData>>, FileAdministrationRequestModel>(url, requestModel);

        return {
            recordsTotal: result.data.recordsFiltered,
            recordsFiltered: result.data.recordsFiltered,
            data: result.data.data.map(f => BuildFileData(f))
        }
    }

    async getProfileImage(fileMetadataId: string): Promise<Uint8Array[]> {
        const url = this.profileImageUrl(fileMetadataId);
        const result = await this.httpClientService.Get<ApiResponse<Uint8Array[]>>(url);
        return result.data;
    }

    async getMapperAndUddannelseFilesByForloeb(forloebId: string): Promise<FileData[]> {
        const url = this.filesOnUddannelsesstederByForloebUrl + forloebId;
        const result = await this.httpClientService.Get<ApiResponse<FileData[]>>(url);
        return result.data.map(f => BuildFileData(f));
    }
}

import {Score} from "../../../core/components/score/score";
import {DatasetModel} from "../datasetModel";
import React, {useEffect, useState} from "react";
import {
    EvalueringStatistikForloebModel
} from "../../../services/api/evalueringStatistik/evalueringStatistikForloebModel";
import {SlideIn} from "../../../core/components/slideIn/slideIn";
import {Localizer} from "../../../infrastructure/localization/localizer";
import {Spoergeramme} from "./spoergeramme";
import {ModalSize} from "../../../core/components/slideIn/modalSize";
import {SpoergsmaalTypeEnum} from "../../../core/sharedmodels/evaluering/spoergsmaalTypeEnum";
import {EmblaIcon} from "core/components/emblaIcon/emblaIcon";
import {LogbogExportApi} from "services/api/logbogExport/logbogExportApi";
import {HttpClientService} from "services/httpClient/httpClientService";
import {AccountService} from "services/account/accountService";
import {FileDownloadHandler} from "services/api/logbogFile/fileDownloadHandler";
import {useAppSelector} from "../../../app/hooks";
import {
    evalueringsstatistikFiltersReducer,
    EvalueringsstatistikFiltersState
} from "../evalueringsstatistikFiltersSlice";
import {EvalueringsstatistikPageConstants} from "./EvalueringsstatistikPageConstants";
import {initTooltip} from "../../../core/utils";
import {GenericDownloadButton} from "../../../core/components/fileMetadata/genericDownloadButton";
import {useMediaQuery} from "react-responsive";
import {mobileMaxWidthQuery} from "../../../core/layout/responsive";
import Tooltip from "core/components/tooltips/Tooltip";
import {ScoreTypeEnum} from "../../../core/componentsPage/score/scoreTypeEnum";
import classNames from "classnames";

type UddannelsesstedSingleScoreCardComponentProps = {
    dataset: DatasetModel;
    forloebEnkeltEvaluering: EvalueringStatistikForloebModel;
}

export const UddannelsesstedSingleScoreCardComponent = (props: UddannelsesstedSingleScoreCardComponentProps) => {
    const exportApi = new LogbogExportApi(new HttpClientService(), new AccountService(), new FileDownloadHandler());
    const [openSpoergerammeModal, setOpenSpoergerammeModal] = useState<boolean>(false);
    const evalueringsstatistikFilterSliceState = useAppSelector(evalueringsstatistikFiltersReducer) as EvalueringsstatistikFiltersState;
    const IsMobile = useMediaQuery(mobileMaxWidthQuery);

    const spoergerammeModalTarget = "spoergeramme-modal-enkeltvis";
    const generalComment = props.forloebEnkeltEvaluering.forloebEvalueringSvar.find(fes => fes.spoergsmaalsnummer === 27)?.tekstSvar;

    let tooltipTekst = `<div class="font-weight-bold">${Localizer.evalueringsstatistikPage_generelKommentar()}</div><br/> ${generalComment}`

    useEffect(() => {
        initTooltip("[data-tooltip]", IsMobile);
    }, [IsMobile])

    return (
        <div className="border-top" role={"button"}>
            <div className="margin-top-s margin-bottom-s col">
                <div className="row align-items-center">
                    <div className="col-sm-1 clickable" onClick={() => setOpenSpoergerammeModal(true)}>
                        {IsMobile &&
                            <b>{Localizer.evalueringPage_afleveret()}: </b>}{props.forloebEnkeltEvaluering.indsendtDato.dateWithoutTimeFormat(true)}
                    </div>
                    <div className="col-sm-1 clickable" onClick={() => setOpenSpoergerammeModal(true)}>
                        {IsMobile &&
                            <b>{Localizer.slutdato()}: </b>}{props.forloebEnkeltEvaluering.slutDato?.dateWithoutTimeFormat(true)}
                    </div>
                    <div className="col-sm-2 clickable" onClick={() => setOpenSpoergerammeModal(true)}>
                        {IsMobile && <b>{Localizer.stilling()}: </b>}{props.forloebEnkeltEvaluering.uddannelseNavn}
                    </div>
                    <div className="col-sm-7 clickable" onClick={() => setOpenSpoergerammeModal(true)}>

                        {(props.forloebEnkeltEvaluering.forloebEvalueringSvar.length > 0 && evalueringsstatistikFilterSliceState.spoergsmaalToShow.length > 0 && evalueringsstatistikFilterSliceState.spoergsmaalsType === SpoergsmaalTypeEnum.Enkeltvis)
                            ?
                            <div className="d-flex wrap">
                                {evalueringsstatistikFilterSliceState.spoergsmaalToShow.map((spoergsmaalsNumber, index) => {
                                    return (
                                        <React.Fragment key={spoergsmaalsNumber + index}>
                                            {+spoergsmaalsNumber < EvalueringsstatistikPageConstants.numberOfQuestionsIncludingOptionalTextInput &&
                                                <Score
                                                    svarVaerdi={props.forloebEnkeltEvaluering.forloebEvalueringSvar[+spoergsmaalsNumber - 1]?.svarVaerdi}
                                                    withDecimal={false}
                                                    showText={false}
                                                    hasComment={props.forloebEnkeltEvaluering.forloebEvalueringSvar[+spoergsmaalsNumber]?.kommentar}
                                                    scoreType={ScoreTypeEnum.ForloebEvalueringScore}/>
                                            }
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                            :
                            <div className="d-flex wrap">
                                {props.forloebEnkeltEvaluering.forloebEvalueringSvar.map((evalueringSvar, index) => {
                                    return (
                                        <React.Fragment key={evalueringSvar.id + index}>
                                            {index < EvalueringsstatistikPageConstants.numberOfQuestionsExcludingOptionalTextInput &&
                                                <Score svarVaerdi={evalueringSvar.svarVaerdi}
                                                       withDecimal={false}
                                                       showText={false}
                                                       hasComment={evalueringSvar.kommentar}
                                                       scoreType={ScoreTypeEnum.ForloebEvalueringScore}/>
                                            }
                                        </React.Fragment>
                                    )
                                })}
                            </div>
                        }
                    </div>
                    <div className={classNames("col-sm-1 d-flex wrap", !IsMobile && "justify-content-end")} role='button'>
                        {generalComment &&
                            <Tooltip
                                className={`align-self-center`}
                                data-tooltip={true}
                                data-html="true"
                                title={tooltipTekst}
                                key={props.forloebEnkeltEvaluering.id}>
                                <EmblaIcon additionalClasses={"subtle margin-right-s"} iconName="dialog"/>
                            </Tooltip>
                        }
                        <GenericDownloadButton
                            additionalClasses={`margin-right-s`}
                            clickFunction={async () => exportApi.getEvalueringStatistikPdf(props.forloebEnkeltEvaluering.id)}
                            child={<EmblaIcon additionalClasses={"subtle"} iconName="download"/>}/>
                    </div>
                </div>

            </div>
            {openSpoergerammeModal &&
                <SlideIn
                    id={spoergerammeModalTarget}
                    title={props.forloebEnkeltEvaluering.uddannelseNavn ?? Localizer.spoergerammeModal_spoergeramme()}
                    actionText={Localizer.spoergerammeModal_spoergeramme()}
                    defaultOpen={true}
                    actionFinishedCallback={() => setOpenSpoergerammeModal(false)}
                    actionOnCloseCallback={() => setOpenSpoergerammeModal(false)}
                    bodyContent={<Spoergeramme modalId={spoergerammeModalTarget}
                                               forloebEnkeltEvalueringer={props.forloebEnkeltEvaluering}/>}
                    modalSize={ModalSize.md}/>
            }
        </div>
    )
}

import {VejlederAfdelingResponseModel} from "../../../services/api/stamdataVejlederApi/vejlederAfdelingResponseModel";
import {nullableDateTimeStringFromApiToDate} from "../../../index";

export class VejlederAfdelingerModel{

    constructor(vejlderAfdelingerResponseModel: VejlederAfdelingResponseModel) {
        this.LaegeInstitutionId = vejlderAfdelingerResponseModel.laegeInstitutionId;
        this.LaegeInstitutionNavn = vejlderAfdelingerResponseModel.laegeInstitutionNavn;
        this.AfdelingId = vejlderAfdelingerResponseModel.afdelingId;
        this.AfdelingNavn = vejlderAfdelingerResponseModel.afdelingNavn;
        this.PeriodeSlut = nullableDateTimeStringFromApiToDate(vejlderAfdelingerResponseModel.periodeSlut);
    }

    public LaegeInstitutionId: string;
    public AfdelingId?: string;
    public LaegeInstitutionNavn: string;
    public AfdelingNavn?: string;
    public PeriodeSlut?: Date;

    public GetStedInfo() {
        return !!this.AfdelingId && !!this.AfdelingNavn
            ? `${this.LaegeInstitutionNavn}, ${this.AfdelingNavn}`
            : this.LaegeInstitutionNavn;
    }
}

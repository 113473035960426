import * as yup from "yup";
import { Assign, ObjectShape, TypeOfShape } from "yup/lib/object";

type CustomYupObjectShape<T> = ObjectShape & {[P in keyof T]: any };
export type CreateValidationSchemaReturnType<T> = yup.ObjectSchema<Assign<ObjectShape, CustomYupObjectShape<T>>, Record<string, any>, TypeOfShape<Assign<ObjectShape, CustomYupObjectShape<T>>>>;

export const createValidationSchema = <T,>(yupShape: CustomYupObjectShape<T>) => {
    return yup.object().shape(yupShape);
};

export default createValidationSchema;

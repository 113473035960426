import {Localizer} from "../../../infrastructure/localization/localizer";
import {ForloebOverviewFiltersState} from "./forloebOverviewFiltersSlice";
import {FilterModel} from "../../sharedmodels/filter/filterModel";
import {FilterItemType} from "../../sharedmodels/filter/filterItemType";
import {DateFilterType, periodFromJson} from "./ForloebOverviewDateFilterModel";
import {toReduxIsoDateString, toReduxIsoDateUtcString} from "core/helpers/reduxHelper";
import {toDateFromApiFormat} from "index";

export class ForloebOverviewFilterModel {
    constructor(
            titel?: string,
            filterId?: string,
            search?: string,
            isPrivate?: boolean,
            regionRettigheder?: Array<string>,
            regioner?: Array<string>,
            uddannelsetyper?: Array<number>,
            specialer?: Array<string>,
            hospitaler?: Array<string>,
            praksis?: Array<string>,
            afdelinger?: Array<string>,
            omraader?: Array<string>,
            dateFilterType?: DateFilterType,
            year?: number,
            date?: Date,
            periodFrom?: Date,
            periodTo?: Date,
            kbuPeriod?: string,
            kbuPeriodYear?:string
        ) {

        const today = new Date();
        const fromInitYear = today.getUTCFullYear() - 1;
        const toInitYear = today.getUTCFullYear() + 10;
        this.dateFilterType = dateFilterType ?? DateFilterType.Period;
        this.year = (year ?? today.getUTCFullYear()).valueOf();
        this.date = toReduxIsoDateString(date ?? new Date(today.toDateString()));
        this.periodFrom = toReduxIsoDateString(periodFrom ?? new Date(fromInitYear, 0, 1, 0, 0, 0, 0));
        this.periodTo = toReduxIsoDateString(periodTo ?? new Date(toInitYear, today.getUTCMonth(), today.getUTCDate(), 0, 0, 0, 0));
        this.titel = titel ?? Localizer.forloebOverviewPage_anvendFilter();
        this.regioner = regioner;
        this.regionRettigheder = regionRettigheder;
        this.specialer = specialer;
        this.uddannelsetyper = uddannelsetyper;
        this.hospitaler = hospitaler;
        this.praksis = praksis;
        this.afdelinger = afdelinger;
        this.omraader = omraader;
        this.filterId = filterId;
        this.isPrivate = isPrivate;
        this.kbuPeriod = kbuPeriod;
        this.kbuPeriodYear = kbuPeriodYear;
    }

    titel: string;
    dateFilterType: DateFilterType;
    year: number;
    date: string;
    periodFrom: string;
    periodTo: string;
    regionRettigheder?: string[];
    regioner?: string[];
    specialer?: string[];
    uddannelsetyper?: number[];
    hospitaler?: string[];
    praksis?: string[];
    afdelinger?: string[];
    omraader?: string[];
    filterId?: string;
    isPrivate?: boolean;
    refreshFilterPing?: boolean;
    alleRegionerValgt?: boolean;
    kbuPeriod? : string
    kbuPeriodYear?: string

    [key: string]: any; // ForloebOverviewFilterModel is indexable; not a new property
}

export function toState(inputModel: ForloebOverviewFilterModel, filterSelected: boolean = false) : ForloebOverviewFiltersState {
    return {
        regioner: inputModel?.regioner,
        regionRettigheder: inputModel?.regionRettigheder,
        specialer: inputModel?.specialer,
        uddannelsetyper: inputModel?.uddannelsetyper,
        hospitaler: inputModel?.hospitaler,
        praksis: inputModel?.praksis,
        afdelinger: inputModel?.afdelinger,
        omraader: inputModel?.omraader,
        titel: inputModel?.titel,
        filterId: inputModel?.filterId,
        isPrivate: inputModel?.isPrivate,
        refreshFilterPing: inputModel?.refreshFilterPing,
        filterSelected: filterSelected,
        alleRegionerValgt: inputModel?.alleRegionerValgt,
        dateFilterType: inputModel?.dateFilterType,
        year: inputModel?.year,
        date: inputModel?.date,
        periodFrom: inputModel?.periodFrom,
        periodTo: inputModel?.periodTo,
        kbuPeriod: inputModel?.kbuPeriod,
        kbuPeriodYear: inputModel?.kbuPeriodYear
    } as ForloebOverviewFiltersState;
}

export function fromResponseModel(filterModel: FilterModel) : ForloebOverviewFilterModel {
    const forloebFilterModel = new ForloebOverviewFilterModel();
    forloebFilterModel.filterId = filterModel.id;
    forloebFilterModel.specialer = [];
    forloebFilterModel.hospitaler = [];
    forloebFilterModel.praksis = [];
    forloebFilterModel.uddannelsetyper = [];
    forloebFilterModel.afdelinger = [];
    forloebFilterModel.omraader = [];
    forloebFilterModel.regioner = [];
    forloebFilterModel.regionRettigheder = [];
    forloebFilterModel.isPrivate = false;
    forloebFilterModel.dateFilterType = filterModel.dateFilterType;

    for (const filterItem of filterModel.filterItems ) {
        switch(filterItem.filterItemType) {
            case FilterItemType.Speciale:{
                forloebFilterModel.specialer?.push(filterItem?.value);
                break;
            }
            case FilterItemType.Hospital:{
                forloebFilterModel.hospitaler?.push(filterItem?.value);
                break;
            }
            case FilterItemType.Praksis:{
                forloebFilterModel.praksis?.push(filterItem?.value);
                break;
            }
            case FilterItemType.UddannelseType:{
                forloebFilterModel.uddannelsetyper?.push(parseInt(filterItem?.value));
                break;
            }
            case FilterItemType.StartYear: {
                forloebFilterModel.year = (parseInt(filterItem?.value));
                break;
            }
            case FilterItemType.Date: {
                forloebFilterModel.date = toReduxIsoDateUtcString(toDateFromApiFormat(filterItem?.value));
                break;
            }
            case FilterItemType.Period: {
                const period = periodFromJson(filterItem?.value);
                forloebFilterModel.periodFrom = toReduxIsoDateUtcString(period.from);
                forloebFilterModel.periodTo = toReduxIsoDateUtcString(period.to);
                break;
            }
            case FilterItemType.Afdeling: {
                forloebFilterModel.afdelinger?.push(filterItem?.value);
                break;
            }
            case FilterItemType.Region: {
                filterItem.all ? forloebFilterModel.alleRegionerValgt = filterItem.all : forloebFilterModel.regioner?.push(filterItem?.value);
                break;
            }
            case FilterItemType.Omraade: {
                forloebFilterModel.omraader?.push(filterItem?.value);
                break;
            }

            case FilterItemType.UddannelseTypePeriode: {
                forloebFilterModel.kbuPeriod = filterItem?.value == "0" ? undefined: filterItem.value
                break;

            }case FilterItemType.UddannelseTypeYear: {
                forloebFilterModel.kbuPeriodYear = filterItem?.value == "0" ? undefined: filterItem.value
                break;
            }
            default: {
                break;
            }
        }
    }
    forloebFilterModel.titel = filterModel.titel;
    forloebFilterModel.regionRettigheder = filterModel.regionRettigheder;
    forloebFilterModel.isPrivate = filterModel.isPrivate;
    return forloebFilterModel;
}

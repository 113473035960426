import {NotatType} from "../../../core/sharedmodels/notat/notatType";

export class LogbogNotatByLaegekompetenceIdsGetModel {
    notatType : NotatType;
    brugerId: string;

    constructor(notatType : NotatType, brugerId: string) {
        this.notatType = notatType;
        this.brugerId = brugerId;
    }
}

import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {ApiResponse} from "services/api/apiResponse"
import {AccountService} from "../../account/accountService";
import {ForSelectModel} from "../../../core/sharedmodels/forSelectModel";
import {ForSelectResponseModel} from "../forSelectResponseModel";
import {LogbogForloebFilterCrudModel} from "./logbogForloebFilterCrudModel";
import {ObjectToQueryString} from "../objectToQueryString";
import {FilterType} from "../../../core/sharedmodels/filter/filterType";
import {LogbogFilterItemGetModel} from "./LogbogFilterItemGetModel";
import {FilterModel} from "../../../core/sharedmodels/filter/filterModel";
import {
    ForloebOverviewFilterModel,
    fromResponseModel
} from "../../../core/components/forloebOverview/ForloebOverviewFilterModel";

export class LogbogFilterApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = () => config.logbogApiUrl + "api/filter/";

    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    async getFilterSelectOptions(): Promise<ForSelectModel[]> {
        const url = this.baseUrl() + "filtersForSelect";
        const result = await this.httpClientService.Get<ApiResponse<ForSelectResponseModel[]>>(url);
        return result.data.map(x => new ForSelectModel(x));
    }

    //CREATE
    async createFilter(inputModel: ForloebOverviewFilterModel): Promise<string> {
        const postModel = new LogbogForloebFilterCrudModel(inputModel);
        const url = this.baseUrl() + "forloebFilter";
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogForloebFilterCrudModel>(url, postModel);
        return result.data;
    }

    async getFilterWithItems(filterType : FilterType, filterId : string): Promise<ForloebOverviewFilterModel> {
        const url = this.baseUrl() + ObjectToQueryString(new LogbogFilterItemGetModel(filterType, filterId));
        const result = await this.httpClientService.Get<ApiResponse<FilterModel>>(url);
        return fromResponseModel(result.data);
    }

    async DeleteFilter(id: string): Promise<string> {
        const url = this.baseUrl() + id;
        const result = await this.httpClientService.Delete<ApiResponse<string>>(url);
        return result.data;
    }

    async EditFilter(inputModel: ForloebOverviewFilterModel) : Promise<string>{
        const patchModel = new LogbogForloebFilterCrudModel(inputModel);
        const url = this.baseUrl() + "editForloebFilter";
        const result = await this.httpClientService.Post<ApiResponse<string>, LogbogForloebFilterCrudModel>(url, patchModel);
        return result.data;
    }
}

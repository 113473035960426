import {
    CrudVejledersamtaleInputModel
} from "../../../pages/vejledning/vejledersamtaler/components/crudVejledersamtaleInputModel";

export class LogbogVejledersamtaleCrudModel {
    constructor(inputModel: CrudVejledersamtaleInputModel) {
        this.id = inputModel.id;
        this.forloebId = inputModel.forloebId;
        this.titel = inputModel.titel;
        this.dato = inputModel.dato.dateToApiPostFormat();
        this.starttidspunkt = inputModel.starttidspunkt !== undefined
            ? inputModel.starttidspunkt.dateAndTimeToApiPostFormat()
            : undefined;
        this.sluttidspunkt = inputModel.sluttidspunkt !== undefined
            ? inputModel.sluttidspunkt.dateAndTimeToApiPostFormat()
            : undefined;
        this.vejleder = inputModel.vejleder;
        this.referat = inputModel.referat;
        this.vejledersamtaleType = inputModel.vejledersamtaleType;
        this.publicAccess = inputModel.publicAccess;
        this.visibleToUserIds = (inputModel.publicAccess || inputModel.isPrivateSamtale) ? [] : inputModel.visibleToUserIds;
        this.forloebOnUserId = inputModel.forloebOnUserId;
        this.isPrivate = inputModel.isPrivateSamtale;
        this.klarmeldtAt = inputModel.klarmeldtAt;
        this.shouldSendCalendarInvi = inputModel.shouldSendCalendarInvi;
    }

    public id?: string;
    public forloebId: string;
    public forloebOnUserId: string;
    public titel: string;
    public dato: string;
    public starttidspunkt?: string;
    public sluttidspunkt?: string;
    public vejleder: string;
    public referat: string;
    public vejledersamtaleType: number;
    public publicAccess: boolean;
    public visibleToUserIds: string[];
    public isPrivate: boolean;
    public klarmeldtAt?: string;
    public shouldSendCalendarInvi: boolean;
}


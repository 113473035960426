import config from "config/config";
import { AccountService } from "services/account/accountService";
import { HttpClientService } from "services/httpClient/httpClientService";
import { ApiResponse } from "../apiResponse";
import { FromNotifikationIndstillingResponseModel, LogbogNotifikationIndstillingResponseModel } from "./logbogNotifikationIndstillingResponseModel";
import { NotifikationIndstillingModel } from "core/components/notifications/notificationSettings/models/notifikationIndstillingModel";
import { NotifikationEventTypeEnum } from "core/components/notifications/models/notificationModel";
import { LogbogNotifikationEventIndstillingPatchModel } from "./logbogNotifikationEventIndstillingPatchModel";

export class LogbogNotifikationIndstillingApi {
  constructor(httpClientService: HttpClientService, userService: AccountService) {
      this.httpClientService = httpClientService;
      this.userService = userService;
  }

  private httpClientService: HttpClientService;
  private userService: AccountService;

  private baseUrl = () => config.logbogApiUrl + "api/notifikationIndstilling";
  private notifikationIndstillingerUrl = () => `${this.baseUrl()}/`;
  private notifikationIndstillingPatchUrl = (eventType: NotifikationEventTypeEnum) => `${this.baseUrl()}/${eventType}`;

  async getNotifikationIndstillinger(): Promise<Array<NotifikationIndstillingModel>> {
    const url = this.notifikationIndstillingerUrl();

    const result = await this.httpClientService.Get<ApiResponse<Array<LogbogNotifikationIndstillingResponseModel>>>(url);
    return result.data.map(x => FromNotifikationIndstillingResponseModel(x));
  }

  async updateNotificationEventSetting(eventType: NotifikationEventTypeEnum, patchModel: LogbogNotifikationEventIndstillingPatchModel) {
    const url = this.notifikationIndstillingPatchUrl(eventType);

    const result = await this.httpClientService.Post<ApiResponse<boolean>, LogbogNotifikationEventIndstillingPatchModel>(url, patchModel);
    return result.data;
  }
}
import {CrudLaegekursusInputModel} from "../../../pages/kurserPage/components/crudLaegekursusInputModel";
import {LaegekursusStatusEnum} from "../../../core/sharedmodels/kursus/laegekursusStatusEnum";

export class LogbogLaegekursusCrudModel {
    constructor(inputModel: CrudLaegekursusInputModel) {
        this.id = inputModel.id;
        this.titel = inputModel.titel;
        this.forloebId = inputModel.forloebId;
        this.eksterntBrugerId = inputModel.eksterntBrugerId;
        this.laegekursusGruppeId = inputModel.laegekursusGruppeId;
        this.brugerKursusraekkeId = inputModel.brugerKursusraekkeId;
        this.status = inputModel.status;
        this.startDato = inputModel.startDato?.dateToApiPostFormat();
        this.slutDato = inputModel.slutDato?.dateToApiPostFormat();
        this.eventuelSpecifikation = inputModel.eventuelSpecifikation;
        this.sortOrder = inputModel.sortOrder;
    }

    public id?: string;
    public forloebId: string;
    public eksterntBrugerId: string;
    public laegekursusGruppeId: string;
    public brugerKursusraekkeId: string;
    public titel: string;
    public status: LaegekursusStatusEnum;
    public startDato?: string;
    public slutDato?: string;
    public eventuelSpecifikation?: string;
    public sortOrder: number;
}


import { tryToString } from "index";
import { DropdownOption } from "../../dropdown";

type DropdownOptionLabelProps = {
    option: DropdownOption;
    hideSublabel?: boolean;
}

const DropdownOptionLabel = ({
    option,
    hideSublabel = true
} : DropdownOptionLabelProps) => {

    return (
        <>
            <div id={tryToString(option.value)}>{option.label}</div>
            {(option.subLabel || !hideSublabel) &&
                <div className="small subtle">{option.subLabel}</div>
            }
        </>
    );
}

export default DropdownOptionLabel;

import {LogbogBeskaeftigelseResponseModel} from "../../../services/api/logbogBeskaeftigelse/logbogBeskaeftigelseResponseModel";
import {dateTimeStringFromApiToDate} from "../../../index";

export class BeskaeftigelseModel {

    public id: string;
    public stillingsbetegnelse: string;
    public stillingsnummer: string;
    public ansaettelsessted: string;
    public ansoegningId: string;
    public afdeling: string;
    public startdato?: Date;
    public slutdato?: Date;
    public maaneder: string;
    public nedsatTidAntalTimer?: number;
}

export function FromResponseModel(responseModel : LogbogBeskaeftigelseResponseModel) : BeskaeftigelseModel {
    const model = new BeskaeftigelseModel();

    model.id = responseModel.id;
    model.stillingsbetegnelse = responseModel.stillingsbetegnelse;
    model.stillingsnummer = responseModel.stillingsnummer;
    model.ansaettelsessted = responseModel.ansaettelsessted;
    model.ansoegningId = responseModel.ansoegningId;
    model.afdeling = responseModel.afdeling;
    model.startdato = responseModel.startDato ? dateTimeStringFromApiToDate(responseModel.startDato) : undefined;
    model.slutdato = responseModel.slutDato ? dateTimeStringFromApiToDate(responseModel.slutDato) : undefined;
    model.maaneder = responseModel.maaneder;
    model.nedsatTidAntalTimer = responseModel.nedsatTidAntalTimer;
    return model;
}

import {Localizer} from "../../../infrastructure/localization/localizer";

export enum RootTypeEnum {
    Praksis = 1,
    Sygehus = 2
}

export const RootTypeEnumTranslator = (rootTypeEnum: RootTypeEnum): string => {
    switch(rootTypeEnum) {
        case RootTypeEnum.Praksis:
            return Localizer.global_praksis();
        case RootTypeEnum.Sygehus:
            return Localizer.global_sygehus();
        default:
            throw Error("RootTypeEnum could not be mapped (input:" + rootTypeEnum + ")");
    }
}

export const getAllTranslatedRootTypeEnums = () => {
    return Object.values(RootTypeEnum)
    .filter(x => Number.isInteger(x))
    .map(x => RootTypeEnumTranslator(x as RootTypeEnum));
}
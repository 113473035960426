import {HttpClientService} from "../../httpClient/httpClientService";
import config from "../../../config/config";
import {ApiResponse} from "../apiResponse";
import {CreateMailInputModel} from "../../../core/components/mail/createMailInputModel";
import {LogbogEmailRequestModel} from "./logbogEmailRequestModel";
import {logbogEmailStpsRequestModel} from "./logbogEmailStpsRequestModel";
import {UserWithForloebAndStillingId} from "../../../core/sharedmodels/email/UserWithForloebAndStillingId";
import {logbogEmailUserEmailRequestModel} from "./logbogEmailUserEmailRequestModel";
import {EmailTypeEnum} from "core/sharedmodels/email/emailTypeEnum";

export class LogbogEmailApi{
    private httpClientService: HttpClientService;

    private emailBaseUrl = () => config.logbogApiUrl + "api/email/";
    private emailUserEmailUrl = () => config.logbogApiUrl + "api/email/createUserEmail";
    public stpsEmailUrl = () => this.emailBaseUrl() + "CreateStpsEmail";

    constructor(httpClientService: HttpClientService) {
        this.httpClientService = httpClientService;
    }
    async CreateEmail(inputModel: CreateMailInputModel, userWithForloebAndStilling: Array<UserWithForloebAndStillingId>, emailTypeEnum: EmailTypeEnum): Promise<ApiResponse<string>> {
        const mail = new LogbogEmailRequestModel(inputModel, userWithForloebAndStilling, emailTypeEnum);
        return await this.httpClientService.Post<ApiResponse<string>, LogbogEmailRequestModel>(this.emailBaseUrl(), mail);
    }

    async CreateUserListEmail(inputModel: CreateMailInputModel, usersIdsToSendEmailTo: string[], emailTypeEnum: EmailTypeEnum): Promise<ApiResponse<string>> {
        const mail = new logbogEmailUserEmailRequestModel(inputModel, usersIdsToSendEmailTo, emailTypeEnum);
        return await this.httpClientService.Post<ApiResponse<string>, logbogEmailUserEmailRequestModel>(this.emailUserEmailUrl(), mail, true);
    }

    async SendStpsAnsoegning(requestModel: logbogEmailStpsRequestModel): Promise<number> {
        const apiUrl = this.stpsEmailUrl();
        return (await this.httpClientService.Post<ApiResponse<number>, logbogEmailStpsRequestModel>(apiUrl, requestModel)).data;
    }
}

import React, {useEffect, useState} from "react";
import "./score.scss"
import {Localizer} from "../../../infrastructure/localization/localizer";
import {SvarmulighedModel} from "../../sharedmodels/evaluering/svarmulighedModel";
import {KompetenceSvarModel} from "../../sharedmodels/evaluering/KompetencesvarModel";
import Tooltip from "../tooltips/Tooltip";
import useScreenResolution from "core/hooks/useScreenResolution";
import {ScoreTypeEnum} from "../../componentsPage/score/scoreTypeEnum";
import classNames from "classnames";

export class ScoreValues {
    constructor(scoreColor: string, scoreText?: string) {
        this.scoreColor = scoreColor;
        this.scoreText = scoreText;
    }
    scoreColor: string;
    scoreText?: string;
}

export class ScoreProps {
    svarmuligheder?: SvarmulighedModel[];
    svar?: KompetenceSvarModel;
    showText: boolean;
    hasComment?: string;
    svarVaerdi?: number;
    userDefinedScoreColor?: string;
    withDecimal?: boolean;
    scoreType: ScoreTypeEnum;
}

export const Score = ({userDefinedScoreColor, ...props}: ScoreProps) => {
    const [scoreValue, setScoreValue] = useState<ScoreValues | undefined>();
    const [scoreState, setScoreState] = useState<number>();

    const { isMobile } = useScreenResolution();

    const showToolTip = props.hasComment !== null;

    useEffect(() => {
        const getScoreValues = (score: number): ScoreValues | undefined => {
            if (userDefinedScoreColor)
                return new ScoreValues(userDefinedScoreColor, scoreValue?.scoreText);
            switch (props.scoreType) {
                case ScoreTypeEnum.ForloebEvalueringScore:
                    if (score === 0)
                        return new ScoreValues("#dcdcdc", Localizer.kompetenceEvalueringPage_IkkeBedoemt());
                    else if (score <= 1)
                        return new ScoreValues("#ee4e2f", Localizer.kompetenceEvalueringPage_UnderForventetNiveau());
                    else if (score > 1 && score <= 2)
                        return new ScoreValues("#f19b37", Localizer.kompetenceEvalueringPage_UnderForventetNiveau());
                    else if (score > 2 && score <= 3)
                        return new ScoreValues("#f3ea3f", Localizer.kompetenceEvalueringPage_ForventetNiveau());
                    else if (score > 3 && score <= 4)
                        return new ScoreValues("#c4de38", Localizer.kompetenceEvalueringPage_OverForventetNiveau());
                    else if (score > 4 && score <= 5)
                        return new ScoreValues("#91cf30", Localizer.kompetenceEvalueringPage_OverForventetNiveau());
                    else if (score > 5 && score <= 6)
                        return new ScoreValues("#27b01f", Localizer.kompetenceEvalueringPage_OverForventetNiveau());
                    return new ScoreValues("#27b01f", Localizer.kompetenceEvalueringPage_OverForventetNiveau());
                case ScoreTypeEnum.KompetenceEvalueringScore:
                    if (score === 0)
                        return new ScoreValues("#dcdcdc", Localizer.kompetenceEvalueringPage_IkkeBedoemt());
                    else if (score <= 1)
                        return new ScoreValues("#ee4e2f", Localizer.kompetenceEvalueringPage_UnderForventetNiveau());
                    else if (score > 1 && score <= 2)
                        return new ScoreValues("#f19b37", Localizer.kompetenceEvalueringPage_UnderForventetNiveau());
                    else if (score > 2 && score <= 3)
                        return new ScoreValues("#c4de38", Localizer.kompetenceEvalueringPage_ForventetNiveau());
                    else if (score > 3 && score <= 4)
                        return new ScoreValues("#61c128", Localizer.kompetenceEvalueringPage_OverForventetNiveau());
                    else if (score > 4 && score <= 5)
                        return new ScoreValues("#27b01f", Localizer.kompetenceEvalueringPage_OverForventetNiveau());
                    return new ScoreValues("#27b01f", Localizer.kompetenceEvalueringPage_OverForventetNiveau());
            }
        }


        if (props.svarVaerdi !== undefined) {
            setScoreValue(getScoreValues(props.svarVaerdi));
            setScoreState(props.svarVaerdi);
        }
        else if (props.svarmuligheder) {
            const svarVaerdi = props.svarmuligheder.find(x => x.id === props.svar?.svarmulighedId)?.svarVaerdi;
            setScoreValue(getScoreValues(svarVaerdi ?? 0));
            setScoreState(svarVaerdi);
        }
    }, [isMobile, props.scoreType, props.svar?.svarmulighedId, props.svarVaerdi, props.svarmuligheder, scoreValue?.scoreText, userDefinedScoreColor])

    //Calculate if the color is light or dark so we can add "White" class if the background is dark
    function hexIsLight(color: string) {
        const hex = color.replace('#', '');
        const colorRed = parseInt(hex.substring(0, 2), 16);
        const colorGreen = parseInt(hex.substring(2, 2 + 2), 16);
        const colorBlue = parseInt(hex.substring(4, 4 + 2), 16);
        const brightness = ((colorRed * 299) + (colorGreen * 587) + (colorBlue * 114)) / 1000;
        return brightness > 50;
    }

    return (
        <>
            {scoreValue &&
                <div className="flex-align-c padding-tbr-s">
                    <Tooltip
                        customStyle={{backgroundColor: scoreValue.scoreColor}}
                        className={classNames(props.withDecimal ? "wide-square" : "square", props.hasComment && "with-comment", scoreValue.scoreColor === "#ffffff" && "border d-flex flex-center", !hexIsLight(scoreValue.scoreColor) && "white-svar")}
                        title={showToolTip ? props.hasComment : ""}
                        dataHtml={showToolTip ? "true" : "false"}
                    >
                        {props.withDecimal ? scoreState?.toFixed(1) : scoreState}
                    </Tooltip>

                    {props.showText &&
                        <p className="subtle small">
                            {scoreValue.scoreText}
                        </p>
                    }
                </div>
            }
        </>
    );
}

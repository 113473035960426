import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {AccountService} from "../../account/accountService";
import {ApiResponse} from "../apiResponse";
import {GodkendPostModel} from "../forloeb/godkendPostModel";
import {LaegekursusGruppeModel} from "../laegekursus/laegekursusGruppeModel";
import {LaegekursusGruppeResponseModel} from "../laegekursus/laegekursusGruppeResponseModel";
import {GetLaegekursusGruppeTranslation} from "../../../core/sharedmodels/kursus/laegekursusGruppeTypeEnum";
import {SamletGodkendelsePaaAndetGrundlagInputModel} from "../../../pages/kurserPage/components/samletGodkendelsePaaAndetGrundlagInputModel";
import {logbogLaegekursusGruppeRequestModel} from "./logbogLaegekursusGruppeRequestModel";

export class LogbogLaegekursusGruppeApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = () => config.logbogApiUrl + "api/laegekursusGruppe/";
    private laegekursusGruppeSamletGodkendelseUrl = () => this.baseUrl() + "samletGodkendelse/";
    private laegekursusGruppeSamletGodkendelsePaaAndetGrundlagUrl = () => this.baseUrl() + "samletGodkendelsePaaAndetGrundlag/";
    private getLaegekursusGrupperByBrugerUrl = () => this.baseUrl() + "byBruger/";
    private getLaegekursusGrupperByBrugerKursusraekke = () => this.baseUrl() + "byBrugerKursusraekke/";
    private laegekursusGruppeFjernSamletGodkendelseUrl = () => this.baseUrl() + "fjernSamletGodkendelse/";
    private kursusRaekkeUrl = () => this.baseUrl() + "getKursusRaekke/";

    constructor(httpClientService: HttpClientService, userService: AccountService) {
        this.httpClientService = httpClientService;
        this.userService = userService;
    }

    //GET - not currently used
    async getKursusGrupper(brugerId: string): Promise<LaegekursusGruppeModel[]> {
        const url = this.getLaegekursusGrupperByBrugerUrl() + brugerId;
        const result = await this.httpClientService.Get<ApiResponse<LaegekursusGruppeResponseModel[]>>(url);
        return result.data.map(kursus => {
            return new LaegekursusGruppeModel(kursus)
        }).sort((a, b) => GetLaegekursusGruppeTranslation(a.kursusGruppeType).localeCompare(GetLaegekursusGruppeTranslation(b.kursusGruppeType)));;
    }

    //GET
    async getKursusGrupperByBrugerKursusraekke(brugerKursusraekkeId: string): Promise<LaegekursusGruppeModel[]> {
        const body = new logbogLaegekursusGruppeRequestModel(brugerKursusraekkeId, this.userService.getUser().UserId);
        const url = this.getLaegekursusGrupperByBrugerKursusraekke();
        const result = await this.httpClientService.Post<ApiResponse<LaegekursusGruppeResponseModel[]>, logbogLaegekursusGruppeRequestModel>(url, body);
        return result.data.map(kursus => {
            return new LaegekursusGruppeModel(kursus)
        }).sort((a, b) => GetLaegekursusGruppeTranslation(a.kursusGruppeType).localeCompare(GetLaegekursusGruppeTranslation(b.kursusGruppeType)));;
    }

    async getKursusRaekkeIdByMaalbeskrivelse(maalbeskrivelseId: string, brugerId: string): Promise<string> {
        const url = this.kursusRaekkeUrl() + maalbeskrivelseId + "/" + brugerId;
        const result = await this.httpClientService.Get<ApiResponse<string>>(url);
        return result.data;
    }

    async tildelSamletGodkendelse(contextId: string, uddannelsessted: string) {
        const body = new GodkendPostModel(contextId, this.userService.getUser().UserId, this.userService.getUser().CurrentRolleTitel, uddannelsessted);
        const url = this.laegekursusGruppeSamletGodkendelseUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, GodkendPostModel>(url, body);
        return result.data;
    }

    async fjernSamletGodkendelseLaegekursus(kursusGruppeId: string): Promise<string> {
        const url = this.laegekursusGruppeFjernSamletGodkendelseUrl() + kursusGruppeId;
        const result = await this.httpClientService.Get<ApiResponse<string>>(url);
        return result.data;
    }

    async givSamletGodkendelsePaaAndetGrundlagForLaegekursusGruppe(inputModel: SamletGodkendelsePaaAndetGrundlagInputModel): Promise<string> {
        const postModel = new GodkendPostModel(inputModel.laegekursusGruppeId, this.userService.getUser().UserId, this.userService.getUser().CurrentRolleTitel, inputModel.uddannelsessted, inputModel.godkendelseKommentar);
        const url = this.laegekursusGruppeSamletGodkendelsePaaAndetGrundlagUrl();
        const result = await this.httpClientService.Post<ApiResponse<string>, GodkendPostModel>(url, postModel);
        return result.data;
    }
}

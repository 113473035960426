import classNames from 'classnames';
import React, {useEffect} from 'react';
import Tooltip, { TooltipPlacement } from '../tooltips/Tooltip';
import { EmblaIcon } from '../emblaIcon/emblaIcon';

type CheckboxProps = {
    id: string,
    label?: string;
    labelInfoTooltipText?: string;
    inputHtml?: JSX.Element;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    value?: boolean;
    defaultValue?: boolean;
    additionalSliderClasses?: string;
}

export const CheckboxSwitch = ({additionalSliderClasses, ...props}: CheckboxProps) => {

    const sliderClasses = classNames('slider', additionalSliderClasses);
    const inputGroupClasses = classNames("input-group");

    return (
        <>
            <div className={inputGroupClasses} 
                style={{columnGap: "0.5rem"}}
            >
                {!!props.label &&
                    <>
                        <label className="mb-0 align-self-center" htmlFor={props.id}>
                                {props.label}
                        </label>
                        {props.labelInfoTooltipText !== undefined &&
                            <Tooltip className="text-truncate" title={props.labelInfoTooltipText}>
                                <EmblaIcon additionalClasses="subtle small" iconName={"info"}/>
                            </Tooltip>
                        }
                    </>
                }
                {props.inputHtml &&
                    <>
                        <label className="switch-container ml-auto align-self-center mb-0">
                            {props.inputHtml}
                            <div className={sliderClasses}></div>
                        </label>

                    </>
                }

                {!props.inputHtml &&
                    <>
                        <label className="switch-container">
                            <input type="checkbox" className="switch input-success" checked={props.value} defaultChecked={props.defaultValue} onChange={props.onChange} id={props.id} />
                            <div className={sliderClasses}></div>
                        </label>
                    </>
                }
            </div>
        </>
    );
}

import { useCallback, useEffect, useState } from "react";
import useStamdataApi from "core/hooks/useStamdataApi";
import MergeFieldData from "services/api/brevskabelon/models/MergeFieldData";
import { renderToString } from "react-dom/server";
import { NotificationModule } from "ditmer-embla";
import { BrevForloeb } from "core/sharedmodels/forloeb/brevForloebDto";
import { Localizer } from "infrastructure/localization/localizer";
import ForloebListBrevskabelonMergefield from "../mergefields/forloebListBrevskabelonMergefield";
import { allBrevForloebMergeFieldArray } from "../shared/brevForloebMergeFieldsType";
import { allBrevTekstMergeFieldArray } from "../shared/brevTekstMergeFieldsType";

type MergedDataString = {
    key: string;
    mergedData: string;
}

const allMergeFields = [...allBrevForloebMergeFieldArray, ...allBrevTekstMergeFieldArray];

const toForloebListString = (forloebs: BrevForloeb[]) => {
    const forloebListHtmlString = renderToString(<ForloebListBrevskabelonMergefield forloebs={forloebs} />);
    return forloebListHtmlString;
}

const useBrevMergeFields = (uddannelseslaegeId: string, markdown: string, onlyOnce=false) => {
    const {brevskabelonApi} = useStamdataApi();

    const [hasMergedInitialy, setHasMergedInitialy] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [mergedMarkdown, setMergedMarkdown] = useState<string | undefined>();
    const [forloebMergeFields, setForloebMergeFields] = useState<MergeFieldData<BrevForloeb>[]>([]);
    const [tekstMergeFields, setTekstMergeFields] = useState<MergeFieldData<string>[]>([]);
    const [mergedDataStrings, setMergedDataStrings] = useState<MergedDataString[]>([]);
    const [prevMergeFields, setPrevMergeFields] = useState<string[]>([]);

    const fetchBrevskabelonData = useCallback(async (mergeFields: string[]) => {
        return await brevskabelonApi.getMergeFieldData({ uddannelseslaegeId, mergeFields });
    }, [brevskabelonApi, uddannelseslaegeId]);

    const getMergeFieldsInMarkdown = useCallback(() => {
        return !markdown ? [] : allMergeFields.filter(field => markdown.includes(field));
    }, [markdown]);

    useEffect(() => {
        if(hasMergedInitialy && onlyOnce) return;

        const mergeFieldsInMarkdown = getMergeFieldsInMarkdown();
        if(mergeFieldsInMarkdown.length <= 0 || mergeFieldsInMarkdown.every(x => prevMergeFields.includes(x))) {
            setIsLoading(false);
            return;
        };
        
        setIsLoading(true);
        
        fetchBrevskabelonData(mergeFieldsInMarkdown)
            .then(({forloebMergeFields, tekstMergeFields}) => {
                setForloebMergeFields(forloebMergeFields);
                setTekstMergeFields(tekstMergeFields);
                setPrevMergeFields(mergeFieldsInMarkdown);
            })
            .catch(() => NotificationModule.showError(Localizer.letterPage_MergeFieldDataFetchError(), ""))
            .finally(() => {
                setIsLoading(false);
                if(onlyOnce)
                    setHasMergedInitialy(true);
            });

    }, [fetchBrevskabelonData, getMergeFieldsInMarkdown, hasMergedInitialy, onlyOnce, prevMergeFields]);

    useEffect(() => {
        setMergedDataStrings([
            ...tekstMergeFields.map(({key, data}) => ({key, mergedData: data[0]})),
            ...forloebMergeFields.map(({key, data}) => ({key, mergedData: toForloebListString(data)})),
        ]);
    }, [forloebMergeFields, tekstMergeFields]);

    const mergeFieldDataWithMarkdown = useCallback((markdownToMerge: string) => {
        
        if(getMergeFieldsInMarkdown().length <= 0) return markdownToMerge;

        mergedDataStrings.forEach(({key, mergedData}) => {
            markdownToMerge = markdownToMerge.replaceAll(key, mergedData);
        });

        return markdownToMerge;
    }, [mergedDataStrings, getMergeFieldsInMarkdown]);

    useEffect(() => {
        setMergedMarkdown(mergeFieldDataWithMarkdown(markdown));
    }, [markdown, mergeFieldDataWithMarkdown]);

    return {
        mergedMarkdown,
        isLoading
    }
}

export default useBrevMergeFields;

import { dateTimeStringFromApiToDate } from "index";
import { UddannelseTypeEnum } from "../stilling/uddannelseTypeEnum";
import { FravaerskodeEnum } from "./fravaerskoderEnum";

type SharedBrevForloebProps = {
    id: string;
    laegeInstitutionName: string;
    specialeName: string;
    afdelingName?: string;
    stillingNummer: string;
    attestationForTidInfotekst?: string;
    uddannelseType: UddannelseTypeEnum;
    fravaerskodeEnum?: FravaerskodeEnum;
    isFravaer: boolean;
    isManueltAfsluttet: boolean;
}

type BrevForloebDto = SharedBrevForloebProps & {
    startDate: string;
    endDate: string;
}

type BrevForloeb = SharedBrevForloebProps & {
    startDate: Date;
    endDate: Date;
}

const BuildFromDto = (dto: BrevForloebDto) : BrevForloeb => {
    return {
        ...dto,
        startDate: dateTimeStringFromApiToDate(dto.startDate),
        endDate: dateTimeStringFromApiToDate(dto.endDate)
    }
}

export type {
    BrevForloeb,
    BrevForloebDto
}

export {
    BuildFromDto
}

import React, {useEffect, useRef, useState} from 'react';
import {EmblaIcon} from "../emblaIcon/emblaIcon";
import './slideIn.scss';
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {
    removeSlideinFromState,
    slideInReducer,
    SlideInState,
    SlideInStateEnum,
    SlideInStateModel
} from "./slideInSlice";
import {Loading} from "../loading/loading";
import {ModalSize} from "./modalSize";
import {ModalType} from "./modalType";
import { useMediaQuery } from 'react-responsive';
import { mobileMaxWidthQuery } from 'core/layout/responsive';
import classNames from 'classnames';
import { EmblaIcons } from 'core/emblaIcons';

interface SlideInProps {
    id : string,
    title: string,
    bodyContent : React.ReactNode,

    modalSize?: ModalSize,
    modalType?: ModalType,
    defaultOpen? : boolean,

    actionFinishedCallback? : () => void,
    actionOnCloseCallback? : () => void,
    actionText? : string,

    headerContent?: React.ReactNode,
    additionalClasses?: string;
    additionalHeaderItems?: React.ReactNode,
    footerContent?: React.ReactNode,
    additionalFooterClasses?: string,
    showBackArrowAsClose?: boolean;
}

export const closeModal = (id? : string) => {
    if (id){
        $(`#${id}`).modal("hide");
    }
    else {
        $(`.modal`).modal("hide");
    }
}

export function SlideIn(props: SlideInProps) {
    const dispatch = useAppDispatch();
    const slideInState = useAppSelector(slideInReducer) as SlideInState;
    const [currentModalState, setCurrentModalState] = useState<SlideInStateEnum>(SlideInStateEnum.Opened);
    const modalSize = props.modalSize ?? ModalSize.sm;
    const isMobile = useMediaQuery(mobileMaxWidthQuery);

    useEffect(() => {
        return () => {
            dispatch(removeSlideinFromState(props.id));
        }
    }, []);

    const prevSlideInState = useRef<SlideInStateModel>();
    useEffect(() => {
        const thisSlideInState = slideInState.slideInState.find(s => s.slideInId == props.id) as SlideInStateModel;

        if(prevSlideInState.current === undefined || thisSlideInState === undefined || prevSlideInState.current?.state !== thisSlideInState.state) {

            if (thisSlideInState){
                setCurrentModalState(thisSlideInState.state);

                if (thisSlideInState?.state == SlideInStateEnum.Closing){
                    $(`#${props.id}`).modal("hide");

                    props.actionFinishedCallback?.();
                }

            }
        }

        prevSlideInState.current = thisSlideInState;
    }, [slideInState, props.id]);

    useEffect(() => {
        if (props.defaultOpen === true){
            $(`#${props.id}`).modal('show');
        }
    }, [props.defaultOpen, props.id])

    useEffect(() => {
        if (props.actionOnCloseCallback){
            $(`#${props.id}`).on('hidden.bs.modal', function (e) {
                props.actionOnCloseCallback?.();
            });
        }
    }, [props.actionOnCloseCallback, props.id])

    return (
        <div className={`modal fade ${classNames(props.modalType, props.additionalClasses)}`} id={props.id} role="dialog" aria-labelledby={props.id + "-label"}>
            <div className={"modal-dialog " + modalSize} role="document">
                <div className="modal-content">
                    <div className={classNames("modal-header", !!props.showBackArrowAsClose && "flex-start")}>
                        {!!props.showBackArrowAsClose &&
                            <button type="button" className="btn btn-default btn-icon close left-align" data-dismiss="modal" aria-label="Close">
                                <EmblaIcon iconName={EmblaIcons.ArrowLeft}/>
                            </button>
                        }
                        {!props.headerContent &&
                            <h4 id={props.id + "-label"} className="modal-title">
                                {props.title}
                            </h4>
                        }
                        {props.headerContent &&
                            <>
                                {props.headerContent}
                            </>
                        }
                        {!props.showBackArrowAsClose &&
                            <button type="button" className="btn btn-default btn-icon close " data-dismiss="modal"
                            aria-label="Close">
                                <EmblaIcon iconName={EmblaIcons.Close}/>
                            </button>
                        }
                        {props.additionalHeaderItems &&
                            <>
                                {props.additionalHeaderItems}
                            </>
                        }
                    </div>
                    <div className="modal-body">
                        <Loading isLoading={currentModalState === SlideInStateEnum.ActionOngoing} text={props.actionText}>
                            {props.bodyContent}
                        </Loading>
                    </div>

                    {props.footerContent &&
                        <div className={`modal-footer ${isMobile ? 'modal-mobile' : ''} ${props.additionalFooterClasses ?? ""}`}>
                            {props.footerContent}
                        </div>
                    }

                </div>
            </div>
        </div>
    );
}

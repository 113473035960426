import React from 'react'
import {Localizer} from '../../../infrastructure/localization/localizer';
import {FravaerskodeEnum, GetFravaerskodeTranslation} from "../../sharedmodels/forloeb/fravaerskoderEnum";
import InfoBadgePill from "../../components/badge/infoBadgePill";
import {EmblaIcons} from "../../emblaIcons";
import Tooltip from "../../components/tooltips/Tooltip";

interface ForloebCardBadgesProps {
    startDate: Date,
    endDate: Date,
    evalueretDato?: Date,
    godkendtDato?: Date,
    manueltAfsluttet: boolean,
    irrelevant: boolean,
    fravaer: boolean,
    forloebSkalEvalueres: boolean,
    fravaerskodeEnum?: FravaerskodeEnum,
}

export function ForloebCardBadges({
                                      startDate,
                                      endDate,
                                      evalueretDato,
                                      manueltAfsluttet,
                                      godkendtDato,
                                      irrelevant,
                                      fravaer,
                                      fravaerskodeEnum,
                                      forloebSkalEvalueres
                                  }: ForloebCardBadgesProps) {

    const todayDate = new Date().getWithoutTime();
    const forloebEndsWithin30Days = endDate < todayDate.addDays(30);

    return (
        <div className='flex-align-c'>
            <div className={"forloeb-badges"}>
                {fravaer && fravaerskodeEnum
                    ?
                    <InfoBadgePill badgeType={"badge-default"}
                                   badgeText={GetFravaerskodeTranslation(fravaerskodeEnum)}></InfoBadgePill>
                    :
                    <div>
                        {startDate > todayDate &&
                            <InfoBadgePill badgeType={"badge-default"}
                                           badgeText={Localizer.global_fremtidigtForloeb()}/>
                        }
                        {endDate < todayDate &&
                            <InfoBadgePill badgeType={"badge-default"}
                                           badgeText={Localizer.global_pastForloeb()}/>
                        }
                        {manueltAfsluttet &&
                            <InfoBadgePill badgeType={"badge-warning"}
                                           badgeText={Localizer.global_manueltAfsluttet()}/>
                        }
                        {(godkendtDato !== null && godkendtDato !== undefined && !irrelevant) &&
                            <InfoBadgePill badgeType={"badge-primary-light"}
                                           badgeText={`${Localizer.global_tidGodkendt()} ${godkendtDato?.dateWithoutTimeFormat(true)}`}
                                           emblaIconName={EmblaIcons.Success}/>
                        }
                        {(!godkendtDato && forloebEndsWithin30Days && !irrelevant) &&
                            <InfoBadgePill badgeType={"badge-danger"} badgeText={Localizer.global_ikkeGodkendt()}
                                           additionalClasses={"color-ikkegodkendt-ikkeevalueret"}/>
                        }
                        {evalueretDato && !irrelevant &&
                            <InfoBadgePill badgeType={"badge-primary-light"} badgeText={Localizer.global_evalueret()}
                                           emblaIconName={EmblaIcons.Success}/>
                        }
                        {(!evalueretDato && forloebEndsWithin30Days && forloebSkalEvalueres && !irrelevant) &&
                            <InfoBadgePill badgeType={"badge-danger"} badgeText={Localizer.global_ikkeEvalueret()}
                                           additionalClasses={"color-ikkegodkendt-ikkeevalueret"}/>
                        }
                        {irrelevant &&
                            <Tooltip title={Localizer.forloebpage_notRelevantDescription()}>
                                <InfoBadgePill badgeType={"badge-danger"}
                                               badgeText={Localizer.forloebpage_ikkeRelevant()}/>
                            </Tooltip>
                        }
                    </div>
                }

            </div>
        </div>
    )
}

import {CrudForloebInputModel} from "../../../core/componentsPage/forloeb/crudForloebInputModel";
import {FravaerskodeEnum} from "../../../core/sharedmodels/forloeb/fravaerskoderEnum";

export class ForloebEditPostModel {
    constructor(inputModel: CrudForloebInputModel) {
        this.startDatePlain = (inputModel.startDato as Date).dateToApiPostFormat();
        this.endDatePlain = (inputModel.slutDato as Date).dateToApiPostFormat();
        this.skalIndgaaIEvalueringsstatistik = inputModel.skalIndgaaIEvalueringsstatistik;
        this.irrelevant = inputModel.irrelevant;
        this.eksterntBrugerId = inputModel.brugerId;
        this.senesteDatoForEvaluering = inputModel.skalIndgaaIEvalueringsstatistik ? (inputModel.senesteDatoForEvaluering as Date).dateToApiPostFormat() : undefined;
        this.stillingId = inputModel.stillingId;
        this.id = inputModel.id;
        this.seAlleKompetencer = inputModel.seAlleKompetencer;
        this.erFravaer = inputModel.erFravaer;
        this.fravaerskode = inputModel.fravaerskode;
        this.infoTekst = inputModel.infoTekst;
    }

    public id: string;
    public startDatePlain: string;
    public endDatePlain: string;
    public senesteDatoForEvaluering?: string;
    public skalIndgaaIEvalueringsstatistik: boolean;
    public irrelevant: boolean;
    public seAlleKompetencer: boolean;
    public stillingId: string;
    public eksterntBrugerId: string;
    public erFravaer?: boolean;
    public fravaerskode?: FravaerskodeEnum;
    public infoTekst?: string;
}

import { ForloebOverviewConstants } from "./forloebOverviewConstants";

type Month = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type ScrollInput<T> = {
    refObject: React.RefObject<T>
}

type ForloebOverviewChartScrollInput = ScrollInput<HTMLDivElement> & {
    year: number,
    month: Month,
    minYear: number,
}

const November: Month = 11;
const monthsInYear: number = 12;

const scrollToYearInForloebOverviewChart = (input: ForloebOverviewChartScrollInput) => {
    if(!input.refObject.current) return;

    const chartHeaderDiv = input.refObject.current.querySelector<HTMLDivElement>(ForloebOverviewConstants.forloebChartHeaderSelector);
    if(!chartHeaderDiv) return;

    const yearDiff = input.year - input.minYear;

    const amountPixelPerYear = chartHeaderDiv.offsetWidth;
    const amountPixelPerMonth = amountPixelPerYear / monthsInYear;

    const pixelsToYearContainerFromChart = amountPixelPerYear * yearDiff;
    const pixelsToMonthFromYearContainer = amountPixelPerMonth * input.month;

    const amountPixelsToScrollFromLeft = pixelsToYearContainerFromChart + pixelsToMonthFromYearContainer
    input.refObject.current.scrollTo({
        left: amountPixelsToScrollFromLeft,
    });
}

const resetScrollToYearInForloebOverviewChart = (input: ForloebOverviewChartScrollInput) => {
    if(!input.refObject.current) return;
    input.refObject.current.scrollTo({
        left: 0,
    });
}

export {
    scrollToYearInForloebOverviewChart,
    resetScrollToYearInForloebOverviewChart,
    November,
    monthsInYear
};

export type {
    Month,
    ScrollInput,
    ForloebOverviewChartScrollInput
};

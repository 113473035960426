import {BrugerListFilter} from "../../../pages/bruger/brugerListFilter";

export class userListExportRequestModel {
    constructor(inputModel: BrugerListFilter, search?: string, order?:string) {
        this.regionIds = inputModel.regionIds;
        this.uddannelseIds = inputModel.uddannelseIds;
        this.specialeIds = inputModel.specialeIds;
        this.omraadeIds = inputModel.omraadeIds;
        this.praksisIds = inputModel.praksisIds;
        this.sygehusIds = inputModel.sygehusIds;
        this.afdelingIds = inputModel.afdelingIds;
        this.rolleTitels = inputModel.rolleTitels;
        this.search = search;
        this.order = order;
    }

    regionIds: string[] = [];
    uddannelseIds: string[] = [];
    specialeIds: string[] = [];
    omraadeIds: string[] = [];
    praksisIds: string[] = [];
    sygehusIds: string[] = [];
    afdelingIds: string[] = [];
    rolleTitels: Number[] = [];
    search?: string;
    order?: string;
}

import { LogbogPersonaleUserSimpleResponseModel } from "services/api/logbogUser/logbogPersonaleUserSimpleResponseModel";
import { UserSimpleModel } from "./userSimpleModel";

export type PersonaleUserSimpleModel = {
  isFavorite: boolean;
} & UserSimpleModel;

export const MapFromPersonaleUserResponsModel = (responseModel: LogbogPersonaleUserSimpleResponseModel): PersonaleUserSimpleModel => {
  return {
    ...UserSimpleModel.FromLogbogResponseModel(responseModel),
    isFavorite: responseModel.isFavorite
  } as PersonaleUserSimpleModel;
}
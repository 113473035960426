import {LogbogUserInfoResponse} from "../../../services/api/logbogUser/logbogUserInfoResponse";
import {KbuRundeEnum} from "../../sharedmodels/user/kbuRundeEnum";
import {UserRolleModel} from "./userRolleModel";
import {VejledertilknytningerModel} from "./vejledertilknytningerModel";
import {ForloebAdgangeModel} from "./forloebAdgangeModel";
import { nullableDateTimeStringFromApiToDate } from "index";

export class UserProfileModel {
    constructor(userInfoResponse? : LogbogUserInfoResponse) {
        if (userInfoResponse){
            this.userId = userInfoResponse.userId;
            this.authorizationId = userInfoResponse.authorizationId;
            this.culture = userInfoResponse.culture;
            this.initialer = userInfoResponse.initialer;
            this.firstName = userInfoResponse.firstName;
            this.lastName = userInfoResponse.lastName;
            this.phone = userInfoResponse.phone;
            this.workPhone = userInfoResponse.workPhone;
            this.email = userInfoResponse.email;
            this.workEmail = userInfoResponse.workEmail;
            this.foedselsdato = userInfoResponse.foedselsdato;
            this.harGivetSamtykke = userInfoResponse.harGivetSamtykke;
            this.address = userInfoResponse.address;
            this.postnummer = userInfoResponse.postnummer;
            this.kbuRundePeriode = userInfoResponse.kbuRundePeriode;
            this.kbuRundeAar = userInfoResponse.kbuRundeAar;
            this.roller = userInfoResponse.roller?.map((rolle) => UserRolleModel.FromResponseModel(rolle));
            this.vejledertilknytninger = userInfoResponse.vejledertilknytninger?.map((tilknytning) => VejledertilknytningerModel.FromResponseModel(tilknytning));
            this.forloebAdgange = userInfoResponse.forloebAdgange?.map((forloebadgnag) => ForloebAdgangeModel.FromResponseModel(forloebadgnag));
            this.gdprLastFetchAt = nullableDateTimeStringFromApiToDate(userInfoResponse.gdprLastFetchAt);
            this.profileImageId = userInfoResponse.profileImageId;
        }
    }

    public userId: string;
    public authorizationId: string;
    public culture: string;
    public initialer: string;
    public firstName: string;
    public lastName: string;
    public phone: string;
    public workPhone: string;
    public email: string;
    public workEmail: string;
    public foedselsdato: string;
    public harGivetSamtykke: boolean;
    public address: string;
    public postnummer: string;
    public kbuRundePeriode?: KbuRundeEnum;
    public kbuRundeAar?: number;
    public roller?: UserRolleModel[];
    public vejledertilknytninger?: VejledertilknytningerModel[];
    public forloebAdgange?: ForloebAdgangeModel[];
    public gdprLastFetchAt?: Date;
    public profileImageId?: string;

    public getFullName = () => {
        return this.firstName + " " + this.lastName;
    }
}

import {HttpClientService} from "services/httpClient/httpClientService";
import config from "config/config";
import {AccountService} from "../../account/accountService";
import StillingDisplayInfo, { BuildStillingDisplayInfo, StillingDisplayInfoResponseModel } from "../logbogStilling/stillingInfo";
import { ApiResponse } from "../apiResponse";

export class StamdataStillingApi {
    private httpClientService: HttpClientService;
    private userService: AccountService;

    private baseUrl = () => config.stamdataApiUrl + "api/stilling/";

    public stillingDatalistUrl = () => this.baseUrl() + "datalist";
    public stillingDisplayInfoUrl = () => this.baseUrl() + "display/";

    constructor() {
        this.httpClientService = new HttpClientService();
        this.userService = new AccountService();
    }

    async getStillingDisplayInfo(id: string, forloebId: string): Promise<StillingDisplayInfo> {
        const url = this.stillingDisplayInfoUrl() + id + "/" + forloebId;
        const result = await this.httpClientService.Get<ApiResponse<StillingDisplayInfoResponseModel>>(url);
        return BuildStillingDisplayInfo(result.data);
    }

}

import {FileMetadata, FromFileMetaDataResponseModel} from "../fileMetaData/fileMetaData";
import {
    LogbogAnsoegningsnotaterResponseModel
} from "../../../services/api/logbogNotat/logbogAnsoegningsnotaterResponseModel";
import {NotatType} from "./notatType";

export class AnsoegningsnotatModel {
    fileMetadatas: FileMetadata[];
    eftersendt: boolean;
}

export const AnsoegningsnotatFromResponseModel = (responseModel: LogbogAnsoegningsnotaterResponseModel) : AnsoegningsnotatModel => {
    const model = new AnsoegningsnotatModel();
    model.eftersendt = responseModel.eftersendt;
    model.fileMetadatas = responseModel.fileMetadatas.map(x => FromFileMetaDataResponseModel(x, NotatType.Ansoegning));
    return model;
}

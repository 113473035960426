import {DeaktiveretForloebDataResponseModel} from "../../../services/api/forloeb/deaktiveretForloebDataResponseModel";
import {VejledersamtaleModel} from "../../../pages/vejledning/vejledersamtaler/vejledersamtaleModel";
import {UddannelsesplanModel} from "../../../pages/vejledning/uddannelsesplan/uddannelsesplanModel";
import {GodkendelseInfoModel} from "../godkendelse/godkendelseInfoModel";
import {BasicNotatModel, FromResponseModel} from "../notat/basicNotatModel";
import {LaegekompetenceMeta} from "../kompetence/laegekompetenceMeta";

export class DeaktiveretForloebDataModel {
    public static FromResponseModel(responseModel: DeaktiveretForloebDataResponseModel): DeaktiveretForloebDataModel {
        const model = new DeaktiveretForloebDataModel();
        model.id = responseModel.id;
        model.vejledersamtaler = responseModel.vejledersamtaler?.map(vejledersamtale => {
            return VejledersamtaleModel.FromResponseModel(vejledersamtale)
        });
        model.uddannelsesplaner = responseModel.uddannelsesplaner?.map(uddannelsesplan => {
            return UddannelsesplanModel.FromResponseModel(uddannelsesplan)
        });
        model.forloebNotater = responseModel.forloebNotater?.map(notat => {
            return FromResponseModel(notat)
        });
        model.forloebLaegekompetencer = responseModel.forloebLaegekompetencer?.map(laegekompetence => {
            return LaegekompetenceMeta.FromResponseModel(laegekompetence)
        });
        model.godkendelser = responseModel.godkendelser?.map(godkendelse => {
            return new GodkendelseInfoModel(godkendelse)
        });
        return model;
    }

    public id: string;
    public vejledersamtaler: VejledersamtaleModel[];
    public uddannelsesplaner: UddannelsesplanModel[];
    public forloebNotater: BasicNotatModel[];
    public forloebLaegekompetencer: LaegekompetenceMeta[];
    public godkendelser: GodkendelseInfoModel[];
}
